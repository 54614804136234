import { TypeAuthorized } from "components/authorized";
import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
import { LoadingIndicator } from "react-select/src/components/indicators";
import store from "store/store";
import styled from "styled-components";
import { BASE_URL, fallbackMapCenter, history } from "../../../..";
import { IconButton, LoaderButton, OutlineButton, Row } from "../../../../components/barbagli/common";
import { Search } from "../../../../components/barbagli/icons";
import { AsyncSelect } from "../../../../components/barbagli/select";
import { Title } from "../../../../components/barbagli/text";
import { TextInput } from "../../../../components/barbagli/text_input";
import { mapIcon } from "../../../../components/icons";
import { Leafleet } from "../../../../components/leafleet_map/leafleet_map";
import { LoaderComponent } from "../../../../components/loading_widget";
import { Condominium, isEnabled } from "../../../../models/barbagli/condominium";
import { GeoJSONPoint } from "../../../../models/geo_json";
import { User } from "../../../../models/user";
import { remoteFetchData } from "../../../../repsitory/generic_repository";
import { fetchPaginatedUsers } from "../../../../repsitory/user_repository";
import { gpsFromGeoJsonPoint, gpsFromGeoJsonPointRaw } from "../../../../service/address_from_coordinates";
import { gray } from "../../../../utils/colors";
import { geolocate } from "../condominium_view_model";

type Props = {
    condominium: Condominium
    onEdit: (c: Condominium) => void
    onActivation: () => Promise<true | string>
}

export function CondominiumDataView(props: Props) {
    const intl = useIntl();
    const alert = useAlert();
    const [loading, setLoading] = useState(false);

    const [admin, setAdmin] = useState<User | null>(null);

    useEffect(() => {
        if (props.condominium.managedBy) {
            remoteFetchData(`/api/v1/user/${props.condominium.managedBy}`).then((u: any) => {
                if (typeof u !== "string") setAdmin(u?.attributes)
            })
        } else {
            setAdmin(null);
        }

    }, [props.condominium.managedBy])

    return <div className="d-flex flex-column">
        <Leafleet
            key={JSON.stringify(props.condominium.node_id) + JSON.stringify(props.condominium.coordinates)}
            height={335}
            map_id={`${props.condominium.node_id}-condominium-data`}
            editableItem={{ ...props.condominium.coordinates!, properties: { ...props.condominium.coordinates.properties, markerColor: "#666666" } }}
            fallbackMapCenter={fallbackMapCenter}
            onEdit={async (dot) => {
                if (dot) {
                    const addr = await gpsFromGeoJsonPointRaw(dot as GeoJSONPoint);
                    if (typeof addr === "string") {
                        props.onEdit({ ...props.condominium, coordinates: dot as GeoJSONPoint, });
                        return;
                    }

                    props.onEdit({
                        ...props.condominium, coordinates: dot as GeoJSONPoint, data: {
                            ...props.condominium.data,
                            postalCode: addr.postcode ?? '',
                            city: `${(addr.city ?? addr.village ?? addr.town ?? '')} ${(addr.city_district ? `(${addr.city_district})` : '')}`,
                            province: addr.county ?? '',
                            address: `${addr.house_number ? `${addr.house_number}, ` : ''}${addr.road ?? ``} ${addr.quarter ?? ''}`
                        }
                    });

                }
            }}
        />
        <Title className="mt-3">{intl.messages["data"].toString()}</Title>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["condo_name"].toString()}
                    enabled={!isEnabled(props.condominium)}
                    value={props.condominium.data.cond_name ?? ''}
                    onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, cond_name: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["vat_number"].toString()}
                    value={props.condominium.data.vatNumber}
                    enabled={!isEnabled(props.condominium)}
                    onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, vatNumber: v } })}
                />
            </div>

            <div className="col-md-4 d-flex">
                <div style={{ flexGrow: 1 }}>
                    <TextInput
                        label={intl.messages["condo_address"].toString()}
                        enabled={!isEnabled(props.condominium)}
                        value={props.condominium.data.address}
                        onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, address: v } })}
                    />
                </div>
                {loading && <div style={{ marginTop: 30 }}><LoaderComponent width={24} height={24} /></div>}
                {!loading && <IconButton style={{ marginTop: 12, paddingTop: 10 }} onClick={() => geolocate(props.condominium, props.onEdit, intl.messages as any, alert, setLoading)}>
                    <div className="d-flex flex-column align-items-center">
                        {mapIcon({ color: gray })}
                        <p style={{ fontSize: 10 }}>{intl.messages["geolocate"]}</p>
                    </div>
                </IconButton>}
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    enabled={!isEnabled(props.condominium)}
                    label={intl.messages["cond_city"].toString()}
                    value={props.condominium.data.city}
                    onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, city: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["condo_province"].toString()}
                    enabled={!isEnabled(props.condominium)}
                    value={props.condominium.data.province}
                    onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, province: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["cond_postal_code"].toString()}
                    enabled={!isEnabled(props.condominium)}
                    value={props.condominium.data.postalCode}
                    onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, postalCode: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    enabled={!isEnabled(props.condominium)}
                    label={intl.messages["nav_id"].toString()}
                    value={props.condominium.data.navId}
                    onChange={(v) => props.onEdit({ ...props.condominium, data: { ...props.condominium.data, navId: v } })}
                />
            </div>
        </div>


        <div className="row mt-4">
            <div className="col-md-6">
                <Title className="">{intl.messages["administrator"].toString()}</Title>

            </div>
            {<div className="col-md-6 d-flex flex-wrap">
                <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                    <AsyncSelect
                        fetchOptions={(partialInput) => {
                            return fetchPaginatedUsers(`/api/v1/user?username=${partialInput}`)(1, 8).then((r) => {
                                if (typeof r !== "string") return r.sublist
                                return [] as User[];
                            }) as Promise<User[]>
                        }}
                        isMulti={false}
                        selectedOptions={[]}
                        style={{ marginTop: 8 }}
                        placeholder={(intl.messages["select_admin"] ?? "select_admin").toString()}
                        valueMapper={(u: any) => u.username}
                        enabled
                        onChange={([user]: any) => {
                            // console.log(user)
                            props.onEdit({ ...props.condominium, managedBy: user.userId })
                        }}
                    />

                    <div className="mt-2 ml-3">

                        <OutlineButton onClick={() =>
                            history.push(`/users/new?condominium_id=${props.condominium.id}`)
                        }>{intl.messages["create_admin"].toString()}</OutlineButton>
                    </div>
                </TypeAuthorized>

            </div>}

        </div>

        <div className="row" >
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["first_name"].toString()}
                    value={admin?.firstName ?? ''}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["last_name"].toString()}
                    value={admin?.lastName ?? ''}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["email"].toString()}
                    value={admin?.email ?? ''}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["business_name"].toString()}
                    value={admin?.businessName?.toString() ?? ''}
                />
            </div>
        </div>
        <div className="row">

            <div className="col-md-12">
                <TextInput
                    label={intl.messages["partner"].toString()}
                    value={props.condominium.partners?.toString() ?? ''}
                />
            </div>
        </div>


        <Row>

            {props.condominium.id && <>
                <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                    <LoaderButton
                        onClick={() => {

                            return props.onActivation().then((r) => {
                                if (typeof r !== "string") props.onEdit({ ...props.condominium, status: isEnabled(props.condominium) ? "disabled" : "enabled" })
                                return r;
                            })
                        }}
                    >{intl.messages[isEnabled(props.condominium) ? "disable" : "enable"].toString()}</LoaderButton>
                </TypeAuthorized>


                <Row style={{ flexGrow: 1, justifyContent: "flex-end" }}>
                    <AA className="outline-button" href={BASE_URL + `/api/v1/condominium/${props.condominium.id}/invite?token=${store.getState().auth.token}`}> Esporta inviti</AA>
                    <AA className="outline-button" href={BASE_URL + `/api/v1/condominium/${props.condominium.id}/invite?token=${store.getState().auth.token}&regenerate=true`}> Ricrea inviti</AA>
                </Row>


            </>}

        </Row>


    </div>
}

const AA = styled.a`
    color: "#666"
`