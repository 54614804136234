import { Condominium, CondominiumNode } from "../../../../models/barbagli/condominium";
import { Attributes } from "../../../../models/meter";
import { associateCondominiumMeter } from "../../../../repsitory/barbagli/condominium_repository";
import { updateMeter } from "../../../../repsitory/barbagli/meter_repository";
import { condominiumSubCondominiumAndImmobile } from "../meter_gateways_association_view_model";



export async function associateMeterToCondominiumNode(node: CondominiumNode, condominium: Condominium, meter: Attributes): Promise<string | true> {
    if(!meter.id) return "meter has not id";
    const data = condominiumSubCondominiumAndImmobile(node, condominium)
    return associateCondominiumMeter(meter.id!, condominium.id!, data?.subCondominiumId, data?.immobileId);
} 