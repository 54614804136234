import {Dashboard} from "../models/dashboard/dashboard";
import {get, httpDelete, post, putRaw, remoteFetchData} from "./generic_repository";

export async function createUpdateDashboard(d: Dashboard): Promise<{ id: string } | string> {
  console.log("Persisting dashboard")
  const data = {
    "data": {
      "attributes": {
        "dashboard": JSON.stringify({...d, error: undefined}),
        "name": d.name
      },
      "type": "dashboard",
    }
  };

  try {
    let id;

    if(d.id !== undefined) {
      id = d.id;
      (await putRaw(data,  `/api/v1/user/dashboard/${d.id}`));
    } else {
      id = (await post(data,  `/api/v1/user/dashboard`)).data.id;
    }

    return { id };
  } catch (e) {
    // console.log(e);
    return e.toString();
  }
}

export async function deleteDashboard(d: Dashboard): Promise<boolean> {
  return (await httpDelete(`/api/v1/user/dashboard/${d.id}`)).status === 204;
}

export async function fetchDashboards(): Promise<Dashboard[] | string> {
  const result = await remoteFetchData<{attributes: {dashboard: string}, id: number}[]>("/api/v1/user/dashboard");

  if(typeof result !== "string") {
    const dashboards: Dashboard[] = result.map((d) => {
      return {...(JSON.parse(d.attributes.dashboard) as Dashboard), id: d.id};
    });
    return dashboards;
  } else {
    return result;
  }

}

const debounce = (func: any, wait: any) => {
  let timeout: any;

  return function executedFunction(...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const refreshWebSocket = debounce(() =>  get("/api/v1/stats"), 500);

