import React, { useState } from "react"
import { useAlert } from "react-alert"
import { mkReadingFromDateAndValue, Reading } from "../../models/barbagli/reading"
import { Meter, Attributes } from "../../models/meter"
import { createSelfReading } from "../../repsitory/barbagli/reading_repository"
import { DatePickerInput } from "../datepicker"
import { Column, OutlineButton, PrimaryButton, Row } from "./common"
import { showModal } from "./modal"
import { H4, P, Title } from "./text"
import { TextInput } from "./text_input"
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';
import { alert_options } from "../.."
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { AnonymousSubject } from "@reactivex/rxjs/dist/package/internal/Subject"

type Props = {
    meter: Attributes,
    intl: Record<string, string>
    onClose?: any,
}

type State = {
    value?: string,
    date: string
}

export function SelfReadingComponent(props: Props) {
    const [state, setState] = useState<State>({date: (new Date()).toISOString() })
    const alert = useAlert();
    return <Column>
        <Title>{props.intl["self_reading"]}</Title>
        <Column style={{marginTop: 30, marginBottom: 30}}>
            <H4>{props.intl["serial_number"]}</H4>
            <P>{props.meter.serialNumber}</P>
        </Column>

        <Column  style={{marginBottom: 30}}> 
            <H4>{props.intl["mechanical_serial_number"]}</H4>
            <P>{props.meter.mechanicalSerialNumber}</P>
        </Column>

          <TextInput
            value={state.value ?? ""}
            label={props.intl["value_m3"].toString().toUpperCase()}
            type={"number"}
            onChange={(s) => { setState({ ...state, value: s }) }}
          />

          <DatePickerInput
            enabled
            labelStyle={{marginBottom: 4} as any}
            label={props.intl["date"].toString().toUpperCase()}
            value={new Date(state.date ?? (new Date()).toISOString())}
            onChange={(d) => { setState({ ...state, date: d.toISOString() }) }}
          />

          <Row style={{flexDirection: "row-reverse", paddingTop: 16}}>
              <PrimaryButton
              onClick={() => {
                const validation = validate(state);
                if(typeof validation === "string") return alert.error(props.intl[validation] ?? validation);
                createSelfReading(mkReadingFromDateAndValue(Number(state.value) ?? 0.0, state.date, props.meter.id!.toString()),)
                .then((r: any) => {
                    if(typeof r === "string") return alert.error(props.intl[r] ?? r);
                    
                    if(r.error) return alert.error(props.intl[r.error] ?? r.error)
                    alert.show(props.intl["self_reading_success"]);
                    //props.onClose && props.onClose();
                })
              }}
              >{props.intl["send"]}</PrimaryButton>
                {props.onClose && <OutlineButton onClick={() => props.onClose()}>{props.intl["close"]}</OutlineButton>}
          </Row>
       
    </Column>
}

function validate(state: State): string | true {
    if(state.value && state.value != "") return true;
    return "value_must_be_set"
}

export function showSelfReadingComponent(props: Props) {
    showModal((onClose, intl) =>
    <AlertProvider template={AlertTemplate} {...alert_options}>
            <SelfReadingComponent
        meter={props.meter}
        onClose={onClose}
        intl={intl}
    />
    </AlertProvider> 

    , props.intl)
}