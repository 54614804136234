import { Column } from 'components/barbagli/common';
import { SortableTableView } from 'components/barbagli/sortable_table_view';
import { H3 } from 'components/barbagli/text';
import { Gateway } from 'models/gateway';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { fetchPaginatedCollection, remoteFetchData } from 'repsitory/generic_repository';
import { deepCopy } from 'utils/deep_copy';
import { showReadingMetaModal } from '../readings_index/reading_meta_modal';

export function GatewayReadings(props: {gateway: Gateway}) {
    const whitelistedMeterIdentifier = useMemo(() => new Set(props?.gateway?.meta?.white_list ?? []), [props?.gateway?.meta?.white_list])
    const intl = useIntl();
    const [nms, setNms] = useState<string[]>([]);

    return <><SortableTableView
        key={JSON.stringify(props.gateway)}
        perPage={8000}
        fetchCollection={
            async (_page, _perPage) => {

                const whitelist = await remoteFetchData<any>(`/api/v1/meter?gateway_identifier=${props.gateway.identifier}&per_page=8000`);
                const whiteListSerialNumber = new Set(whitelist.map((m: any) => m.attributes.serialNumber))
                const metersNotInWhitelist = new Set(deepCopy(whitelist.map((m: any) => m.attributes.serialNumber)))
                
                // console.log(filter)
                const r = await remoteFetchData<any>(`/api/v1/gateway/${props.gateway.id!}/reading`);
                if(typeof r == "string") return r;

                const l = r.attributes.readings as GatewayReading[];

                

                for(const i of l) {
                    const serial = i.meter_serial_number
                    metersNotInWhitelist.delete(serial)
                    if(whiteListSerialNumber.has(i.meter_serial_number)) {
                        // @ts-ignore
                        i["white_list"] = true;
                    }
                }

                setNms(Array.from(metersNotInWhitelist as any))

                return {
                    sublist: l.map((i) => ({...i, white_list: whiteListSerialNumber.has(i.meter_serial_number)})),
                    count: l.length,
                    perPage: 8000,
                    page: 1
                }
            
            }}
        translated
        onTap={(c) => showReadingMetaModal({ intl: intl.messages as any, content: c.raw })}
        itemsPreFormatter={(c: GatewayReading) => {
            const cc: any = deepCopy(c);
            delete cc["sub_condominium_id"]
            delete cc["raw"]
            delete cc["gateway"]
            delete cc["id"]
            delete cc["meter_id"]
            cc["class"] = cc["meter_class"] 
            delete cc["meter_class"] 
            delete cc["meter_identifier"]
            delete cc["gateway_identifier"]
            delete cc["identifier"]
            delete cc["meta"]
            cc["date_gateway"] = cc["date"]
            cc["date_meter"] = cc["read_time"]
            delete cc["read_time"]
            delete cc["date"]
            delete cc["condominium_id"]
            delete cc["immobile_id"]
            delete cc["__meta__"]
            delete cc["meter"]
            delete cc["value"]
            cc["values"] = Object.values(cc["values"] ?? {}).map((it: any) => (Math.round((it + Number.EPSILON) * 100) / 100)).join("; ")
            
            return cc;
        }}
        showPaginationElement={false}
        orderableColumns={[
            "inserted_at",
            "class",
            "condominium",
            "sub_condominium",
            "immobile",
            "address"
        ]}
    />
    {nms.length > 0 && <Column style={{marginTop: 40}}>
        <H3>Meter in white list senza letture Gateway</H3>
        {nms.map((a: any) => <p key={a}>{a}</p>)}
    </Column>}
    </>
}

type GatewayReading =  {
    "sub_condominium_id": string,
    "sub_condominium": string,
    "raw": string,
    "meter_serial_number": string,
    "meter_id": number,
    "meter_class": string,
    "meta": {
      "gateway_received_datetime": string
    },
    "immobile_id": string,
    "immobile": string,
    "identifier": string,
    "meter_identifier": string,
    "gateway": {
      "__owner__": "Elixir.SwarmBackend.GatewayReadings.GatewayReading",
      "__field__": "gateway",
      "__cardinality__": "one"
    },
    "floor": string,
    "flat_number": string,
    "date": string,
    "condominium_id": number,
    "condominium": string
  }