import React from "react";
import {useIntl} from 'react-intl';

// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../components/job_schedule_form/datepicker-override.css";
import styled from "styled-components";
import { H4 } from "./barbagli/text";
import { AnyRecord } from "dns";


type Props = {
    value: Date,
    label: string
    onChange: (d: Date) => void
    enabled?: boolean
    labelStyle?: AnyRecord,
    style?: any
    showTimeSelect?: boolean
}

const Container = styled.div`
display: flex;
flex-direction: column;
`

export function DatePickerInput(props: Props) {

  const intl = useIntl();

  return <Container style={{...(props.style || {})}}>
      <H4 style={{marginBottom: 2, ...(props.labelStyle ?? {})}}>{props.label}</H4>
      <DatePicker
            disabled={!props.enabled}
            className={"form-control date-picker-override"}
            selected={props.value}
            onChange={ (date: Date) => props.onChange(date)}
            showTimeSelect= {props.showTimeSelect ?? true}
            dateFormatCalendar={"dd-MM-yyyy"}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
            dateFormat={props.showTimeSelect ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"}
          />
  </Container>
}