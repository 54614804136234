import { Column, OutlineButton, PrimaryButton, Row } from 'components/barbagli/common';
import { AsyncSelect, Select } from 'components/barbagli/select';
import { H4 } from 'components/barbagli/text';
import { TextInput } from 'components/barbagli/text_input';
import { Divider } from 'components/divider';
import { ClearIcon } from 'components/icons';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { fetchPaginatedUsers } from 'repsitory/user_repository';
import styled from 'styled-components';
import { ConsumptionRule } from './meters_notification_state';

type Props = {
    notification: ConsumptionRule | null,
     onCreate: (c: ConsumptionRule) => void,
      nextIndex: number
}

export function showNotificationModal(props: Props) {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) =>
            <div className={"configuration-editor p-3"}>
                <div className={"row d-flex flex-row justify-content-between"}>
                    <Dialog
                        onClose={onClose}
                        {...props}
                    />
                </div>
            </div>

    });
}


const LC = styled.div`
padding-top: 16px;
width: 50%;
`

function Dialog(props: Props & { onClose: any }) {

    const [notification, setNotification] = useState<ConsumptionRule>(props.notification ?? { expression: ">", value: 50, index: props.nextIndex, recipients: [], text: "" })

    return <Column style={{width: "100%", padding: "0.5rem"}}>
        <div className={"row d-flex flex-row justify-content-between p-1"}>
            <h2 className={"dashboard-editor-title"}>{"Crea Notifica"}</h2>
        </div>
        <Row>
            <LC>
                <H4>Notifica</H4>
            </LC>
            <AsyncSelect
                    fetchOptions={(partialInput) => {
                        return fetchPaginatedUsers(`/api/v1/user?username=${partialInput}`, true)(1, 8).then((r) => {
                            if(typeof r !== "string") return [ "administrator", "tenant", ...r.sublist.map((u) => u.username), ]
                            return [] as string[];
                        }) as Promise<string[]>
                    }}
                    isMulti
                    cached
                    selectedOptions={notification.recipients}
                    style={{marginTop: 8}}
                    placeholder={""}
                    valueMapper={(u: string) => {
                        if(u == "administrator") return "Amministratore"
                        if(u == "tenant") return "Occupante"
                        return u
                    }}
                    enabled
                    onChange={(users: string[]) => { 
                        const u = users.map((u) => {
                            if(u == "Amministratore") return "administrator"
                            if(u == "Occupante") return "tenant"
                            return u;
                        })
                        setNotification({...notification, recipients: u})
                     }}
                />
        </Row>

        <Row style={{marginTop: 16}}>
            <LC>
                <H4>Se il consumo</H4>
            </LC>
            <Select 
            enabled
            isMulti={false}
            selectedOptions={[notification.expression]}
            availableOptions={[">", "<", ">=", "<="]}
            onChange={([value]) => {
                setNotification({...notification, expression: (value ?? ">") as any})
            }}
            />
        </Row>
        <Row>
            <LC>
                <H4>A</H4>
            </LC>
            <TextInput type="number" value={notification.value.toString()} onChange={(v) => setNotification({...notification, value: Number(v)})}></TextInput>
        </Row>

        <Row>
            <LC>
                <H4>Testo</H4>
            </LC>
            <TextInput type="string" value={notification.text} onChange={(v) => setNotification({...notification, text: v})}></TextInput>
        </Row>

        <Row style={{flexDirection: "row-reverse"}}>
            <OutlineButton onClick={props.onClose}>Chiudi</OutlineButton>
            <PrimaryButton onClick={() => {
                props.onClose();
                return props.onCreate(notification);
            }}>Salva</PrimaryButton>
        </Row>
        </Column>


}