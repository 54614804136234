import {AuthAction} from "../actions/auth_actions";

export type AuthState = {
  token: string | null,
  permissions: string[]
}

function getDefaultPermissions(): string[] {
  try  {
    return JSON.parse(window.sessionStorage.getItem("swarm-permissions")!) ?? []
  } catch(e) {
    return []
  }
}

export default function(state: AuthState = { 
  token: window.sessionStorage.getItem("swarm-token"),
  permissions:  getDefaultPermissions()
 }, action: AuthAction): AuthState {
  switch (action.type) {
    case "SET_TOKEN":       return { token: action.token, permissions: state.permissions };
    case "DELETE_TOKEN":    return { token: null, permissions: [] };
    case "SET_PERMISSIONS": return { ...state, permissions: action.permissions };
    default:                return state;
  }
}
