import { TypeAuthorized } from "components/authorized";
import React, { useState } from "react"
import { useIntl } from "react-intl";
import { Label } from "recharts";
import { FlatButton, OutlineButton } from "../../../../components/barbagli/common";
import { Select } from "../../../../components/barbagli/select";
import { Title } from "../../../../components/barbagli/text";
import { TextInput } from "../../../../components/barbagli/text_input";
import { Immobile, Floor, floors } from "../../../../models/barbagli/condominium";


type Props = {
    immobile: Immobile,
    condominiumEnabled: boolean
    moveUp: () => void
    onEdit: (c: Immobile) => void
}

export function ImmobileDataView(props: Props) {
    const intl = useIntl();
    const [search, setSearch] = useState("");
    return <div className="d-flex flex-column">
        <Title className="mt-3"> {intl.messages["data"].toString()}</Title>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["flat_number"].toString()}
                    value={props.immobile.data.flatNumber}
                    enabled={!props.condominiumEnabled}
                    onChange={(v) => props.onEdit({ ...props.immobile, data: { ...props.immobile.data, flatNumber: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["imm_location"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.data.location}
                    onChange={(v) => props.onEdit({ ...props.immobile, data: { ...props.immobile.data, location: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["imm_identifier"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.data.identifier}
                    onChange={(v) => props.onEdit({ ...props.immobile, data: { ...props.immobile.data, identifier: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["imm_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.data.name ?? ''}
                    onChange={(v) => props.onEdit({ ...props.immobile, data: { ...props.immobile.data, name: v } })}
                />
            </div>
            <div className="col-md-4">
                <Select
                    label={intl.messages["imm_floor"].toString()}
                    labelStyle={{ marginBottom: 10, marginTop: 6 }}
                    isMulti={false}
                    enabled={!props.condominiumEnabled}
                    valueMapper={(id) => id}
                    availableOptions={floors}
                    selectedOptions={[props?.immobile?.data?.floor ?? ""]}
                    onChange={([floor]) => props.onEdit({ ...props.immobile, data: { ...props.immobile.data, floor } })} />
            </div>
        </div>

        <Title className="mt-3">{intl.messages["occupant"].toString()}</Title>
        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["first_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.tenant.firstName}
                    onChange={(v) => props.onEdit({ ...props.immobile, tenant: { ...props.immobile.tenant, firstName: v } })}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["imm_last_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.tenant.lastName}
                    onChange={(v) => props.onEdit({ ...props.immobile, tenant: { ...props.immobile.tenant, lastName: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["email"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.tenant.email}
                    onChange={(v) => props.onEdit({ ...props.immobile, tenant: { ...props.immobile.tenant, email: v } })}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["phone_number"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.tenant.phoneNumber}
                    onChange={(v) => props.onEdit({ ...props.immobile, tenant: { ...props.immobile.tenant, phoneNumber: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["fax"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.tenant.fax}
                    onChange={(v) => props.onEdit({ ...props.immobile, tenant: { ...props.immobile.tenant, fax: v } })}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["imm_company_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.tenant.vatNumber}
                    onChange={(v) => props.onEdit({ ...props.immobile, tenant: { ...props.immobile.tenant, vatNumber: v } })}
                />
            </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
            <Title className="">{intl.messages["owner"].toString()}</Title>
            <div className="mt-1">
                <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                    <OutlineButton onClick={
                        () => {
                            props.onEdit({ ...props.immobile, owner: { ...props.immobile.tenant } })
                        }
                    }>
                        {intl.messages["copy_from_occupant"].toString()}
                    </OutlineButton>
                </TypeAuthorized>

            </div>

        </div>

        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["first_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.owner.firstName}
                    onChange={(v) => props.onEdit({ ...props.immobile, owner: { ...props.immobile.owner, firstName: v } })}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["last_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.owner.lastName}
                    onChange={(v) => props.onEdit({ ...props.immobile, owner: { ...props.immobile.owner, lastName: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["email"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.owner.email}
                    onChange={(v) => props.onEdit({ ...props.immobile, owner: { ...props.immobile.owner, email: v } })}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["phone_number"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.owner.phoneNumber}
                    onChange={(v) => props.onEdit({ ...props.immobile, owner: { ...props.immobile.owner, phoneNumber: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["fax"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.owner.fax}
                    onChange={(v) => props.onEdit({ ...props.immobile, owner: { ...props.immobile.owner, fax: v } })}
                />
            </div>
            <div className="col-md-6">
                <TextInput
                    label={intl.messages["company_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.immobile.owner.vatNumber}
                    onChange={(v) => props.onEdit({ ...props.immobile, owner: { ...props.immobile.owner, vatNumber: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-4" style={{ marginTop: 36 }}>
                <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                    <FlatButton onClick={() => {
                        props.moveUp()
                    }}>{intl.messages["move_up"]}</FlatButton>
                </TypeAuthorized>
            </div>
        </div>
    </div>
}
