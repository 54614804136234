import React, {ReactNode, useEffect, useLayoutEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {PaginatedList} from "./paginated_sublist";
import {useIntl} from 'react-intl';
import CSS from 'csstype';

type Props<T> = {
  headers: string[],
  rowBuilder: (t: T) => ReactNode[],
  onTap?: (t: T) => void,
  perPage?: number,
  fetchCollection : (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string>
  initialPage?: number,
  headerBuilder?: (s: string) => React.ReactNode
  showPaginationElement?: boolean;
  rowStyle?: (t: T) => CSS.Properties;
  reload?: boolean;
};

export function PaginatedListComponent<T>(props: Props<T>) {

  const onTap = props.onTap || ((_) => {});
  const columnsPerPage = props.perPage || 4;
  const [currentPage, setCurrentPage] = useState(props.initialPage || 0);
  const [sublist, setSublist] = useState<T[]>([]);
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);
  const [reload] = useState(props.reload ?? false)
  const showPaginatedElement = props.showPaginationElement ?? true;
  const intl = useIntl();
  const headerBuilder = props.headerBuilder ?? ((s: string) => <h3>{s.toUpperCase()}</h3>);

  useEffect(() => {
    (async () => {
      
      //  In server first page is page 1 not 0
      const result = await props.fetchCollection(currentPage + 1, columnsPerPage);
      if(typeof result === "string") {
        setError(result);
        return;
      }
      setSublist(result?.sublist || []);
      setCount(result.count);

    })();

  }, [currentPage, props.initialPage, props.reload]);


  return (
    <div>
      {error !== "" && <p className={"error-message"}>{error}</p>}

      <table className={"table table-borderless"}>
        <tbody>
        <tr>{ props.headers.map((s: string, i: number) => <th key={`${s}${i}`} className={ className(i, props.headers.length) }>{headerBuilder(s)}</th>) }</tr>
        { sublist.map((item: T, index: number) => <tr key={JSON.stringify(item)} onClick={() => onTap(item)}>
          {
            props.rowBuilder(item).map((node: ReactNode, index: number) => <td style={(props.rowStyle && props.rowStyle(item)) ?? {}} key={ JSON.stringify(item) + index } className={ `table-row ${className(index, props.rowBuilder(item).length)}` }>{node}</td>)
          }
        </tr>) }
        { count === 0 && <tr><td><h2 style={{marginLeft: "-12px"}}>{intl.messages["no_data"]}</h2></td></tr> }
        </tbody>

      </table>
      {showPaginatedElement && <ReactPaginate
        initialPage={currentPage}
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={ Math.ceil(count / columnsPerPage) }
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({selected}) => setCurrentPage(selected)}
        containerClassName={'d-flex flex-row m-0 p-0'}
        activeClassName={'bold ml-2 mr-2'}
        pageClassName={"text ml-2 mr-2"}
        previousClassName={"text ml-2 mr-2"}
        nextClassName={"text ml-2 mr-2"}
      />}
    </div>
  );
}

const className = (index: number, length: number) => {
  // if(index > 0 && index !== length - 1) return "text-left ml-4";
//  if(index === length - 1) return "actions-column";
  return "text-left pl-0";
};
