import React, {useState} from 'react';
import {LoaderButton, OutlineButton} from 'components/barbagli/common';
import {useIntl} from 'react-intl';
import Modal from 'react-modal';
import {TextInput} from 'components/barbagli/text_input';

type Props = {
  onSend: (email: string) => Promise<string | true>;
  modalIsOpen: boolean;
  closeModal: () => void;
  title: string;
};
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export function ModalEmail(props: Props) {
  const intl = useIntl();
  const [recipientEmail, setRecipientEmail] = useState('');
  return (
    <Modal
      isOpen={props.modalIsOpen}
      //        onAfterOpen={afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}>
      <h2>{props.title}</h2>
      <form>
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(
            intl.messages['recipient_email']?.toString() ?? 'Recipient Email'
          ).toUpperCase()}
          value={recipientEmail}
          onChange={v => {
            setRecipientEmail(v);
          }}
        />
      </form>
      <OutlineButton
        style={{marginRight: 8}}
        onClick={_ => {
          props.closeModal();
        }}>
        {intl.messages['close'] ?? 'close'}
      </OutlineButton>
      <LoaderButton
        style={{marginRight: 8}}
        onClick={() => {
          const r = props.onSend(recipientEmail);
          return new Promise((resolve, reject) => {
            r.then(_ => {
              props.closeModal();
              resolve(true);
            });
          });
        }}>
        {intl.messages['send_test_email'] ?? 'Send test email'}
      </LoaderButton>
    </Modal>
  );
}
