import React, { useMemo } from "react";

export function ScaledP(props: {text: string, selected: boolean}) {

    let style = useMemo(() => {
        let style: any = {
            fontSize: props.text.length < 20 ? 12 : Math.max(Math.min(14, 2 * (- Math.log(Math.min(props.text.length, 60)) + 9), 10)),
            display: "block",
            wordWrap: "break-word",
            overflow: "hidden",
            maxHeight: "2rem",
            textAlign: "left"
        };
        if(props.selected) style = {...style, fontWeight: 900}
        
        return style;
    }, [props]) ;



    return <p style={style}>
        {props.text.length < 60 ? props.text : props.text.substring(0, 60) + ".."}
    </p>
}