import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import store from "../store";
import {subscribeSocket} from "./log_effects";
import {fetchDashboardsAction} from "../actions/dashboard_actions";
import {fetchStatsConfigAction} from "../actions/stat_config_actions";
import {AuthAction, setPermissions} from "../actions/auth_actions";
import { fetchCurrentUserPermissions } from "../../repsitory/user_repository";
import { type } from "os";

export const authEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: AuthAction) => {


  switch (action.type) {
    case "SET_TOKEN":
      const result = next(action);
      window.sessionStorage.setItem("swarm-token", action.token);

      store.dispatch(fetchDashboardsAction());
      store.dispatch(fetchStatsConfigAction());
      subscribeSocket(store);
      (async() => {
        const p = (await fetchCurrentUserPermissions()) as {permissions: string[], type: string};
        window.sessionStorage.setItem("user_type", p.type)
        store.dispatch(setPermissions(p.permissions))
        window.sessionStorage.setItem("swarm-permissions", JSON.stringify(p));
      })()
      
    default: next(action)
  }

}) as Dispatch;