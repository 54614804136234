import { Condominium } from "../../../models/barbagli/condominium";
import { Meter, Attributes } from "../../../models/meter";
import { fetchCondominium } from "../../../repsitory/barbagli/condominium_repository";
import { createMeter, updateMeter, deleteMeter as dm } from "../../../repsitory/barbagli/meter_repository";
import store from "../../../store/store";
import { subscribeToAction } from "../../../store/actions/logs_actions";
import { v1 } from "uuid";

export async function loadAssociatedCondominium(id?: number): Promise<Condominium | string> {
    if (!id) return "no id"
    return fetchCondominium(id);
}


export function validate(state: Attributes & { error?: string, loading?: boolean, uuid: string | null }): string[] {
    let validation_result: string[] = [];
    if (!state.serialNumber || state.serialNumber == "") validation_result = [...validation_result, "serial number should be present"];
    //if(!state.address || state.address == "") validation_result = [...validation_result, "address should be present"];
    if (!state.deviceTypeId || state.deviceTypeId == "") validation_result = [...validation_result, "class_should_be_present"]
    if (!state.mechanicalSerialNumber || state.mechanicalSerialNumber == "") validation_result = [...validation_result, "mechanical serial number should be present"];
    return validation_result;
}

export async function createEditMeterOnlyState(state: Attributes & { error?: string, loading?: boolean, uuid: string | null }): Promise<string | true> {
    if (state.id) {
        //  @ts-ignore
        return updateMeter(state).then((r) => {
            if (typeof r === "string") return r;
            return true;
        })
    } else {

        return createMeter(state).then((r: any) => {
            if (typeof r === "string") return r;
            return true;
        })
    }
};

export async function createEditMeter(state: Attributes & { error?: string, loading?: boolean, uuid: string | null }, setState: React.Dispatch<React.SetStateAction<Attributes & { error?: string, loading?: boolean, uuid: string | null }>>): Promise<string | true> {
    if (state.id) {
        //  @ts-ignore
        return updateMeter(state).then((r) => {
            if (typeof r === "string") return r;
            return true;
        })
    } else {

        return createMeter(state).then((r: any) => {
            if (typeof r === "string") return r;
            return true;
        })
    }
};

export function is_editing_mapping(meter: Attributes, state: Attributes): boolean {
    return JSON.stringify(meter.meterHeatDivider) !== JSON.stringify(state.meterHeatDivider)
}

export async function deleteMeter(meter: Attributes & { id: number }): Promise<string | true> {
    return dm(meter.id);
};
