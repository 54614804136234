import { SortableTableView } from "components/barbagli/sortable_table_view";
import React, { useEffect, useMemo, useState } from "react"
import { fetchPaginatedCollection, post } from "repsitory/generic_repository"
import { history } from "../../../index";
import { TypeAuthorized } from "components/authorized";
import { OutlineButton, PrimaryButton, Row } from "components/barbagli/common";
import { useIntl } from "react-intl";
import { TextAreaInput, TextInput } from "components/barbagli/text_input";
import { Title } from "components/barbagli/text";
import { AsyncSelect } from "components/barbagli/select";
import { Condominium } from "models/barbagli/condominium";


export interface IWaterBalance {
    adjustment: number,
    condominiumAddress: string,
    condominiumCity: string,
    condominiumName: string,
    in_meters: [],
    out_meters: [],
    name: string,
    description: string,
    condominiumId?: number,
    weekAdjustment?: number,
    monthAdjustment?: number,
    dayAdjustment?: number

}
export function MagliaIdricaNew() {
    const [waterBalance, setWaterBalance] = useState<IWaterBalance>({
        adjustment: 0,
        condominiumAddress: "",
        condominiumCity: "",
        condominiumName: "",
        in_meters: [],
        out_meters: [],
        name: "",
        description: ""
    })
    const [condominium, setCondominium] = useState<any>()
    const [meters, setMeters] = useState<any>([])
    const [inMeters, setInMeters] = useState<any>([])
    const [outMeters, setOutMeters] = useState<any>([])
    const [selectedMeters, setSelectedMeters] = useState<any>([])
    const [setMeterSelected, setSetMeterSelected] = useState<boolean>(false)
    const [setInMeterSelected, setSeIntMeterSelected] = useState<boolean>(false)
    const [setOutMeterSelected, setSetOutMeterSelected] = useState<boolean>(false)
    const intl = useIntl();

    useEffect(() => {
        if (condominium) {

            fetchPaginatedCollection(`/api/v1/meter?condominium_id=${condominium.id}`)(1, 1000).then((results: any) => {
                setInMeters([])
                setOutMeters([])

                setMeters(results.sublist.map((e: any) => { return { ...e, selected: false } }))
            })
        }
    }, [condominium])

    if (waterBalance) {
        return <div>

            <div className="row">
                <div className="col-md-4">
                    <TextInput
                        label={intl.messages["name"].toString()}
                        value={waterBalance.name}
                        onChange={(v) => setWaterBalance({ ...waterBalance, name: v })}
                    />
                </div>
                <div className="col-md-4">
                    <TextInput
                        label={intl.messages["adjustment"].toString()}
                        value={waterBalance.adjustment.toString()}
                        type="number"
                        onChange={(v) => setWaterBalance({ ...waterBalance, adjustment: parseFloat(v) })}
                    />
                </div>
                <div className="col-md-4 water-balance-select">
                    <AsyncSelect
                        fetchOptions={(partialInput) => {
                            return fetchPaginatedCollection(`/api/v1/condominium?name=${partialInput}`)(1, 8).then((r) => {
                                if (typeof r !== "string") return r.sublist
                                return [] as Condominium[];
                            }) as Promise<Condominium[]>
                        }}
                        labelStyle={{ marginBottom: 8 }}
                        isMulti={false}
                        selectedOptions={[]}
                        style={{ marginTop: 8 }}
                        label="Condominio"
                        placeholder={(intl.messages["select_condominium"] ?? "select_condominium").toString()}
                        valueMapper={(u: any) => {
                            return u.name
                        }}
                        enabled
                        onChange={([selected]: any) => {
                            console.log(selected)
                            setCondominium(selected)

                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4> Contatori del condominio </h4>
                    <div className="row">
                        <div className="col-md-12">
                            <PrimaryButton style={{ marginRight: 5 }} onClick={() => {
                                setInMeters([...inMeters].concat(meters.filter((m: any) => m.selected == true).map((m: any) => { return { ...m, selected: false } })))
                                setMeters(meters.filter((m: any) => m.selected == false))
                                setSetMeterSelected(false)
                            }}>{intl.messages["add_in_meter"]}</PrimaryButton>
                            <PrimaryButton onClick={() => {
                                setOutMeters([...outMeters].concat(meters.filter((m: any) => m.selected == true).map((m: any) => { return { ...m, selected: false } })))
                                setMeters(meters.filter((m: any) => m.selected == false))
                                setSetMeterSelected(false)
                            }}>{intl.messages["add_out_meter"]}</PrimaryButton>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="col-md-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input className="f" type="checkbox" checked={setMeterSelected} onClick={(v: any) => {
                                                if (v.target.checked) {
                                                    const newMeters = [...meters]
                                                    newMeters.map((m: any) => {
                                                        m.selected = true
                                                        return m
                                                    })
                                                    setMeters(newMeters)
                                                    setSetMeterSelected(true)
                                                } else {
                                                    const newMeters = [...meters]
                                                    newMeters.map((m: any) => {
                                                        m.selected = false
                                                        return m
                                                    })
                                                    setMeters(newMeters)
                                                    setSetMeterSelected(false)
                                                }
                                            }} />
                                        </th>
                                        <th scope="col">Sotto Condominio</th>
                                        <th scope="col">Immobile</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Matricola</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {meters.map((meter: any) => <tr>
                                        <th scope="row"><input className="" type="checkbox" checked={meter.selected} onClick={(v: any) => {
                                            if (v.target.checked) {
                                                meter.selected = true
                                            } else {
                                                meter.selected = false
                                            }
                                            setMeters([...meters])

                                        }} /></th>
                                        <td>{meter.subCondominium}</td>
                                        <td>{meter.immobile}</td>
                                        <td>{meter.type} </td>
                                        <td>{meter.class} {meter.serialNumber} </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {/* <!-- In meter --> */}
                    <div className="row">
                        <div className="col-md-12">
                            <h4> Contatori Entranti </h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <PrimaryButton onClick={() => {
                                        const newMeters = [...meters]
                                        setMeters(newMeters.concat(inMeters.filter((m: any) => m.selected == true).map((m: any) => { return { ...m, selected: false } })))
                                        setInMeters(inMeters.filter((m: any) => m.selected == false))
                                        setSeIntMeterSelected(false)

                                    }}>{intl.messages["remove"]}</PrimaryButton>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input className="" type="checkbox" checked={setInMeterSelected} onClick={(v: any) => {
                                                    if (v.target.checked) {
                                                        const newMeters = [...inMeters]
                                                        newMeters.map((m: any) => {
                                                            m.selected = true
                                                            return m
                                                        })
                                                        setInMeters(newMeters)
                                                        setSeIntMeterSelected(true)
                                                    } else {
                                                        const newMeters = [...inMeters]
                                                        newMeters.map((m: any) => {
                                                            m.selected = false
                                                            return m
                                                        })
                                                        setInMeters(newMeters)
                                                        setSeIntMeterSelected(false)
                                                    }
                                                }} />
                                            </th>
                                            <th scope="col">Sotto Condominio</th>
                                            <th scope="col">Immobile</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Matricola</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inMeters.map((meter: any) => <tr>
                                            <th scope="row"><input className="" type="checkbox" checked={meter.selected} onClick={(v: any) => {
                                                if (v.target.checked) {
                                                    meter.selected = true
                                                } else {
                                                    meter.selected = false
                                                }
                                                setInMeters([...inMeters])

                                            }} /></th>
                                            <td>{meter.subCondominium}</td>
                                            <td>{meter.immobile}</td>
                                            <td>{meter.type} </td>
                                            <td>{meter.class} {meter.serialNumber} </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- out meter --> */}
                    <div className="row">
                        <div className="col-md-12">
                            <h4> Contatori Uscenti </h4>
                            <div className="row" >
                                <div className="col-md-12">
                                    <PrimaryButton onClick={() => {
                                        const newMeters = [...meters]
                                        setMeters(newMeters.concat(outMeters.filter((m: any) => m.selected == true).map((m: any) => { return { ...m, selected: false } })))
                                        setOutMeters(outMeters.filter((m: any) => m.selected == false))
                                        setSetOutMeterSelected(false)

                                    }}>{intl.messages["remove"]}</PrimaryButton>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input className="" type="checkbox" checked={setOutMeterSelected} onClick={(v: any) => {
                                                    if (v.target.checked) {
                                                        const newMeters = [...outMeters]
                                                        newMeters.map((m: any) => {
                                                            m.selected = true
                                                            return m
                                                        })
                                                        setOutMeters(newMeters)
                                                        setSetOutMeterSelected(true)
                                                    } else {
                                                        const newMeters = [...outMeters]
                                                        newMeters.map((m: any) => {
                                                            m.selected = false
                                                            return m
                                                        })
                                                        setOutMeters(newMeters)
                                                        setSetOutMeterSelected(false)
                                                    }
                                                }} />
                                            </th>
                                            <th scope="col">Sotto Condominio</th>
                                            <th scope="col">Immobile</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Matricola</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {outMeters.map((meter: any) => <tr>
                                            <th scope="row"><input className="" type="checkbox" checked={meter.selected} onClick={(v: any) => {
                                                if (v.target.checked) {
                                                    meter.selected = true
                                                } else {
                                                    meter.selected = false
                                                }
                                                setOutMeters([...outMeters])

                                            }} /></th>
                                            <td>{meter.subCondominium}</td>
                                            <td>{meter.immobile}</td>
                                            <td>{meter.type} </td>
                                            <td>{meter.class} {meter.serialNumber} </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <TextAreaInput
                        label={intl.messages["description"].toString()}
                        value={waterBalance.description.toString()}
                        onChange={(v) => setWaterBalance({ ...waterBalance, description: v })}></TextAreaInput>
                </div>
            </div>
            <Row style={{ flexGrow: 1, justifyContent: "flex-end" }}>
                <PrimaryButton onClick={() => {
                    const newWateBalance = {
                        ...waterBalance, in_meters: inMeters.map((m: any) => m.id), out_meters: outMeters.map((m: any) => m.id),
                        condominiumAddress: condominium.address,
                        condominiumCity: condominium.city,
                        condominiumName: condominium.name,
                        condominiumId: condominium.id
                    }
                    console.log(newWateBalance)
                    post({ data: { type: "water_balance", attributes: newWateBalance } }, "/api/v1/water_balance")
                        .then((r: any) => history.push(`/maglia_idrica/${r.data.id}`))
                        .catch((e: any) => console.error(e))

                }}>{intl.messages["create"]}</PrimaryButton>
            </Row>
        </div >
    } return <div> load..</div>
}