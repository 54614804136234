
import { Attributes as MeterAttributes, Meter } from "../../models/meter";
import { httpDelete, post, put } from "../generic_repository";

export async function createMeter(meter: MeterAttributes): Promise<MeterAttributes | string> {
    const j = toJson(meter);
    try {
        const r = await post(j, `/api/v1/meter`);
        if(r.errors != undefined) return JSON.stringify(r.errors);
        if(typeof r !== "string") return r;
        else return r;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}

export async function updateMeter(meter: MeterAttributes & { id: number}): Promise<MeterAttributes | string> {
    const json = toJson(meter);
    try {
        const r = await put(json, `/api/v1/meter/${meter.id}`);
        if(r.errors != undefined)  return JSON.stringify(r.errors);
        if(typeof r !== "string") return meter;
        else return r;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}

export async function deleteMeter(id: number): Promise<string | true> {
    try {
        const r = await httpDelete(`/api/v1/meter/${id}`);
        if(typeof r === "string") return r;
        return true;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}  

function toJson(attributes: MeterAttributes): any {
    return {
        "data": {
            "type": "meter",
            "attributes": attributes 
            }
    }
}