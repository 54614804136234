import React, { useState } from "react"
import { useIntl } from "react-intl";
import { PrimaryButton } from "../../../components/barbagli/common";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { SortableTableView } from "../../../components/barbagli/sortable_table_view";
import { TabView } from "../../../components/barbagli/tab_view";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { emptyGateway, Gateway } from "../../../models/gateway";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import { history } from "../../../index";
import { deepCopy } from "../../../utils/deep_copy";
import { useLocation } from "react-router-dom";
import { TypeAuthorized } from "components/authorized";

type Props = {
    onClick?: (m: Gateway) => void
}

export function GatewaysIndex(props: Props) {
    const [order, setOrder] = useState<OrderParam>({ column: "id", order: "asc" });
    const s = useLocation()
    const query = new URLSearchParams(s.search);
    const dashboardValue = query.get("dashboard");

    const [filter, setFilter] = useState<FilterParam[]>(dashboardValue ? [{ column: "dashboard", value: dashboardValue }] : []);
    const intl = useIntl();
    return <div className="d-flex flex-column">
        <SearchBar filters={filter} avaliableFilters={["serial", "address"]}
            filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
            onFiltersChange={setFilter}
        />
        <SortableTableView
            key={JSON.stringify(filter)}
            perPage={8}
            fetchCollection={
                async (page, perPage) => {
                    return fetchPaginatedCollection<Gateway>(
                        `/api/v1/gateway?sort=${order.column}&order=${order.order}${filter.map(f => `&${f.column}=${f.value}`).join("")}`)
                        (page, perPage);
                }
            }
            onTap={
                (item: Gateway | string) => {
                    // console.log(item)
                    if (props.onClick && typeof item != "string") {
                        props.onClick(item)
                    } else {
                        if (typeof item !== "string") history.push(`/gateways/${item.serial ?? item.identifier ?? item.id}`, item)
                    }
                }
            }
            translated
            itemsPreFormatter={(c: Gateway) => {
                const cc: any = deepCopy(c);
                delete cc["backhaulQos"];
                delete cc["messagesQueue"];
                delete cc["coordinates"];
                delete cc["immobileId"];
                delete cc["condominiumId"];
                delete cc["subCondominiumId"];
                delete cc["meta"];
                delete cc["cpu"];
                delete cc["battery"];
                delete cc["alarms"];
                delete cc["deviceTypeId"];
                delete cc["description"];
                delete cc["configured"];
                delete cc["offline"];
                delete cc["height"];
                delete cc["installed"];
                delete cc["manufacturer"];
                delete cc["network"];
                delete cc["radioCoverage"];
                delete cc["ram"];
                delete cc["storage"];
                delete cc["updatedAt"];
                delete cc["deleteGroups"];

                delete cc["latitude"];
                delete cc["listGroups"];
                delete cc["longitude"];
                delete cc["updateGroups"];
                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "inserted_at", "serial",
                "name", "identifier", "class", "status"
            ]}
        />
        <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
            <div className="d-flex flex-row-reverse">
                {!props.onClick && <PrimaryButton onClick={() => {
                    history.push("/gateways/new", null);
                }}>{intl.messages["add_gateway"]}</PrimaryButton>}
            </div>
        </TypeAuthorized>

    </div>
}
