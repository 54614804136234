import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";
import { ErrorComponent } from '../../../components/error_component';
import { emptyGateway, Gateway } from '../../../models/gateway';
import { Leafleet } from '../../../components/leafleet_map/leafleet_map';
import { fallbackMapCenter } from '../../..';
import { GeoJSONPoint, geoJsonPointFromCoordinates, MapItem } from '../../../models/geo_json';
import { gpsFromGeoJsonPoint } from '../../../service/address_from_coordinates';
import { deepCopy, first } from '../../../utils/deep_copy';
import { stat } from 'fs';
import { H4, Title } from '../../../components/barbagli/text';
import { TextInput } from '../../../components/barbagli/text_input';
import { AltiorGatewayType } from '../../../models/altior_gateway_type';
import { createEditGateway, fetchAltiorGatewayTypes, fetchBackHauls, backHaulToGroupOption, backhaulWithId, calculateRadius } from '../../gateways_page/edit_gateway_page_vm';
import Select from 'react-select';
import { Select as S } from "../../../components/barbagli/select";
import { gray } from '../../../utils/colors';
import { selectCustomStyles } from '../../../components/barbagli/select';
import { DatePickerInput } from '../../../components/datepicker';
import * as B from '../../../components/barbagli/select';
import { Condominium, floors } from '../../../models/barbagli/condominium';
import { fetchCondominium } from '../../../repsitory/barbagli/condominium_repository';
import { GatewayCondominiumView } from './gateway_condominium_view';
import { LoaderButton, OutlineButton, PrimaryButton } from '../../../components/barbagli/common';
import { confirmAlert } from 'react-confirm-alert';
import { can } from '../../../contexts/auth';
import * as vm from './gateway_view_model';
import { useAlert } from 'react-alert';
import { BackHaul } from '../../../models/back_haul';
import { showConfigurationEditor } from '../../../components/configuration_editor/configuration_editor';
import { configToConfigurationValues, configurationValuesToConfig } from '../../../components/configuration_editor/configuration_editor_vm';
import { ConfigurationValue, Command } from '../../../models/configuration';
import { EditableRow } from '../../../components/InfoRow';
import { fetchCommands, execCommand } from '../../../components/command_invocation_widget/command_invocation_view_model';
import { CommandInvocationWidget } from '../../../components/command_invocation_widget/command_invocation_widget';
import { Log } from '../../../models/log';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store/store';
import { TopTabView } from '../../../components/barbagli/top_tab_view';
import { MetersIndex } from '../meters_index/meters_index';
import { EyeIcon, EyeOffIcon } from '../../../components/icons';
import { ReadingPage } from '../readings_index/reading_page';
import { ConsumptionsIndex } from '../consumptions_index/consumptions_index';
import { ConsumptionsPage } from '../consumptions_index/consumptions_page';
import { DigitalTwinState } from './digital_twin_state';
import { GatewayReadings } from './gateway_readings';

type Props = {
  disabled?: boolean
  embedded?: boolean
  gateway?: Gateway
}



export function GatewayView(props: Props) {

  const history = useHistory();
  const attributes: Gateway = props.gateway ?? ((history?.location?.state as Gateway)?.deviceTypeId ? (history.location.state as Gateway) : emptyGateway());
  const [state, setState] = useState<Gateway & { error?: string, loading?: boolean, uuid?: string }>({
    ...deepCopy(attributes),
  });

  const [altiorGatewayTypes, setAltiorGatewayTypes] = useState<AltiorGatewayType[]>([]);
  const [condominium, setCondominium] = useState<Condominium | null>(null);
  const [deviceTypeSelectValue, setDeviceTypeSelectValue] = useState<{ value: string, label: string }>({ value: state.deviceTypeId ?? "", label: "loading" });
  const [backauls, setBackauls] = useState<BackHaul[]>([]);
  const [commands, setCommands] = useState<Command[]>([]);
  const [radioCoverageVisible, setRadioCoverageVisible] = useState(false);
  const alert = useAlert();
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      fetchAltiorGatewayTypes().then((mt) => {
        if (typeof mt === "string") {
          //setState({ ...state, error: mt });
          setAltiorGatewayTypes([]);
        } else {
          setAltiorGatewayTypes(mt);

          const deviceTypeId: string = (() => {
            if (state.deviceTypeId && state.deviceTypeId != "") return state.deviceTypeId
            return first(mt)?.id ?? ""
          })()
          const label = first(mt?.filter((mt) => mt?.attributes?.id?.toString() === deviceTypeId) ?? [])?.attributes?.digitalTwinFields?.class ?? first(mt)?.attributes?.digitalTwinFields?.class ?? "Caricamento..";
          setDeviceTypeSelectValue({
            label: label,
            value: deviceTypeId
          })
          setState({
            ...state, deviceTypeId: deepCopy(deviceTypeId),
            class: (label == "Caricamento in corso.." || label == "Errore, Nessun elemento presente") ? state.class : label
          })
        }
      });
      fetchBackHauls().then((b) => {
        if (typeof b != "string") {
          setBackauls(b);
          if (!state.backhaulId) setState({ ...state, backhaulId: first(b)?.id ? Number(first(b)!.id) : undefined })
        }
      })

      if (state.id !== undefined) {
        const commands = await fetchCommands(state.deviceTypeId);
        if (typeof commands === "string") { /*setState({ ...state, error: commands });*/ return; }
        setCommands(commands);
      }

      if (!state.condominiumId) return;
      fetchCondominium(state.condominiumId).then((c) => { if (typeof c !== "string") setCondominium(c); })

    })()
  }, [0]);

  useEffect(() => {
    setTimeout(() => {
      if (deviceTypeSelectValue.label == "Caricamento..") {
        setDeviceTypeSelectValue({ ...deviceTypeSelectValue, label: "Errore, nessun elemento presente" })
      }
    }, 5000)
  }, [deviceTypeSelectValue.label])

  const radioCoverageCircle: MapItem[] = (() => {
    const radius = calculateRadius(state);
    if (typeof radius === "string" || (!radioCoverageVisible)) return [];
    return [{ feature: { ...state.coordinates!, properties: { ...state!.coordinates!.properties, radius } }, popUp: (_: any) => null }]
  })();



  if (state.error && state.error !== "") return <ErrorComponent message={state.error} />;
  if (state.loading) return <p>Loading</p>;

  const page = (
    <>
      {condominium && !props.embedded && <>
        <GatewayCondominiumView
          condominium={condominium}
          subCondominiumId={state.subCondominiumId}
          immobileId={state.immobileId}
        />
      </>}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className={"row "}>
            <div className="col-md-12">
              <Title>{intl.messages["geolocation"]}</Title>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <TextInput
                enabled={!props.disabled}
                label={intl.messages["address"].toString()}
                value={state.address}
                onChange={(s) => setState({ ...state, address: s })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextInput
                type={"number"}
                enabled={!props.disabled}
                value={state?.coordinates?.geometry?.coordinates[1]?.toString() ?? fallbackMapCenter.lat.toString()}
                onChange={(s) => {
                  if (state.coordinates) {
                    const coordinates = {
                      ...state.coordinates,
                      geometry: {
                        ...state.coordinates.geometry,
                        coordinates: [state.coordinates.geometry.coordinates[0], Number(s)]
                      }
                    };
                    setState({ ...state, coordinates })
                  }
                }}
                label={intl.messages["lat"].toString().toUpperCase()}
              />

            </div>
            <div className="col-md-4">
              <TextInput
                type={"number"}
                enabled={!props.disabled}
                value={state?.coordinates?.geometry?.coordinates[0]?.toString() ?? fallbackMapCenter.lng.toString()}
                onChange={(s) => {
                  if (state.coordinates) {
                    const coordinates = {
                      ...state.coordinates,
                      geometry: {
                        ...state.coordinates.geometry,
                        coordinates: [Number(s), state.coordinates.geometry.coordinates[1]]
                      }
                    };
                    setState({ ...state, coordinates })
                  }
                }}
                label={intl.messages["lng"].toString().toUpperCase()}
              />

            </div>

            <div className="col-md-4">
              <TextInput
                label={intl.messages["height"].toString().toUpperCase()}
                enabled={!props.disabled}
                value={state?.height?.toString() || "0"}
                type={"number"}
                onChange={(evt) => {
                  setState({ ...state, height: Number(evt) })
                }}
              />
            </div>

          </div>
        </div>
        <div className="col-md-6 d-flex flex-column align-items-start">
          <Leafleet
            key={JSON.stringify(state.coordinates)}
            height={186}
            map_id={"gateways-create"}
            editableItem={state.coordinates ?? geoJsonPointFromCoordinates(fallbackMapCenter) ?? undefined}
            fallbackMapCenter={fallbackMapCenter}
            staticMapItems={radioCoverageCircle}
            onEdit={async (dot) => {

              if (dot && !props.disabled) {
                setState({ ...state, coordinates: dot as GeoJSONPoint, address: await gpsFromGeoJsonPoint(dot as GeoJSONPoint) });
              }

            }} />
          {state.radioCoverage && state.radioCoverage.radius_from_height.length > 0 && state.id && <><button className={'icon-button mb-2'} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setRadioCoverageVisible(!radioCoverageVisible)
          }}>

            <div className="d-flex">
              <p style={{ marginRight: 3 }}>{intl.messages["show_radio_coverage"]}</p>
              {radioCoverageVisible && <EyeIcon />}
              {!radioCoverageVisible && <EyeOffIcon />}
            </div>

          </button></>}
        </div>

      </div>


      <div className="row mt-4">
        <div className="col-md-12">
          <Title>{intl.messages["data"]}</Title>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ marginTop: -10 }}>
          {<TextInput
            value={state.serial ?? ""}
            enabled={!state.id && !props.disabled}
            label={intl.messages["serial"].toString().toUpperCase()}
            onChange={(s) => { setState({ ...state, serial: s }) }}
          />}
        </div>
        <div className="col-md-6">
          <DatePickerInput
            labelStyle={{ marginBottom: 6 } as any}
            enabled={!state.id && !props.disabled}
            label={intl.messages["install_date"].toString().toUpperCase()}
            value={new Date(state.installDate ?? (new Date()).toISOString())}
            onChange={(d) => { setState({ ...state, installDate: d.toISOString() }) }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <H4 style={{ marginTop: 8 }}>{intl.messages["class"].toString().toUpperCase()}</H4>
          <Select
            value={deviceTypeSelectValue}
            isDisabled={state.id !== undefined || props.disabled}
            onChange={(g: any) => {
              if (g.label == "Caricamento in corso.." || g.label == "Errore, Nessun elemento presente") {

              } else {
                setState({ ...state, deviceTypeId: g.value, class: g.label });
                setDeviceTypeSelectValue(g);
              }



            }}
            isMulti={false}
            options={altiorGatewayTypes?.map((g: any) => ({ value: g.attributes.id.toString(), label: g.attributes.digitalTwinFields.class }))}
            theme={(t) => ({ ...t, colors: { ...t.colors, primary: gray, primary25: "rgba(0, 0, 0, 0.05)", neutral5: "#ccc" } })}
            name=""
            styles={selectCustomStyles}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label={intl.messages["description"].toString().toUpperCase()}
            value={state.description}
            enabled={!props.disabled}
            onChange={(v) => { setState({ ...state, description: v }) }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <S
            label={intl.messages["gat_floor"].toString()}
            labelStyle={{ marginBottom: 10, marginTop: 6 }}
            isMulti={false}
            enabled
            valueMapper={(id: any) => id}
            availableOptions={floors}
            selectedOptions={[state?.meta?.floor ?? ""]}
            onChange={([floor]: any) => setState({ ...state, meta: { ...(state.meta ?? {}), floor } })} />
        </div>
        <div className="col-md-6">
          {state.class != null && state.class == 'lorawan_gateway' ?
            <TextInput
              value={state.mac ?? ""}
              enabled={!state.id && !props.disabled}
              label={intl.messages["mac"].toString().toUpperCase()}
              onChange={(s) => { setState({ ...state, mac: s }) }}
            />
            : <></>}
        </div>
      </div>





      {
        state.id && <>
          <div className="row mt-4">
            <div className="col-md-12">
              <Title>{intl.messages["commands"]}</Title>
            </div>
          </div>

          <div className={"row mb-4"}>
            <div className={"col-md-12"}>
              <h3>{intl.messages["send_command"].toString().toUpperCase()}</h3>
              <CommandInvocationWidget
                commands={commands}
                intl={intl.messages as any}
                executor={execCommand(state.deviceTypeId ?? "", state.identifier)}
              />
            </div>
          </div>
        </>

      }

      {state.id && <> <div className="row mt-4">
        <div className="col-md-12">
          <Title>{intl.messages["configuration"]}</Title>
        </div>
      </div>

        <DigitalTwinState gateway={state} /> </>}

      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-end">
          {!props.embedded && <OutlineButton style={{ marginRight: 12 }} onClick={() => { history.push("/gateways") }}>
            {intl.messages["close"]}
          </OutlineButton>}


          {((state.id && can("edit", attributes)) || !state.id) && !props.disabled &&

            <>
              <LoaderButton
                primary={false}
                style={{ marginRight: 12 }}
                onClick={() => {
                  return new Promise((resolve, reject) => {
                    confirmAlert({
                      title: intl.messages["confirm"].toString(),
                      message: intl.messages["meter_deletion_confirm"].toString(),
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: async () => {
                            // @ts-ignore
                            if (typeof await vm.deleteGateway(state) != "string") {
                              history.push("/gateways");
                              alert.show(intl.messages["delete_success"])
                              resolve(true)
                            } else {
                              alert.show(intl.messages["operation_error"])
                              resolve(intl.messages["cannot_delete"].toString())
                            }
                          }
                        },
                        {
                          label: 'No',
                          onClick: () => { resolve(true) }
                        }
                      ]
                    });
                  })

                }}>{intl.messages["delete"]}</LoaderButton>

              <LoaderButton disableSuccess onClick={async () => {

                const validation_result = vm.validate(state);
                if (validation_result.length > 0) {
                  const msg = validation_result.map(vr => intl.messages[vr]?.toString() ?? vr).join(" ");
                  alert.error(msg);
                  return msg;
                }
                const result = await vm.createEditGateway(state, setState);

                if (typeof result === "string") {
                  alert.show(intl.messages["operation_error"])
                } else {
                  if (state.id) {
                    alert.show(intl.messages["update_success"])
                  } else {
                    alert.show(intl.messages["creation_success"])
                  }
                  if (!props.embedded) history.goBack();
                }

                return result;
              }}>
                {intl.messages["save"]}
              </LoaderButton>
            </>
          }
        </div>
      </div>
    </>
  );

  if ((props.embedded ?? false) || !attributes.id) return page;

  return <div style={{ margin: -30 }}>
    <TopTabView
      children={[
        [
          "dati",
          page
        ],
        ["Meters",
          <MetersIndex gateway_id={attributes.id}></MetersIndex>
        ],
        ["Letture",
          <GatewayReadings gateway={attributes}></GatewayReadings>
        ],
        ["Meter in whitelist",
          <MetersIndex filters={[{ column: "gateway_identifier", value: state.identifier }]}></MetersIndex>
        ],
        [
          intl.messages["consumption"].toString(),
          <ConsumptionsPage condominium={condominium as Condominium} selectedNode={{ ...state, children: [], availableTabs: [], path_url: "", node_id: attributes.id.toString(), type: "GATEWAY" }} />
        ]
      ]} />
  </div>

}

