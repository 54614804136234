import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Dashboard, emptyDashboard } from "../../../models/dashboard/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { DashboardDetail } from "../dashboard_detail/dashboard_detail";
import { AppState } from "../../../store/store";
import { DashboardEditableDetail } from "../dashboard_detail/dashboard_editable_detail";
import { refreshWebSocket } from "../../../repsitory/dashboards_repository";
import { DashboardsIndexHeader } from "./dashboards_index_header";
import "../dashboard.css";
import { fetchDashboardsAction } from "../../../store/actions/dashboard_actions";
import _ from "lodash";
import { Authorized } from "../../../components/authorized";

export function DashboardsIndexPage() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const dashboards = useSelector((s: AppState) => s.dashboard.dashboards);

  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    dispatch(fetchDashboardsAction());
  }, [0]);

  useEffect(() => {
    if (dashboards.length > 0) {
      setDashboard(dashboards[0]);
    } else {
      setDashboard(null);
    }
  }, [dashboards]);

  useEffect(() => {
    setTimeout(refreshWebSocket, 150);
  }, [dashboard]);

  return (
    <div>
      <DashboardsIndexHeader
        dashboards={dashboards}
        dashboard={dashboard}
        setDashboard={setDashboard}
        editable={editable}
        setEditable={setEditable}
      />

      {dashboards.length === 0 && (
        <h2 className={"mt-5 mb-5"} style={{ minHeight: 500 }}>
          {intl.messages["no_data"].toString()}
        </h2>
      )}
      {dashboard &&
        (editable ? (
          <DashboardEditableDetail
            key={JSON.stringify(dashboard)}
            dashboard={dashboard}
            editable={editable}
            onDashBoardUpdate={(old, newd) => {
              // console.log(old, newd)
              dispatch({
                type: "EDIT_DASHBOARD",
                oldDashboard: old,
                newDashboard: newd,
              });

              setDashboard(newd);
              setEditable(false);
            }}
            onCancel={() => {
              setEditable(false);
              setDashboard(
                _.first(dashboards.filter((d) => d.id === dashboard?.id)) ??
                  null
              );
            }}
          />
        ) : (
          <DashboardDetail dashboard={dashboard} />
        ))}

      {!editable && (
        <div className={"d-flex flex-row-reverse"}>
          <button
            className={"p3 primary-button"}
            onClick={() => {
              dispatch({
                type: "CREATE_DASHBOARD",
                dashboard: emptyDashboard(dashboards),
              });
              setEditable(true);
            }}
          >
            {intl.messages["add_dashboard"].toString().toUpperCase()}
          </button>
        </div>
      )}
    </div>
  );
}
