import React from "react"
import {TriangleIcon} from "./icons";

type Props = {
  message: string
}


export  function ErrorComponent(props: Props) {
  return(
    <div>
      <div className={"row mb-5"}>
        <div className={"col-md-12"}>
          <div className={"d-flex justify-content-center"}>
            <TriangleIcon/>
          </div>
        </div>
      </div>
      <div className={"row mt-5"}>
        <div className={"col-md-12"}>
          <div className={"d-flex justify-content-center"}>
            <div className="d-flex flex-column">
            {props.message.includes("\n") ? props.message.split("\n").map((m) => <p key={m}>{m}</p>) : <p>{props.message}</p>}

            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}