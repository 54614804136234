import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import { ErrorMapping } from "../../../models/barbagli/error_mapping";
import ReactPaginate from "react-paginate";
import { Group } from "../../../models/group";
import { UserGroup } from "../../../models/user_group";
import { fetchAllGroups, fetchPaginatedUsers } from "../../../repsitory/user_repository";
import { MoreVerticalIcon, NotSelectedIcon, SelectedIcon, TriangleIcon } from "../../../components/icons";
import { IconButton, LoaderButton } from "../../../components/barbagli/common";
import styled from "styled-components";
import { TextInput } from "../../../components/barbagli/text_input";
import { deepCopy } from "../../../utils/deep_copy";
import { AsyncSelect, AsyncSelectIdsFromCrud, Select } from "../../../components/barbagli/select";
import { H4 } from "../../../components/barbagli/text";
import { FilterParam } from "../../../models/barbagli/filter_order";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { syncPage } from "./error_mapping_viewmodel";
import { TabView } from "../../../components/barbagli/tab_view";
import { LoaderComponent } from "components/loading_widget";


type Props = {
    perPage?: number
    initialPage?: number
}

type State = {
    selected_tab:
    | "RIPARTITORE"
    | "AF"
    | "ACS"
    | "CALORIE"
    | "FRIGORIE"
}

export function  ErrorMappingPage(props: Props) {
    const [state, setState] = useState<State>({ selected_tab: "AF" });
    return <div>
        <TabView
            children={[
                ["AF / ACS", <ErrorMappingPagePerType tab={"AFACS"} {...props} />],
                ["RIPARTITORE", <ErrorMappingPagePerType tab={"RIPARTITORE"}{...props} />],
                ["CALORIE / FRIGORIE", <ErrorMappingPagePerType tab={"CALORIE"}{...props} />],
            ]}
        />
    </div>
}


function ErrorMappingPagePerType(props: Props & {tab: string}) {

    const columnsPerPage = props.perPage || 400;
    const [currentPage, setCurrentPage] = useState(props.initialPage || 0);
    const [sublist, setSublist] = useState<ErrorMapping[]>([]);
    const [groupedSubList, setGroupedSubList] = useState<Record<string, ErrorMapping[]>>({});
    const [error, setError] = useState("");
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState<FilterParam[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [loading, setLoading] = useState(false);
    const intl = useIntl();


    const fetchCollection = useMemo(() => {
        return async (page: number, perPage: number) => {
            return fetchPaginatedCollection<ErrorMapping>(
                `/api/v1/mapping?${[...filter, { value: props.tab, column: "meter_type" }].map(f => `${f.column}=${f.value}`).join("&")}`)
                (page, perPage);
        }
    }, [props.tab, filter]);


    useEffect(() => {
        fetchAllGroups().then(setGroups);
    }, [0])

    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await fetchCollection(currentPage + 1, columnsPerPage);
            if (typeof result === "string") {
                setError(result);
                return;
            }
            const sublist = result?.sublist || [];
            setSublist(sublist);
            const groupedSubList: Record<string, ErrorMapping[]> =
            sublist.reduce((acc: Record<string, ErrorMapping[]>, em: ErrorMapping) => {
                const modelList = acc[em.model] ?? [];
                modelList.push(deepCopy(em));
                acc[em.model] = modelList;
                return acc;
            }, {})

            setGroupedSubList(groupedSubList);
            setCount(result.count);
            setLoading(false);
        })();

    }, [currentPage, props.initialPage, props.tab, filter]);


    return (
        <div>
            {error !== "" && <p className={"error-message"}>{error}</p>}
            <SearchBar filters={filter} avaliableFilters={["model", "name", "display_name"]} 
                filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
                onFiltersChange={(fp) => {setFilter(fp); }}
                
            />
            {loading && <LoaderComponent height={64} width={64}></LoaderComponent>}
            {
                Object.keys(groupedSubList).map((groupName, i) => {
                    return <MappingGroup
                        key={`g${i}`}
                        setMappings={(mappings) => {
                            const nl = deepCopy(groupedSubList);
                            nl[groupName] = mappings;
                            setGroupedSubList(nl);
                        }}
                        groups={groups}
                        intl={intl.messages as any}
                        model={groupName}
                        mappings={groupedSubList[groupName]}
                    />
                })
            }

            { count === 0 && <tr><td><h2 style={{ marginLeft: "-12px" }}>{intl.messages["no_data"]}</h2></td></tr>}


            <ButtonFrame>
            <ReactPaginate
                initialPage={currentPage}
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(count / columnsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName={'d-flex flex-row m-0 p-0 pointer'}
                activeClassName={'bold ml-2 mr-2 pointer'}
                pageClassName={"text ml-2 mr-2 pointer"}
                previousClassName={"text ml-2 mr-2 pointer"}
                nextClassName={"text ml-2 mr-2 pointer"}
            />
                <LoaderButton  onClick={() => syncPage(groupedSubList, sublist)}>
                    {intl.messages["save"]}
                </LoaderButton>
            </ButtonFrame>

        </div>
    );
}

function MappingGroup(props: { mappings: ErrorMapping[], model: string, groups: UserGroup[], setMappings: (em: ErrorMapping[]) => void, intl: Record<string, string> }) {

    return <div className="d-flex mt-4 flex-column">
        <h3>{props.model.toUpperCase()}:</h3>
        <div className="ml-5 d-flex flex-column">
            {props.mappings.map((mapping, i) => <MappingRow key={i} mapping={mapping} groups={props.groups} intl={props.intl} onMappingUpdate={
                (m) => {
                    props.mappings[i] = m;
                    props.setMappings(props.mappings);
                }} ></MappingRow>)}
        </div>

        
    </div>
}

const ButtonFrame = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: center;
`

const ARC = styled.div`
display:flex;
align-items: center;
flex-grow:1;
margin-right: 12px;
height: 10px;
line-height: 10px;

`
const ARB = styled.div`
flex-grow:1;
margin-top: 0px;
margin-left: 12px;   
height:3px;
margin-right: -6px;
border-bottom: 1px solid black;
`;
export const Arrow = () => {


    return <ARC>
        <ARB></ARB>{">"}
    </ARC>

}

export const MappingContainer = styled.div`
    width: 50vw;
    display: flex;
    flex-direction: column;
`

export const MappingRowContainer = styled.div`
display: flex;
align-items:center;
margin-bottom: 2rem;
`

export const ButtonsRow = styled.div`
display:flex;
`
export const ButtonItem = styled.div`
    display: flex;
    flex-grow: 1;
    width: 50%;
    flex-direction: row;
    align-items: center;
    height: 32px;
`


function MappingRow(props: {mapping: ErrorMapping, groups: UserGroup[], intl: Record<string, string>, onMappingUpdate: (m: ErrorMapping) => void}) {

    const groups_names: Record<string, UserGroup> = useMemo(() => {
        return props.groups.reduce((acc: Record<string, UserGroup>, v: UserGroup) => { 
            acc[v.id] = v;
            return acc;
        }, {});
    }, [props.groups])

    const groups_ids: Record<string, UserGroup> = useMemo(() => {
        return props.groups.reduce((acc: Record<string, UserGroup>, v: UserGroup) => { 
            acc[v.name] = v;
            return acc;
        }, {});
    }, [props.groups])

    return <MappingRowContainer>
        <H4>{props.mapping.name.toUpperCase()}</H4>
        <Arrow></Arrow>
        <MappingContainer>
            <TextInput value={props.mapping.displayName} label={props.intl["display_name"]} onChange={(v) => props.onMappingUpdate({...props.mapping, displayName: v})}></TextInput>           
            <Select 
            label={props.intl["visible_by"]}
            enabled
            isMulti
            selectedOptions={props.mapping.visibility.map((group_id) => groups_names[group_id]).filter((s) => s)}
            availableOptions={props.groups} 
            valueMapper={(ug) => ug.name}
            onChange={(v) => {
                // console.log(v);
                props.onMappingUpdate({...props.mapping, visibility: v.map(a => a.id)})
            }}></Select>
            <AsyncSelect
                    fetchOptions={(partialInput) => {
                        return fetchPaginatedUsers(`/api/v1/user?username=${partialInput}`, true)(1, 8).then((r) => {
                            if(typeof r !== "string") return [ "administrator", "tenant", ...r.sublist.map((u) => u.username), ]
                            return [] as string[];
                        }) as Promise<string[]>
                    }}
                    isMulti
                    cached
                    selectedOptions={props?.mapping?.meta?.notifications?.recipients ?? []}
                    style={{marginTop: 8}}
                    label={"Notificabile a"}
                    placeholder={"Notificabile a"}
                    valueMapper={(u: string) => {
                        if(u == "administrator") return "Amministratore"
                        if(u == "tenant") return "Occupante"
                        return u
                    }}
                    enabled
                    onChange={(users: string[]) => { 
                        const m = users.map((u: string) => {
                            if(u == "Amministratore") return "administrator"
                            if(u == "Occupante") return "tenant"
                            return u;
                        });
                        props.onMappingUpdate({...props.mapping, meta: {notifications: {recipients: m}}})
                     }}
                />
            <ButtonsRow>
            <ButtonItem>
                    <H4 style={{marginTop: 8, marginRight: 8}}>{props.intl["notificable"].toUpperCase()}</H4>
                    {props.mapping.notifiable && <IconButton onClick={() => {props.onMappingUpdate({...props.mapping, notifiable: false})}}><SelectedIcon/></IconButton>}
                    {!props.mapping.notifiable && <IconButton onClick={() => {props.onMappingUpdate({...props.mapping, notifiable: true})}}><NotSelectedIcon/></IconButton>}
                </ButtonItem>
                <ButtonItem>
                    <H4 style={{marginTop: 8, marginRight: 8}}>{props.intl["stops_consumpiton_calculation"]}</H4>
                    {props.mapping.blocksConsumptions && <IconButton onClick={() => {props.onMappingUpdate({...props.mapping, blocksConsumptions: false})}}><SelectedIcon/></IconButton>}
                    {!props.mapping.blocksConsumptions && <IconButton onClick={() => {props.onMappingUpdate({...props.mapping, blocksConsumptions: true})}}><NotSelectedIcon/></IconButton>}
                </ButtonItem>
            </ButtonsRow>
        </MappingContainer>
    </MappingRowContainer>
}