import {confirmAlert} from "react-confirm-alert";
import React, {useEffect, useState} from "react";
import {postRaw} from "../repsitory/generic_repository";
import {LoaderComponent} from "./loading_widget";
import {WebSocketLoadingBar} from "./web_socket_loading_bar";
import store from "../store/store";
import {Provider} from "react-redux";
import {BASE_URL} from "../index";


export async function showExportFileDialog(intl: Record<string, string>, query: any, url?: string) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <Provider store={store}>
          <ExportFileDialog intl={intl} query={query} close={onClose} url={url}/>
        </Provider>
      </div>
  });
}

function ExportFileDialog(props: {intl: Record<string, string>, query: any, close: () => void, url?: string}, ) {

  const [link, setLink] = useState<string | null>(null);
  const [uuid, setUuid] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    postRaw(props.query, props.url ?? "/api/v1/readings/export").then(async (r) => {
      if(typeof r === "string") {
        setError("Impossible to get export");
        return
      }
      const uuid = await r.text();
      setUuid(uuid)
    })
  }, [JSON.stringify(props.query)])

  return <div>
    <div className={"row "}>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["Exporting"].toString().toUpperCase()}</h3>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12"}>
        {uuid === null && error === null && <LoaderComponent/>}
        {error !== null && <p className={"error-message"}>{error}</p>}
        {uuid !== null && link === null && <WebSocketLoadingBar channelName={"export_notification_channel"} eventName={uuid} onLog={(l) => {
          if(l.log.done && l.log.filename) {
            setLink(BASE_URL + `/api/v1/download_file?token=${store.getState().auth.token}&filename=${l.log.filename}`)
          } else if(l.log.done === true) {
            setLink(BASE_URL + `/api/v1/download_file?token=${store.getState().auth.token}&uuid=${uuid}`)
          }

        }}/>}
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["close"]}</button>
        {link !== null && <a className={"outline-button dashboard-editor-icon"} href={link}>DOWNLOAD FILE</a>}
      </div>
    </div>
  </div>
}


