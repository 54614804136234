import React, {ReactNode} from 'react';
import MenuItemType from "../models/menu_item_type";
import {Sidebar} from "./sidebar";
import {Header} from "./header";
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

type Props = {
  children: ReactNode,
  selectedItem: MenuItemType,
  // header props
  filteredItems?: string[],
  maxLength?: number
};


export function Layout(props: Props) {
  const perms = useSelector((state: AppState) => state.auth.permissions)
  return (
    <div className={"c-app"}>
      <div className={"app-body"}>
        <Sidebar selectedItem={props.selectedItem} key={JSON.stringify(perms)}/>
        <div className={"c-wrapper"}>
          <Header filteredItems={props.filteredItems} maxLength={props.maxLength}/>
          <div className={"c-body"}>
            <main className={"c-main"}>
              <div className={"main-container"} id={"main-container"} key={"main-container"}>
                  {props.children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};
