import { Column, IconButton, OutlineButton, PrimaryButton, Row } from 'components/barbagli/common'
import { AsyncSelect, AsyncSelectIdsFromCrud, Select } from 'components/barbagli/select'
import { TabView } from 'components/barbagli/tab_view'
import { H4 } from 'components/barbagli/text'
import { TextInput } from 'components/barbagli/text_input'
import { SelectedIcon, NotSelectedIcon } from 'components/icons'
import { Attributes } from 'models/meter'
import { User } from 'models/user'
import { MappingRowContainer, MappingContainer, ButtonsRow, ButtonItem, Arrow } from 'pages/barbagli/error_mapping/error_mapping_page'
import React from 'react'
import { useIntl } from 'react-intl'
import { fetchPaginatedUsers } from 'repsitory/user_repository'
import styled from 'styled-components'
import { translate } from 'utils/intl'
import { convertiTBack, useMetersNotificationState } from './meters_notification_state'
import { showNotificationModal } from './notificationModal'

type Props = {
    attributes: Attributes
    onMeterUpdate: (a: Attributes) => void
}



export function MeterNotificationsPanel(props: Props) {

    const [state, dispatch] = useMetersNotificationState(props.attributes)
    const intl = useIntl();
    const translated = translate(intl.messages as any)

    return <><TabView
        children={[
            ["ERRORI", <>
                {

                    Object.keys(state.mapping).map((e) => {
                        const mapping = state.mapping[e];
                        return <MappingRowContainer>
                            <H4>{mapping.displayName.toLocaleUpperCase()}</H4>
                            <Arrow></Arrow>
                            <MappingContainer>


                                <AsyncSelect
                                    fetchOptions={(partialInput) => {
                                        return fetchPaginatedUsers(`/api/v1/user?username=${partialInput}`, true)(1, 8).then((r) => {
                                            if (typeof r !== "string") return ["administrator", "tenant", ...r.sublist.map((u) => u.username),]
                                            return [] as string[];
                                        }) as Promise<string[]>
                                    }}
                                    isMulti
                                    cached
                                    selectedOptions={mapping.recipients}
                                    style={{ marginTop: 8 }}
                                    placeholder={translated("select_user")}
                                    valueMapper={(u: string) => {
                                        if (u == "administrator") return "Amministratore"
                                        if (u == "tenant") return "Occupante"
                                        return u
                                    }}
                                    enabled
                                    onChange={(users: string[]) => {
                                        mapping.recipients = users.map((u: string) => {
                                            if (u == "Amministratore") return "administrator"
                                            if (u == "Occupante") return "tenant"
                                            return u;
                                        });
                                        state.mapping[e] = mapping;
                                        dispatch({ type: "setMappings", value: state.mapping })
                                    }}
                                />
                            </MappingContainer>
                        </MappingRowContainer>
                    })
                }

            </>],
            ["CONSUMI", <>
                <Column>
                    {state.consumptions.map((c, i) => <Row key={i} style={{ justifyContent: "space-between" }}>
                        <p>{"Notifica "} {c.recipients.map((u) => {
                            if (u == "administrator") return "Amministratore"
                            if (u == "tenant") return "Occupante"
                            return u
                        }).join(", ")} {" quando il consumo è"} {c.expression} {c.value}</p>
                        <OutlineButton onClick={() => dispatch({ type: "removeConsumptionRule", value: c })} >Cancella</OutlineButton>
                    </Row>)}

                    <Row style={{ flexDirection: "row-reverse" }}>
                        <PrimaryButton onClick={() => {
                            showNotificationModal({
                                notification: null,
                                nextIndex: Math.max(...[...state.consumptions.map((_c, i) => i + 1), 0]),
                                onCreate: (c) => { dispatch({ type: "addConsuptionRule", value: c }) },
                            })
                        }}>{translated("add_notification")}</PrimaryButton>
                    </Row>

                </Column>

            </>]
        ]}
    />
        <Row style={{ flexDirection: "row-reverse" }}>
            <PrimaryButton onClick={() => {
                console.log()
                const nm = { ...props.attributes, meta: { ...(props.attributes.meta ?? {}), ...convertiTBack(state) } }
                console.log(nm)
                props.onMeterUpdate(nm);


            }}>Salva</PrimaryButton>
        </Row>
    </>



}

