import React, { useReducer, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { TextInput } from "components/barbagli/text_input";
import {
  Column,
  LoaderButton,
  OutlineButton,
  Row,
} from "components/barbagli/common";
import { BASE_URL, history } from "index";
import { deepCopy } from "utils/deep_copy";
import {
  httpDelete,
  post,
  put,
  putFormData,
} from "repsitory/generic_repository";
import { useAlert } from "react-alert";
import { showAlert } from "components/alerts/alert";
import { AsyncSelectIdsFromCrud } from "components/barbagli/select";
import {
  emptyPartner,
  emptyPartnerConfig,
  isAPartner,
  Partner,
  PartnerCssConfig,
} from "models/barbagli/partner";
import { PartnerCssConfigWidget } from "./partner_css_config";
import { PartnerSmtpConfigWidget } from "./partner_smtp_config_widget";
import { SmtpConfig } from "models/barbagli/partner";

type Props = {
  partner?: Partner;
  validationKeys?: string[];
};

export function PartnerDetailPage(props: Props) {
  const item: Partner =
    props.partner ?? isAPartner(history?.location?.state)
      ? (history?.location?.state as Partner)
      : emptyPartner();
  const [state, dispach] = useReducer(reducer, {
    ...deepCopy(item),
    logo: null,
    logoFile: null,
  });
  const intl = useIntl();
  const alert = useAlert();

  useEffect(() => {
    if (state.logoFile == null) return;
    let reader = new FileReader();
    reader.readAsDataURL(state.logoFile);
    reader.onload = async () => {
      const logo = reader?.result?.toString() ?? null;
      if (logo) dispach({ type: "set_logo", value: logo });
    };
  }, [state.logoFile]);

  return (
    <Column>
      <Layout>
        <Row>
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(
              intl.messages["address"]?.toString() ?? "address"
            ).toUpperCase()}
            value={state.address?.toString()}
            onChange={(v) => {
              dispach({ type: "set_address", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(intl.messages["cap"]?.toString() ?? "cap").toUpperCase()}
            value={state.cap?.toString()}
            onChange={(v) => {
              dispach({ type: "set_cap", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(intl.messages["city"]?.toString() ?? "city").toUpperCase()}
            value={state.city?.toString()}
            onChange={(v) => {
              dispach({ type: "set_city", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(intl.messages["code"]?.toString() ?? "code").toUpperCase()}
            value={state.code?.toString()}
            onChange={(v) => {
              dispach({ type: "set_code", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(
              intl.messages["companyName"]?.toString() ?? "companyName"
            ).toUpperCase()}
            value={state.companyName?.toString()}
            onChange={(v) => {
              dispach({ type: "set_companyName", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(
              intl.messages["portalAddress"]?.toString() ?? "portalAddress"
            ).toUpperCase()}
            value={state.portalAddress?.toString()}
            onChange={(v) => {
              dispach({ type: "set_portalAddress", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(
              intl.messages["portalName"]?.toString() ?? "portalName"
            ).toUpperCase()}
            value={state.portalName?.toString()}
            onChange={(v) => {
              dispach({ type: "set_portalName", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(
              intl.messages["portalState"]?.toString() ?? "portalState"
            ).toUpperCase()}
            value={state.portalState?.toString()}
            onChange={(v) => {
              dispach({ type: "set_portalState", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(intl.messages["prov"]?.toString() ?? "prov").toUpperCase()}
            value={state.prov.toString()}
            onChange={(v) => {
              dispach({ type: "set_prov", value: v });
            }}
          />
          <TextInput
            enabled
            style={{ flex: "0 0 33%", paddingRight: 8 }}
            label={(intl.messages["vat"]?.toString() ?? "vat").toUpperCase()}
            value={state.vat.toString()}
            onChange={(v) => {
              dispach({ type: "set_vat", value: v });
            }}
          />
          <AsyncSelectIdsFromCrud
            style={{ marginRight: 24, marginTop: 8 }}
            label="Condomini"
            baseUrl="/api/v1/condominium"
            idExtractor={(a: any) => a?.id}
            valueMapper={(a: any) => a?.name}
            filterName="name"
            placeholder="Seleziona condominio"
            selectedIds={state.condominiums}
            onIdsSelected={(ids: number[]) =>
              dispach({ type: "set_conds", value: ids })
            }
          />
        </Row>
        <Row style={{ width: "100%", marginRight: 20, marginTop: 30 }}>
          <div style={{ flexGrow: 1, height: "100%" }}>
            <PartnerCssConfigWidget
              config={state.config ?? emptyPartnerConfig()}
              onChange={(conf) =>
                dispach({ type: "set_partner_config", value: conf })
              }
            ></PartnerCssConfigWidget>
          </div>

          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3 className={""}>
                {intl.messages["logo-preview"].toString().toUpperCase()}
              </h3>
              <img
                className={"img-preview "}
                src={
                  state.logo ??
                  (state.id
                    ? `${BASE_URL}/api/v1/partner/${state.id}/logo`
                    : `${BASE_URL}/api/v1/owner-configuration/logo`)
                }
              />
            </div>
            <div
              className={"pt-1"}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div className="file">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="resume"
                    onChange={(e) =>
                      dispach({
                        type: "set_logo_file",
                        value: e?.target?.files?.item(0) || null,
                      })
                    }
                  />
                  <span className="file-cta">
                    <span className="file-label">Carica nuovo </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Row>
        <Row style={{ width: "100%" }}>
          <PartnerSmtpConfigWidget
            smtpConfig={state.smtpConfig}
            onChange={(smtpConfig) =>
              dispach({ type: "set_smtp_config", value: smtpConfig })
            }
          ></PartnerSmtpConfigWidget>
        </Row>
      </Layout>
      <BottomRow>
        <LoaderButton
          onClick={async () => {
            return createUpdate(
              state,
              intl,
              history,
              alert,
              props.validationKeys ?? [],
              onCreate,
              onUpdate
            );
          }}
        >
          {(state.id
            ? intl.messages["update"] ?? "update"
            : intl.messages["create"] ?? "create"
          ).toString()}
        </LoaderButton>
        {state.id && (
          <OutlineButton
            style={{ marginRight: 8 }}
            onClick={async (_) => {
              showAlert(
                intl.messages["are_you_sure"].toString(),
                intl.messages["this_action_cannot_be_undone"].toString(),
                {
                  onDone: async () => {
                    const result = await onDelete(state);
                    if (typeof result == "string") alert.error(result);
                    history.goBack();
                    alert.info(intl.messages["success_deletion"]);
                  },
                  secondaryButtonText: intl.messages["discard"].toString(),
                }
              );
            }}
          >
            {intl.messages["delete"] ?? "delete"}
          </OutlineButton>
        )}

        <OutlineButton
          style={{ marginRight: 8 }}
          onClick={(_) => history.goBack()}
        >
          {intl.messages["cancel"] ?? "cancel"}
        </OutlineButton>
      </BottomRow>
    </Column>
  );
}

//_______________Visual Blocks
const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const BottomRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-right: 8px;
  margin-right: 8px;
`;
//______________End Visual Blocks

//_______________Reducer
type PageState = Partner & { logo: string | null; logoFile: File | null };

type PageAction =
  | { type: "set_address"; value: string }
  | { type: "set_cap"; value: string }
  | { type: "set_city"; value: string }
  | { type: "set_code"; value: string }
  | { type: "set_companyName"; value: string }
  | { type: "set_portalAddress"; value: string }
  | { type: "set_portalErrorsColor"; value: string }
  | { type: "set_portalLogo"; value: string }
  | { type: "set_portalName"; value: string }
  | { type: "set_portalPrimaryColor"; value: string }
  | { type: "set_portalState"; value: string }
  | { type: "set_portalTextColor"; value: string }
  | { type: "set_prov"; value: string }
  | { type: "set_vat"; value: string }
  | { type: "set_conds"; value: number[] }
  | { type: "set_logo"; value: string }
  | { type: "set_logo_file"; value: File | null }
  | { type: "set_partner_config"; value: PartnerCssConfig }
  | { type: "set_smtp_config"; value: SmtpConfig };

function reducer(state: PageState, action: PageAction): PageState {
  switch (action.type) {
    case "set_address":
      return { ...state, address: action.value };
    case "set_cap":
      return { ...state, cap: action.value };
    case "set_city":
      return { ...state, city: action.value };
    case "set_code":
      return { ...state, code: action.value };
    case "set_companyName":
      return { ...state, companyName: action.value };
    case "set_portalAddress":
      return { ...state, portalAddress: action.value };
    case "set_portalErrorsColor":
      return { ...state, portalErrorsColor: action.value };
    case "set_portalLogo":
      return { ...state, portalLogo: action.value };
    case "set_portalName":
      return { ...state, portalName: action.value };
    case "set_portalPrimaryColor":
      return { ...state, portalPrimaryColor: action.value };
    case "set_portalState":
      return { ...state, portalState: action.value };
    case "set_portalTextColor":
      return { ...state, portalTextColor: action.value };
    case "set_prov":
      return { ...state, prov: action.value };
    case "set_vat":
      return { ...state, vat: action.value };
    case "set_conds":
      return { ...state, condominiums: action.value };
    case "set_logo":
      return { ...state, logo: action.value };
    case "set_smtp_config":
      return { ...state, smtpConfig: action.value };
    case "set_partner_config":
      return { ...state, config: action.value };
    case "set_logo_file":
      if (action.value) return { ...state, logoFile: action.value };
      else return { ...state };
  }
}
//______________End Reducer

//____Functions to Handle Effects

async function createUpdate(
  state: PageState,
  intl: any,
  history: any,
  alert: any,
  requiredFields: string[],
  onCreate: any,
  onUpdate: any
): Promise<string | true> {
  // Phase 1 validation of state
  const errors = requiredFields.reduce((acc: string, x: string) => {
    // @ts-ignore
    if (!state[x] || state[x] === "") {
      return `Il campo ${(
        intl.messages[x] ?? x
      ).toString()} non può essere vuoto\n${acc}`;
    }
    return acc;
  }, "");

  if (errors != "") {
    showAlert(intl.messages["validation_errors"], errors);
    return "errore di validazione";
  }

  const result = await (state.id ? onUpdate(state) : onCreate(state));

  if (typeof result != "string") {
    const id = result.data.id;
    // Logo updated
    if (state.logoFile) {
      const dataKeys = new FormData();
      dataKeys.append("logo", state.logoFile);
      const keysResponse = await putFormData(
        dataKeys,
        `/api/v1/partner/${id}/logo`
      );
      if (keysResponse.status !== 204 && keysResponse.status !== 201) {
        if (keysResponse.status == 422) alert.error("File troppo grande");
        else alert.error("Errore nell'upload del file");
        return "file troppo grande";
      } else {
        history.goBack();
        alert.info(intl.messages["success"]);
        return true;
      }
    } else {
      history.goBack();
      alert.info(intl.messages["success"]);
      return true;
    }
  } else {
    alert.error(result);
    return result;
  }
}

const onCreate = async (i: PageState) =>
  post({ data: { type: "partner", attributes: i } }, "/api/v1/partner");
const onUpdate = async (i: PageState) =>
  put({ data: { type: "partner", attributes: i } }, `/api/v1/partner/${i.id}`);
const onDelete = async (i: PageState) => httpDelete(`/api/v1/partner/${i.id}`);

//End Functions to Handle Effects
