import { Column, LoaderButton, OutlineButton, PrimaryButton, Row } from 'components/barbagli/common';
import { TextInput } from 'components/barbagli/text_input';
import { I18n } from 'components/I18n';
import { BASE_URL } from 'index';
import moment from 'moment';
import React, { useReducer, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Provider } from 'react-redux';
import { post } from 'repsitory/generic_repository';
import store from 'store/store';
import styled from 'styled-components';
import {history} from '../../../../index';


type Props = {
    notes: AlarmMeta,
    onUpdate: (c: AlarmMeta) => void,
    alarmId: number,
}

export function showAlarmNotesPage(props: Props) {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) =>
            <div className={"configuration-editor p-3"} style={{ width: "50vw", minWidth: 300 }}>
                <Provider store={store}>
                <I18n>
                    <div className={"row d-flex flex-row justify-content-between"}>
                        <Dialog
                            onClose={onClose}
                            {...props}
                        />
                    </div>
                </I18n>
                </Provider>


            </div>

    });
}


const LC = styled.div`
padding-top: 16px;
width: 50%;
`

function Dialog(props: Props & { onClose: any }) {

    const tmpnotes =JSON.parse(window.sessionStorage.getItem(`notification${props.alarmId}`) ?? "[]")
    const [state, dispatch] = useAlarmsNotesState( tmpnotes.length >= props.notes.notifications.length ? {notifications: tmpnotes} : props.notes)

    return <Column style={{ width: "100%", padding: "0.5rem" }}>
        <div className={"row d-flex flex-row justify-content-between p-1"}>
            <h2 className={"dashboard-editor-title"}>{"Note"}</h2>
        </div>

        {state.items.map((it, i) => <Row style={{padding: 8}} key={`note${i}`}><p>
            {`${it.sent_email_time ? `Email inviata in data ${moment(it.sent_email_time).format("DD/mm/yyyy")}` : ''}
            ${it.sent_email_status ? `stato: ${it.sent_email_status}` : ""}
             ${it.recipient_role ? `destinatario: ${it.recipient_role} (${it.recipient_email})` : ""} ${it.note ?? ""}`}
        </p></Row>)}




        <Row style={{ justifyContent: "space-between"}}>
        <Row style={{ flexGrow: 1, paddingRight: "1rem"}}>


            {!state.formVisible && <OutlineButton onClick={() => {
                dispatch({ type: "setNewNoteText", value: "" })
                dispatch({ type: "setFormVisible", value: true });
            }}>Aggiungi Nota</OutlineButton>}
                        {state.formVisible && <>
                
                <TextInput style={{flexGrow: 1}} value={state.newNoteText} onChange={(v) => dispatch({ type: "setNewNoteText", value: v })}></TextInput>
            </>}
        </Row>
            <Row>
            <OutlineButton onClick={props.onClose}>Chiudi</OutlineButton>
            { state.newNoteText != "" && <LoaderButton onClick={async () => {

                const r = await saveNote(props.alarmId, { notifications: [...state.items, { note: state.newNoteText }] })
                if (r == true) {
                    dispatch({ type: "setItems", value: [...state.items, { note: state.newNoteText }] })
                    dispatch({ type: "setNewNoteText", value: "" })
                    dispatch({ type: "setFormVisible", value: false });
                    props.onClose();
                    window.sessionStorage.setItem(`notification${props.alarmId}`, JSON.stringify([...state.items, { note: state.newNoteText }]))
                    props.notes.notifications = [...state.items, { note: state.newNoteText }]
                    //props.onUpdate({notifications: state.items})
                }
                return r;
            }}>
                Salva
            </LoaderButton>}
            </Row>

        </Row>
    </Column>


}

async function saveNote(alarmId: number, notes: { notifications: any[] }): Promise<string | true> {

    const body = {
        "data": {
            "type": "alarm",
            "attributes": {
                ...notes
            }
        }
    }

    const resp = await post(body, `/api/v1/alarm/${alarmId}/notification`);
    if (typeof resp == "string") return resp;
    return true

}


function useAlarmsNotesState(am: AlarmMeta): [ANState, React.Dispatch<ANACtion>] {
    const [state, dispatch] = useReducer(reducer, {
        items: am.notifications,
        error: "",
        loading: false,
        formVisible: false,
        newNoteText: ""
    })

    return [state, dispatch]
}

type ANState = {
    items: Note[]
    newNoteText: string
    loading: boolean
    error: string
    formVisible: boolean
}

type ANACtion =
    | { type: "setNewNoteText", value: string }
    | { type: "setError", value: string }
    | { type: "setLoading", value: boolean }
    | { type: "setFormVisible", value: boolean }
    | { type: "setItems", value: Note[] }

function reducer(state: ANState, action: ANACtion): ANState {
    switch (action.type) {
        case "setError": return { ...state, error: action.value }
        case "setLoading": return { ...state, loading: action.value }
        case "setItems": return { ...state, items: action.value }
        case "setFormVisible": return { ...state, formVisible: action.value }
        case "setNewNoteText": return { ...state, newNoteText: action.value }
    }
}


type AlarmMeta = {
    notifications: Note[]
}

type Note = {
    note: string | null,
    recipient_role?: string
    recipient_email?: string
    sent_email_time?: string
    sent_email_status?: string
    recipient_username?: string
}

const body = {
    "notifications": [
        {
            "note": null,
            "recipient_role": "tenant",
            "recipient_email": "testone@one.it",
            "sent_email_time": "2021-04-22T14:07:19.432719Z",
            "sent_email_status": "ok",
            "recipient_username": "testone"
        },
        {
            "note": null,
            "recipient_role": "administrator",
            "recipient_email": "tonto",
            "sent_email_time": "2021-04-22T14:07:19.386269Z",
            "sent_email_status": "ok",
            "recipient_username": "utanto"
        }, {
            "note": "Bla bla",
            "created_at": "2021-04-22T14:07:19.386269Z"
        }
    ]
}