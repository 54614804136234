import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {gray} from '../../utils/colors';
import {Search} from './icons';
import {H4} from './text';

type Props = {
  value: string;
  onChange?: (s: string) => void;
  enabled?: boolean;
  label?: string;
  big?: boolean;
  searchIconVisible?: boolean;
  className?: string;
  tabValue?: number;
  type?: string;
  style?: any;
};

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
`;

export const InputLabel = styled.h4`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${_ => gray};
  margin-bottom: 4px;
`;

export const Input = styled.input<{big?: boolean; disabled: boolean}>`
  flex-grow: 1;
  border: 1px ${_ => gray} solid;
  color: ${_ => gray};
  border-radius: 6px;
  overflow-y: hidden;
  height: ${pr => (pr.big ?? false ? '128px' : '32px')};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: white;
  line-height: 30px;
  background-color: transparent;
  transform-origin: center center;
  resize: none;
  transition: all 0.3s ease-in-out;
  ${props =>
    props.disabled &&
    css`
      background-color: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    `}
  &:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transform: scaleX(1.02);
  }
`;

export const InputArea = styled.textarea<{big?: boolean; disabled: boolean}>`
  flex-grow: 1;
  border: 1px ${_ => gray} solid;
  color: ${_ => gray};
  border-radius: 6px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: white;
  line-height: 30px;
  background-color: transparent;
  transform-origin: center center;
  transition: all 0.3s ease-in-out;
  ${props =>
    props.disabled &&
    css`
      background-color: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    `}
  &:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transform: scaleX(1.02);
  }
`;

const InputIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 4px;
`;

export function TextAreaInput(props: Props) {
  const searchIconVisible = props.searchIconVisible ?? false;

  return (
    <InputContainer
      className={`${props.className ? props.className : ''}`}
      style={{marginLeft: (props.tabValue ?? 0) * 8, ...(props.style ?? {})}}>
      {props.label && <H4> {props.label}</H4>}
      <InputArea
        value={props.value}
        rows={10}
        lang={'it-IT'}
        disabled={props.onChange === undefined || !(props.enabled ?? true)}
        onChange={e => {
          props.onChange && props.onChange(e.target.value);
        }}
      />
      {searchIconVisible && (
        <InputIcon className={'tv-input-icon'}>
          <Search />
        </InputIcon>
      )}
    </InputContainer>
  );
}

export function TextInput(props: Props) {
  const searchIconVisible = props.searchIconVisible ?? false;

  return (
    <InputContainer
      className={`${props.className ? props.className : ''}`}
      style={{marginLeft: (props.tabValue ?? 0) * 8, ...(props.style ?? {})}}>
      {props.label && <H4>{props.label}</H4>}

      {props.type === 'integer' && (
        <Input
          big={props.big}
          type={props.type}
          value={
            Number(props.value) != NaN
              ? Number(props.value).toString()
              : props.value
          }
          step={1}
          min={1}
          lang={'it-IT'}
          disabled={props.onChange === undefined || !(props.enabled ?? true)}
          onChange={e => {
            if (!isNaN(Number(e.target.value)))
              props.onChange && props.onChange(e.target.value);
          }}
        />
      )}

      {props.type !== 'integer' && (
        <Input
          big={props.big}
          type={props.type}
          value={props.value}
          lang={'it-IT'}
          disabled={props.onChange === undefined || !(props.enabled ?? true)}
          onChange={e => {
            // if(props.type === "number") {

            //     const value: string = e.target.value;

            //     if(new RegExp("^-?[0-9][0-9,\.]+$").test(value)) {
            //       props.onChange && props.onChange(e.target.value)
            //       return
            //     }

            // }

            props.onChange && props.onChange(e.target.value);
          }}
        />
      )}
      {searchIconVisible && (
        <InputIcon className={'tv-input-icon'}>
          <Search />
        </InputIcon>
      )}
    </InputContainer>
  );
}
