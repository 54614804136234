import moment from "moment";
import React, { useEffect, useReducer } from "react"
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { OutlineButton, Row } from "../../../components/barbagli/common";
import { H4, Title } from "../../../components/barbagli/text";
import { DatePickerInput } from "../../../components/datepicker";
import { Divider } from "../../../components/divider";
import { ErrorComponent } from "../../../components/error_component";
import { LoaderComponent } from "../../../components/loading_widget";
import { Alarm } from "../../../models/barbagli/alarm"
import { Condominium } from "../../../models/barbagli/condominium";
import { ErrorMapping } from "../../../models/barbagli/error_mapping";
import { Attributes, Meter } from "../../../models/meter";
import { fetchCondominium } from "../../../repsitory/barbagli/condominium_repository";
import { remoteFetchAttributes, remoteFetchData } from "../../../repsitory/generic_repository";
import { ConsumptionsIndex } from "../consumptions_index/consumptions_index";
import { showMeterBlacklist } from "../meters_page/meter_blacklist_view";
import { MeterCondominiumView } from "../meters_page/meter_condominium_view";
import { ReadingsIndex } from "../readings_index/readings_index";
import { showAlarmCloseModal } from "./alarm_close_view";
import { AlarmsCondominiumAndMeterLinks } from "./alarm_links_component";
import { showAlarmNotesPage } from "./alarm_notes_page/alarms_notes_page";

type Props = {
    alarm?: Alarm
}

type AlarmDetailPageState =
  | {type: "error", error: string}
  | {type: "loading"}
  | { 
    type: "success",
    alarm: Alarm,
    meterAttributes: Attributes,
    condominium?: Condominium,
    consumptionsFrom: Date,
    consumptionsTo: Date,
    readingsFrom: Date,
    readingsTo: Date,
    mapping: ErrorMapping
  }


type AlarmDetailPageActions = 
| { type: "setAlarm", alarm: Alarm }
| { type: "setError", error: string}
| { type: "setConsominium", condominium: Condominium }
| { type: "setMeterAttributes", meterAttributes: Attributes }
| { type: "setConsumptionsFrom", consumptionsFrom: Date }
| { type: "setConsumptionsTo", consumptionsTo: Date }
| { type: "setReadingsFrom", readingsFrom: Date }
| { type: "setReadingsTo", readingsTo: Date }
| { type: "setLoading" }
| { type: "setSuccess", alarm: Alarm, meterAttributes: Attributes, condominium?: Condominium, mapping: ErrorMapping}



function reducer(state: AlarmDetailPageState, action: AlarmDetailPageActions): AlarmDetailPageState {

    if(action.type === "setLoading") return {type: "loading"}
    if(action.type === "setError") return {type: "error", error: action.error}



    if(action.type === "setSuccess") {

        const now = moment(new Date(action.alarm.date)).endOf("day").toDate()
        const oneWeekAgo = moment(new Date(action.alarm.date)).day(-7).startOf("day").toDate()

        return {
        type: "success",
        condominium: action.condominium,
        meterAttributes: action.meterAttributes,
        alarm: action.alarm,
        consumptionsFrom: oneWeekAgo,
        consumptionsTo: now,
        readingsFrom: oneWeekAgo,
        readingsTo: now,
        mapping: action.mapping
    }}

    if(state.type !== "success") return state;

    switch(action.type) {
    case "setAlarm": return {...state, alarm: action.alarm }
    case "setConsominium": return {...state, condominium: action.condominium }
    case "setMeterAttributes": return {...state, meterAttributes: action.meterAttributes }
    case "setConsumptionsFrom": return {...state, consumptionsFrom: action.consumptionsFrom }
    case "setConsumptionsTo": return {...state, consumptionsTo: action.consumptionsTo }
    case "setReadingsFrom": return {...state, readingsFrom: action.readingsFrom }
    case "setReadingsTo": return {...state, readingsTo: action.readingsTo }
    }

}

const PanelContainer = styled.div`
`

async function loadAssociatedResources(alarm: Alarm, state: AlarmDetailPageState, dispatch: React.Dispatch<AlarmDetailPageActions>) {
    const meterIdentifier = alarm.meterIdentifier
    const meter_response: string | Meter[] = await remoteFetchData(`/api/v1/meter?identifier=${meterIdentifier}`)
    if(typeof meter_response === "string") {
        dispatch({type: "setError", error: meter_response})
        return
    }
    const [meter] = meter_response;

    if(meter === undefined) dispatch({ type: "setError", error: `Identificativo del meter non trovato ${meterIdentifier}`})

    // Mappings
    const mappingsUrl = `/api/v1/mapping?class=${meter?.attributes?.class}`
    const mappingResponse: string | ErrorMapping[] = await remoteFetchData(mappingsUrl);

    if(typeof(mappingResponse) === "string" || mappingResponse.length === 0) {
        console.error("cannot_find_mapping_for_this_alarm")
        dispatch({type: "setError", error: "cannot_find_mapping_for_this_alarm"})
        return
    }
    // @ts-ignore
    const [mapping] = mappingResponse.map((m) => m.attributes).filter((m) => m.name === alarm.name);


    let condominium: Condominium | undefined = undefined;

    if(alarm.condominiumId) {
        const condominium_response = await fetchCondominium(alarm.condominiumId) 
        if(typeof condominium_response !== "string") {
            condominium = condominium_response;
        } else {
            console.error(condominium_response)
        }
    }

    dispatch({type: "setSuccess", condominium, meterAttributes: meter.attributes, alarm, mapping})
} 

export function AlarmDetailPage(props: Props) {
    const history = useHistory();
    const intl = useIntl();
    const alarm: Alarm | undefined = props.alarm ?? ((history?.location?.state as Alarm)?.name ? (history.location.state as Alarm) : undefined);


    const [state, dispatch] = useReducer(reducer, alarm 
        ? {type: "loading"}
        : {type: "error", error: intl.messages["error_cannot_find_alarm"].toString()})

    useEffect(() => {
        if(alarm) loadAssociatedResources(alarm, state, dispatch)
    }, [props]) 



    if(state.type === "loading") return <LoaderComponent></LoaderComponent>
    if(state.type === "error") return <ErrorComponent message={intl.messages[state.error] ? intl.messages[state.error].toString() : state.error}></ErrorComponent>

    return <div>
        
        {state.condominium && 
        <AlarmsCondominiumAndMeterLinks
            condominium={state.condominium}
            subCondominiumId={state.alarm.subCondominiumId}
            immobileId={state.alarm.immobileId}
            meterAttributes={state.meterAttributes}
        />}
        {state.condominium && <Divider></Divider>}
        <div>
            <Title>{state.mapping.displayName}</Title>
            <H4>{moment(state.alarm.date).format("DD/MM/yyyy HH:mm")}</H4>
        </div>
        <Row center>
            {/* <div style={{ height: 55, border: "1px solid black", flexGrow: 1}}>
                Componente Notifiche in costruzione 
            </div> */}
            {state.alarm.id && <OutlineButton onClick={() => showAlarmNotesPage({
                    notes: state?.alarm?.meta ?? {notifications: []},
                    onUpdate: (meta: any) => dispatch({type: "setAlarm", alarm: {...state.alarm, meta: meta}})
                , alarmId: state.alarm.id!})}>NOTE</OutlineButton> }



            <OutlineButton onClick={() => showAlarmCloseModal(intl.messages as any, state.meterAttributes, state.alarm)}>
                {intl.messages["close_alarm"].toString().toUpperCase()}
            </OutlineButton>
        </Row>
        <Divider></Divider>
        <PanelContainer>
            <Title>{intl.messages["readings"]}</Title>
            <ReadingsIndex tabHidden dexFilters={{type: "CONDITION", name:"meter_identifier", value: state.meterAttributes.identifier}} 
            from={moment(state.alarm.date).subtract(3, "days").startOf("day").toDate()}
            to={moment(state.alarm.date).add(3, "days").startOf("day").toDate()}
            ></ReadingsIndex>
        </PanelContainer>
        <Divider></Divider>
        <PanelContainer>
            <Title>{intl.messages["consumptions"]}</Title>
            <ConsumptionsIndex 
            tabHidden 
            source="consumptions"
            dexFilters={{type: "CONDITION", name:"meter_identifier", value: state.meterAttributes.identifier}}
            from={moment(state.alarm.date).subtract(7, "days").startOf("day").toDate()}
            to={moment(state.alarm.date).add(7, "days").startOf("day").toDate()}
            ></ConsumptionsIndex>
        </PanelContainer>

    </div>
}

