import { Condominium } from "../../../models/barbagli/condominium";
import { Gateway } from "../../../models/gateway";
import { fetchCondominium } from "../../../repsitory/barbagli/condominium_repository";
import { createGateway, updateGateway, deleteGateway as dm } from "../../../repsitory/barbagli/gateway_repository";
import { ConfigurationSpec } from "../../../models/configuration";
import { v1 } from "uuid";
import store from "../../../store/store";
import { subscribeToAction } from "../../../store/actions/logs_actions";
import { AlertManager } from "react-alert";
import { remoteFetchData } from "repsitory/generic_repository";


export function validate(state: Gateway & { error?: string, loading?: boolean, uuid?: string }): string[] {
    let validation_result: string[] = [];
    if (!state.serial || state.serial == "") validation_result = [...validation_result, "serial number should be present"];
    // if(!state.address || state.address == "") validation_result = [...validation_result, "address should be present"];
    // if(!state.backhaulId ) validation_result = [...validation_result, "backhauld config should be present"];
    if (!state.deviceTypeId || state.deviceTypeId == "") validation_result = [...validation_result, "class_should_be_present"];
    return validation_result;
}


export async function loadAssociatedCondominium(id?: number): Promise<Condominium | string> {
    if (!id) return "no id"
    return fetchCondominium(id);
}


export async function createEditGateway(state: Gateway & { error?: string, loading?: boolean }, setState: React.Dispatch<React.SetStateAction<Gateway & { error?: string, loading?: boolean, uuid?: string }>>): Promise<string | true> {
    if (state.id) {
        //  @ts-ignore
        return updateGateway(state).then((r) => {
            if (typeof r === "string") return r;
            return true;
        })
    } else {
        const uuid = v1();

        return createGateway(state).then((r: any) => {

            if (r.uuid !== undefined) return true

            if (typeof r === "string") return r;
            return true;
        })
    }
};

export async function deleteGateway(gateway: Gateway & { error?: string, loading?: boolean }): Promise<string | true> {
    return dm(gateway.id!);
};

export const gatewayConfigSpecs: ConfigurationSpec[] = [
    {
        name: "proxy_ip",
        type: "string"
    },
    {
        name: "box_ip",
        type: "string"
    },
    {
        name: "iccd",
        type: "string"
    },
    {
        name: "imei",
        type: "string"
    },
    {
        name: "phone_number",
        type: "string"
    },
    {
        name: "filter",
        type: "string"
    },
    {
        name: "rx_time",
        type: "string"
    },
    {
        name: "warning",
        type: "string"
    },
]


export async function fetchCommandState(deviceTypeId: number | string, identifier: string) {
    const r = await remoteFetchData(`/api/v1/commands/${deviceTypeId}/status/${identifier}`);
    if (typeof r === "string") return "Impossible scaricare lo stato del gateway";
    return r;
}