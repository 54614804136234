import {history } from '../../index';


export function showDashboardDetail(name: string) {

    const namelc = name.toLowerCase();
    if(namelc.includes("meter")) {
        history.push(`/meters?dashboard=${name}`)
    }

    if(namelc.includes("centralin")) {
        history.push(`/gateways?dashboard=${name}`)
    }

    if(namelc.includes("cond")) {
        history.push(`/condominiums?dashboard=${name}`)
    }

    if(namelc.includes("aziende")) {
        history.push(`/companies`)
    }

    if(namelc.includes("installatori")) {
        history.push(`/installer`)
    }

}