import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { APP_BASE_URL, BASE_URL } from "../../../..";
import { Column, OutlineButton, Row } from "../../../../components/barbagli/common";
import { StandardModalFrame } from "../../../../components/barbagli/modal";
import { Title } from "../../../../components/barbagli/text";
import kc_values from "./kc_values.json";




export function showKcSelection(onDone: (n: string) => void, inlt: Record<string, string>) {

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({onClose}) =>
      <StandardModalFrame
      style={{ minHeight: 150, overflowX: "auto", backgroundColor: "white" }}
      className={""}
  >
        <Column>
            <Title>{inlt["select_kind"]}</Title>
            {kc_values.map((i) => {
                return <Row key={i.ntipo_corposcaldante}>
                    <OutlineButton style={{flexGrow: 1}} onClick={() => {
                        onDone(i.tipo_corposcaldante);
                        onClose();
                    }}>
                        <Column style={{justifyContent: "center", alignItems: "center"}}>
                        ({i.ntipo_corposcaldante}) {i.tipo_corposcaldante}
                        <img style={{height: 100, width: 100}} src={`${APP_BASE_URL}/${i.immagine}`}></img>
                        </Column>

                    </OutlineButton>
                </Row>
            })}
            <Row style={{flexDirection: "row-reverse"}}>
                <OutlineButton onClick={onClose}>{inlt["cancel"]}</OutlineButton>
            </Row>
        </Column>
        </StandardModalFrame>
    });
  }