import React from 'react';
import { useIntl } from 'react-intl';
import {Link} from "react-router-dom";
import MenuItemType, {menuRoutes} from "../models/menu_item_type";

type Props = {
  active: boolean,
  name: MenuItemType
  linkTo?: string,
  secondary?: boolean
  visible?: boolean
}

export function SidebarItem(props: Props) {

  const secondary = props.secondary ?? false;
  const visible = props.visible ?? true;
  const className = `d-flex flex-row align-items-center ${props.active ? "sidebar-item-active" : "sidebar-item"}`;
  const linkTo: string = props.linkTo || menuRoutes[props.name];
  const intl = useIntl();

  return (
    <div className={ visible ? className : "" }>
      { visible && <Link to={linkTo} className={secondary ? "pl-4" : ""} style={{textDecoration: 'none', color: '#fff'}}>
        <div className={`ml-4 ${secondary ? "p-2 pl-4" : "p-4"} pr-5`}>
          {intl.messages[props.name] ?? props.name}
        </div>
      </Link> }
    </div>
);
}