import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Select = styled.select`
  width: 49.9%; /* Занимает примерно половину ширины контейнера */
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px; /* Скругление углов */
  border: 1px solid #666666; /* Добавление рамки для видимости */
  background: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const SearchButton = styled.button`
  background-color: #3c4b64;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #125699;
  }
`;

const Option = styled.option`
  padding: 0.5rem;
`;

const Result = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`;

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

interface QuarterYearSelectProps {
  setDates: (dates: string[]) => void;
}

const QuarterYearSelect: React.FC<QuarterYearSelectProps> = ({ setDates }) => {
  const intl = useIntl();
  const quarters = [
    { value: "Q1", label: intl.messages["first_quarter"] },
    { value: "Q2", label: intl.messages["second_quarter"] },
    { value: "Q3", label: intl.messages["third_quarter"] },
    { value: "Q4", label: intl.messages["fourth_quarter"] },
  ];

  const getPreviousQuarter = (month: number) => {
    if (month >= 0 && month <= 2) {
      return { quarter: "Q4", year: currentYear - 1 };
    } else if (month >= 3 && month <= 5) {
      return { quarter: "Q1", year: currentYear };
    } else if (month >= 6 && month <= 8) {
      return { quarter: "Q2", year: currentYear };
    } else {
      return { quarter: "Q3", year: currentYear };
    }
  };

  const currentMonth = new Date().getMonth();
  const { quarter: initialQuarter, year: initialYear } =
    getPreviousQuarter(currentMonth);

  const [selectedQuarter, setSelectedQuarter] =
    useState<string>(initialQuarter);
  const [selectedYear, setSelectedYear] = useState<number>(initialYear);
  const [firstRender, setFirstRender] = useState(true);

  const handleQuarterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedQuarter(e.target.value);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const generateDates = () => {
    let months: number[];
    switch (selectedQuarter) {
      case "Q1":
        months = [0, 1, 2];
        break;
      case "Q2":
        months = [3, 4, 5];
        break;
      case "Q3":
        months = [6, 7, 8];
        break;
      case "Q4":
        months = [9, 10, 11];
        break;
      default:
        months = [];
    }

    const isoDates = months.map((month) => {
      let year = selectedYear;
      if (month === 11) {
        month = 0;
        year += 1;
      } else {
        month += 1;
      }
      const date = new Date(Date.UTC(year, month, 1, 0, 0, 0));
      return date.toISOString();
    });

    setDates(isoDates);
  };

  useEffect(() => {
    if (firstRender) {
      generateDates();
      setFirstRender(false);
    }
  }, [firstRender]);

  return (
    <Container>
      <SelectContainer>
        <Select value={selectedQuarter} onChange={handleQuarterChange}>
          {quarters.map((quarter) => (
            <Option key={quarter.value} value={quarter.value}>
              {quarter.label}
            </Option>
          ))}
        </Select>
        <Select value={selectedYear} onChange={handleYearChange}>
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </SelectContainer>
      <ButtonContainer>
        <SearchButton onClick={generateDates}>Cerca</SearchButton>
      </ButtonContainer>
    </Container>
  );
};

export default QuarterYearSelect;
