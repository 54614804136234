type MenuItemType =
  | "Dashboards"
  | "Network"
  | "Meters"
  | "Admin"
  | "Users"
  | "Groups"
  | "Data Explorer"
  | "Condominium"
  | "ErrorMapping"
  | "Alarms"
  | "Anomalies"
  | "Companies"
  | "Partner"
  | "Installer"
  | "My Net Domus User"
  | "Maglia Idrica"
  | "summary";

export const menuRoutes = {
  Dashboards: "/dashboards",
  Companies: "/companies",
  Condominium: "/condominiums",
  Network: "/gateways",
  ErrorMapping: "/error-mapping",
  Meters: "/meters",
  Admin: "/admin",
  Alarms: "/alarms",
  Anomalies: "/anomalies",
  "Maglia Idrica": "/maglia_idrica",
  Users: "/users",
  Groups: "/groups",
  "Data Explorer": "/data-explorer",
  Partner: "/partner",
  Installer: "/installer",
  "My Net Domus User": "/my-net-domus-user",
  summary: "/summary",
};

export default MenuItemType;
