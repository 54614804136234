import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled, { css } from "styled-components";
import { applyColumnTransform } from "../../contexts/filter_collection_context";
import { showColumnOrderModal } from "../../pages/data_explorer_page/columns_edit_modal/columns_edit_modal";
import { Asc, CogIcon, Desc } from "../icons";
import { DynamicPaginatedListComponent } from "../paginated_list/dynamic_paginated_list_component";
import { PaginatedList } from "../paginated_list/paginated_sublist";
import { P, Title } from "./text";
import { Row, IconButton } from "./common";
import { OrderParam } from "../../models/barbagli/filter_order";
import { camelToSnake } from "../../utils/object_formatting";
import { SortableIcon } from "./icons";
import { LoaderComponent } from "../loading_widget";
import { useLocation } from "react-router-dom";
import CSS from 'csstype';


type Props<T, U> = {
    /*Callback invoked when an item on the list is clicked on*/
    onTap?: (t: T) => void,
    /*Number of items to be shown on a page*/
    perPage?: number,
    /* Function delegated to fetch items, takes the current page and the amount of items per page returns a PaginatedList or a string in case of an error */
    fetchCollection: (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string>
    /* this function when specified is applied to each fetched item in order to pre process it (i.e. hide some field from visualizazion, changhe some field's name) */
    itemsPreFormatter?: (t: T) => U,
    /* The initial page to be shown */
    initialPage?: number,
    /* Whether or not to show badge with pagination on bottom of the list */
    showPaginationElement?: boolean;
    /* Optional list title */
    title?: string;
    /* If the list is ordered you should provide order to show on header of each column if the list is ordered by that header and if is asc or desc
       On order is a callback invoked when the user clicks on a header corresponding to an orderable column
       orderable columns is the list of header that can be uset to order the list
    */
    order?: OrderParam;
    onOrder?: (op: OrderParam) => void;
    orderableColumns?: string[];
    translated?: boolean;
    permutationKeyPostfix?: string;
    rowStyle?: (t: T) => CSS.Properties;
    reload?: boolean;

}



export function SortableTableView<T, U>(props: Props<T, U>) {
    const [headers, setHeaders] = useState<string[]>([]);
    const location = useLocation();



    const wrappedFetch = async (pn: number, ipp: number) => {
  
        setState({ ...state, loading: true });
        return props.fetchCollection(pn, ipp).then((r) => {
            setState({ ...state, loading: false });
            return r;
        })
    }

    const loadedPermutations = useMemo(() => {
        try {
            const sproperties = window.localStorage.getItem(`permutations${props.permutationKeyPostfix ?? ""}`);
            if (!sproperties) return { visibleColumns: [], permutations: [] }
            return JSON.parse(sproperties)
        } catch (e) {
            console.error("Cannot fetch configuration")
            return { visibleColumns: [], permutations: [] }
        }
    }, [props])

    const [state, setState] = useState<{ visibleColumns: boolean[], permutations: number[], loading: boolean }>({ ...loadedPermutations, loading: false });


    useEffect(() => {

        window.localStorage.setItem(`permutations${props.permutationKeyPostfix ?? ""}`, JSON.stringify({ visibleColumns: state.visibleColumns, permutations: state.permutations }))
    }, [state.visibleColumns, state.permutations])




    const intl = useIntl();
    return <ListContainer>
        <ListControls>
            <Title>{props.title ?? ""}</Title>
            <IconButton onClick={() => {
                showColumnOrderModal(
                    intl.messages as any,
                    applyColumnTransform(headers, state.permutations),
                    (order: number[], visibleColumns: boolean[]) => { setState({ ...state, permutations: order, visibleColumns }) },
                    state.visibleColumns,
                    state.permutations
                )
            }}>
                <Row style={{
                    display: "absolute",
                    right: 16
                }}>
                    <P className={"mr-2"} style={{ marginTop: 1 }}>{intl.messages["columns_order"].toString()}</P>
                    <CogIcon />
                </Row>
            </IconButton>
        </ListControls>
        <div style={{ maxWidth: "100vw", overflowX: "auto" }}>
            {state.loading && <div className="row d-flex justify-content-center"><LoaderComponent height={40} width={40}></LoaderComponent></div>}
            <DynamicPaginatedListComponent
                key={JSON.stringify(props.order)}
                fetchCollection={wrappedFetch}
                reload={props.reload}
                perPage={props.perPage}
                itemsPreFormatter={props.itemsPreFormatter}
                onTap={props.onTap}
                columnPermutations={state.permutations}
                visibleColumns={state.visibleColumns}
                rowStyle={props.rowStyle}
                onHeaders={(hs) => {
                    debugger
                    let nhs;
                    if (!props.translated) nhs = hs.map((s) => camelToSnake(s));
                    else {
                        nhs = hs.map((s) => intl.messages[s]?.toString() ?? camelToSnake(s))
                    }
                    setHeaders(nhs);
                }}
                headerBuilder={(s) =>
                    <OrderedHeader
                        order={props.order}
                        name={camelToSnake(s)}
                        translatedName={props.translated ? intl.messages[s]?.toString() : undefined}
                        orderableColumns={props.orderableColumns ?? []}
                        onClick={(o) => props.onOrder && props.onOrder(o)}
                    />
                }
            />
        </div>


    </ListContainer>
}

/*
    Shows the header with an icon indicating the direction of order (desc or asc), when clicked
    checks if orderable columns contain that name, if so calls the callback with the new order for the list item. 

    Otherwise it just shows the header as h3.
*/
export function OrderedHeader(props: { order?: OrderParam, translatedName?: string, name: string, orderableColumns: string[], onClick: (order: OrderParam) => void }) {

    if (!props.order) return <h3>{props.translatedName?.toUpperCase() ?? props.name.replace("_", " ")}</h3>
    return <Row style={{ whiteSpace: "nowrap", flexWrap: "nowrap" }} onClick={() => {

        if (!props.orderableColumns.includes(props.name)) return;

        if (props.order && props.order.column === props.name) {
            props.onClick({ ...props.order, order: (props.order.order! == "asc" ? "desc" : "asc") });
        } else {
            props.onClick({
                column: props.name,
                order: "desc"
            })
        }
    }}>
        {
            props.order && props.order.column === props.name &&
            <OrderIcon order={props.order!}></OrderIcon>
        }
        {
            props.orderableColumns.includes(props.name) && ((props.order && props.order.column !== props.name)) &&
            <div style={{ marginRight: 4, paddingBottom: 8 }}><SortableIcon /></div>
        }
        <HeaderText>{props.translatedName?.toUpperCase() ?? props.name.replace("_", " ").toUpperCase()}</HeaderText>
    </Row>
}

function OrderIcon(props: { order: OrderParam }) {
    if (props.order.order === "asc") {
        return <Asc></Asc>
    } else {
        return <Desc></Desc>
    }
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
`

const ListControls = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
`

const HeaderText = styled.h3`
    text-transform: uppercase;
    user-select: none;
    color: "#666";
`
