import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";
import { OutlineButton } from "../../../../components/barbagli/common";
import { Title } from "../../../../components/barbagli/text";
import { Condominium } from "../../../../models/barbagli/condominium";
import { Gateway } from "../../../../models/gateway";
import { GatewaysIndex } from "../../gateways_index/gateways_index";
import { CondominiumViewState } from "../condominium_view_model";
import { associateGatewayToCondominiumNode } from "./add_gateway_view_model";
import 'react-confirm-alert/src/react-confirm-alert.css';

type Props = {
  state: CondominiumViewState,
  intl: Record<string, string>,
  setState: React.Dispatch<React.SetStateAction<CondominiumViewState>>
  alert: any
}

export function AddGatewayView(props: Props) {
  const intl = props.intl;
  return <>
    <Title>{intl["select_a_gateway"]}</Title>
    <GatewaysIndex
      onClick={(gateway: Gateway) => {
        // console.log("Clicked on", gateway.id)
        if (gateway.condominiumId) {
          confirmAlert({
            title: intl["confirm"].toString(),
            message: intl["gateway_already_associated"].toString(),
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                  associateGatewayToCondominiumNode(props.state.selectedNode, props.state.condominium as Condominium, gateway).then(
                    (resp) => {
                      if (typeof resp === "string") {
                        props.alert.show(intl["impossible_to_associate_gateway"])
                      }
                      else {
                        props.alert.show(intl["gateway_successfully_associated"]);
                        props.setState({ ...props.state, state: "show" })
                      }
                    }
                  );

                }
              },
              {
                label: 'No',
                onClick: () => { }
              }
            ]
          });

        } else {
          associateGatewayToCondominiumNode(props.state.selectedNode, props.state.condominium as Condominium, gateway).then(
            (resp) => {
              if (typeof resp === "string") {
                //props.alert.show(intl["impossible_to_associate_gateway"])
                props.alert.show(resp)
              }
              else {
                props.alert.show(intl["gateway_successfully_associated"]);
                props.setState({ ...props.state, state: "show" })
              }
            }
          );

        }
      }}
    ></GatewaysIndex>
    <div className="row mt-3">
      <div className="col-md-12 d-flex justify-content-end">
        <OutlineButton onClick={() => props.setState({ ...props.state, state: "show" })}>{intl["back"]}</OutlineButton>
      </div>
    </div>
  </>
}