import { ErrorMapping } from "../../models/barbagli/error_mapping";
import { putRaw } from "../generic_repository";

export async function updateErrorMapping(em: ErrorMapping): Promise<{ id: number } | string> {
    const data = {
      "data": {
        "attributes": {
          ...em,
          "display_name": em.displayName,
          "blocks_consumptions": em.blocksConsumptions
        },
        "type": "mapping",
      }
    };
  
    try {
      let  id = em.id;
      (await putRaw(data,  `/api/v1/mapping/${em.id}`));
      return { id };
    } catch (e) {
      console.log(e);
      return e.toString();
    }
  }