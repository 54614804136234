import { Attributes } from "models/meter"
import { deepCopy } from "utils/deep_copy"

export type DynamicMeterField<T extends "string" | "number"> = {
    type: T,
    value: T extends "number" ? number : string
}

export type DynamicMeterFieldState = Record<string, DynamicMeterField<"string" | "number">>

export type DynamicMeterFieldAction = 
    | {type: "updateField", key: string, value: DynamicMeterField<"string" | "number">}

export function reducer(state: DynamicMeterFieldState, action: DynamicMeterFieldAction): DynamicMeterFieldState {
    const state2 = deepCopy(state);
    state2[action.key] = action.value;
    return state2;
}

export function fromAttributes(meterAttributes: Attributes): DynamicMeterFieldState {
    if(meterAttributes.meta["additional_fields"]) {
        return meterAttributes.meta["additional_fields"] as DynamicMeterFieldState
    } else {
        return {}
    }
}