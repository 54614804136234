import { TypeAuthorized } from "components/authorized";
import { FlatButton, OutlineButton } from "components/barbagli/common";
import React, { useState } from "react"
import { useIntl } from "react-intl";
import { Title } from "../../../../components/barbagli/text";
import { TextInput } from "../../../../components/barbagli/text_input";
import { SubCondominium } from "../../../../models/barbagli/condominium";
import { moveUp } from "../condominium_view_model";

type Props = {
    subcondominium: SubCondominium
    onEdit: (c: SubCondominium) => void
    condominiumEnabled: boolean
    moveUp: () => void
}

export function SubCondominiumDataView(props: Props) {
    const intl = useIntl();
    const [search, setSearch] = useState("");
    return <div className="d-flex flex-column">
        <Title className="mt-3">{intl.messages["data"].toString()}</Title>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["stair"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.subcondominium.data.stair}
                    onChange={(v) => props.onEdit({ ...props.subcondominium, data: { ...props.subcondominium.data, stair: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["sc_address"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.subcondominium.data.address}
                    onChange={(v) => props.onEdit({ ...props.subcondominium, data: { ...props.subcondominium.data, address: v } })}
                />
            </div>
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["house_number"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.subcondominium.data.number}
                    onChange={(v) => props.onEdit({ ...props.subcondominium, data: { ...props.subcondominium.data, number: v } })}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <TextInput
                    label={intl.messages["sc_name"].toString()}
                    enabled={!props.condominiumEnabled}
                    value={props.subcondominium.data.name ?? ''}
                    onChange={(v) => props.onEdit({ ...props.subcondominium, data: { ...props.subcondominium.data, name: v } })}
                />
            </div>
            <div className="col-md-4" style={{ marginTop: 30 }}>
                <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                    <OutlineButton onClick={() => {
                        props.moveUp()
                    }}>{intl.messages["move_up"]}</OutlineButton>
                </TypeAuthorized>
            </div>
        </div>

    </div>
}