import React, { useState } from "react"
import styled from "styled-components"
import { gray, lightGray } from "../../utils/colors"

type Props = {
    children: [string, React.ReactElement][]
}

const Tab = styled.button`
    padding-left: 16px;
    padding-right: 16px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: transparent;
    border: 1px solid ${(_) => gray};
    margin-right: -1px;
    height: 32px;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    &:active {
        background-color: ${(_) => lightGray};
    }
`
const TabValue = styled.p<{selected: boolean}>`
    font-weight: ${(props) => props.selected ? 900 : 400};
    text-transform: uppercase;
`

const ButtonPanel = styled.div`
`
const TabViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
`

const TabViewHeaderContainer = styled.div`
display: flex;
flex-direction: row;
`

const TabViewContentContainer = styled.div`
    margin-top: -1px;
    border: 1px solid ${(_) => gray};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1rem;
`

export function TabView(props: Props) {
    const [selectedIndex, setSekectedIndex] = useState(0)
    return <TabViewContainer>
        <TabViewHeaderContainer>
        { props.children.map(([title, _], i) => <Tab key={title} onClick={() => setSekectedIndex(i)}><TabValue selected={i == selectedIndex}>{title}</TabValue></Tab>) }
        </TabViewHeaderContainer>
        
        <TabViewContentContainer>
            {props.children[selectedIndex][1]}
        </TabViewContentContainer>
        
    </TabViewContainer>
}