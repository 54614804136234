import React, { useReducer, useMemo } from "react"
import { useIntl } from "react-intl";
import styled from "styled-components";
import { TextInput } from "components/barbagli/text_input";
import { Column, LoaderButton, OutlineButton } from "components/barbagli/common";
import {history} from "index";
import { deepCopy } from "utils/deep_copy";
import { fetchPaginatedCollection, httpDelete, post, put } from "repsitory/generic_repository";
import { useAlert } from 'react-alert'
import { showAlert } from "components/alerts/alert";
import { AsyncSelect, AsyncSelectIdsFromCrud } from "components/barbagli/select";
import { fetchCondominium, fromJson } from "repsitory/barbagli/condominium_repository";
import { Condominium } from "models/barbagli/condominium";

type Props = {
    company?: Company
    validationKeys?: string[] 
}

export function CompanyDetailPage(props: Props) {
    const item: Company = props.company ?? isACompany(history?.location?.state) ? (history?.location?.state as Company) : emptyCompany();

    const [state, dispach] = useReducer(reducer, deepCopy(item))

    const intl = useIntl()
    const alert = useAlert();
    
    return <Column>

        <Layout>
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["address"]?.toString() ?? "address").toUpperCase()}
                value={(state.address).toString()}
                onChange={(v) => { dispach({ type: "set_address", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["cap"]?.toString() ?? "cap").toUpperCase()}
                value={(state.cap).toString()}
                onChange={(v) => { dispach({ type: "set_cap", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["city"]?.toString() ?? "city").toUpperCase()}
                value={(state.city).toString()}
                onChange={(v) => { dispach({ type: "set_city", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["code"]?.toString() ?? "code").toUpperCase()}
                value={(state.code).toString()}
                onChange={(v) => { dispach({ type: "set_code", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["name"]?.toString() ?? "name").toUpperCase()}
                value={(state.name).toString()}
                onChange={(v) => { dispach({ type: "set_name", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["prov"]?.toString() ?? "prov").toUpperCase()}
                value={(state.prov).toString()}
                onChange={(v) => { dispach({ type: "set_prov", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["vat"]?.toString() ?? "vat").toUpperCase()}
                value={(state.vat).toString()}
                onChange={(v) => { dispach({ type: "set_vat", value: v }) }}
            />
            <AsyncSelectIdsFromCrud 
            style={{marginRight: 24, marginTop: 8}}
            label="Condomini"
            baseUrl="/api/v1/condominium"
            idExtractor={(a: any) => a?.id}
            valueMapper={(a: any) => a?.name}
            filterName="name"
            placeholder="Seleziona condominio"
            selectedIds={state.condominiums}
            onIdsSelected={(ids: number[]) => dispach({type: "set_conds", value: ids})}
            />
                        <AsyncSelectIdsFromCrud
                style={{ marginRight: 24, marginTop: 8 }}
                label="Partner"
                baseUrl="/api/v1/partner"
                idExtractor={(a: any) => a?.id}
                valueMapper={(a: any) => a?.companyName}
                filterName="name"
                placeholder="Seleziona Partner"
                selectedIds={state.partners ?? []}
                onIdsSelected={(ids: number[]) => dispach({ type: "set_partners", value: ids })}
            />

        </Layout>
<BottomRow>
            <LoaderButton onClick={async () => {
                return createUpdate(state, intl, history, alert, props.validationKeys ?? [], onCreate, onUpdate)
            }}>
                {(state.id ? (intl.messages["update"] ?? "update") : (intl.messages["create"] ?? "create")).toString()}
            </LoaderButton>
            {
                state.id && <OutlineButton style={{marginRight: 8}} onClick={
                    async (_) => {
                        showAlert(intl.messages["are_you_sure"].toString(), intl.messages["this_action_cannot_be_undone"].toString(), {
                            onDone: async () => {
                                const result = await onDelete(state);
                                if(typeof result == "string") alert.error(result);
                                history.goBack();
                                alert.info(intl.messages["success_deletion"])
                            }, secondaryButtonText: intl.messages["discard"].toString()
                        })
                        
                    }}>{(intl.messages["delete"] ?? "delete")
                    }</OutlineButton>
            }
            
            <OutlineButton style={{marginRight: 8}} onClick={(_) => history.goBack()}>{(intl.messages["cancel"] ?? "cancel")}</OutlineButton>
        </BottomRow>
    </Column>

}

//_______________Visual Blocks
const Layout = styled.div`
display: flex;
flex-wrap: wrap;
`
const BottomRow = styled.div`
display: flex;
flex-direction: row-reverse;
padding-right: 8px;
margin-right: 8px;
`
//______________End Visual Blocks



//______________Model
export type Company = {
    id?: number,
  address: string,
  cap: string,
  city: string,
  code: string,
  name: string,
  prov: string,
  vat: string
  condominiums: number[],
  partners: number[]
    
}

export function emptyCompany(): Company {
  return {
    address: "",
    cap: "",
    city: "",
    code: "",
    name: "",
    prov: "",
    vat: "",
    condominiums: [],
    partners: []
  }
}

export function isACompany(c: any): boolean {
    return c?.address && c?.cap && c?.city && c?.code && c?.name && c?.prov && c?.vat;
}
//______________End 



//_______________Reducer
type PageState = Company;

type PageAction = 
  | { type: "set_address", value: string }
  | { type: "set_cap", value: string }
  | { type: "set_city", value: string }
  | { type: "set_code", value: string }
  | { type: "set_name", value: string }
  | { type: "set_prov", value: string }
  | { type: "set_vat", value: string }
  | { type: "set_conds", value: number[]}
  | { type: "set_partners", value: number[]}

function reducer(state: PageState, action: PageAction): PageState {
  switch(action.type) {
    case "set_address": return { ...state, address: action.value }
    case "set_cap": return { ...state, cap: action.value }
    case "set_city": return { ...state, city: action.value }
    case "set_code": return { ...state, code: action.value }
    case "set_name": return { ...state, name: action.value }
    case "set_prov": return { ...state, prov: action.value }
    case "set_vat": return { ...state, vat: action.value }
    case "set_conds": return {...state, condominiums: action.value}
    case "set_partners": return {...state, partners: action.value}
  }
}
//______________End Reducer




//____Functions to Handle Effects

async function createUpdate(state: PageState, intl: any, history: any, alert: any, requiredFields: string[], onCreate: any, onUpdate: any): Promise<string | true> {
    // Phase 1 validation of state
    const errors = requiredFields.reduce((acc: string, x: string) => {
        // @ts-ignore
        if(!state[x] || state[x] === "") {
            return `Il campo ${(intl.messages[x] ?? x).toString()} non può essere vuoto\n${acc}`
        }
        return acc;
    }, "")

    if(errors != "") { 
        showAlert(intl.messages["validation_errors"], errors) 
        return "errore di validazione"
    }

    const result = await (state.id ? onUpdate(state) : onCreate(state));
    if(typeof result != "string") { 
        history.goBack();
        alert.info(intl.messages["success"])
        return true;
    } else {
        alert.error(result)
        return result;
    }
}


const onCreate =  (async (i: PageState) => post({data: {type: "company", attributes: i}}, "/api/v1/company"))
const onUpdate = (async (i: PageState) => put({data: {type: "company", attributes: i}}, `/api/v1/company/${i.id}`))
const onDelete =  (async (i: PageState) => httpDelete( `/api/v1/company/${i.id}`))

//End Functions to Handle Effects
