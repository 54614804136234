import { confirmAlert } from "react-confirm-alert";
import React, { ReactNode } from "react";
import "../configuration_editor/configuration_editor.css"
import { ClearIcon } from "../icons";
import { Divider } from "../divider";
import { Column, OutlineButton, Row } from "components/barbagli/common";
import { useFileUploaderState } from "./file_uploader_state";
import { Provider } from "react-redux";
import store from "store/store";
import styled from "styled-components";
import { LoaderComponent } from "components/loading_widget";
import { formatBody } from "components/paginated_list/dynamic_paginated_list_component";
import { useIntl } from "react-intl";
import { I18n } from "components/I18n";
import { translate } from "utils/intl";

type Config = {
  onDone?: () => void,
  secondaryButtonText?: string
  secondaryButtonCallback?: () => void
  icon?: ReactNode
}

export function showFileUploader(url: string, config: Config = {}, webSocketEnabled?: boolean) {

  const onDoneCallback = config.onDone ?? (() => { });

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({ onClose }) => <div className={"configuration-editor p-3"}>
      <Provider store={store}>
      <I18n>
      <FileUploaderComponent onClose={onClose} url={url} webSocketEnabled={webSocketEnabled ?? false} />
      </I18n>
        
      </Provider>

    </div>

  });
}

export const OutlineLabel = styled.label`
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(60, 75, 100, 0.77);
    color: #3C4B64;
    padding: 0.5rem 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 15px;
    text-align: center;
`

const ResCont = styled.div`
height: 400px;
overflow-y: auto;
margin-top: 20px;
`

function FileUploaderComponent(props: { onClose: any, webSocketEnabled: boolean, url: string }) {
  const [state, dispach, uploadFile, logs] = useFileUploaderState(props.webSocketEnabled, props.url ?? "/api/v1/meter/import")
  const intl = useIntl();

  return <Column>
  <h1>Importazione da file</h1>
  {state.loading && <Row style={{justifyContent: "center"}}><LoaderComponent></LoaderComponent></Row>}
     {(state.api_result || state.logs != []) && <ResCont>
    {parseApiResponse(state.api_result, intl.messages as any)}
    {parseApiLogs(logs, intl.messages as any)}
    </ResCont>}

    <Row style={{ justifyContent: "flex-end" }}>
        <OutlineLabel htmlFor="file-upload" style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: 0 }} >
          <span className="material-icons-outlined">file_upload</span>
            Load File
        </OutlineLabel>
        {!state.loading && <input id="file-upload" style={{ display: "none" }} type="file" onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) return;
          const file = e.target.files[0];
          uploadFile(file);
        }} />}

      <OutlineButton onClick={props.onClose}>Chiudi</OutlineButton>
    </Row>
  </Column>

  // if(state.loading && props.webSocketEnabled) return <pre>{JSON.stringify(state, null, 2)}</pre>

}

function parseApiResponse(apiResponse: any, intl: Record<string, string>): React.ReactNode {
  try {
      return apiResponse.map((ar: any) => <Row key={JSON.stringify(ar) } style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <p>
          {`${ar.class} ${ar.serial_number}`}

          </p>
          {ar.status && ar.status == "inserted" && <span className="material-icons-outlined" style={{color: "green"}}>
            done
          </span>}

          {ar.status && ar.status == "not_inserted" && <span className="material-icons-outlined" style={{color: "red"}}>
            report_problem
          </span>}
      </Row>)
  } catch(e) {
      return formatBody(apiResponse, intl);
  }
}

function parseApiLogs(logs: any, intl: Record<string, string>): React.ReactNode {
  const translated = translate(intl)
  try {
      return logs.reverse().map((ar: any) => <Row key={JSON.stringify(ar) } style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <p>
          {`${translated(ar.log.action ?? "")} ${translated(ar.log.reference ?? "")}`}

          </p>
          {ar.log.status && ar.log.status == "ok" && <span className="material-icons-outlined" style={{color: "green"}}>
            done
          </span>}

          {ar.log.status && ar.log.status !== "ok" && <span className="material-icons-outlined" style={{color: "red"}}>
            report_problem
          </span>}
      </Row>)
  } catch(e) {
      return formatBody(logs, intl);
  }
}