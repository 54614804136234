type SetToken = {
  type: "SET_TOKEN",
  token: string
}

type DeleteToken = {
  type: "DELETE_TOKEN"
}

type SetPermissions = {
  type: "SET_PERMISSIONS",
  permissions: string[]
}

export function setPermissions(permissions: string[]) {
  return {
    type: "SET_PERMISSIONS",
    permissions
  }
}

export type AuthAction = SetToken | DeleteToken | SetPermissions;