import { from } from "@reactivex/rxjs/dist/package";
import React from "react";
import { useIntl } from "react-intl";
import { H4, InformativeText, Title } from "../../../components/barbagli/text";
import { Condominium, Immobile, SubCondominium } from "../../../models/barbagli/condominium";
import { nodeForId } from "../condominium/condominium_view_model";
import { history } from "../../../index";
import { FlatButton } from "../../../components/barbagli/common";
import { Attributes } from "../../../models/meter";
import styled from "styled-components";


type Props = {
    condominium: Condominium,
    subCondominiumId?: string,
    immobileId?: string,
    meterAttributes: Attributes
}

const ItemContainer = styled.div`
    width: 33%;
    height: 32px;
    display: flex;
    align-items: baseline;
`

const Row = styled.div`
display: flex;
flex-wrap: wrap;
`

export function AlarmsCondominiumAndMeterLinks(props: Props) {
    const intl = useIntl();
    const subCondominium = props.subCondominiumId ? nodeForId(props.condominium, props.subCondominiumId) : undefined;
    const immobile = props.immobileId ? nodeForId(props.condominium, props.immobileId) : undefined;
    return <>
        <Title>{intl.messages["condominium"]}</Title>
        <Row>

            {props.condominium?.data?.cond_name &&
                <ItemContainer>
                    <H4 style={{ marginRight: 12 }}>{intl.messages["condominium"]}</H4>
                    <FlatButton onClick={() => {
                        history.push(`/condominiums/${props.condominium?.data?.cond_name ?? props.condominium.id}`, props.condominium);
                    }}>{props.condominium.data.cond_name}
                    </FlatButton>
                </ItemContainer>
            }
            {subCondominium && (subCondominium as SubCondominium).data.name &&
                <ItemContainer>
                    <InformativeText padded title={intl.messages["subcondominium"].toString()}>
                        <FlatButton onClick={() => {
                            history.push(`/condominiums/${props.condominium?.data?.cond_name ?? props.condominium.id}/node_id/${props.subCondominiumId}`, props.condominium);
                        }}>
                            {(subCondominium as SubCondominium).data.name}
                        </FlatButton>
                    </InformativeText>
                </ItemContainer>
            }
            {immobile && (immobile as Immobile).data.name &&
                <ItemContainer>
                    <InformativeText padded title={intl.messages["immobile"].toString()}>
                        <FlatButton onClick={() => {
                            history.push(`/condominiums/${props.condominium?.data?.cond_name ?? props.condominium.id}/node_id/${props.immobileId}`, props.condominium);
                        }}>
                            {(immobile as Immobile).data.name}
                        </FlatButton>
                    </InformativeText>
                </ItemContainer>
            }
            <ItemContainer>
                <InformativeText padded title={intl.messages["meter"].toString()}>
                    <FlatButton onClick={() => { history.push(`/meters/${props.meterAttributes.serialNumber}`, props.meterAttributes); }}>
                        {`${props.meterAttributes.type} ${props.meterAttributes.serialNumber}`}
                    </FlatButton>
                </InformativeText>
            </ItemContainer>
        </Row>
    </>
}