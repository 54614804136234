import { confirmAlert } from "react-confirm-alert";
import { FlatButton, LoaderButton, OutlineButton } from "../../../components/barbagli/common";
import { Attributes } from "../../../models/meter";
import React, { useEffect, useReducer } from 'react';
import { StandardModalFrame } from "../../../components/barbagli/modal";
import { LoaderComponent } from "../../../components/loading_widget";
import styled from "styled-components";
import { I18n } from "../../../components/I18n";
import { Provider } from "react-redux";
import store from "../../../store/store";
import { fetchSinglePageCollection, remoteFetchAttributes, remoteFetchData } from "../../../repsitory/generic_repository";
import { BlackList } from "../../../models/barbagli/blacklist";
import { fetchAllGroups } from "../../../repsitory/user_repository";
import {  Select } from "../../../components/barbagli/select";
import { User } from "../../../models/user";
import { ErrorComponent } from "../../../components/error_component";
import { ErrorMapping } from "../../../models/barbagli/error_mapping";
import { H4, Title } from "../../../components/barbagli/text";
import * as vm from "./alarm_close_viewmodel";
import { Alarm } from "../../../models/barbagli/alarm";
import { DatePickerInput } from "../../../components/datepicker";
import moment from "moment";

export async function showAlarmCloseModal(intl: Record<string, string>, meter: Attributes, alarm: Alarm) {
    confirmAlert({
        customUI: ({ onClose }) =>
            <StandardModalFrame
                style={{ minHeight: 150, overflowX: "auto", backgroundColor: "white" }}
                className={""}
            >
                <CloseAlarmWidget intl={intl} meter={meter} close={onClose} alarm={alarm} />
            </StandardModalFrame>
    });
}

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
`




function CloseAlarmWidget(props: { intl: Record<string, string>, close: any, meter: Attributes, alarm: Alarm}) {

    
    const [state, dispatch] = useReducer(vm.reducer, vm.initialState(props.meter, props.alarm.name))

    useEffect(() => {
        (async () => {
            const blr = await remoteFetchData<BlackList[]>(`/api/v1/meter/${props.meter.id}/blacklist`)
            if (typeof blr === "string") {
                dispatch({ type: "blackListFetchCompletedError", error: blr })
            } else {
                dispatch({ type: "blackListFetchCompletedSuccess", bl: blr.map((a: any) => a.attributes).filter((bl: BlackList) => bl.alarmName === props.alarm.name) })
            }

            const gr = await fetchAllGroups()
            dispatch({ type: "setGroups", groups: gr });

            const users = await fetchSinglePageCollection<User>(`/api/v1/user`)(1, 10000)

            if (typeof users !== "string") {
                dispatch({ type: "setUsers", users: users.sublist })
            } else {
                dispatch({ type: "setUsers", users: [] });
            }

            const mappings = await fetchSinglePageCollection<ErrorMapping>(`/api/v1/mapping?device_id=${props.meter.deviceTypeId}`)(1, 10000)
            if (typeof mappings !== "string") {
                dispatch({ type: "setMapping", mappings: mappings.sublist });
            } else {
                dispatch({ type: "setMapping", mappings: [] });
            }
        })()
    }, [0])

    return <div>
        <Provider store={store}>
            <I18n>
                {state.loading && <LoaderComponent height={30}></LoaderComponent>}
                {state.error && <ErrorComponent message={state.error}></ErrorComponent>}
                <Title>{props.intl["hide_to"]}</Title>

                {state.editedMeterBlackLists.map((mbl, i) =>
                 <MeterBlacklistRow 
                    blacklist={mbl}
                    state={state}
                    intl={props.intl}
                    updateBlacklist={(b) => dispatch({type: "updateBlacklist", bl: b, index: i})}
                    key={i}
                 />
                 )}
                {/*Enable Dates Button*/}
                <OutlineButton style= {{marginBottom: 12}}
                onClick={() => dispatch({type: "toggleDateEnabled"})}>
                    { state.datesEnabled ? props.intl["disable_dates"] : props.intl["enable_dates"]}
                </OutlineButton>
                {
                    state.datesEnabled &&             <DatePickerInput
                    style={{ marginBottom: 12, flexGrow: 1, marginRight: 2 }}
                    value={state.from ?? moment(new Date()).startOf("day").toDate()}
                    onChange={(d) => dispatch({type: "setFrom", from: d})}
                    showTimeSelect={false}
                    enabled
                    label={props.intl["close_from"].toString()} 
                />

                }
                {
                    state.datesEnabled &&                
                    <DatePickerInput
                        style={{ marginBottom: 12, flexGrow: 1, marginLeft: 2 }}
                        value={state.to ?? moment(new Date()).add(7, "days").startOf("day").toDate()}
                        onChange={(d) => dispatch({type: "setTo", to: d})}
                        showTimeSelect={false}
                        enabled
                        label={props.intl["close_to"].toString()} 
                    />

                }
                <ButtonRow>
                    <FlatButton onClick={() => props.close()}> {props.intl["close"]} </FlatButton>
                    <LoaderButton style={{ marginLeft: 12 }} onClick={() => vm.onSave(state)}>{props.intl["save"]}</LoaderButton>
                </ButtonRow>
            </I18n>
        </Provider>



    </div>
}

const BlacklistRowContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`
const SelectContainer = styled.div`
    width: 100%;
    margin-top: 6px;
    margin-bottom: 12px;
`

function MeterBlacklistRow(props: { blacklist: vm.MappingAndBlacklist, updateBlacklist: (b: vm.MappingAndBlacklist) => void, state: vm.CloseAlarmModalState, intl: Record<string, string> }) {
    return <BlacklistRowContainer>
        <H4 style={{marginTop: 12}}>{props.blacklist.display_name}:</H4>
        <SelectContainer>
        <Select
            label={props.intl["hidden_to_groups"]}
            enabled
            isMulti
            selectedOptions={props.blacklist.groups.map((group_id) => props.state.groupIdName[group_id]).filter((s) => s)}
            availableOptions={props.state.groups}
            valueMapper={(ug) => ug.name}
            onChange={(v) => {
                // console.log(v);
                props.updateBlacklist({ ...props.blacklist, groups: v.map(a => a.id) })
            }}></Select>
            </SelectContainer>
            <SelectContainer>
            <Select
            label={props.intl["hidden_to_users"]}
            enabled
            isMulti
            selectedOptions={props.blacklist.users.map((user_id) => props.state.userIdName[user_id]).filter((s) => s)}
            availableOptions={props.state.users}
            valueMapper={(u) => u.username}
            onChange={(v) => {
                // console.log(v);
                props.updateBlacklist({ ...props.blacklist, users: v.map(a => a.id) })
            }}></Select>
            </SelectContainer>

        
        
    </BlacklistRowContainer>
}