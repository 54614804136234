import {confirmAlert} from "react-confirm-alert";
import React, {ReactNode} from "react";
import "../configuration_editor/configuration_editor.css"
import {ClearIcon} from "../icons";
import {Divider} from "../divider";

type Config = {
  onDone?: () => void,
  secondaryButtonText?: string
  secondaryButtonCallback?: () => void
  icon?: ReactNode
}

export function showAlert(title: string, body: string, config: Config = {}) {

  const onDoneCallback = config.onDone ?? (() => {});

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
      <AlertDialog
        config={
          {
            ...config,
            onDone: () => { onDoneCallback(); onClose(); },
            
          }
        }
        onClose={onClose}
        title={title}
        body={body}
      />
  });
}

function AlertDialog(props: {title: string, body: string, config: Config, onClose: any}) {

  const onDoneCallback = props.config.onDone ?? (() => {});

  return <div className={"configuration-editor p-3"}>
    <div className={"row d-flex flex-row justify-content-between"}>
      <h3 className={"dashboard-editor-title"}>{props.title.toUpperCase()}</h3>
      <button className={"icon-button dashboard-editor-icon"} onClick={props.onClose}><ClearIcon/></button>
    </div>
    <Divider className={"mt-1 mb-3"}/>
    {props.config.icon && <div className={"row mb-2 mt-2"}>
      <div className={"col-md-12 d-flex flex-row justify-content-center"}>
        {props.config.icon}
      </div>
    </div> }
    <div className={"row mb-3"}>
      <div className="col-md-12">
      <div className="d-flex flex-column">
            {props.body.includes("\n") ? props.body.split("\n").map((m) => <p key={m}>{m}</p>) : <p>{props.body}</p>}

            </div>
      </div>
    </div>
    <div className={"row d-flex justify-content-end mr-1"}>
      { props.config.secondaryButtonText && <button className={"p3 outline-button mr-3"}  onClick={() => {
        (props.config.secondaryButtonCallback ?? (() => {props.onClose()}))();
      }}>
        {props.config.secondaryButtonText}
      </button>}
      <button className={"p3 primary-button"}  onClick={() => {
        onDoneCallback();
      }}>
        OK
      </button>
    </div>
  </div>

}