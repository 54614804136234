// Aggiungere la selezione della stanza nei ripartitori

import { type } from "os";
import { Attributes, MeterHeatDivider } from "../../../../models/meter"
import { first } from "../../../../utils/deep_copy";
import kc_values from "./kc_values.json";



// K = (Kq*Kc)/(1000* FATTORE DI CONVERSIONE)

// il campo Kq può essere inserito manualmente oppure calcolato premendo il pulsante dedicato. La formula utilizzata per il calcolo è:

// S = 2*(ALTEZZA/1000)*(LARGHEZZA/1000) + 2*(PROFONDITA/1000)*(ALTEZZA/1000) + 2*(PROFONDITA/1000)*(LARGHEZZA/1000)
// V = (ALTEZZA/1000)*(PROFONDITA/1000)*(LARGHEZZA/1000)
// Kq =314*S + V*C

export function fromAttributes(attributes: Attributes): MeterHeatDividerState {

    const ntipoCorposcaldante = attributes?.meta?.ntipo_corposcaldante;
    const filtered_items = kc_values.filter(c => c.ntipo_corposcaldante == ntipoCorposcaldante);
    const description_key = filtered_items.length > 0 ? filtered_items[0].tipo_corposcaldante : undefined;


    if(attributes.meterHeatDivider) return {
        ...attributes.meterHeatDivider,
        k: attributes.meterHeatDivider ? Number(attributes.meterHeatDivider.k?.toFixed(3)) : 1,
         kqCalculationEnabled: attributes.meterHeatDivider.height_mm !== undefined,
          type: attributes.meterHeatDivider.type ?? "normal",
          conversion_factor: attributes.meterHeatDivider.conversion_factor ?? 1,
          k_update_from: (new Date).toISOString(),
          class: attributes.class!!,
          room: attributes.meterHeatDivider.room ?? '--',
          description_key: attributes.meterHeatDivider.description_key ?? description_key
        }
    return {
        kqCalculationEnabled: false,
        type: "normal",
        thermal_power: undefined,
        k: undefined,
        description_key: description_key,
        k_update_from: (new Date()).toISOString(),
        room: "--",
        C: undefined,
        conversion_factor: undefined,
        kq: undefined, height_mm: undefined, kc: undefined, width_mm: undefined, depth_mm: undefined, class: attributes.class!!
    }
}


export type MeterHeatDividerState = MeterHeatDivider & { kqCalculationEnabled: boolean, class: string }

export type MeterHeatDividerAction = 
| { type: "setRoom", room: string }
| { type: "setThermalPower", thermalPower: number }
| { type: "setDescriptionKey", descriptionKey: string }
| { type: "setKc", kc: number }
| { type: "setC", C: number }
| { type: "setK", k: number }
| { type: "setType", t: "normal" | "external" }
| { type: "setConversionFactor", conversionFactor: number }
| { type: "setUpdateFrom", from: Date }
| { type: "setHeight_mm", height_mm: number }
| { type: "setWidth_mm", width_mm: number }
| { type: "setDepth_mm", depth_mm: number }
| { type: "toggleKqCalculation" }

export function reducer(state: MeterHeatDividerState, action: MeterHeatDividerAction): MeterHeatDividerState {

    const ns: MeterHeatDividerState = (()=>{
        switch(action.type) {
            case "setRoom": return {...state, room: action.room}
            case "setType": return withCalculatedK(withDescriptionKey({...state, type: action.t}))
            case "setK": return {...state, k: action.k}
            case "setC": return withCalculatedK(withThermalPower({...state, C: action.C}))
            case "setThermalPower": return withCalculatedK({...state, thermal_power: action.thermalPower})
            case "setDescriptionKey": return withCalculatedK(withThermalPower(withDescriptionKey({...state, description_key: action.descriptionKey})))
            case "setKc": return withCalculatedK({...state, kc: action.kc })
            case "setHeight_mm": return withCalculatedK(withThermalPower({...state, height_mm: action.height_mm }))
            case "setWidth_mm": return withCalculatedK(withThermalPower({...state, width_mm: action.width_mm }))
            case "setDepth_mm": return withCalculatedK(withThermalPower({...state, depth_mm: action.depth_mm }))
            case "toggleKqCalculation": return {...state, kqCalculationEnabled: !state.kqCalculationEnabled }
            case "setConversionFactor": return withCalculatedK({...state, conversion_factor: action.conversionFactor})
            case "setUpdateFrom": return {...state, k_update_from: action.from.toISOString()}
        }
    })()

    return ns;
}

export function calculateKq(kc: number, height_mm: number, width_mm: number, depth_mm: number): number {
    const surface = 2*(height_mm / 1000) * (width_mm / 1000) + 2 * (depth_mm / 1000) * (height_mm / 1000) + 2 * (depth_mm / 1000) * (width_mm / 1000);
    const volume = (height_mm / 1000) * (width_mm / 1000) * (depth_mm / 1000)
    return 314 * surface + volume * kc
}

// height, width, depth, C -> thermal_power
function withThermalPower(state: MeterHeatDividerState): MeterHeatDividerState {
    if(!state.height_mm || ! state.width_mm || !state.depth_mm || !state.C) return state;


    const surface = 2*(state.height_mm / 1000) * (state.width_mm / 1000) + 2 * (state.depth_mm / 1000) * (state.height_mm / 1000) + 2 * (state.depth_mm / 1000) * (state.width_mm / 1000);
    const volume = (state.height_mm / 1000) * (state.width_mm / 1000) * (state.depth_mm / 1000)
    const thermalPower = 314 * surface + volume * state.C;

    return {...state, thermal_power: thermalPower}
}
//  description_key, type -> C, kc, conversion_factor
function withDescriptionKey(state: MeterHeatDividerState) : MeterHeatDividerState {
    if(!state.description_key) return state;
    if(!state.class.includes(":")) return state;

    const make = state.class.split(":")[0];

    const tableMapping = first(kc_values.filter((kv) => kv.tipo_corposcaldante === state.description_key));
    if(!tableMapping) return state;

    let kc: number; 
    let conversion_factor = 1;
    try {
        // console.log(`calculating kc for ${make} ${state.type}`)
        //  @ts-ignore
        kc = (tableMapping.kc[make][state.type]) as number

    } catch(e) {
        kc = 1;
    }
    try {
        // console.log(`calculating conv factor for ${make} ${state.type}`)
        // @ts-ignore
        conversion_factor = (tableMapping.conversion_factor[make][state.type]) as number
    } catch(e) {
        conversion_factor = 1;
    }
    

    return {...state, C: tableMapping.val_C, kc: kc, conversion_factor}

}

//  kc, thermal_power, conversion_factor -> k
function withCalculatedK(state: MeterHeatDividerState) : MeterHeatDividerState {
    if(!state.kc || !state.thermal_power || !state.conversion_factor) return state;
    return {...state, k: ((state.thermal_power * state.kc) / state.conversion_factor) / 1000}
}

export function defaultConversionFactor(state: MeterHeatDividerState) : number {
    const defaultV = 1
    if(!state.description_key) return defaultV;
    if(!state.class.includes(":")) return defaultV;

    const make = state.class.split(":")[0];

    const tableMapping = first(kc_values.filter((kv) => kv.tipo_corposcaldante === state.description_key));
    if(!tableMapping) return defaultV;

    let kc: number; 
    let conversion_factor = 1;
    try {
        // console.log(`calculating kc for ${make} ${state.type}`)
        //  @ts-ignore
        kc = (tableMapping.kc[make][state.type]) as number

    } catch(e) {
        kc = 1;
    }
    try {
        // console.log(`calculating conv factor for ${make} ${state.type}`)
        // @ts-ignore
        conversion_factor = (tableMapping.conversion_factor[make][state.type]) as number
    } catch(e) {
        conversion_factor = 1;
    }
    

    return  conversion_factor

}