import React, {useEffect, useState} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// @ts-ignore
import Loader from 'react-loader-spinner'
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {APP_BASE_URL, BASE_URL, history} from "../../index";
import {ErrorComponent} from "../../components/error_component";
import {useIntl} from "react-intl";

export function LoginPage() {

  const l = useLocation();
  const intl = useIntl();

  const location = l.pathname;

  const token = useSelector((s: AppState) => s.auth.token);
  const dispatch = useDispatch();

  const [error, setError] = useState("");

  useEffect(() => {
    if(location === "/login" || location === "/") {
      debugger
      window.location.href = `${BASE_URL}/api/v1/login?callback=${APP_BASE_URL}/login`;
    } else {
      const locationSplit = location.split("/");
      if(locationSplit.length === 3) {
        const token = locationSplit[2];
        dispatch({
          type: "SET_TOKEN",
          token
        });
        history.push('/condominiums');
      } else {

      }

    }
  }, [0]);


  

  return (<div className={"d-flex flex-column justify-content-center align-items-center"} style={{backgroundColor: "#3C4B64", height: "100%" }}>
    <img src={`${BASE_URL}/api/v1/owner-configuration/logo`} alt={"Product logo"} className={"product-logo mb-4 p-3"}/>
    { error === "" && <Loader type="Rings" color="#fff" height={100} width={100} /> }
    { error !== "" && <ErrorComponent message={ intl.messages["login_error"].toString() }/> }
  </div>);

}