import React, { useMemo, useState } from "react"
import { useIntl } from "react-intl";
import { OutlineButton, PrimaryButton } from "../../../components/barbagli/common";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { SortableTableView } from "../../../components/barbagli/sortable_table_view";
import { TabView } from "../../../components/barbagli/tab_view";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { Attributes, emptyMeter, Meter } from "../../../models/meter";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import { history } from "../../../index";
import { deepCopy } from "../../../utils/deep_copy";
import { Title } from "../../../components/barbagli/text";
import { showFileUploader } from "components/file_uploader/file_uploader_component";
import { translate } from "utils/intl";
import { useLocation } from "react-router-dom";
import { TypeAuthorized } from "components/authorized";

type Props = {
    onClick?: (m: Meter) => void,
    gateway_id?: number,
    filters?: FilterParam[],
    visibleFilters?: FilterParam[],
    selfReading?: boolean
}

type MetersIndexState = {
    selected_tab:
    | "RIPARTITORE"
    | "AF"
    | "ACS"
    | "CALORIE"
    | "FRIGORIE"
}




export function MetersIndex(props: Props) {
    const [state, setState] = useState<MetersIndexState>({ selected_tab: "AF" });

    return <div>
        <TabView
            children={[
                ["AF / ACS", <ViewForState tab={"AFACS"} state={state} setState={setState} {...props} />],
                ["RIPARTITORE", <ViewForState tab={"RIPARTITORE"} state={state} setState={setState}{...props} />],
                ["CALORIE / FRIGORIE", <ViewForState tab={"CALORIE"} state={state} setState={setState}{...props} />],
            ]}
        />
    </div>
}



function ViewForState(props: {
    tab: string,
    state: MetersIndexState,
    gateway_id?: number,
    setState: React.Dispatch<React.SetStateAction<MetersIndexState>>,
    onClick?: (m: Meter) => void,
    filters?: FilterParam[],
    visibleFilters?: FilterParam[],
    selfReading?: boolean
},) {
    const [order, setOrder] = useState<OrderParam>({ column: "id", order: "asc" });

    const s = useLocation()
    const query = new URLSearchParams(s.search);
    const dashboardValue = query.get("dashboard");

    const [filter, setFilter] = useState<FilterParam[]>([...(props.visibleFilters ?? []), ...(dashboardValue ? [{ column: "dashboard", value: dashboardValue }] : [])]);
    const gatewayFilters: FilterParam[] = useMemo(() => props.gateway_id ? [{
        column: "gateway_id",
        value: props.gateway_id?.toString()
    }] : [], [props.gateway_id])
    const intl = useIntl();
    const translated = useMemo(() => translate(intl.messages as any), [intl])
    return <div className="d-flex flex-column">
        {props.selfReading && <h2>{intl.messages["select_meter_for_self_reading"]}</h2>}
        <SearchBar filters={filter} avaliableFilters={["serial_number", "mechanical_serial_number", "identifier", "class", "condominium", "sub_condominium", "immobile"]}
            filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
            onFiltersChange={setFilter}
        />
        <SortableTableView
            permutationKeyPostfix={props.selfReading ? "self_reading" : ""}
            key={JSON.stringify([...filter, ...(props.filters ?? []), { value: props.tab, column: "type" }])}
            perPage={8}
            fetchCollection={
                async (page, perPage) => {
                    // console.log(filter)
                    return fetchPaginatedCollection<Attributes>(
                        `/api/v1/meter?sort=${order.column}&order=${order.order}${[...filter, ...gatewayFilters, ...(props.filters ?? []), { value: props.tab, column: "type" }].map(f => `&${f.column}=${f.value}`).join("")}`)
                        (page, perPage);
                }
            }
            onTap={
                (item: Attributes | string) => {
                    // console.log(item)
                    if (props.onClick && typeof item != "string") {
                        props.onClick({ ...emptyMeter(), attributes: item })
                    } else {
                        if (typeof item !== "string") history.push(`/meters/${item.serialNumber}`, item)
                    }
                }
            }
            translated
            itemsPreFormatter={(c: Attributes) => {
                const cc: any = deepCopy(c);
                delete cc["battery"];
                delete cc["activeKeys"];
                delete cc["altiorStatus"];
                delete cc["battery"];
                delete cc["contract"];
                delete cc["coordinates"];
                delete cc["currentKey"];
                delete cc["deleteGroups"];
                delete cc["description"];
                delete cc["deviceTypeId"];
                delete cc["height"];
                delete cc["id"];
                delete cc["initialReading"];
                delete cc["lastCommission"];
                delete cc["lastSeen"];
                delete cc["latitude"];
                delete cc["listGroups"];
                delete cc["longitude"];
                delete cc["meterTypeId"];
                delete cc["rfConfig"];
                delete cc["rfInterface"];
                delete cc["rssi"];
                delete cc["state"];
                delete cc["type"];
                delete cc["updateGroups"];
                delete cc["updatedAt"];
                delete cc["valve"];
                delete cc["ldn"];
                delete cc["pdr"];
                delete cc["condominiumId"];
                delete cc["subCondominiumId"];
                delete cc["immobileId"];
                delete cc["meterHeatDivider"];
                delete cc["activeAlarms"];
                delete cc["anomalies"];
                delete cc["meta"];
                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "inserted_at",
                "class",
                "condominium",
                "sub_condominium",
                "immobile",
                "address"
            ]}
        />
        <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
            {!props.onClick && <div className="d-flex flex-row-reverse">
                <OutlineButton style={{ marginLeft: 8 }} onClick={() => { showFileUploader("/api/v1/meter/import") }}>
                    {translated("Create from Csv")}
                </OutlineButton>

                <PrimaryButton onClick={() => {
                    history.push("/meters/new", null);
                }}>{intl.messages["add_meter"]}</PrimaryButton>
            </div>}
        </TypeAuthorized>

    </div>
}