import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { remoteFetchData } from "repsitory/generic_repository";
import { useIntl } from "react-intl";
import { TypeAuthorized } from "components/authorized";
import QuarterYearSelect from "./QuarterYearSelect";
import MonthSelect from "./MonthSelect";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Table = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.div`
  display: table-row;
`;
const TableHeader = styled.div<{ colSpan?: number }>`
  display: table-cell;
  padding: 8px;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  background-color: #f8f8f8;
  text-align: center;
`;
const BigHeader = styled.div<{ colSpan?: number }>`
  display: table-cell;
  padding: 4px;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  background-color: #f8f8f8;
  text-align: start;
`;

const TableCell = styled.div<{
  colSpan?: number;
  color?: string;
  font?: string;
}>`
  display: table-cell;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  ${({ colSpan }) => colSpan && `grid-column: span ${colSpan};`}
  ${({ color }) => color && `color: ${color};`}
 ${({ font }) => font && `font: ${font};`}
`;
const WholeCell = styled.div<{ colSpan?: number }>`
  display: table-cell;
  padding: 2px;
  width: 15px;
  border-bottom: 2px solid #ccc; /* Убедитесь, что это свойство уникально задано */
  text-align: center;
  background: #f8f8f8;
  ${({ colSpan }) => colSpan && `grid-column: span ${colSpan};`}
  font-weight: 2px;
`;

const ErrorContainer = styled.div`
  color: red;
  text-align: center;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

const SearchButton = styled.button`
  background-color: #1976d2;
  color: white;
  padding: 0.2rem 0.6rem;
  border: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  width: 80px;
  &:hover {
    background-color: #125699;
  }
`;
const WideTableHeader = styled(TableHeader)`
  width: 40%; /* Ширина заголовков Second Quarter и Third Quarter */
`;

type Stats = {
  [key: string]: number;
};

type Attributes = {
  statsDate: string;
  stats: Stats;
};

interface Summary {
  type: string;
  id: string;
  attributes: {
    statsDate: string;
    stats: {
      [key: string]: number;
    };
    statsDifference?: Stats;
  };
  firstQuarter: any;
  secondQuarter: any;
  thirdQuarter: any;
}

const colorSelector = (val?: number) => {
  if (val != undefined && val > 0) {
    return "green";
  }
  if (val != undefined && val < 0) {
    return "red";
  }
};
const renderDifferenceValue = (val: number) => {
  if (val != undefined && val > 0) {
    return `+${val}`;
  }
  if (val != undefined && val < 0) {
    return `${val}`;
  }
  if (val != undefined && val == 0) {
    return `${val}`;
  }
};
const fontSelector = (val: number) => {
  if (val != undefined && val > 0) {
    return `bold`;
  } else {
    return "2px";
  }
};
const fakedata = {
  firstQuarter: {
    type: "summary",
    id: "",
    attributes: {
      statsDate: "2024-04-01T00:00:00.000000Z",
      stats: {
        "Heat cost allocators": 100,
        "Heat / cooling meters": 100,
        Gateway: 200,
        "Cold and hot water meters > 1": 100,
        "Cold and hot water meters": 100,
      },
    },
  },
  secondQuarter: {
    type: "summary",
    id: "",
    attributes: {
      statsDate: "2024-05-01T00:00:00.000000Z",
      stats: {
        "Heat cost allocators": 200,
        "Heat / cooling meters": 200,
        Gateway: 200,
        "Cold and hot water meters > 1": 200,
        "Cold and hot water meters": 200,
      },
      statsDifference: {
        "Heat cost allocators": 100,
        "Heat / cooling meters": 100,
        Gateway: 0,
        "Cold and hot water meters > 1": 100,
        "Cold and hot water meters": 100,
      },
    },
  },
  thirdQuarter: {
    type: "summary",
    id: "",
    attributes: {
      statsDate: "2024-06-01T00:00:00.000000Z",
      stats: {
        "Heat cost allocators": 150,
        "Heat / cooling meters": 150,
        Gateway: 479,
        "Cold and hot water meters > 1": 150,
        "Cold and hot water meters": 150,
      },
      statsDifference: {
        "Heat cost allocators": -50,
        "Heat / cooling meters": -50,
        Gateway: 279,
        "Cold and hot water meters > 1": -50,
        "Cold and hot water meters": -50,
      },
    },
  },
};

const SummaryTable = ({ data }: { data: any }) => {
  const intl = useIntl();
  if (
    !data ||
    !data.firstQuarter ||
    !data.secondQuarter ||
    !data.thirdQuarter
  ) {
    return null;
  }

  const types = [
    "Heat cost allocators",
    "Heat / cooling meters",
    "Gateway",
    "Cold and hot water meters",
    "Cold and hot water meters > 1",
  ];

  return (
    <Container>
      <Table>
        <TableRow>
          <TableHeader>{intl.messages[`type`]}</TableHeader>
          <TableHeader>{intl.messages[`first_month`]}</TableHeader>
          <WholeCell></WholeCell>
          <BigHeader>{intl.messages[`second_month`]}</BigHeader>
          <WholeCell></WholeCell>
          <BigHeader>{intl.messages[`third_month`]}</BigHeader>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableHeader>{intl.messages[`total_count`]}</TableHeader>
          <TableHeader>{intl.messages[`difference`]}</TableHeader>
          <TableHeader>{intl.messages[`total_count`]}</TableHeader>
          <TableHeader>{intl.messages[`difference`]}</TableHeader>
        </TableRow>
        {types.map((type) => (
          <TableRow key={type}>
            <TableCell>{intl.messages[`${type}`]}</TableCell>
            <TableCell>{data.firstQuarter.attributes.stats[type]}</TableCell>
            <TableCell colSpan={1}>
              {data.secondQuarter.attributes.stats[type]}
            </TableCell>
            <TableCell
              colSpan={1}
              font={fontSelector(
                data.secondQuarter.attributes.statsDifference[type]
              )}
              color={colorSelector(
                data.secondQuarter.attributes.statsDifference[type]
              )}
            >
              {renderDifferenceValue(
                data.secondQuarter.attributes.statsDifference[type]
              )}
            </TableCell>
            <TableCell colSpan={1}>
              {data.thirdQuarter.attributes.stats[type]}
            </TableCell>
            <TableCell
              colSpan={1}
              font={fontSelector(
                data.thirdQuarter.attributes.statsDifference[type]
              )}
              color={colorSelector(
                data.thirdQuarter.attributes.statsDifference[type]
              )}
            >
              {renderDifferenceValue(
                data.thirdQuarter.attributes.statsDifference[type]
              )}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Container>
  );
};
const SummaryIndex = () => {
  const [summary, setSummary] = useState({});
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [dates, setDates] = useState<string[]>([]);
  const intl = useIntl();

  const fetchSummaryData = async (date: string): Promise<Summary | string> => {
    const url = `/api/v1/summary?date=${encodeURIComponent(date)}`;
    return remoteFetchData<Summary>(url);
  };
  const getSummary = async () => {
    try {
      let firstQuarter: any;
      let secondQuarter: any;
      let thirdQuarter: any;

      firstQuarter = await fetchSummaryData(dates[0]);
      if (typeof firstQuarter === "string") {
        return;
      }

      setSummary((prevSummary) => ({
        ...prevSummary,
        firstQuarter:
          typeof firstQuarter === "object" ? { ...firstQuarter } : null,
      }));

      secondQuarter = await fetchSummaryData(dates[1]);
      if (typeof secondQuarter === "string") {
        return;
      }

      const secondQuarterStatsDifference: Stats = {};
      if (
        typeof firstQuarter === "object" &&
        typeof secondQuarter === "object"
      ) {
        Object.keys(secondQuarter.attributes?.stats ?? {}).forEach((key) => {
          secondQuarterStatsDifference[key] =
            (secondQuarter.attributes?.stats[key] ?? 0) -
            (firstQuarter.attributes?.stats[key] ?? 0);
        });
      }

      setSummary((prevSummary) => ({
        ...prevSummary,
        secondQuarter:
          typeof secondQuarter === "object"
            ? {
                ...secondQuarter,
                attributes: {
                  ...secondQuarter.attributes,
                  statsDifference: secondQuarterStatsDifference,
                },
              }
            : null,
      }));

      thirdQuarter = await fetchSummaryData(dates[2]);
      if (typeof thirdQuarter === "string") {
        console.error("Ошибка при получении третьего квартала:", thirdQuarter);
        return;
      }

      const thirdQuarterStatsDifference: Stats = {};
      if (
        typeof secondQuarter === "object" &&
        typeof thirdQuarter === "object"
      ) {
        Object.keys(thirdQuarter.attributes?.stats ?? {}).forEach((key) => {
          thirdQuarterStatsDifference[key] =
            (thirdQuarter.attributes?.stats[key] ?? 0) -
            (secondQuarter.attributes?.stats[key] ?? 0);
        });
      }

      setSummary((prevSummary) => ({
        ...prevSummary,
        thirdQuarter:
          typeof thirdQuarter === "object"
            ? {
                ...thirdQuarter,
                attributes: {
                  ...thirdQuarter.attributes,
                  statsDifference: thirdQuarterStatsDifference,
                },
              }
            : null,
      }));
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };

  useEffect(() => {
    if (dates?.length == 3) {
      getSummary();
    }
  }, [dates]);

  useEffect(() => {
    if (summary) {
      console.log("selectedMonth", selectedMonth);
    }
  }, [selectedMonth]);

  const handleMonthSelect = (selectedMonth: string) => {
    setSelectedMonth(selectedMonth);
  };

  useEffect(() => {
    console.log("Dates", summary);
  }, [summary]);

  return (
    <TypeAuthorized user_types={["admin"]}>
      <Container>
        <QuarterYearSelect setDates={setDates} />
        <SummaryTable data={summary} />
      </Container>
    </TypeAuthorized>
  );
};

export default SummaryIndex;
