export type Reading = {
    value: number,
    meta: any,
    meter_identifier: string,
    gateway_identifier?: string,
    type?: string,
    id?: number,
    has_errors?: boolean,
    read_time: string,
}

export function mkReadingFromDateAndValue(value: number, read_time: string, meter_identifier: string): Reading {
    return {
        value,
        read_time,
        meter_identifier,
        has_errors: false,
        meta: {
            "self_reading": true
        }
    }
}