
import { BASE_URL, fallbackMapCenter } from "../..";
import { Condominium, SubCondominium, Immobile } from "../../models/barbagli/condominium";
import { Gateway } from "../../models/gateway";
import { geoJsonPointFromCoordinates } from "../../models/geo_json";
import { Meter } from "../../models/meter";
import { defaultLogFormatter, formatLogObject } from "../../utils/object_formatting";
import { fetchPaginatedCollection, httpDelete, post, postRaw, put, remoteFetchAttributes, remoteFetchData } from "../generic_repository";


export async function fetchCondominiumMeters(condominium_id: number, sub_condominium_id?: string, immobile_id?: string): Promise<Meter[] | string> {

    const cacheHit = getCache("meter", condominium_id, sub_condominium_id, immobile_id);
    if(cacheHit) return cacheHit;

    const url = `/api/v1/condominium/${condominium_id}/${sub_condominium_id ? `sub_condominium/${sub_condominium_id}/${immobile_id ? `immobile/${immobile_id}/` : ""}` : ""}meter`
    const result = (await remoteFetchData(url)) as string | Meter[];
    if(typeof result != "string") {
        setCache("meter", result, condominium_id, sub_condominium_id, immobile_id)
    }
    return result;
}

export async function fetchCondominiumGateways(condominium_id: number, sub_condominium_id?: string, immobile_id?: string): Promise<Gateway[] | string> {
    const cacheHit = getCache("gateway", condominium_id, sub_condominium_id, immobile_id);
    if(cacheHit) return cacheHit;
    
    const url = `/api/v1/condominium/${condominium_id}/${sub_condominium_id ? `sub_condominium/${sub_condominium_id}/${immobile_id ? `immobile/${immobile_id}/` : ""}` : ""}gateway`
    return remoteFetchData(url).then((v: any) => {
        if(v && typeof v !== "string") {
            const rr = v.map((v: any) => v.attributes)
            setCache("gateway", rr, condominium_id, sub_condominium_id, immobile_id)
            return rr
        } else {
            return v;
        }
    });
}

export async function associateCondominiumMeter(meter_id: string | number, condominium_id: number, sub_condominium_id?: string, immobile_id?: string): Promise<true | string> {
    const url = `/api/v1/condominium/${condominium_id}/${sub_condominium_id ? `sub_condominium/${sub_condominium_id}/${immobile_id ? `immobile/${immobile_id}/` : ""}` : ""}meter/${meter_id}/associate`
    const r = (await postRaw("", url))
    invalidateCache(condominium_id)
    console.log(r);
    if (typeof r === "string") return r 
    else return true; 
}

export async function associateCondominiumGateway(gateway_id: string | number, condominium_id: number, sub_condominium_id?: string, immobile_id?: string): Promise<true | string> {
    console.log("associate gateway to condominium")
    const url = `/api/v1/condominium/${condominium_id}/${sub_condominium_id ? `sub_condominium/${sub_condominium_id}/${immobile_id ? `immobile/${immobile_id}/` : ""}` : ""}gateway/${gateway_id}/associate`
    console.log(url)
    invalidateCache(condominium_id)
    const r = (await postRaw("", url))
    console.log(r);
    if (typeof r === "string") return r 
    else return true; 
}

export async function fetchCondominium(id: number | string): Promise<Condominium | string> {
    try {
        invalidateCache(id as number)
        return fromJson(await remoteFetchAttributes<any>(`/api/v1/condominium/${id}`));
    } catch(e) {
        return e.toString();
    }
}

export async function createCondominium(condominium: Condominium): Promise<Condominium | string> {
    const j = toJson(condominium, false);
    try {
        const r = await post(j, `/api/v1/condominium`);
        if(r.errors != undefined) return JSON.stringify(r.errors);  
        if(typeof r !== "string") return fromJson(r.data.attributes);
        else return r;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}

export async function updateCondominium(condominium: Condominium & {id: number}, updateOrder?: boolean): Promise<Condominium | string> {
    invalidateCache(condominium.id);
    const json = toJson(condominium, updateOrder ?? false);
    try {
        const r = await put(json, `/api/v1/condominium/${condominium.id}`);
        if(r.errors != undefined)  return JSON.stringify(r.errors);
        if(typeof r !== "string") return condominium;
        else return r;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}

export function invalidateCache(condominium_id: number) {
    window.sessionStorage.removeItem(`condominium_tree_cache${condominium_id}`)
}

function setCache(type: "meter" | "gateway", value: any, condominium_id: number, sub_condominium_id?: string, immobile_id?: string) {
    const c: any = JSON.parse(window.sessionStorage.getItem(`condominium_tree_cache${condominium_id}`) ?? "{}");
    const key = `${type} ${condominium_id} ${sub_condominium_id ?? ""} ${immobile_id ?? ""}`;
    c[key] = value;
    window.sessionStorage.setItem(`condominium_tree_cache${condominium_id}`, JSON.stringify(c));
}

function getCache(type: "meter" | "gateway", condominium_id: number, sub_condominium_id?: string, immobile_id?: string): any | null {
    const c: any = JSON.parse(window.sessionStorage.getItem(`condominium_tree_cache${condominium_id}`) ?? "{}");
    const key = `${type} ${condominium_id} ${sub_condominium_id ?? ""} ${immobile_id ?? ""}`;
    return c[key] ?? null;
}

export async function deleteCondominium(id: number): Promise<string | true> {
    try {
        const r = await httpDelete(`/api/v1/condominium/${id}`);
        invalidateCache(id)
        if(typeof r === "string") return r;
        if(r.status >= 400) return "Impossibile cancellare il condominio"
        return true;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}  

function toJson(c: Condominium, updateOrder: boolean): any {
    return {
        "data": {
            "type": "condominium",
            "update_order": updateOrder,
            "attributes": {
                "code": c.node_id,
                "name": c.data.cond_name ?? '',
                "address": c.data.address,
                "city": c.data.city,
                "zip": c.data.postalCode,
                "companyName": c.data.companyName,
                "vatNumber": c.data.vatNumber,
                "province": c.data.province,
                "coordinates": c.coordinates,
                "updateGroups": c.updateGroups,
                "listGroups": c.listGroups,
                "deleteGroups": c.deleteGroups,
                "region": c.data.province,
                "navId": c.data.navId,
                "managedBy": c.managedBy ?? null,
                "status": c.status ?? "disabled",
                "subCondominiums": [
                    ...c.children.filter((c) => c.type === "SUBCONDOMINIUM").map((c) => toJsonSc(c as any))
                ]
            }
        }
    }
}

function toJsonSc(sc: SubCondominium): any {
    return {
        "uuid": sc.node_id,
        "stair": sc.data.stair,
        "address": sc.data.address,
        "number": sc.data.number,
        "name": sc.data.name ?? '',
        "immobiles": [
            ...sc.children.filter(c => c.type === "IMMOBILE").map(c => toJsonIm(c as any))
        ]
    }
}

function toJsonIm(i: Immobile): any {
    return {
        "uuid": i.node_id,
        "code": i.node_id,
        "floor": i.data.floor,
        "name": i.data.name ?? '',
        "location": i.data.location,
        "internal": i.data.flatNumber,
        "cadastral_identification": i.data.identifier,
        "tenant_first_name": i.tenant.firstName,
        "tenant_last_name": i.tenant.lastName,
        "tenant_email": i.tenant.email,
        "tenant_phone_number": i.tenant.phoneNumber,
        "tenant_fax": i.tenant.fax,
        "tenant_vat_number": i.tenant.vatNumber,
        "owner_first_name": i.owner.firstName,
        "owner_last_name": i.owner.lastName,
        "owner_email": i.owner.email,
        "owner_phone_number": i.owner.phoneNumber,
        "owner_fax": i.owner.fax,
        "owner_vat_number": i.owner.vatNumber
    }
}
 

export function fromJson(json: any): Condominium | string {
    try {
        return {
            type: "CONDOMINIUM",
            id: json.id,
            path_url: "/",
            status: json.status,
            node_id: `${json.id}`,
            partners: json.partners,
            insertedAt: (() => {
                try { return new Date(json.insertedAt) } catch(e) { return undefined; }
            })(),
            availableTabs: ["data", "readings", "consumption", "alarms", "self_reading"],
            data: {
                companyName: json.companyName,
                vatNumber: json.vatNumber,
                address: json.address,
                city: json.city,
                cond_name: json.name,
                province: json.province,
                postalCode: json.zip,
                navId: json.navId
            },
            managedBy: json.managedBy,
            coordinates: json.coordinates ?? geoJsonPointFromCoordinates(fallbackMapCenter),
            children: (json.subCondominiums ?? []).map(fromJsonSc),
        }
    } catch (e) {
        return e.toString();
    }
}

function fromJsonSc(json: any): SubCondominium {
    return {
        path_url: `/${json.uuid}`,
        node_id: json.uuid,
        id: json.uuid,
        type: "SUBCONDOMINIUM",
        data: {
            stair: json.stair,
            address: json.address,
            number: json.number,
            name: json.name ?? ''
        },
        availableTabs: ["data", "readings", "consumption", "alarms", "self_reading"],
        children: (json.immobiles ?? []).map((i: any) => fromJsonIm(i, `/${json.id}`)),
    }
}


function fromJsonIm(json:any, pathPrefix: string): Immobile {

    return {
        path_url: `${pathPrefix}/${json.uuid}`,
        node_id: json.uuid,
        id: json.uuid,
        type: "IMMOBILE",
        data: {
            floor: json.floor,
            location: json.location,
            flatNumber: json.internal,
            name: json.name,
            identifier: json.cadastral_identification
        },
        tenant: {
            firstName: json.tenant_first_name,
            lastName: json.tenant_last_name,
            email: json.tenant_email,
            phoneNumber: json.tenant_phone_number,
            fax: json.tenant_fax,
            vatNumber: json.tenant_vat_number,
        },
        owner: {
            firstName: json.owner_first_name,
            lastName: json.owner_last_name,
            email: json.owner_email,
            phoneNumber: json.owner_phone_number,
            fax: json.owner_fax,
            vatNumber: json.owner_vat_number,
        },
        availableTabs: ["data", "readings",  "consumption", "alarms", "self_reading", "note"],
        children: []
    }
}

