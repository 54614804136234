import React, { useEffect, useMemo, useReducer, useState } from "react"
import { useIntl } from "react-intl";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { fetchPaginatedCollection, fetchSinglePageCollection } from "../../../repsitory/generic_repository";
import { history } from "../../../index";
import moment from "moment";
import { Attributes } from "../../../models/meter";
import ReactPaginate from "react-paginate";
import { LoaderComponent } from "../../../components/loading_widget";
import styled from "styled-components";
import { ErrorComponent } from "../../../components/error_component";
import { H4, Title } from "../../../components/barbagli/text";
import { ErrorMapping } from "../../../models/barbagli/error_mapping";
import { Expanded } from "../../../components/barbagli/icons";
import { Desc, MoreVerticalIcon } from "../../../components/icons";
import { OrderedHeader } from "../../../components/barbagli/sortable_table_view";

type Props = {
    onClick?: (m: any) => void
    preexisting_filters?: FilterParam[]
}

type AnomalyIndexState = {
    order: OrderParam,
    filters: FilterParam[],
    page: number,
    meters: Attributes[],
    loading: boolean,
    error: string | null,
    totalItems: number,
    mappings: Record<string, ErrorMapping>
}


type AnomalyIndexAction =
    | { type: "setFilters", filters: FilterParam[] }
    | { type: "setOrder", order: OrderParam }
    | { type: "setPage", page: number }
    | { type: "setTotalItems", count: number }
    | { type: "setMeters", meters: Attributes[] }
    | { type: "setError", error: string }
    | { type: "setLoading" }
    | { type: "setMappings", mappings: Record<string, ErrorMapping>}


function reducer(state: AnomalyIndexState, action: AnomalyIndexAction): AnomalyIndexState {


    switch (action.type) {
        case "setFilters": return { ...state, filters: action.filters, page: 0 }
        case "setPage": return { ...state, page: action.page, error: null }
        case "setMeters": return { ...state, meters: action.meters, error: null, loading: false }
        case "setError": return { ...state, meters: [], error: action.error, loading: false }
        case "setLoading": return { ...state, meters: [], error: null, loading: true }
        case "setTotalItems": return { ...state, totalItems: action.count }
        case "setMappings": return {...state, mappings: action.mappings}
        case "setOrder": return {...state, order: action.order}
    }
}

const perPage = 12;

const Centerd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%
`

export function MetersAnomaliesIndex(props: Props) {

    const [state, dispatch] = useReducer(reducer, {
        order: { column: "last_anomaly_at", order: "desc" },
        filters: [],
        page: 0,
        meters: [],
        loading: true,
        error: null,
        totalItems: 0,
        mappings: {}
    })

    useEffect(() => {
        fetchSinglePageCollection<ErrorMapping>(`/api/v1/mapping`)(1, 10000).then((m) => {
            if(typeof m === "string") return;
            let map: Record<string, ErrorMapping> = {};
            m.sublist.forEach((em) => map[em.name] = em);
            dispatch({type: "setMappings", mappings: map})

        })
    }, [props])
    // TO FETCH CORRECT ONES
    useEffect(() => {
        /* console.log(state.page) */
        dispatch({type: "setLoading"})
        fetchPaginatedCollection<Attributes>(
            `/api/v1/meter-anomaly?sort=${state.order.column}&order=${state.order.order}${[...state.filters, ...(props.preexisting_filters ?? [])].map(f => `&${f.column}=${f.value}`).join("")}`)
            (state.page + 1, perPage).then((r) => {
                if (typeof r === "string") {
                    dispatch({ type: "setError", error: r });
                    return;
                }
                dispatch({ type: "setTotalItems", count: r.count })
                dispatch({ type: "setMeters", meters: r.sublist })
            });

    }, [state.page, state.filters, state.order])

    const orderableColumns = useMemo(() => ["last_anomaly_at", "class", "condominium"], [0]);


    const intl = useIntl();
    return <div className="d-flex flex-column">
        <SearchBar filters={state.filters} avaliableFilters={["serial_number", "class", "condominium", "sub_condominium", "immobile", "identifier"]}
            filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
            onFiltersChange={(f) => {
                if(JSON.stringify(f) !== JSON.stringify(state.filters))
                return dispatch({ type: "setFilters", filters: f });
            }}
        />

        {state.loading && <Centerd><LoaderComponent /></Centerd>}
        {state.error && <Centerd><ErrorComponent message={state.error}></ErrorComponent></Centerd>}

        <table style={{marginTop: "2rem", marginBottom: "3rem"}}>
            {!state.loading && !state.error && (!(state.meters.length === 0)) && <tr>
                
                {[
                    "condominium",
                    "sub_condominium",
                    "immobile",
                    "class",
                    "serial_number",
                    "last_anomaly_at",
                ].map((it) => <th><OrderedHeader 
                order={state.order}
                name={it}
                translatedName= { intl.messages[it]?.toString() ?? it} 
                orderableColumns={orderableColumns}
                onClick={(o: OrderParam) => dispatch({type: "setOrder", order: o})}
                /></th>)}
                
            </tr>}
            {state.meters.map((meter, i) => <MeterErrorIndexView key={i} intl={intl.messages as any} meter={meter} mappings={state.mappings}/>)}
            {state.meters.length === 0 && <Title>Nessun elemento presente</Title>}

</table>

        

        
        <ReactPaginate
            initialPage={state.page}
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(state.totalItems / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => dispatch({ type: "setPage", page: selected })}
            containerClassName={'d-flex flex-row m-0 p-0 pointer'}
            activeClassName={'bold ml-2 mr-2 pointer'}
            pageClassName={"text ml-2 mr-2 pointer"}
            previousClassName={"text ml-2 mr-2 pointer"}
            nextClassName={"text ml-2 mr-2 pointer"}
        />

    </div>
}




const Grow = styled.div`
flex-grow: 1;
`




function MeterErrorIndexView(props: {meter: Attributes, intl: Record<string, string>, mappings: Record<string, ErrorMapping>}) {

    const columns = useMemo(() => {
        const errors = props.meter.anomalies ?? {};
        const keys = Object.keys(errors);
        let ret: {errorNames: string[], openedAt: string[], lastErroEvent: string[]} = {errorNames: [], openedAt: [], lastErroEvent:[]};
        ret.errorNames = keys.map((k) => props?.mappings[k]?.name ?? k)
        return ret;
    }, [props.meter])

    return <tr style={{cursor: "pointer", marginBottom: "1rem"}} onClick={() => { history.push(`/meters/${props.meter.serialNumber}/tab/state`, props.meter) }}>
    <td><p>{props.meter.condominium ?? '-'}</p></td>
    <td><p>{props.meter.subCondominium ?? '-'}</p></td>
    <td><p>{props.meter.immobile ?? '-'}</p></td>
    <td><p>{props.meter.class ?? '-'}</p></td>
    <td><p>{props.meter.serialNumber}</p></td>
    <td><p>{moment(props.meter.lastAnomalyAt).format("DD/MM/yyyy HH:mm")}</p></td>

 
  </tr>
    
    

}