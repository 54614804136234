export function deepCopy<T>(t: T): T {
    if(t === null || t === undefined) return t;
    return JSON.parse(JSON.stringify(t)) as T;
}

export function first<T>(t: T[], or?: T): T | undefined {
    try {
        return t[0];
    } catch(e) {
        return or ?? undefined;
    }
}