import {useAlert} from 'react-alert';
import React, {useEffect, useReducer, useState} from 'react';
import {Attributes, Meter} from '../../../../models/meter';
import {Note} from '../../../../models/barbagli/note';
import {LoaderComponent} from '../../../../components/loading_widget';
import {OrderParam} from '../../../../models/barbagli/filter_order';
import {SortableTableView} from '../../../../components/barbagli/sortable_table_view';
import {fetchPaginatedCollection} from '../../../../repsitory/generic_repository';
import {useIntl} from 'react-intl';
import {PrimaryButton} from '../../../../components/barbagli/common';
import {ModalNote} from '../../../../components/barbagli/modal_note';
import {post} from '../../../../repsitory/generic_repository';
import {
  Condominium,
  CondominiumNode,
} from '../../../../models/barbagli/condominium';

type Props = {
  condominium: Condominium;
  selectedNode: CondominiumNode;
};
export function MeterNoteView(props: Props) {
  const intl = useIntl();
  const alert = useAlert();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState<OrderParam>({
    column: 'inserted_at',
    order: 'desc',
  });
  const createNote = async (
    state: CondominiumNode,
    contentNote: string,
    alert: any,
  ): Promise<string | true> => {
    const result = await post(
      {
        data: {
          type: 'meterNote',
          attributes: {content: contentNote},
        },
      },
      `/api/v1/meter/${state.id}/note`,
    );
    if (typeof result === 'string') {
      alert.error(result);
    } else if (result.errors?.detail != null) {
      alert.error(result.errors.detail);
    } else {
      setReload(!reload);
      alert.success('Operazione effettuata con successo');
    }
    return result;
  };
  return <div className="d-flex flex-column">
      <SortableTableView
        reload={reload}
        perPage={15}
        key={1}
        fetchCollection={async (page, perPage) => {
          const data = await fetchPaginatedCollection<any>(
            `/api/v1/meter/${props.selectedNode.id}/note?sort=${order.column}&order=${order.order}`,
          )(page, perPage);
          return data;
        }}
        itemsPreFormatter={(c: Note) => {
          const data = {...c};
          delete data['meterId'];
          delete data['immobileId'];
          delete data['creatorId'];
          delete data['condominiumId'];
          delete data['subCondominiumId'];
          delete data['meterSerial'];
          delete data['id'];
          return {...data, insertedAt: c.insertedAt};
        }}
        order={order}
        translated
        onOrder={setOrder}
        orderableColumns={['inserted_at', 'creator_nickname']}
      />
      <div className="d-flex flex-row-reverse">
        <PrimaryButton
          style={{marginLeft: 8}}
          onClick={() => {
            setIsOpen(true);
          }}>
          {intl.messages['add_note']}
        </PrimaryButton>
      </div>
      <ModalNote
        onSend={content => createNote(props.selectedNode, content, alert)}
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        title={(
          intl.messages['add_note']?.toString() ?? 'Add new note'
        ).toUpperCase()}
      />
    </div>
}
