import {
  Condominium,
  CondominiumNode,
  CondominiumNodeData,
  CondominiumNodeType,
  CondominiumTab,
  Immobile,
  SubCondominium,
} from '../../../models/barbagli/condominium';
import { Gateway } from '../../../models/gateway';
import { Meter } from '../../../models/meter';
import {
  fetchCondominiumGateways,
  fetchCondominiumMeters,
} from '../../../repsitory/barbagli/condominium_repository';
import { deepCopy, first } from '../../../utils/deep_copy';
import { nodeForId, updateCondominium } from './condominium_view_model';

export function meters(
  cn: CondominiumNode,
): (Meter & CondominiumNodeData & { type: 'METER' })[] {
  if (cn.type === 'METER') return [cn];
  if (cn.children.length == 0) return [];
  return [...cn.children.flatMap(meters)];
}

export async function fetchAssociatedResources(
  condominium: Condominium,
  selected_node: CondominiumNode,
) {
  //  da espandere coi gateway
  const data = condominiumSubCondominiumAndImmobile(selected_node, condominium);

  // to be used in creation
  if (!condominium.id) return condominium;

  // fetching meters associated to condominium
  const resp = await fetchCondominiumMeters(
    condominium.id!,
    data?.subCondominiumId,
    data?.immobileId,
  );
  if (!resp || typeof resp == 'string') return condominium;

  // to condominium node
  const nc = resp
    .map(meterToCondominiumNode)
    .reduce((c: Condominium, node: CondominiumNode) => {
      return appendNode(c, node, {
        subCondominiumId: data?.subCondominiumId,
        immobileId: data?.immobileId,
      });
    }, condominium);

  // fetching gateways associated to condominium
  const gResp = await fetchCondominiumGateways(
    condominium.id!,
    data?.subCondominiumId,
    data?.immobileId,
  );
  if (!gResp || typeof gResp == 'string') return nc;

  const ncg = gResp
    .map(gatewayToCondominiumNode)
    .reduce((c: Condominium, node: CondominiumNode) => {
      return appendNode(c, node, {
        subCondominiumId: data?.subCondominiumId,
        immobileId: data?.immobileId,
      });
    }, nc);

  return ncg;
}

export async function fetchAssociatedResourcesList(
  condominium: Condominium,
  active_nodes: string[],
): Promise<Condominium> {
  return fetchAssociatedResourcesListR(
    without(without(condominium, 'METER'), 'GATEWAY'),
    active_nodes,
  );
}

async function fetchAssociatedResourcesListR(
  condominium: Condominium,
  active_nodes: string[],
): Promise<Condominium> {
  if (active_nodes.length == 0) return condominium;
  const [node, ...nodes] = active_nodes;
  const condNode = nodeForId(condominium, node);
  if (!condNode) return fetchAssociatedResourcesListR(condominium, nodes);
  const ncondominium = await fetchAssociatedResources(condominium, condNode);
  return fetchAssociatedResourcesListR(ncondominium, nodes);
}

const gatewayToCondominiumNode = (g: Gateway) => {
  const cn: CondominiumNode = {
    ...g,
    type: 'GATEWAY',
    path_url: ``,
    node_id: `${g.id}|${[g.condominiumId, g.subCondominiumId, g.immobileId]
      .filter(i => i)
      .join('|')}`,
    children: [],
    availableTabs: [
      'data',
      'readings',
      'consumption',
      'self_reading',
      'meter_whitelist',
    ],
  };
  return cn;
};

const meterToCondominiumNode = (m: Meter) => {
  const cn: CondominiumNode = {
    ...m,
    type: 'METER',
    path_url: ``,
    node_id: `${m.id}|${[
      m.attributes.condominiumId,
      m.attributes.subCondominiumId,
      m.attributes.immobileId,
    ]
      .filter(i => i)
      .join('|')}`,
    children: [],
    availableTabs: [
      'data',
      'readings',
      'consumption',
      'alarms',
      'self_reading',
      'state',
      'notifications',
      'note'
    ],
  };
  return cn;
};

function without(c: Condominium, type: CondominiumNodeType): Condominium {
  const cc = deepCopy(c);
  return {
    ...cc,
    children: cc.children
      .filter(n => n.type != type)
      .map(n => ({
        ...n,
        children: n.children
          .filter(c => c.type !== type)
          .map(n => ({
            ...n,
            children: n.children.filter(n => n.type !== type),
          })),
      })),
  };
}

export function condominiumSubCondominiumAndImmobile(
  node: CondominiumNode,
  condominium: Condominium,
):
  | { condominiumId: number; immobileId?: string; subCondominiumId?: string }
  | undefined {
  const target_node_id = node.node_id;

  if (condominium.node_id === target_node_id)
    return { condominiumId: condominium.id! };

  for (const sc of condominium.children) {
    if (sc.type !== 'SUBCONDOMINIUM') continue;

    if (sc.node_id === target_node_id)
      return { condominiumId: condominium.id!, subCondominiumId: sc.node_id };

    for (const im of sc.children) {
      if (im.type !== 'IMMOBILE') continue;
      if (im.node_id === target_node_id)
        return {
          condominiumId: condominium.id!,
          subCondominiumId: sc.node_id,
          immobileId: im.node_id,
        };
    }
  }
}

function appendNode(
  condominium: Condominium,
  node: CondominiumNode,
  position: { subCondominiumId?: string; immobileId?: string },
): Condominium {
  // if node is already present avoid to re insert
  if (nodeForId(condominium, node.node_id)) {
    return condominium;
  }

  if (!position.subCondominiumId && !position.immobileId) {
    return {
      ...condominium,
      children: [...condominium.children, node],
    };
  }

  const sc = first(
    condominium.children.filter(
      n =>
        n.type === 'SUBCONDOMINIUM' && n.node_id === position.subCondominiumId,
    ),
  );
  if (!sc) {
    return condominium;
  }
  if (!position.immobileId) {
    return updateCondominium(condominium, {
      ...sc,
      children: [...sc.children, node],
    }) as Condominium;
  }

  const im = first(
    sc.children.filter(
      im => im.type === 'IMMOBILE' && im.node_id === position.immobileId,
    ),
  );
  if (!im) return condominium;

  return updateCondominium(condominium, {
    ...im,
    children: [...im.children, node],
  }) as Condominium;
}
