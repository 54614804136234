import React, { useMemo, useState } from "react"
import { useIntl } from "react-intl";
import { OutlineButton, PrimaryButton, Row } from "../../../components/barbagli/common";
import { SortableTableView } from "../../../components/barbagli/sortable_table_view";
import { TabView } from "../../../components/barbagli/tab_view";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { Attributes, emptyMeter, Meter } from "../../../models/meter";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import { deepCopy } from "../../../utils/deep_copy";
import { DatePickerInput } from "../../../components/datepicker";
import { Condominium, CondominiumNode } from "../../../models/barbagli/condominium";
import { parentSubCondominiumId } from "../condominium/condominium_view_model";
import { Gateway } from "../../../models/gateway";
import { and, BooleanFilter, DataSource, EventFilter, MappedFilter } from "../../data_explorer_page/ast/ast";
import { showExportFileDialog } from "../../../components/export_file_dialog";
import moment from "moment";

/*
    Dex filters inserted here will be combined in AND with a filter for date and a filter for type selected in this view
*/
type Props = {
    gateway_id?: number
    tabHidden?: boolean
    filters?: FilterParam[]
    dexFilters?: BooleanFilter
    from?: Date
    to?: Date
    source?: DataSource
}

type MetersIndexState = {
    selected_tab:
    | "RIPARTITORE"
    | "AF"
    | "ACS"
    | "CALORIE"
    | "FRIGORIE"
}

export function ConsumptionsIndex(props: Props) {


    const [state, setState] = useState<MetersIndexState>({ selected_tab: "AF" });
    if (props.tabHidden) return <ViewForState state={state} setState={setState} {...props} />
    return <div>
        <TabView
            children={[
                ["AF / ACS", <ViewForState tab={"AFACS"} state={state} setState={setState}   {...props} />],
                ["RIPARTITORE", <ViewForState tab={"RIPARTITORE"} state={state} setState={setState}  {...props} />],
                ["CALORIE / FRIGORIE", <ViewForState tab={"CALORIE"} state={state} setState={setState}  {...props} />],
            ]}
        />


    </div>
}

function ViewForState(props: {
    tab?: string,
    tabHidden?: boolean,
    state: MetersIndexState,
    gateway_id?: number, setState: React.Dispatch<React.SetStateAction<MetersIndexState>>,
    filters?: FilterParam[],
    dexFilters?: BooleanFilter,
    in_condominium?: boolean,
    from?: Date,
    source?: DataSource,
    to?: Date
},) {
    const [order, setOrder] = useState<OrderParam>(props.tabHidden ? { column: "time", order: "desc" } : { column: "condominium_order", order: "asc" });
    const [date, setDate] = useState<Date>((() => {
        if (props.from) return props.from;
        let d = new Date();
        d.setDate(d.getDate() - 7);
        return d;
    })());
    const [dateBefore, setDateBefore] = useState<Date>(props.to ?? new Date());
    const filter: FilterParam[] = useMemo(() => {
        return [
            { column: "date_before", value: dateBefore.toISOString() },
            { column: "date_after", value: date.toISOString() }
        ];
    }, [dateBefore, date])
    const intl = useIntl();
    return <div className="d-flex flex-column">
        <Row>
            <DatePickerInput
                style={{ marginBottom: 12, flexGrow: 1, marginRight: 2 }}
                value={date} onChange={setDate}
                showTimeSelect={false}
                enabled
                label={intl.messages["reading_date"].toString()} />
            <DatePickerInput
                style={{ marginBottom: 12, flexGrow: 1, marginLeft: 2 }}
                value={dateBefore}
                onChange={setDateBefore}
                showTimeSelect={false}
                enabled
                label={intl.messages["reading_date_before"].toString()} />

        </Row>
        <SortableTableView
            key={JSON.stringify([{ value: props.tab, column: "type" }, date.toISOString(), dateBefore.toISOString()])}
            perPage={250}
            permutationKeyPostfix={props.tab}
            rowStyle={(row: any) => {
                // console.log(row)
                if (row?.meta?.interpolated) {
                    return {
                        //backgroundColor: "rgba(66, 219, 209, 0.1)",

                    } as any
                }
                return {}
            }}
            fetchCollection={
                fetchPaginatedCollection<any>(
                    `/api/v1/events?q=${encodeURIComponent(JSON.stringify(toEventFilter(
                        date, dateBefore, props.tab, props.dexFilters, props.source
                    )))}&sort=${order.column}&order=${order.order}`,
                    undefined
                    , (items) => {
                        // Pre Process
                        return items.map((i) => {
                            const time = i.time;
                            const fix_utc_time = ((time: string) => {
                                try {
                                    return time.split(".")[0] + "Z"
                                } catch (e) {
                                    return time;
                                }
                            });
                            const k = i["k"] ?? 1;
                            return {
                                ...i,
                                time: moment(fix_utc_time(i.time)).format("DD/MM/YYYY HH:mm:ss"),
                                initial_reading_time: moment(fix_utc_time(i.initial_reading_time)).format("DD/MM/YYYY HH:mm:ss"),
                                final_reading_time: moment(fix_utc_time(i.final_reading_time)).format("DD/MM/YYYY HH:mm:ss"),
                                initial_reading_value: Number(i.initial_reading_value).toFixed(2).toString().replace(".", ","),
                                final_reading_value: Number(i.final_reading_value).toFixed(2).toString().replace(".", ","),
                                delta: ((Number(i.delta))).toFixed(2).toString().replace(".", ",")
                            };
                        })
                    })
            }
            translated
            itemsPreFormatter={(c: Attributes) => {
                const cc: any = deepCopy(c);
                if (cc?.meta?.absolute_consumption) cc["rovescio"] = true;
                delete cc["meta"];
                delete cc["updatedAt"];
                delete cc["insertedAt"];
                delete cc["condominium_id"];
                delete cc["sub_condominium_id"];
                delete cc["immobile_id"];
                delete cc["meter_identifier"];
                delete cc["id"];
                delete cc["gateway_identifier"];
                delete cc["initial_reading_value"];
                delete cc["final_reading_value"];
                delete cc["delta"];
                delete cc["condominium_order"];
                cc["final_reading_time"] = cc["final_reading_time"].substring(0, 10);
                cc["initial_reading_time"] = cc["initial_reading_time"].substring(0, 10);
                cc["time"] = cc["time"].substring(0, 10);

                if (props.tab?.includes("CALORIE") || cc["measure_unit"] === "watt_hour" || (cc["meter_type"] ?? "").includes("CALORIE")) {
                    cc["measure_unit"] = "kWh";
                    for (const k of Object.keys(cc["final_reading_values"] ?? {})) {
                        cc["final_reading_values"][k] = cc["final_reading_values"][k] / 1000
                    }
                    for (const k of Object.keys(cc["initial_reading_values"] ?? {})) {
                        cc["initial_reading_values"][k] = cc["initial_reading_values"][k] / 1000
                    }
                }

                cc["final_reading_values"] = Object.values(cc["final_reading_values"])?.map((it: any) => (Math.round((it + Number.EPSILON) * 100) / 100))?.reverse()?.join("; ")
                cc["initial_reading_values"] = Object.values(cc["initial_reading_values"])?.map((it: any) => (Math.round((it + Number.EPSILON) * 100) / 100))?.reverse()?.join("; ")
                cc["deltas"] = Object.values(cc["deltas"])?.map((it: any) => (it / 1000).toLocaleString("it-IT", { maximumSignificantDigits: 3 }))?.reverse()?.join("; ")
                // delete cc["battery"];

                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "final_reading_value",
                "final_reading_values",
                "final_reading_time",
                "initial_reading_value",
                "initial_reading_values",
                "initial_reading_time",
                "condominium_id",
                "sub_condominium_id",
                "condominium",
                "sub_condominium",
                "immobile",
                "meter_identifier",
                "room",
                "immobile_id",
                "time",
                "meta",
                "k",
                "gateway_identifier",
                "measure_unit",
                "floor",
                "internal",
                "meter_serial_number",
                "meter_type"
            ]}

        />
        <div className="d-flex flex-row-reverse">
            <OutlineButton style={{ marginTop: 10 }} onClick={() => showExportFileDialog(intl.messages as any, toEventFilterEXPORT(date, dateBefore, props.tab, props.dexFilters))}>
                {intl.messages["export_consumptions"]}
            </OutlineButton>
        </div>
    </div>
}


function toEventFilter(fromDate: Date, dateBefore: Date, type?: string, booleanFilter?: BooleanFilter, source?: DataSource): MappedFilter {


    const type_filter: BooleanFilter = type ? {
        type: "CONDITION", name: "type", value: type
    } : true;

    const fromDateFilter: BooleanFilter = {
        type: "CONDITION", name: "date_greater", value: fromDate.toISOString()
    }

    const dateBeforeFilter: BooleanFilter = {
        type: "CONDITION", name: "date_smaller", value: dateBefore.toISOString()
    }

    const fiter = and(and(fromDateFilter, type_filter), dateBeforeFilter);


    const value: EventFilter = {
        type: "FILTER",
        source: source ?? "cumulative_consumptions",
        filter: booleanFilter ? and(fiter, booleanFilter) : fiter
    }

    return {
        "type": "MAP_EX",
        "module": "SwarmBackend.DEX.CumulativeConsumptionMapping",
        "query": value
    }

}

function toEventFilterEXPORT(fromDate: Date, dateBefore: Date, type?: string, booleanFilter?: BooleanFilter, source?: DataSource): MappedFilter {

    const type_filter: BooleanFilter = true;

    const fromDateFilter: BooleanFilter = {
        type: "CONDITION", name: "date_greater", value: fromDate.toISOString()
    }

    const dateBeforeFilter: BooleanFilter = {
        type: "CONDITION", name: "date_smaller", value: dateBefore.toISOString()
    }

    const fiter = and(and(fromDateFilter, type_filter), dateBeforeFilter);


    const value: EventFilter = {
        type: "FILTER",
        source: source ?? "cumulative_consumptions",
        filter: booleanFilter ? and(fiter, booleanFilter) : fiter
    }

    return {
        "type": "MAP_EX",
        "module": "SwarmBackend.DEX.CumulativeConsumptionMapping",
        "query": value
    }

}