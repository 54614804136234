import { SortableTableView } from "components/barbagli/sortable_table_view";
import React, { useMemo, useState } from "react"
import { fetchPaginatedCollection } from "repsitory/generic_repository"
import { history } from "../../../index";
import { TypeAuthorized } from "components/authorized";
import { PrimaryButton } from "components/barbagli/common";
import { useIntl } from "react-intl";
import { SearchBar } from "components/barbagli/search_bar";
import { FilterParam } from "models/barbagli/filter_order";
import { useLocation } from "react-router-dom";

export function MagliaIdricaIndex() {
    const s = useLocation()
    const query = new URLSearchParams(s.search);
    const dashboardValue = query.get("dashboard");

    const [filter, setFilter] = useState<FilterParam[]>(dashboardValue ? [{ column: "dashboard", value: dashboardValue }] : []);
    const intl = useIntl();
    return <div>
        <SearchBar filters={filter} avaliableFilters={["name", "condominium_name"]} filterNamePostProcessor={(id) => intl.messages[`${id}`]?.toString() ?? id} onFiltersChange={setFilter} />
        <SortableTableView
            perPage={15}
            key={JSON.stringify(filter)}
            fetchCollection={
                async (page, perPage) => {
                    if (filter.length > 0) {
                        debugger
                    }
                    const data = await fetchPaginatedCollection<any>(`/api/v1/water_balance?sort=updated_at&order=desc${filter.map(f => `&${f.column}=${f.value}`).join("")}`)(page, perPage);
                    return data
                }
            }
            onTap={
                (item: any) => {

                    history.push(`/maglia_idrica/${item.id}`, item)
                }
            }
            itemsPreFormatter={(c: any) => {
                // const data = deepCopy(c.data);
                return c
                // delete data["companyName"];
                // return ({ ...data, insertedAt: c.insertedAt, partner: c.partners });
            }}
            // order={order}
            translated

        />
        <div className="d-flex flex-row-reverse">
            <TypeAuthorized user_types={["admin"]}>

                <PrimaryButton onClick={() => {
                    history.push("/maglia_idrica/new");
                }}>{intl.messages["add_water_balacne"]}</PrimaryButton>
            </TypeAuthorized>
        </div>
    </div>
}