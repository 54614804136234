import React, {useState} from 'react';
import {LoaderButton, OutlineButton} from 'components/barbagli/common';
import {useIntl} from 'react-intl';
import Modal from 'react-modal';
import {TextAreaInput} from 'components/barbagli/text_input';

type Props = {
  onSend: (email: string) => Promise<string | true>;
  modalIsOpen: boolean;
  closeModal: () => void;
  title: string;
};
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export function ModalNote(props: Props) {
  const intl = useIntl();
  const [content, setContent] = useState('');
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}>
      <h2>{props.title}</h2>
      <form>
        <TextAreaInput
          enabled
          label={(
            intl.messages['content']?.toString() ?? 'Content'
          ).toUpperCase()}
          value={content}
          onChange={v => {
            setContent(v);
          }}
        />
      </form>
      <OutlineButton
        style={{marginRight: 8}}
        onClick={_ => {
          props.closeModal();
        }}>
        {intl.messages['close'] ?? 'close'}
      </OutlineButton>
      <LoaderButton
        style={{marginRight: 8}}
        onClick={() => {
          const r = props.onSend(content);
          return new Promise((resolve, reject) => {
            r.then(_ => {
              setContent("")
              props.closeModal();
              resolve(true);
            });
          });
        }}>
        {intl.messages['add'] ?? 'Add'}
      </LoaderButton>
    </Modal>
  );
}
