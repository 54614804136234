import React from "react";

export function Search() {
  return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9167 17.4167C7.86658 17.4167 4.58333 14.1334 4.58333 10.0833C4.58333 6.03325 7.86658 2.75 11.9167 2.75C15.9668 2.75 19.25 6.03325 19.25 10.0833C19.25 14.1334 15.9668 17.4167 11.9167 17.4167Z" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.74999 19.25L6.73749 15.2625" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;
}

export function Collapsed() {
  return <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9436 1.7998L6.9436 6.7998L11.9436 1.7998" stroke="#5A5A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}

export function Expanded() {
  return <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9436 11.7998L6.9436 6.7998L1.9436 1.7998" stroke="#5A5A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

}

export function SortableIcon() {
  return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M7.5 3.33335L5 0.833355L2.5 3.33335" stroke="#5A5A5A" strokeOpacity="0.51" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.5 6.66664L5 9.16664L7.5 6.66664" stroke="#5A5A5A" strokeOpacity="0.51" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>

}