import React, {useEffect, useState} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import {useIntl} from 'react-intl';
import {useHistory} from "react-router-dom";
import Select from 'react-select';
import AsyncSelect from "react-select/async";
import { ErrorComponent } from '../../../components/error_component';
import { TrashIcon, MoreVerticalIcon } from '../../../components/icons';
import { EditableRow } from '../../../components/InfoRow';
import { PaginatedListComponent } from '../../../components/paginated_list/paginated_list_component';
import { PaginatedList } from '../../../components/paginated_list/paginated_sublist';
import { emptyUserGroup, User } from '../../../models/user';
import { UserGroup } from '../../../models/user_group';
import { UserPermission } from '../../../models/user_permission';
import { fetchSinglePageCollection } from '../../../repsitory/generic_repository';
import { fetchAllUserPermissions, fetchUserGroupPermissions } from '../../../repsitory/user_permission_repository';
import { addUserToGroup, fetchPaginatedUsers, removeUserToGroup } from '../../../repsitory/user_repository';
import { mkUserGroupState, createEditUserGroup } from '../../user_groups_page/edit_group_page_vm';
import { EditGroupPageState } from './edit_group_page_vm';



export function CreateEditBarbagliUserGroupPage() {

  const history = useHistory();
  const group: UserGroup = (history?.location?.state as UserGroup) || emptyUserGroup();

  const [state, setState] = useState<EditGroupPageState>(mkUserGroupState(group));
  const [error, setError] = useState("");
  const [initialPage, setInitialPage] = useState(0);
  const [allPermissions, setAllPermissions] = useState<UserPermission[]>([]);
  const [currentGroupsPermissions, setCurrentGroupsPermissions] = useState<UserPermission[]>([]);
  const [selectValue, setSelectValue] = useState<{label: string, value: string}[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<string[]>([]);

  const intl = useIntl();

  useEffect(() => {
    (async () => {
      setAllPermissions(await fetchAllUserPermissions());
      const currentGroupPermissions = (state.id && await fetchUserGroupPermissions(state.id)) || [];
      setCurrentGroupsPermissions(currentGroupPermissions);

      setSelectValue(currentGroupPermissions.map(ug => ({label: ug.attributes.name, value: ug.id})));
      setState({...state, authorizations: currentGroupPermissions.map(ug => ug.id)});
    })();
  }, [0]);

  if(state.loading) return <p>Loading</p>;

  return (
    <div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <EditableRow title={"name"} value= {state.name} onEdit={ !state.id && ((s) => setState({...state, name: s})) || undefined } />
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <EditableRow title={"description"} value= {state.description} onEdit={ (s) => setState({...state, description: s}) } />
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <div className={"mb-2"}>
            <h3>{intl.messages["authorization"].toString().toUpperCase()}</h3>
            <Select
                value={ selectValue }
                onChange={(g: any) => {
                  setState({...state, authorizations: g?.map((g: any) => g.value)});
                  setSelectValue(g);
                }}
                isMulti
                options={allPermissions.map((userPermission) => ({ value: userPermission.id, label: userPermission.attributes.name }))}
                name="authorization"
                className="basic-multi-select"
                classNamePrefix="select"
            />
          </div>
        </div>
      </div>
      { state.id !== undefined && state.id !== "" && <div className={"row mt-3 mb-4"}>
        <div className={"col-md-12"}>
          <h3>{intl.messages["add_users"].toString().toUpperCase()}</h3>
          <div className={"d-flex flex-row"}>
            <AsyncSelect
              defaultValue={[]}
              onChange={(g: any) => setUsersToAdd(g?.map((g: any) => g.value) ?? [])}
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={ (input: string) => {


                return fetchPaginatedUsers(`/api/v1/user?username=${input}`)(1, 8).then((r) => {
                  if(typeof r !== "string") return r.sublist.map((u) => ({value: u.userId, label: u.username}))
                  return [];
                }) as Promise<User[]>

              } }

              name="groups"
              className="basic-multi-select flex-grow-1"
              classNamePrefix="select"
            />
            <button className={"outline-button ml-2"} onClick={() => {
              Promise.all(usersToAdd.map((u) => addUserToGroup(u, group.id))).then(() => {
                setInitialPage(1);
                setInitialPage(0);
                setUsersToAdd([]);
              });
            }}>{intl.messages["add_users"]}</button>
          </div>
        </div>
      </div> }

        { state.id !== undefined && state.id !== "" && <div className={"row"}>
            <div className={"col-md-12 ellipsis"}>
                {state.error !== "" && <p>{state.error}</p>}
                <PaginatedListComponent headers={
                    ["id", "username", "email", "groups", "actions"]
                } rowBuilder={
                    (u: User) => [
                        <p>{u.id}</p>,
                        <p>{u.username}</p>,
                        <p>{u.email || "--"}</p>,
                        <p>{(u.groups || []).join(" ")}</p>,
                        <div style={{marginTop: "-6px"}} className={"d-flex"}>
                            <button className={"icon-button"} onClick={(e) => {
                                e.stopPropagation();
                                confirmAlert({
                                    title: intl.messages["confirm"].toString(),
                                    message: intl.messages["user_will_be_removed_from_group"].toString(),
                                    buttons: [
                                        {
                                            label: 'Yes',
                                            onClick: async () => {
                                                setState({...state, error: (await removeUserToGroup(u.id!, group.id!) ? "" : intl.messages["cannot remove user from group"].toString())});
                                                setInitialPage(1);
                                            }
                                        },
                                        {
                                            label: 'No',
                                            onClick: () => {}
                                        }
                                    ]
                                });

                            }}>
                                <TrashIcon/>
                            </button>
                            <button className={"icon-button"}>
                                <MoreVerticalIcon/>
                            </button>
                        </div>,
                    ]
                }
                fetchCollection={
                    fetchSinglePageCollection<User>(`/api/v1/user/group/${state.id}/user`)
                }
                perPage={8}
                onTap={ (u: User) => {
                    history.push(`/users/${u.username}/edit`, u)
                }}
                initialPage={initialPage}
                />
            </div>
        </div>}

        { state.error !== "" && <ErrorComponent message={state.error}/> }

      <div className={"row mt-3"}>
        <div className={"col-md-12 ellipsis mt-1"}>
          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"}  onClick={() => { createEditUserGroup(state, currentGroupsPermissions, setState, history) }}>
              {intl.messages['save']}
            </button>
            <button
              className={"p3 outline-button mr-3"}
              onClick={() => { history.goBack(); }}
            >
              {intl.messages['cancel']}
            </button>
            <p className={"p-2 mr-5"}>{ state.validationErrors.join(", ") }</p>
          </div>
        </div>
      </div>
    </div>
  );
}
