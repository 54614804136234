
// Returns fitting styles for dragged/idle items
import React, {useRef} from "react";
import {animated, interpolate, useSprings} from "react-spring";
import {grayGradient} from "../../utils/colors";
import {useGesture} from "react-use-gesture";
import clamp from "lodash-es/clamp";
import {permute, swap} from "../../utils/arrays";

const fn = (order: number[], down?: boolean, originalIndex?: number, curIndex?: number, y?: number) => (index: number) =>
  down && index === originalIndex  //  if pressed
    ? { y: curIndex! * 60 + y!, scale: 1.1, zIndex: '1', shadow: 15, immediate: (n:any) => n === 'y' || n === 'zIndex' }
    : { y: order.indexOf(index) * 60, scale: 1, zIndex: '0', shadow: 1, immediate: false };



export function DraggableList<T>(props: { items: T[], onOrderChanged?: (o: number[]) => void, permutation?: number[], style?: any}, ) {

  const oldP = /*(props?.permutation?.length ?? 0) > 0 ? props.permutation :*/ null;


  const order = useRef<number[]>(oldP ?? props.items.map((_, i) => i)) // Store indicies as a local ref, this represents the item order
  let colorPermutation = props.permutation ?? order.current;
  if(colorPermutation.length !== order.current.length) colorPermutation = order.current;

  // @ts-ignore
  const [springs, setSprings]: [any, any] = useSprings(props.items.length, fn(order.current)) // Cre // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const gradients = grayGradient(springs.length + 1);
  const bind = useGesture(({ args: [originalIndex], down, delta: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(Math.round((curIndex * 60 + y) / 60), 0, props.items.length - 1)
    const newOrder: number[] = swap(order.current, curIndex, curRow);

    setSprings(fn(newOrder, down, originalIndex, curIndex, y)) // Feed springs new style data, they'll animate the view without causing a single render
    if (!down) {
      order.current = newOrder;
      props.onOrderChanged && props.onOrderChanged(permute(colorPermutation, newOrder));
    }
  })
  return (
    <div className="content d-flex justify-content-center"
         style={{
           minHeight: `${Math.min(60 * springs.length, 700)}px`, maxHeight: 700, width: "100%", overflowY: "auto", overflowX: "visible"
           ,...(props.style ?? {})
         }}>
      {springs.map(({ zIndex, shadow, y, scale }: any, i: number) => (
        <animated.div
          {...bind(i)}
          key={i}
          className={"noselect"}
          style={{
            zIndex,
            boxShadow: shadow.interpolate((s: any) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
            transform: interpolate([y, scale], (y, s) => `translate3d(0,${y}px,0) scale(${s})`),
            background: `linear-gradient(180deg, ${gradients[colorPermutation[i]]} 0%, ${gradients[colorPermutation[i]+1]} 100%)`
          }}
          children={props.items[i]}
        />
      ))}
    </div>
  )
}

