import { GeoJSONPoint, geoJsonPointFromCoordinates } from "./geo_json";
import { fallbackMapCenter } from "../index";
import { getDefaultGatwayCoordinates } from "../pages/gateways_page/edit_gateway_page_vm";

export function emptyGateway(): Gateway {
  return {
    battery: 0,
    config: {},
    backhaulQos: "",
    updatedAt: new Date(),
    manufacturer: "",
    longitude: 11.87,
    latitude: 45.40,
    identifier: "",
    coordinates: geoJsonPointFromCoordinates(getDefaultGatwayCoordinates()) ?? {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [fallbackMapCenter.lng, fallbackMapCenter.lat]
      }
    },
    deviceTypeId: "",
    address: "",
    active: false,
    status: "",
    name: "",
    serial: "",
    description: "",
    height: 0,
    floor: "",
    updateGroups: [],
    deleteGroups: [],
    listGroups: [],
    backhaulConfig: {},
    radioCoverage: {
      radius_from_height: []
    }

  };

}

export interface Gateway {
  updatedAt: Date;
  condominiumId?: number;
  subCondominiumId?: string;
  immobileId?: string;
  class?: string;
  floor: string;
  meta?: any;
  installDate?: string;
  manufacturer: string;
  longitude: number;
  latitude: number;
  identifier: string;
  deviceTypeId: string;
  backhaulConfig: Object
  backhaulId?: number
  height: number
  storage?: number
  battery: number
  backhaulQos: string
  config: any
  description: string
  id?: number;
  coordinates?: GeoJSONPoint;
  address: string;
  active: boolean;
  status: string;
  name: string;
  serial: string;
  updateGroups: string[],
  deleteGroups: string[],
  listGroups: string[],
  radioCoverage: {
    radius_from_height: { height: number, radius: number }[]
  },
  mac?: string
}

// const a = {
//   "radius_from_height": [
//   {"height": 0, "radius": 150},
//   {"height": 8, "radius": 450},
//   {"height": 15, "radius": 1500},
//   {"height": 30, "radius": 4500}
// ]}