import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ErrorComponent } from '../../../components/error_component';
import { EditableRow } from '../../../components/InfoRow';
import { User, emptyUserGroup } from '../../../models/user';
import { UserGroup } from '../../../models/user_group';
import { fetchUserGroups, fetchAllGroups } from '../../../repsitory/user_repository';
import { mkUserState, createEditUser, EditUserPageState, resendVerifyEmailToUser } from './edit_user_page_vm';
import { TextInput } from '../../../components/barbagli/text_input';
import { AsyncSelectIdsFromCrud, Select } from '../../../components/barbagli/select';
import { type } from 'os';
import { showAlert } from '../../../components/alerts/alert';
import { useAlert } from 'react-alert';

export function CreateEditBarbagliUserPage() {

  const history = useHistory();
  const user: User = (history?.location?.state as User) || emptyUserGroup();

  const alert = useAlert();
  const [state, setState] = useState<EditUserPageState>(mkUserState(user));

  const dispatch = useDispatch();
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [initialUserGroups, setInitialUserGroups] = useState<UserGroup[]>([]);
  const [selectValue, setSelectValue] = useState<{ label: string, value: string }[]>([]);


  useEffect(() => {
    (async () => {
      const initialUserGroups = (state.id && (await fetchUserGroups(state.id))) || [];
      const allGroups = await fetchAllGroups();
      setGroups(allGroups);
      setInitialUserGroups(initialUserGroups);
      setSelectValue(initialUserGroups.map(ug => ({ label: ug.name, value: ug.id })));
      setState({ ...state, groups: initialUserGroups.map(ug => ug.id) });
    })();
  }, [0, state.id]);

  useEffect(() => {
    if (state.confirmationSent) {
      alert.success('Operazione effettuata con successo');
    }
  }, [state.confirmationSent])

  const location = useLocation();
  const condominium: string | undefined = useMemo(() => {
    return (new URLSearchParams(location.search ?? "")).get("condominium_id") ?? undefined
  }, [location.search])


  const intl = useIntl();

  if (state.error !== "") return <ErrorComponent message={state.error} />;
  if (state.loading) return <p>Loading</p>;


  return (
    <div>

      <TextInput
        enabled={!state.id}
        label={(intl.messages["username"] ?? "username").toString()}
        value={(state.username ?? "--")}
        onChange={(v) => { setState({ ...state, username: v }) }}
      />

      <TextInput
        enabled
        label={(intl.messages["firstName"] ?? "firstName").toString()}
        value={(state.firstName ?? "--")}
        onChange={(v) => { setState({ ...state, firstName: v }) }}
      />

      <TextInput
        enabled
        label={(intl.messages["lastName"] ?? "lastName").toString()}
        value={(state.lastName ?? "--")}
        onChange={(v) => { setState({ ...state, lastName: v }) }}
      />

      <TextInput
        enabled={!state.id}
        label={(intl.messages["email"] ?? "email").toString()}
        value={(state.email ?? "--")}
        onChange={(v) => { setState({ ...state, email: v }) }}
      />

      <TextInput

        label={(intl.messages["business_name"] ?? "businessName").toString()}
        value={(state.businessName ?? "")}
        onChange={(v) => { setState({ ...state, businessName: v }) }}
      />


      <AsyncSelectIdsFromCrud
        style={{ marginBottom: 16, marginTop: 8 }}
        label="Condomini"
        baseUrl="/api/v1/condominium"
        idExtractor={(a: any) => a?.id}
        valueMapper={(a: any) => a?.name}
        filterName="name"
        placeholder="Seleziona condominio"
        selectedIds={state.condominiums ?? []}
        onIdsSelected={(ids: number[]) => setState({ ...state, condominiums: ids })}
      />

      <AsyncSelectIdsFromCrud
        style={{ marginBottom: 16, marginTop: 8 }}
        label="Azienda"
        baseUrl="/api/v1/company"
        idExtractor={(a: any) => a?.id}
        valueMapper={(a: any) => a?.name}
        filterName="name"
        placeholder="Seleziona azienda"
        selectedIds={state.companies ?? []}
        onIdsSelected={(ids: number[]) => setState({ ...state, companies: ids })}
      />



      <AsyncSelectIdsFromCrud
        style={{ marginBottom: 24, marginTop: 8 }}
        label="Partner"
        baseUrl="/api/v1/partner"
        idExtractor={(a: any) => a?.id}
        valueMapper={(a: any) => a?.companyName}
        filterName="name"
        placeholder="Seleziona Partner"
        selectedIds={state.partners ?? []}
        onIdsSelected={(ids: number[]) => setState({ ...state, partners: ids })}
      />

      <Select
        enabled
        label={(intl.messages["profile"] ?? "profile").toString()}
        isMulti={false}
        availableOptions={["user", "admin", "company-admin", "partner-admin", "installer", "mnd-user"]}
        selectedOptions={[state.type ?? ((condominium && !state.id) ? "admin" : "--")]}
        valueMapper={(v: any) => v.toString()}
        onChange={([r]) => setState({ ...state, type: r })}
      />



      <div className={"row mt-3"}>
        <div className={"col-md-4 ellipsis mt-1"}>
          <button className={"p3 outline-button "} onClick={() => resendVerifyEmailToUser(state, initialUserGroups, setState, dispatch, history, condominium)}>
            {intl.messages['resend_verify_email']}
          </button>
        </div>
        <div className={"col-md-8  mt-1"}>
          <div className={"d-flex flex-row-reverse"}>

            <button className={"p3 primary-button"} onClick={() => {
              if (condominium) {
                showAlert(intl.messages["warning"].toString(), intl.messages["this action will associate user to condominium"].toString(), {
                  onDone: () => {
                    createEditUser(state, initialUserGroups, setState, dispatch, history, condominium)
                  }
                })
              } else {
                createEditUser(state, initialUserGroups, setState, dispatch, history, condominium)
              }


            }}>
              {intl.messages['save']}
            </button>
            <button className={"p3 outline-button mr-3"} onClick={() => history.goBack()}>
              {intl.messages['cancel']}
            </button>
            <p className={"p-2 mr-5"}>{state.validationErrors.join(", ")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
