import React, { useState } from "react"
import { useIntl } from "react-intl";
import { PrimaryButton } from "components/barbagli/common";
import { SearchBar } from "components/barbagli/search_bar";
import { SortableTableView } from "components/barbagli/sortable_table_view";
import { FilterParam, OrderParam } from "models/barbagli/filter_order";
import { fetchPaginatedCollection } from "repsitory/generic_repository";
import { history } from "index";
import { deepCopy } from "utils/deep_copy";


type Props = {
    onClick?: (m: any) => void,
    filters?: FilterParam[],
}


export function CompaniesIndex(props: Props,) {
    const [order, setOrder] = useState<OrderParam>({ column: "inserted_at", order: "asc" });
    const [filter, setFilter] = useState<FilterParam[]>([]);
    const intl = useIntl();
    return <div className="d-flex flex-column">
        <SearchBar filters={filter} avaliableFilters={[]}
            filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
            onFiltersChange={setFilter}
        />
        <SortableTableView
            key={JSON.stringify([...filter, ...(props.filters ?? [])])}
            perPage={8}
            fetchCollection={
                async (page, perPage) => {
                    // console.log(filter)
                    return fetchPaginatedCollection<any>(
                        `/api/v1/company?sort=${order.column}&order=${order.order}${[...filter, ...(props.filters ?? [])].map(f => `&${f.column}=${f.value}`).join("")}`)
                        (page, perPage);
                }
            }
            onTap={
                (item: any) => {
                    // console.log(item)
                    if (props.onClick && typeof item != "string") {
                        props.onClick(item)
                    } else {
                        if (typeof item !== "string") history.push(`/companies/${item.name}`, item)
                    }
                }
            }
            translated
            itemsPreFormatter={(c: any) => {
                const cc: any = deepCopy(c);
                delete cc["condominiums"]
                delete cc["sub_condominiums"]
                delete cc["id"]
                delete cc["partners"]
                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "name", "vat_number", "code", "city", "cap", "address"
            ]}
        />
        <div className="d-flex flex-row-reverse">
            {!props.onClick && <PrimaryButton onClick={() => {
                history.push("/companies/new", null);
            }}>{intl.messages["add_company"]}</PrimaryButton>}
        </div>
    </div>
}