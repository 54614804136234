
import { Gateway } from "../../models/gateway";
import { httpDelete, post, put } from "../generic_repository";

export async function createGateway(gateway: Gateway): Promise<Gateway | string> {
    const j = toJson(gateway);
    try {
        const r = await post(j, `/api/v1/gateway`);
        if (r.errors != undefined) return JSON.stringify(r.errors);
        if (typeof r !== "string") return gateway;
        else return r;
    } catch (e) {
        console.error(e);
        return e.toString();
    }
}

export async function updateGateway(gateway: Gateway & { id: number }): Promise<Gateway | string> {
    const json = toJson(gateway);
    try {
        const r = await put(json, `/api/v1/gateway/${gateway.id}`);
        if (r.errors != undefined) return JSON.stringify(r.errors);
        if (typeof r !== "string") return gateway;
        else return r;
    } catch (e) {
        console.error(e);
        return e.toString();
    }
}

export async function deleteGateway(id: number): Promise<string | true> {
    try {
        const r = await httpDelete(`/api/v1/gateway/${id}`);
        if (typeof r === "string") return r;
        return true;
    } catch (e) {
        console.error(e);
        return e.toString();
    }
}

function toJson(attributes: Gateway): any {

    return {
        "data": {
            "type": "gateway",
            "attributes": { ...attributes, active: undefined, install_date: attributes.installDate }
        }
    }
}