import React from "react"
import styled from "styled-components"
import { Column, FlatButton, Row } from "../../../components/barbagli/common"
import { showModal } from "../../../components/barbagli/modal"
import { Title } from "../../../components/barbagli/text"

type Props = {
    intl: Record<string, string>,
    content: any
}

export function showReadingMetaModal(props: Props) {
    showModal((onClose, intl) =>
        <ReadingMetaModal intl={props.intl} content={props.content} close={onClose}></ReadingMetaModal>
    , props.intl)
}

const ResCont = styled.div`
height: 400px;
overflow-y: auto;
margin-top: 20px;
`

export function ReadingMetaModal(props: Props & {close: any}) {
    return <Column>
    <Title>Dati grezzi della lettura</Title>
    <ResCont>
    <pre>{JSON.stringify(props.content, null, 2)}</pre>
    </ResCont>
    <Row style={{flexDirection: "flex-row-reverse"}}>
        <FlatButton onClick={props.close}>{props.intl["close"]}</FlatButton>
    </Row>
    </Column>
}