import React, { useReducer, useMemo } from "react"
import { useIntl } from "react-intl";
import styled from "styled-components";
import { TextInput } from "components/barbagli/text_input";
import { Column, LoaderButton, OutlineButton } from "components/barbagli/common";
import { history } from "index";
import { deepCopy } from "utils/deep_copy";
import { httpDelete, post, put } from "repsitory/generic_repository";
import { useAlert } from 'react-alert'
import { showAlert } from "components/alerts/alert";
import { AsyncSelectIdsFromCrud } from "components/barbagli/select";


type Props = {
    mndUser?: MndUser
    validationKeys?: string[]
}

export function MndUserDetailPage(props: Props) {
    const item: MndUser = props.mndUser ?? isAMndUser(history?.location?.state) ? (history?.location?.state as MndUser) : emptyMndUser();

    const [state, dispach] = useReducer(reducer, deepCopy(item))
    const intl = useIntl()
    const alert = useAlert();

    return <Column>

        <Layout>
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["username"]?.toString() ?? "username").toUpperCase()}
                value={(state.username).toString()}
                onChange={(v) => { dispach({ type: "set_username", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["email"]?.toString() ?? "email").toUpperCase()}
                value={(state.email).toString()}
                onChange={(v) => { dispach({ type: "set_email", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["firstName"]?.toString() ?? "firstName").toUpperCase()}
                value={(state.firstName).toString()}
                onChange={(v) => { dispach({ type: "set_firstName", value: v }) }}
            />
            <TextInput
                enabled
                style={{ flex: "0 0 33%", paddingRight: 8 }}
                label={(intl.messages["lastName"]?.toString() ?? "lastName").toUpperCase()}
                value={(state.lastName).toString()}
                onChange={(v) => { dispach({ type: "set_lastName", value: v }) }}
            />
            <AsyncSelectIdsFromCrud
                style={{ marginRight: 24, marginTop: 8 }}
                label="Condomini"
                baseUrl="/api/v1/condominium"
                idExtractor={(a: any) => a?.id}
                valueMapper={(a: any) => a?.name}
                filterName="name"
                placeholder="Seleziona condominio"
                selectedIds={state.condominiums ?? []}
                onIdsSelected={(ids: number[]) => dispach({ type: "set_conds", value: ids })}
            />
            <AsyncSelectIdsFromCrud
                style={{ marginRight: 24, marginTop: 8 }}
                label="Partner"
                baseUrl="/api/v1/partner"
                idExtractor={(a: any) => a?.id}
                valueMapper={(a: any) => a?.companyName}
                filterName="name"
                placeholder="Seleziona Partner"
                selectedIds={state.partners ?? []}
                onIdsSelected={(ids: number[]) => dispach({ type: "set_partners", value: ids })}
            />
        </Layout>
        <BottomRow>

            <LoaderButton onClick={async () => {
                return createUpdate(state, intl, history, alert, props.validationKeys ?? [], onCreate, onUpdate)
            }}>
                {(state.id ? (intl.messages["update"] ?? "update") : (intl.messages["create"] ?? "create")).toString()}
            </LoaderButton>
            {
                state.id && <OutlineButton style={{ marginRight: 8 }} onClick={
                    async (_) => {
                        showAlert(intl.messages["are_you_sure"].toString(), intl.messages["this_action_cannot_be_undone"].toString(), {
                            onDone: async () => {
                                const result = await onDelete(state);
                                if (typeof result == "string") alert.error(result);
                                history.goBack();
                                alert.info(intl.messages["success_deletion"])
                            }, secondaryButtonText: intl.messages["discard"].toString()
                        })

                    }}>{(intl.messages["delete"] ?? "delete")
                    }</OutlineButton>
            }

            <OutlineButton style={{ marginRight: 8 }} onClick={(_) => history.goBack()}>{(intl.messages["cancel"] ?? "cancel")}</OutlineButton>
            <OutlineButton style={{ marginRight: 8 }} onClick={async () => { return resendVerifyEmailToUser(state, intl, history, alert, [], onResendVerifyEmailToUser) }}>{(intl.messages["resend_verify_email"] ?? "resend_verify_email")}</OutlineButton>

        </BottomRow>
    </Column>

}

//_______________Visual Blocks
const Layout = styled.div`
display: flex;
flex-wrap: wrap;
`
const BottomRow = styled.div`
display: flex;
flex-direction: row-reverse;
padding-right: 8px;
margin-right: 8px;
`
//______________End Visual Blocks



//______________Model
export type MndUser = {
    username: string,
    id?: number,
    partners?: number[],
    email: string,
    firstName: string,
    lastName: string,
    partnerId: string,
    condominiums: number[]
}

export function emptyMndUser(): MndUser {
    return {
        username: "",
        partners: [],
        email: "",
        firstName: "",
        lastName: "",
        partnerId: "",
        condominiums: []
    }
}

export function isAMndUser(c: any): boolean {
    return c?.email && c?.firstName && c?.lastName
}
//______________End 



//_______________Reducer
type PageState = MndUser;

type PageAction =
    | { type: "set_partners", value: number[] }
    | { type: "set_username", value: string }
    | { type: "set_email", value: string }
    | { type: "set_firstName", value: string }
    | { type: "set_lastName", value: string }
    | { type: "set_partnerId", value: string }
    | { type: "set_conds", value: number[] }


function reducer(state: PageState, action: PageAction): PageState {
    switch (action.type) {
        case "set_partners": return { ...state, partners: action.value }
        case "set_username": return { ...state, username: action.value }
        case "set_email": return { ...state, email: action.value }
        case "set_firstName": return { ...state, firstName: action.value }
        case "set_lastName": return { ...state, lastName: action.value }
        case "set_partnerId": return { ...state, partnerId: action.value }
        case "set_conds": return { ...state, condominiums: action.value }
    }
}
//______________End Reducer




//____Functions to Handle Effects

async function createUpdate(state: PageState, intl: any, history: any, alert: any, requiredFields: string[], onCreate: any, onUpdate: any): Promise<string | true> {
    // Phase 1 validation of state
    const errors = requiredFields.reduce((acc: string, x: string) => {
        // @ts-ignore
        if (!state[x] || state[x] === "") {
            return `Il campo ${(intl.messages[x] ?? x).toString()} non può essere vuoto\n${acc}`
        }
        return acc;
    }, "")

    if (errors != "") {
        showAlert(intl.messages["validation_errors"], errors)
        return "errore di validazione"
    }

    const result = await (state.id ? onUpdate(state) : onCreate(state));
    if (typeof result != "string") {
        history.goBack();
        alert.info(intl.messages["success"])
        return true;
    } else {
        alert.error(result)
        return result;
    }
}


async function resendVerifyEmailToUser(state: PageState, intl: any, history: any, alert: any, requiredFields: string[], onResendVerifyEmailToUser: any): Promise<string | true> {
    // Phase 1 validation of state
    const errors = requiredFields.reduce((acc: string, x: string) => {
        // @ts-ignore
        if (!state[x] || state[x] === "") {
            return `Il campo ${(intl.messages[x] ?? x).toString()} non può essere vuoto\n${acc}`
        }
        return acc;
    }, "")

    if (errors != "") {
        showAlert(intl.messages["validation_errors"], errors)
        return "errore di validazione"
    }

    const result = await onResendVerifyEmailToUser(state);
    alert.info(result)
    return true;


}


const onCreate = (async (i: PageState) => post({ data: { type: "mnd-user", attributes: i } }, "/api/v1/my-net-domus-user"))
const onUpdate = (async (i: PageState) => put({ data: { type: "mnd-user", attributes: i } }, `/api/v1/my-net-domus-user/${i.id}`))
const onDelete = (async (i: PageState) => httpDelete(`/api/v1/my-net-domus-user/${i.id}`))
const onResendVerifyEmailToUser = (async (i: PageState) => post({}, `/api/v1/user/${i.id}/send_confirm`))


//End Functions to Handle Effects