import React from "react";
import {WidgetConfig} from "../../../../../models/dashboard/widgetConfig";
import {Log} from "../../../../../models/log";
import * as _ from "lodash";
import moment from "moment";
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {SaveIcon} from "../../../../../components/icons";
import {downloadCsvFile, downloadJsonFile} from "../../../../../service/download_json_file";
import {showExportFileDialog} from "../../../../../components/export_file_dialog";
import {bigNumberFormatter} from "../../../../../utils/object_formatting";

type Props = {
  widget: WidgetConfig,
  logs: Log[]
}



export function TimeSeriesChartWidget(props: Props, intlMessages: Record<string, any>) {

  const name = intlMessages[props.widget.name] ?? props.widget.name;

  const values = extractValue(props.logs, props.widget);

  return (<div className={"d-flex flex-column justify-content-between title-value-widget"}>
    <div className={"row"}>
      <div className={"col-md-12 mb-2"}>
        <h3 style={{color: "#fff"}} className={"m-2"}> {name.toUpperCase().replace("_", " ") ?? ""} </h3>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <ResponsiveContainer width={"100%"} height={220} className={"dashboard-chart"}>
          <LineChart data={values}>
            <Line type="monotone" dataKey="value" stroke="#eee" />
            <XAxis dataKey="time" stroke={"#eee"} />
            <YAxis stroke={"#eee"} tickFormatter={bigNumberFormatter} />
            <Tooltip
              labelStyle={{color: "black"}}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12 d-flex flex-row-reverse"} style={{ marginBottom: 6}}>
        <button className={"icon-button d-flex flex-row mr-2"} onClick={() => {
          showExportFileDialog(intlMessages, {stat_name: props.widget.name}, "/api/v1/stats/export");
          // downloadCsvFile(values, `${props.widget.name}.csv`);
        }}>
          <p style={{color: "white", marginTop: 6, marginRight: 6}}>{intlMessages["export-data"].toString().toUpperCase()}</p>
          <SaveIcon white/>
        </button>
      </div>
    </div>
  </div>);
}

export function extractValue(logs: Log[], widgetConfig: WidgetConfig): {time: string, value: number}[] {

  const source = widgetConfig.source;

  if(source.type !== "WEBSOCKET") return []

  const l = (_.first(logs.filter((l) => l.channel === source.channelName && l.eventName === source.eventName)));
  let values = (l?.log?.values ?? []);
  // console.log(values);
  if(typeof _.first(values) === "string") values = [];
  return values.map(([v, date]: number[]) => ({time: date, value: v})).sort((v1: any, v2: any) => {
    if(v1.time === v2.time) return 0;
    return v1.time > v2.time ? 1 : -1
  });
}

