import { useIntl } from "react-intl";
import { SelfReadingComponent, showSelfReadingComponent } from "../../../components/barbagli/self_reading_component";
import { Condominium, CondominiumNode } from "../../../models/barbagli/condominium";
import { Meter } from "../../../models/meter";
import { parentSubCondominiumId } from "../condominium/condominium_view_model";
import { MetersIndex } from "../meters_index/meters_index";
import { ConsumptionsIndex } from "./consumptions_index";
import React, { useEffect, useMemo } from "react"
import { and } from "../../data_explorer_page/ast/ast";

type Props = {
    condominium: Condominium,
    selectedNode: CondominiumNode 
}

export function ConsumptionsPage(props: Props) {
    const intl = useIntl();


    switch (props.selectedNode.type) {
        case "METER": return <ConsumptionsIndex
            key={JSON.stringify(props.selectedNode)}
            tabHidden
            dexFilters={{type: "CONDITION", name:"meter_identifier", value: props.selectedNode.attributes.identifier}} 
        />
        case "CONDOMINIUM": return <ConsumptionsIndex
            key={JSON.stringify(props.selectedNode)}
            dexFilters={{type: "CONDITION", name:"condominium_id", value: props.condominium.id!.toString()}}
        />
        case "SUBCONDOMINIUM": return <ConsumptionsIndex
            key={JSON.stringify(props.selectedNode)} 
            dexFilters={and(
                {type: "CONDITION", name:"condominium_id", value: props.condominium.id!.toString()},
                {type: "CONDITION", name:"sub_condominium_id", value: props.selectedNode.node_id},
                )}
        />
        case "IMMOBILE": return <ConsumptionsIndex
                key={JSON.stringify(props.selectedNode)}
        dexFilters={and(
            {type: "CONDITION", name:"condominium_id", value: props.condominium.id!.toString()},
            {type: "CONDITION", name:"immobile_id", value: props.selectedNode.node_id},
            )}
        />
        case "GATEWAY": return <ConsumptionsIndex
            key={JSON.stringify(props.selectedNode)}
            dexFilters={{type: "CONDITION", name:"gateway_identifier", value: props.selectedNode.identifier}} 
        />
        default: return <p></p>

    }

}