import {getNoBaseUrl} from "../repsitory/generic_repository";
import {GeoJSONPoint} from "../models/geo_json";

type Coordinates = {
  lat: number,
  lng: number
}

export const coordinatesFromAddress: (params: {country: string, city: string, address: string, postalcode: string}) => Promise<string | Coordinates[]> = async (params) => {
  //const param = `${params.country ? `&country=${params.country}` : ""}${params.city ? `&city=${params.city}` : ""}${params.address ? `&address=${params.address}` : ""}${params.postalcode ? `&postalcode=${params.postalcode}` : ""}`
  const address = params.address.replace(/[0-9]/g, '');
  const url = "https://nominatim.openstreetmap.org/search?format=json&q=" + `${address}, ${params.city}, ${params.postalcode}, ${params.country}`;
  //Via degli  Zabarella, Padova, 35129, Italy
  const resp = await getNoBaseUrl(url);
  if(typeof resp == "string") return resp;
  return resp.map((r: any) => ({lat: r.lat, lng: r.lon}))
}

export const gpsFromCoordinates: (c: Coordinates) => Promise<string> = async (coordinates: Coordinates) => {
  console.log("asking coords");
  const resp = await getNoBaseUrl(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinates.lat}&lon=${coordinates.lng}&addressdetails=1`);
  console.log(resp);
  return resp.display_name || "Error"
};

export const gpsFromGeoJsonPoint: (p: GeoJSONPoint) => Promise<string> = async (p) => {
  console.log("asking coords");
  const resp = await getNoBaseUrl(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${p.geometry.coordinates[1]}&lon=${p.geometry.coordinates[0]}&addressdetails=1`);
  console.log(resp);
  return resp.display_name || "Error"
};

export const gpsFromGeoJsonPointRaw: (p: GeoJSONPoint) => Promise<string | {
  city?: string,
  city_district?: string,
  country?: string,
  country_code?: string,
  county?: string,
  house_number?: string,
  postcode?: string,
  quarter?: string,
  road?: string,
  town?: string,
  state?: string,
  village?: string,
  state_district?: string,
}> = async (p) => {
  console.log("asking coords");
  const resp = await getNoBaseUrl(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${p.geometry.coordinates[1]}&lon=${p.geometry.coordinates[0]}&addressdetails=1`);
  console.log(resp);
  return resp.address || "Error"
};

