import { stat } from "fs"
import { BlackList } from "../../../models/barbagli/blacklist"
import { ErrorMapping } from "../../../models/barbagli/error_mapping"
import { Attributes } from "../../../models/meter"
import { User } from "../../../models/user"
import { UserGroup } from "../../../models/user_group"
import { createBlackList, updateBlacklist } from "../../../repsitory/barbagli/blacklist_repository"
import { deepCopy } from "../../../utils/deep_copy"

export type MappingAndBlacklist = BlackList & { display_name: string }

export type MeterBlacklistWidgetState = {
    downloadedMeterBlackLists: BlackList[],
    allMeterBlackLists: MappingAndBlacklist[], // This is built combining error mappings with balcklists
    editedMeterBlackLists: MappingAndBlacklist[],
    groups: UserGroup[],
    meterAttributes: Attributes,
    groupNameId: Record<string, UserGroup>,
    groupIdName: Record<string, UserGroup>,
    userNameId: Record<string, User>,
    userIdName: Record<string, User>,
    users: User[],
    mappings: ErrorMapping[],
    loading: boolean,
    error: string | null
}

export type Actions = { type: "blackListFetchCompletedSuccess", bl: BlackList[] }
    | { type: "blackListFetchCompletedError", error: string }
    | { type: "setGroups", groups: UserGroup[] }
    | { type: "setUsers", users: User[] }
    | { type: "setMapping", mappings: ErrorMapping[] }
    | { type: "updateBlacklist", bl: MappingAndBlacklist, index: number }

export const reducer: (state: MeterBlacklistWidgetState, action: Actions) => MeterBlacklistWidgetState = (state: MeterBlacklistWidgetState, action: Actions) => {

    switch (action.type) {
        case "blackListFetchCompletedSuccess": return { ...state, loading: false, error: null, downloadedMeterBlackLists: action.bl }
        case "blackListFetchCompletedError": return { ...state, loading: false, error: action.error, downloadedMeterBlackLists: [] }

        case "setGroups":
            const groupNameId = action.groups.reduce((acc: Record<string, UserGroup>, v: UserGroup) => {
                acc[v.name] = v;
                return acc;
            }, {});
            const groupIdName = action.groups.reduce((acc: Record<string, UserGroup>, v: UserGroup) => {
                acc[v.id] = v;
                return acc;
            }, {});

            return { ...state, groups: action.groups, groupIdName, groupNameId }
        case "setUsers":

            const userNameId = action.users.reduce((acc: Record<string, User>, v: User) => {
                acc[v.username] = v;
                return acc;
            }, {});
            const userIdName = action.users.reduce((acc: Record<string, User>, v: User) => {
                acc[v.id] = v;
                return acc;
            }, {});

            return { ...state, users: action.users, userNameId, userIdName }

        case "setMapping":
            const mappingAndBlacklists = buildMappingAndBlacklists(action.mappings, state.downloadedMeterBlackLists, state.meterAttributes)
            return {
                ...state,
                mappings: action.mappings,
                allMeterBlackLists: mappingAndBlacklists,
                editedMeterBlackLists: deepCopy(mappingAndBlacklists)
            }


        case "updateBlacklist": 
        state.editedMeterBlackLists[action.index] = action.bl;
        return state;
    }
}

export function buildMappingAndBlacklists(mappings: ErrorMapping[], blacklists: BlackList[], meterAttributes: Attributes): MappingAndBlacklist[] {

    //  Hash of blacklists by name in order to speed up a little bit lookup
    const blacklistByName = blacklists.reduce((acc: Record<string, BlackList[]>, bl: BlackList) => {
        acc[bl.alarmName] = [...(acc[bl.alarmName] ?? []), bl];
        return acc;
    }, {})


    return mappings.flatMap((mapping: ErrorMapping) => {
        const existingBlacklists = blacklistByName[mapping.name];
        if (existingBlacklists) return existingBlacklists.map((bl) => ({ ...bl, display_name: mapping.displayName, meterIdentifier: meterAttributes.identifier }))
        else return [{
            display_name: mapping.displayName,
            alarmName: mapping.name,
            meterIdentifier: meterAttributes.identifier,
            groups: [],
            users: []
        }]
    })

}

export async function onSave(state: MeterBlacklistWidgetState): Promise<string | true> {
    
    const items_to_update: BlackList[] = [];
    const items_to_create: BlackList[] = [];

    state.editedMeterBlackLists.forEach((mbl, i) => {
        if(!mbl.id) { 
            items_to_create.push(mbl);
        } else {
            if(JSON.stringify(state.allMeterBlackLists[i]) !== JSON.stringify(mbl)) {
                items_to_update.push(mbl);
            }
        }
    })

    // creating stuff

    const creation_results = await Promise.all(items_to_create.map(createBlackList))
    // updating stuff
    // @ts-ignore
    const update_results = await Promise.all(items_to_update.map(updateBlacklist))

    const result : string | true =  [...creation_results, ...update_results].reduce((acc: string | boolean, v: true | string) => {
        if(typeof v === "string") {
            if(typeof acc === "string") return `${acc}, ${v}`;
            else return v;
        }
        return true;
    }, true)
    
    return result
}