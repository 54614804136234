import { ErrorMapping } from "models/barbagli/error_mapping"
import { Attributes } from "models/meter";
import { User } from "models/user";
import React, { useEffect, useReducer } from "react";
import { remoteFetchData } from "repsitory/generic_repository";

export type NotificationState = {
    errors: Record<string, { name: string, recipients: string[] }>,
    consumptions: ConsumptionRule[],
    users: User[],
    loading: boolean,
    error: string,
    mapping: Record<string, ErrorMapping & { recipients: string[] }>
}

export type ConsumptionRule = {
    expression: ">" | "<=" | ">=" | "<",
    value: number,
    recipients: string[],
    index: number,
    text: string
}

export function convertiTBack(state: NotificationState): {
    notifications: { errors: Record<string, { name: string, recipients: string[] }>, consumptions: ConsumptionRule[] }
} {
    const errors = Object.keys(state.mapping).reduce((acc: Record<string, { name: string, recipients: string[] }>, key) => {
        const item = state.mapping[key];
        acc[key] = { name: item.name, recipients: item.recipients }
        return acc
    }, {})
    return {
        notifications: {
            errors, consumptions: state.consumptions
        }
    }
}

type NotificationAction =
    | { type: "addConsuptionRule", value: ConsumptionRule }
    | { type: "editConsumptionRule", value: ConsumptionRule }
    | { type: "removeConsumptionRule", value: ConsumptionRule }
    | { type: "setLoading", value: boolean }
    | { type: "setMappings", value: Record<string, ErrorMapping & { recipients: string[] }> }
    | { type: "setErrors", value: Record<string, { name: string, recipients: string[] }> }
    | { type: "setError", value: string }


function reducer(s: NotificationState, action: NotificationAction): NotificationState {
    switch (action.type) {
        case "addConsuptionRule": return { ...s, consumptions: [...s.consumptions, action.value] };
        case "editConsumptionRule": return { ...s, consumptions: s.consumptions.map((c) => c.index == action.value.index ? action.value : c) };
        case "removeConsumptionRule": return { ...s, consumptions: s.consumptions.filter((c) => c.index !== action.value.index) };
        case "setLoading": return { ...s, loading: action.value };
        case "setMappings": return { ...s, mapping: action.value };
        case "setErrors": return { ...s, errors: action.value };
        case "setError": return { ...s, error: action.value };
    }
}

export function useMetersNotificationState(attributes: Attributes): [NotificationState, React.Dispatch<NotificationAction>] {
    const [state, dispatch] = useReducer(reducer, {
        errors: attributes?.meta?.notifications?.error ?? {},
        loading: false,
        consumptions: attributes?.meta?.notifications?.consumptions ?? [],
        mapping: {},
        error: "",
        users: [],
    })

    useEffect(() => {
        (async () => {
            // Mappings
            const mappingsUrl = `/api/v1/mapping?class=${attributes?.class}`
            dispatch({ type: "setLoading", value: true })
            const mappingResponse: string | ErrorMapping[] = await remoteFetchData(mappingsUrl);

            if (typeof (mappingResponse) === "string" || mappingResponse.length === 0) {
                console.error("cannot_find_mapping_for_this_class")
                dispatch({ type: "setLoading", value: false })
                dispatch({ type: "setError", value: "cannot_find_mapping" })
                return
            }


            dispatch({ type: "setLoading", value: false })

            // @ts-ignore
            const mapping: Record<string, ErrorMapping & { recipients: string[] }> = mappingResponse.map((m) => m.attributes).reduce((acc: any, x) => {
                const id = x?.id?.toString();
                const se = attributes?.meta?.notifications?.errors ?? {};
                console.log(se)
                acc[id] = { ...x, recipients: se[id]?.recipients ?? [] };
                return acc
            }, {})

            dispatch({ type: "setMappings", value: mapping });

            // updating error names or building it from scratch

        })()

    }, [attributes.class])


    return [state, dispatch]
}

export const body = {
    "data": {
        "type": "meter",
        "update_order": false,
        "attributes": {
            "notifications": {
                "errors": {
                    "15": {
                        "name": "overflow_was",
                        "recipients": [
                            "administrator",
                            "tenant",
                            "tommaso"
                        ]
                    },
                    "20": {
                        "name": "no_consumption_is",
                        "recipients": [
                            "administrator",
                            "tenant",
                            "tommaso"
                        ]
                    }
                },
                "consumptions": [
                    {
                        "expression": ">",
                        "value": 19,
                        "recipients": [
                            "tommaso"
                        ]
                    },
                    {
                        "expression": ">",
                        "value": 50,
                        "recipients": [
                            "administrator",
                            "tenant",
                            "tommaso"
                        ]
                    }
                ]
            }
        }
    }
}