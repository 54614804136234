import {useIntl} from 'react-intl';
import {
  SelfReadingComponent,
  showSelfReadingComponent,
} from '../../../../components/barbagli/self_reading_component';
import {
  Condominium,
  CondominiumNode,
} from '../../../../models/barbagli/condominium';
import {Meter} from '../../../../models/meter';
import {parentSubCondominiumId} from '../../condominium/condominium_view_model';
import {MetersIndex} from '../../meters_index/meters_index';
import React, {useEffect, useState} from 'react';
import {OrderParam} from '../../../../models/barbagli/filter_order';
import {useAlert} from 'react-alert';
import {SortableTableView} from '../../../../components/barbagli/sortable_table_view';
import {fetchPaginatedCollection} from '../../../../repsitory/generic_repository';
import {Note} from '../../../../models/barbagli/note';

type Props = {
  condominium: Condominium;
  selectedNode: CondominiumNode;
};

const findIds = (condominium: Condominium, selectedNode: CondominiumNode) => {
  const immobileId = selectedNode.id;
  const condominiumId = condominium.id;
  const subCondominium = condominium.children.find(
    (subCondominium: CondominiumNode) => {
      return subCondominium.children.find(
        (immobile: CondominiumNode) => immobile.id === immobileId,
      );
    },
  );
  debugger
  if (subCondominium) {
    return {
      immobileId: immobileId,
      subCondominiumId: subCondominium!.id,
      condominiumId: condominiumId,
    };
  }
  return {
    immobileId: immobileId,
    subCondominiumId: '0',
    condominiumId: condominiumId,
  };
};

export function MetersNoteView(props: Props) {
  const intl = useIntl();
  const alert = useAlert();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState<OrderParam>({
    column: 'inserted_at',
    order: 'desc',
  });

  return (
    <div className="d-flex flex-column">
      <SortableTableView
        reload={reload}
        perPage={15}
        key={1}
        fetchCollection={async (page, perPage) => {
        debugger
        const {immobileId, subCondominiumId, condominiumId} = findIds(props.condominium, props.selectedNode);
          const data = await fetchPaginatedCollection<any>(
            `/api/v1/condominium/${condominiumId}/sub_condominium/${subCondominiumId}/immobile/${immobileId}/note?sort=${order.column}&order=${order.order}`,
          )(page, perPage);
          return data;
        }}
        itemsPreFormatter={(c: Note) => {
          const data = {...c};
          delete data['meterId'];
          delete data['immobileId'];
          delete data['creatorId'];
          delete data['condominiumId'];
          delete data['subCondominiumId'];
          delete data['id'];
          return {...data, insertedAt: c.insertedAt};
        }}
        order={order}
        translated
        onOrder={setOrder}
        orderableColumns={['inserted_at', 'creator_nickname']}
      />
    </div>
  );
}
