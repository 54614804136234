import { Column } from "components/barbagli/common";
import { H3, H4, P, Title } from "components/barbagli/text";
import { TextInput } from "components/barbagli/text_input";
import { ErrorComponent } from "components/error_component";
import { LoaderComponent } from "components/loading_widget";
import { formatBody } from "components/paginated_list/dynamic_paginated_list_component";
import { PaginatedListComponent } from "components/paginated_list/paginated_list_component";
import { Gateway } from "models/gateway";
import React, { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { formatObject } from "utils/object_formatting";
import { fetchCommandState } from "./gateway_view_model";

export function DigitalTwinState(props: {gateway: Gateway}) {
    const [state, setState] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const intl = useIntl();

    useEffect(() => {
        if(props.gateway.deviceTypeId) fetchCommandState(props.gateway.deviceTypeId, props.gateway.identifier).then((r:any) => {
            setLoading(false);
            if(typeof r === "string") return setError(r);
            if(r?.error) return setError("Impossibile scaricare i dati");
            return setState(r); 
        })
    }, [props.gateway])


    if(error) return <ErrorComponent message={error}/>
    if(loading) return <LoaderComponent />
 
    return <Column>
    <H4>BackHaul</H4>
    {formatBody(state?.back_haul, intl)}

    <H4 style={{marginTop: 8}}>Configurazione</H4>
    {formatBody(state?.config, intl)}

    <H4 style={{marginTop: 8}}>Data di ricezione</H4>
    {formatBody(state?.rx_time, intl)}

    <H4 style={{marginTop: 8}}>Comandi in coda?</H4>
    {formatObject((state && state["has_outbound_messages?"])) }

    {state?.filter?.length == 0 && <H4>Nessun filtro impostato</H4>}
    {(state?.filter?.length ?? 0) > 0 && <PaginatedListComponent
        headers={
            //Object.keys(state.filter[0])
            ["number", "m_field", "a_field", "version", "device", "time_frame", "is_negative"]
        }
        showPaginationElement={false}
        fetchCollection={async (a: any, b: any) => ( { 
            sublist: state.filter.map((item: any) => ({...item, m_field: parseMfield(item.m_field)})),
            count: state.filter?.length,
            perPage: 1000000,
            page: 1})
        }
        rowBuilder={(item: any) => {
            //return Object.values(item).map(formatBody)
            return [
                formatBody(item.number, intl),
                formatBody(item.m_field, intl),
                formatBody(item.a_field, intl),
                formatBody(item.version, intl),
                formatBody(item.device, intl),
                formatBody(item.time_frame, intl),
                formatBody(item.is_negative, intl)
            ]
        }} 
    />}

    </Column>
    

}

function parseMfield(mfield: string) {
    var a = parseInt("3424",16);
    var first_char = ((a / (Math.pow(2, 10))) & 31) + 64 ;
    var second_char = ((a / (Math.pow(2, 5))) & 31) + 64 ;
    var third_char = ((a / (Math.pow(2, 0))) & 31) + 64 ;
    return String.fromCharCode(first_char) + String.fromCharCode(second_char) + String.fromCharCode(third_char);
}