import React from "react";

import * as H from 'history'
import {httpDelete, post, put, remoteFetchData} from "../../repsitory/generic_repository";
import {emptyGateway, Gateway} from "../../models/gateway";
import {HardwareType} from "../../models/hardware_type";
import {HardwareStatus} from "../../models/hardware_status";
import {ApplicationType} from "../../models/application_type";
import {ApplicationStatus} from "../../models/application_status";
import {AltiorGatewayType} from "../../models/altior_gateway_type";
import {BackHaul} from "../../models/back_haul";
import _ from "lodash";
import {emptyGatewayConfig, GatewayConfig} from "../../models/gateway_config";
import {GeoJSONPoint} from "../../models/geo_json";
import {calculateRadioCoverageRadius} from "../../contexts/gateway_context";
import {Log, LogLevel} from "../../models/log";
import {gray, orange, red} from "../../utils/colors";
import { SIMULATED_ALTIOR } from "../..";
import { v1 } from "uuid";
import { subscribeToAction } from "../../store/actions/logs_actions";
import store from "../../store/store";

//  Page state
export type EditGatewayPageState = {
  serial: string,
  id?: number
  gps: number[],
  location: string
  status: string,
  deviceTypeId: string,
  identifier: string, //  da create e edit
  installDate: Date,
  storage: string,
  battery: number,
  description: string,
  backhaulId?: number,
  backhaulConfig: any,
  config: GatewayConfig,
  backhaulQos: string,
  loading: boolean,
  error: string,
  validationErrors: string[],
  commandsAvailable: string[],
  hardwareTypes: HardwareType[],
  hardwareStatus: HardwareStatus[],
  applicationTypes: ApplicationType[],
  applicationStatus: ApplicationStatus[],
  altiorGatewayTypes: AltiorGatewayType[],
  updateGroups: string[],
  deleteGroups: string[],
  listGroups: string[],
  backHauls: BackHaul[],
  coordinates: GeoJSONPoint | null,
  uuid?: string,
  height: number,
  radioCoverage: {
    radius_from_height: { height: number, radius: number }[]
  },
  radioCoverageVisible: boolean
}

export type Property = {name: string, value: string,}


const validate = (state: EditGatewayPageState, intl: Record<string, string>) => {
  let errors: string[] = [];

  if(!state.identifier || state.identifier === "") errors = [...errors, intl['identifier_must_be_present']]

  return errors;
};

//  Page effects
export const createEditGateway = async(state: EditGatewayPageState, setState: React.Dispatch<React.SetStateAction<EditGatewayPageState>>, history: H.History, intl: Record<string, string>) =>  {

  const validation = validate(state, intl);

  if(validation.length > 0) {
    setState({...state, validationErrors: validation});
    return;
  } else {
    setState({...state, loading: true})
  }

  let uuidMap = {};

  if (!state.id) {
    const uuid = v1() + (new Date()).getTime().toString();
    setState({ ...state, uuid })
    uuidMap = { uuid }
    store.dispatch(subscribeToAction("altior_item_creation_channel", uuid));
  }


  const data = {
    "data":{
      ...uuidMap,
      "type":"gateway",
      "attributes": {
        "serial": state.serial,
        "id": state.id,
        "address": state.location,
        "status": state.status,
        "device_type_id": state.deviceTypeId,
        "installDate": state.installDate,
        "description": state.description,
        "backhaulId": state.backhaulId,
        "backhaulConfig": state.backhaulConfig,
        "identifier": state.identifier,
        "config": state.config,
        "loading": state.loading,
        "error": state.error,
        "validationErrors": state.validationErrors,
        "commandsAvailable": state.commandsAvailable,
        "latitude": state.gps[0],
        "longitude": state.gps[1],
        "coordinates": state.coordinates,
        "height": state.height,
        "updateGroups": state.updateGroups,
        "deleteGroups": state.deleteGroups,
        "listGroups": state.listGroups
      }
    }
  };


  try {
    let result;

    if(!state.id) {
      result = await post(data,  '/api/v1/gateway');
    } else {
      result = await put(data,  `/api/v1/gateway/${state.id}`);
    }

    // console.log(result);

    if(result.errors !== undefined) {
      setState({...state, error: JSON.stringify(result), loading: false})
    } else if(state.id) {
      // setState({...state, loading: false});
      history.push('/gateways');
    }
  } catch (e) {
    setState({...state, error: e.toString(), loading: false})
  }

  saveDefaultGatwayCoordinates({
    lat: state.coordinates?.geometry.coordinates[1]!,
    lng: state.coordinates?.geometry.coordinates[0]!
  });

};

export const deleteGateway = async (id: string) => {
  try {
    const result = await httpDelete('/api/v1/gateway/' + id.toString());
    if(result.status === 204) return "";
    return result.status.toString();

  } catch (e) {
    console.log(e);
    return e.toString();
  }
}


export const mkGatewayState: (g: Gateway) => EditGatewayPageState = (gateway: Gateway) => {
  if(gateway === null) return mkGatewayState(emptyGateway())
  return ({
    serial: gateway?.serial ?? "",
    id: gateway.id,
    gps: [gateway.latitude, gateway.longitude],
    location: gateway.address,
    status: gateway.status,
    deviceTypeId: gateway?.deviceTypeId ?? "",
    installDate: new Date(),
    identifier: gateway.identifier ?? "",
    storage: gateway?.storage?.toString() ?? "",
    battery: gateway.battery,
    description: gateway.description,
    backhaulId: gateway.backhaulId,
    backhaulQos: gateway.backhaulQos,
    backhaulConfig: gateway.backhaulConfig ?? {},
    loading: false,
    error: "",
    coordinates: gateway.coordinates ?? null,
    config: gateway.config ?? { "hardware": [], "application": [] },
    validationErrors: [],
    commandsAvailable: [],
    hardwareTypes: [],
    hardwareStatus: [],
    applicationTypes: [],
    applicationStatus: [],
    altiorGatewayTypes: [],
    backHauls: [],
    updateGroups: gateway.updateGroups ?? [],
    deleteGroups: gateway.deleteGroups ?? [],
    listGroups: gateway.listGroups ?? [],
    gatewayConfig: emptyGatewayConfig(),
    height: gateway.height ?? 0,
    radioCoverage: gateway.radioCoverage ?? { radius_from_height: [] },
    radioCoverageVisible: true
  });
};


export function backhaulWithId(id: number | undefined, backhauls: BackHaul[]): BackHaul | undefined {
  return _.first(backhauls.filter((b) => b.id === id?.toString()));
}

export async function fetchHardwareTypes(): Promise<HardwareType[] | string> {
  return remoteFetchData<HardwareType[]>("/api/v1/hardware/type");
}

export async function fetchHardwareStatus(): Promise<HardwareStatus[] | string> {
  return remoteFetchData<HardwareStatus[]>("/api/v1/hardware/status");
}

export async function fetchApplicationTypes(): Promise<ApplicationType[] | string> {
  return remoteFetchData<ApplicationType[]>("/api/v1/application/type");
}

export async function fetchApplicationStatus(): Promise<ApplicationStatus[] | string> {
  return remoteFetchData<ApplicationStatus[]>("/api/v1/application/status");
}

export async function fetchAltiorGatewayTypes(): Promise<AltiorGatewayType[] | string> {


  if(SIMULATED_ALTIOR) {
    return [
      {
        type: "altiorGatewayType",
        attributes: {
          name: "Gateway",
          id: 1,
          deviceClass: "class",
          version: "3.0.0",
          category: "gateway",
          digitalTwinFields: {class: "gateway"}
        },
        id: "1"
      }
    ];
  }


  return remoteFetchData<AltiorGatewayType[]>("/api/v1/altior_gateway_type");
}

export async function fetchBackHauls(): Promise<BackHaul[] | string> {
  return remoteFetchData<BackHaul[]>("/api/v1/back_haul");
}

export function backHaulToGroupOption(b: BackHaul | undefined) {

  if(typeof b === "undefined") return {label: "", value: -1}

  return {
    label: b.attributes.name,
    value: Number(b.id)
  }
}

export function calculateRadius(state: {height?: number, radioCoverage: {radius_from_height: {height: number, radius: number}[]}}) : number | "not-available" {
  if(!state.height) return "not-available";
  return calculateRadioCoverageRadius(state.height, state.radioCoverage.radius_from_height);
}

export function saveDefaultGatwayCoordinates(coords: {lat: number, lng: number}) {
  try {
    window.localStorage.setItem("defualt-gateway-coords", JSON.stringify(coords))
  } catch (e) {
    console.log(e);
  }
}

export function getDefaultGatwayCoordinates(): {lat: number, lng: number} | null {
  try {
    return JSON.parse(window.localStorage.getItem("defualt-gateway-coords")!)
  } catch (e) {
    console.log(e);
    return null;
  }
}