import React from "react";
import { ReactElement, ReactNode } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Provider } from "react-redux";
import styled from "styled-components";
import store from "../../store/store";
import { I18n } from "../I18n";

export const StandardModalFrame = styled.div`
    width: 500px;
    max-height: 70vh;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    padding: 1rem;
`
export function showModal(children: (onClose: any, intl: Record<string, string>) => ReactNode, intl: Record<string, string>) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) => <Provider store={store}><I18n><StandardModalFrame>
      {children(onClose, intl)}
    </StandardModalFrame></I18n></Provider>
  });
}