import React, {useReducer, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAlert} from 'react-alert';
import {LoaderButton, OutlineButton} from 'components/barbagli/common';
import {post} from 'repsitory/generic_repository';
import {TextInput} from 'components/barbagli/text_input';
import {Select} from 'components/barbagli/select';
import {SmtpConfig} from 'models/barbagli/partner';
import {ModalEmail} from 'components/barbagli/modal_email';

type Props = {
  smtpConfig: SmtpConfig;
  onChange: (config: SmtpConfig) => void;
};

export function PartnerSmtpConfigWidget(props: Props) {
  const intl = useIntl();
  const [state, dispach] = useReducer(reducer, {...props.smtpConfig});
  const [modalIsOpen, setIsOpen] = useState(false);

  let selectedOption = {};
  if (state.tls != null) {
    selectedOption = {
      name: state.tls.replace(/^./, state.tls[0].toUpperCase()),
      value: state.tls,
    };
  }
  const alert = useAlert();
  return (
    <>
      <div style={{flexDirection: 'row'}}>
        <h3>
          {(
            intl.messages['smtpConfig']?.toString() ?? 'SMTP config'
          ).toUpperCase()}
          :
        </h3>
      </div>
      <div
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          display: 'flex',
          width: '100%',
        }}>
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(intl.messages['email']?.toString() ?? 'email').toUpperCase()}
          value={state.email?.toString()}
          onChange={v => {
            dispach({type: 'set_email', value: v});
            props.onChange({...state, email: v});
          }}
        />
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(
            intl.messages['reply_email']?.toString() ?? 'reply_email'
          ).toUpperCase()}
          value={state.reply_email?.toString()}
          onChange={v => {
            dispach({type: 'set_reply_email', value: v});
            props.onChange({...state, reply_email: v});
          }}
        />
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(intl.messages['host']?.toString() ?? 'host').toUpperCase()}
          value={state.server?.toString()}
          onChange={v => {
            dispach({type: 'set_server', value: v});
            props.onChange({...state, server: v});
          }}
        />
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(intl.messages['port']?.toString() ?? 'port').toUpperCase()}
          value={state.port?.toString()}
          onChange={v => {
            dispach({type: 'set_port', value: Number(v)});
            props.onChange({...state, port: Number(v)});
          }}
        />
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(
            intl.messages['username']?.toString() ?? 'username'
          ).toUpperCase()}
          value={state.username?.toString()}
          onChange={v => {
            dispach({type: 'set_username', value: v});
            props.onChange({...state, username: v});
          }}
        />
        <TextInput
          enabled
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(
            intl.messages['password']?.toString() ?? 'password'
          ).toUpperCase()}
          value={state.password?.toString()}
          type="password"
          onChange={v => {
            dispach({type: 'set_password', value: v});
            props.onChange({...state, password: v});
          }}
        />
        <Select
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(intl.messages['tls']?.toString() ?? 'tls').toUpperCase()}
          enabled
          isMulti={false}
          selectedOptions={[
            {
              name: state.tls
                ? state.tls.replace(/^./, state.tls[0].toUpperCase())
                : null,
              value: state.tls,
            },
          ]}
          availableOptions={[
            {value: 'if_available', name: 'If available'},
            {value: 'always', name: 'Always'},
            {value: 'never', name: 'Never'},
          ]}
          valueMapper={ug => ug.name ?? 'never'}
          onChange={v => {
            dispach({type: 'set_tls', value: v[0].value});
            props.onChange({...state, tls: v[0].value});
          }}></Select>
        <Select
          style={{flex: '0 0 33%', paddingRight: 8}}
          label={(intl.messages['ssl']?.toString() ?? 'ssl').toUpperCase()}
          enabled
          isMulti={false}
          selectedOptions={[
            {
              name: state.ssl === true ? 'True' : 'False',
              value: state.ssl,
            },
          ]}
          availableOptions={[
            {value: true, name: 'True'},
            {value: false, name: 'False'},
          ]}
          valueMapper={ug => ug.name}
          onChange={v => {
            dispach({type: 'set_ssl', value: v[0].value});
            props.onChange({...state, ssl: v[0].value});
          }}></Select>

        <OutlineButton
          style={{marginTop: 16, marginRight: 8}}
          onClick={_ => {
            setIsOpen(true);
          }}>
          {intl.messages['send_test_email'] ?? 'Send modal email'}
        </OutlineButton>
        <ModalEmail
          onSend={mail => {
            const result = sendTestEmail(state, mail, alert);
            return result;
          }}
          modalIsOpen={modalIsOpen}
          closeModal={() => setIsOpen(false)}
          title={(
            intl.messages['send_test_email']?.toString() ?? 'Send test email?'
          ).toUpperCase()}
        />
      </div>
    </>
  );
}

type PageState = SmtpConfig;

type PageAction =
  | {type: 'set_email'; value: string}
  | {type: 'set_port'; value: number}
  | {type: 'set_server'; value: string}
  | {type: 'set_ssl'; value: boolean}
  | {type: 'set_retries'; value: number}
  | {type: 'set_password'; value: string}
  | {type: 'set_username'; value: string}
  | {type: 'set_reply_email'; value: string}
  | {type: 'set_tls'; value: string};

function reducer(state: PageState, action: PageAction): PageState {
  switch (action.type) {
    case 'set_email':
      return {...state, email: action.value};
    case 'set_tls':
      return {...state, tls: action.value};
    case 'set_server':
      return {...state, server: action.value};
    case 'set_retries':
      return {...state, retries: action.value};
    case 'set_reply_email':
      return {...state, reply_email: action.value};
    case 'set_username':
      return {...state, username: action.value};
    case 'set_password':
      return {...state, password: action.value};
    case 'set_ssl':
      return {...state, ssl: action.value};
    case 'set_port':
      return {...state, port: action.value};
  }
}

async function sendTestEmail(
  state: PageState,
  recipient: string,
  alert: any,
): Promise<string | true> {
  const result = await post(
    {
      data: {
        type: 'test_email',
        attributes: {smtpConfig: state, recipient: recipient},
      },
    },
    '/api/v1/test_email',
  );
  if (typeof result === 'string') {
    alert.error(result);
  } else if (result.errors?.detail != null) {
    alert.error(result.errors.detail);
  } else {
    alert.success('Operazione effettuata con successo');
  }
  return result;
}
