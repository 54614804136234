import { Log } from "../models/log";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { subscribeToAction } from "../store/actions/logs_actions";
import React from "react";
import store, { AppState } from "../store/store";
import _ from "lodash";

export function WebSocketLoadingBar(props: { channelName: string, eventName: string, percentage?: (l: Log | undefined) => number, onLog?: (l: Log) => void }) {
  const percentage = props.percentage ?? ((l: Log | undefined) => Number(l?.log?.message ?? "0"));
  const logs = useSelector((a: AppState) => a.logs.items.filter((l) => l.channel === props.channelName && l.eventName === props.eventName));

  let currentValue = percentage(_.first(logs));
  if (currentValue === 0) currentValue = 1;


  useEffect(() => {
    if (logs.length > 0) (props.onLog ?? ((l: Log) => { }))(_.first(logs)!);
  }, [logs])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subscribeToAction(props.channelName, props.eventName));
  }, [props.channelName, props.eventName]);

  return <div className="progress" style={{ marginRight: "0.5rem" }}>
    <div
      className="progress-bar"
      role="progressbar"
      style={{ width: `${currentValue}%` }}
      aria-valuenow={currentValue}
      aria-valuemin={0}
      aria-valuemax={100}>
      {currentValue}
    </div>
  </div>



}