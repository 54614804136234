import { ErrorMapping } from "../../../models/barbagli/error_mapping";
import {  updateErrorMapping } from "../../../repsitory/barbagli/error_mapping_repository";
import { first } from "../../../utils/deep_copy";

export async function syncPage(newValues: Record<string, ErrorMapping[]>, oldMappings: ErrorMapping[]) : Promise<true | string> {
    const valuesToUpdate: ErrorMapping[] = [];

    const findMapping = (m: ErrorMapping, e: Record<string, ErrorMapping[]>) => {
        const itemsToSearchInto = e[m.model] ?? [];
        return first(itemsToSearchInto.filter((em) => em.id === m.id))
    }

    const results: ({id: any} | string)[] = await Promise.all(oldMappings.reduce((acc: ErrorMapping[], m: ErrorMapping) => {
        const newMapping = findMapping(m, newValues);
        if(newMapping && JSON.stringify(newMapping) !== JSON.stringify(m)) return [...acc, newMapping]
        return acc
    }, []).map(updateErrorMapping))

    const result : string | true =  results.reduce((acc: string | boolean, v: {id: any} | string) => {
        if(typeof v === "string") {
            if(typeof acc === "string") return `${acc}, ${v}`;
            else return v;
        }
        return true;
    }, true)

    return result;
}