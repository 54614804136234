import { debug } from "console";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";
import { OutlineButton } from "../../../../components/barbagli/common";
import { Title } from "../../../../components/barbagli/text";
import { Condominium } from "../../../../models/barbagli/condominium";
import { Attributes, Meter } from "../../../../models/meter";
import { MetersIndex } from "../../meters_index/meters_index";
import { CondominiumViewState } from "../condominium_view_model";
import { associateMeterToCondominiumNode } from "./add_mter_view_model";
import 'react-confirm-alert/src/react-confirm-alert.css';

type Props = {
  state: CondominiumViewState,
  intl: Record<string, string>,
  setState: React.Dispatch<React.SetStateAction<CondominiumViewState>>
  alert: any
}

export function AddMeterView(props: Props) {
  const intl = props.intl;
  return <>
    <Title>{intl["select_a_meter"]}</Title>
    <MetersIndex
      onClick={(meter: Meter) => {
        if (meter.attributes.condominiumId) {
          confirmAlert({
            title: intl["confirm"].toString(),
            message: intl["meter_already_associated"].toString(),
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                  associateMeterToCondominiumNode(props.state.selectedNode, props.state.condominium as Condominium, meter.attributes).then(
                    (resp) => {
                      if (typeof resp === "string") {
                        props.alert.show(intl["impossible_to_associate_meter"])
                      }
                      else {
                        props.alert.show(intl["meter_successfully_associated"]);
                        props.setState({ ...props.state, state: "show" })
                      }
                    }
                  );

                }
              },
              {
                label: 'No',
                onClick: () => { }
              }
            ]
          });

        } else {
          associateMeterToCondominiumNode(props.state.selectedNode, props.state.condominium as Condominium, meter.attributes).then(
            (resp) => {
              if (typeof resp === "string") {
                props.alert.show(intl["impossible_to_associate_meter"])
              }
              else {
                props.alert.show(intl["meter_successfully_associated"]);
                props.setState({ ...props.state, state: "show" })
              }
            }
          );

        }
      }}
    ></MetersIndex>
    <div className="row mt-3">
      <div className="col-md-12 d-flex justify-content-end">
        <OutlineButton onClick={() => props.setState({ ...props.state, state: "show" })}>{intl["back"]}</OutlineButton>
      </div>
    </div>
  </>
}