import { stat } from "fs"
import moment from "moment"
import { BlackList } from "../../../models/barbagli/blacklist"
import { ErrorMapping } from "../../../models/barbagli/error_mapping"
import { Attributes } from "../../../models/meter"
import { User } from "../../../models/user"
import { UserGroup } from "../../../models/user_group"
import { createBlackList, updateBlacklist } from "../../../repsitory/barbagli/blacklist_repository"
import { deepCopy } from "../../../utils/deep_copy"

export type MappingAndBlacklist = BlackList & { display_name: string }

export type CloseAlarmModalState = {
    alarmName: string,
    downloadedMeterBlackLists: BlackList[],
    allMeterBlackLists: MappingAndBlacklist[], // This is built combining error mappings with balcklists
    editedMeterBlackLists: MappingAndBlacklist[],
    groups: UserGroup[],
    meterAttributes: Attributes,
    groupNameId: Record<string, UserGroup>,
    groupIdName: Record<string, UserGroup>,
    userNameId: Record<string, User>,
    userIdName: Record<string, User>,
    from?: Date,
    to?: Date,
    datesEnabled: boolean
    users: User[],
    mappings: ErrorMapping[],
    loading: boolean,
    error: string | null
}

export function initialState(meterAttributes: Attributes, alarmName: string): CloseAlarmModalState {
    return ({
        alarmName,
        downloadedMeterBlackLists: [],
        allMeterBlackLists: [],
        editedMeterBlackLists: [],
        groups: [],
        meterAttributes,
        groupIdName: {},
        groupNameId: {},
        userIdName: {},
        userNameId: {},
        from: undefined,
        to: undefined,
        datesEnabled: false,
        users: [],
        mappings: [],
        loading: false,
        error: null
    })
}

export type Actions = { type: "blackListFetchCompletedSuccess", bl: BlackList[] }
    | { type: "blackListFetchCompletedError", error: string }
    | { type: "setGroups", groups: UserGroup[] }
    | { type: "setUsers", users: User[] }
    | { type: "setMapping", mappings: ErrorMapping[] }
    | { type: "updateBlacklist", bl: MappingAndBlacklist, index: number }
    | { type: "toggleDateEnabled" }
    | { type: "setFrom", from: Date }
    | { type: "setTo", to: Date }

export const reducer: (state: CloseAlarmModalState, action: Actions) => CloseAlarmModalState = (state: CloseAlarmModalState, action: Actions) => {

    switch (action.type) {
        case "blackListFetchCompletedSuccess": return { ...state, loading: false, error: null, downloadedMeterBlackLists: action.bl }
        case "blackListFetchCompletedError": return { ...state, loading: false, error: action.error, downloadedMeterBlackLists: [] }

        case "setGroups":
            const groupNameId = action.groups.reduce((acc: Record<string, UserGroup>, v: UserGroup) => {
                acc[v.name] = v;
                return acc;
            }, {});
            const groupIdName = action.groups.reduce((acc: Record<string, UserGroup>, v: UserGroup) => {
                acc[v.id] = v;
                return acc;
            }, {});

            return { ...state, groups: action.groups, groupIdName, groupNameId }
        case "setUsers":

            const userNameId = action.users.reduce((acc: Record<string, User>, v: User) => {
                acc[v.username] = v;
                return acc;
            }, {});
            const userIdName = action.users.reduce((acc: Record<string, User>, v: User) => {
                acc[v.id] = v;
                return acc;
            }, {});

            return { ...state, users: action.users, userNameId, userIdName }

        case "setMapping":
            const mappingAndBlacklists = buildMappingAndBlacklists(action.mappings, state.downloadedMeterBlackLists, state.meterAttributes, state.alarmName)
            const from = mappingAndBlacklists[0]?.from ?? undefined
            const to = mappingAndBlacklists[0]?.to ?? undefined
            return {
                ...state,
                mappings: action.mappings,
                allMeterBlackLists: mappingAndBlacklists,
                editedMeterBlackLists: deepCopy(mappingAndBlacklists),
                datesEnabled: to !== undefined,
                from: from ? new Date(from) : undefined,
                to: to ? new Date(to) : undefined
            }


        case "updateBlacklist":
            state.editedMeterBlackLists[action.index] = action.bl;
            return state;

        case "toggleDateEnabled":
            if (state.datesEnabled) {
                return {
                    ...state, datesEnabled: false, from: undefined, to: undefined
                }
            } else {
                return {
                    ...state, datesEnabled: true,
                    from: state.from ?? moment(new Date()).startOf("day").toDate(),
                    to: state.to ?? moment(new Date()).add(14, "days").startOf("day").toDate()
                }
            }


        case "setFrom": return { ...state, from: action.from, datesEnabled: true }
        case "setTo": return { ...state, to: action.to, datesEnabled: true }
    }
}

export function buildMappingAndBlacklists(mappings: ErrorMapping[], blacklists: BlackList[], meterAttributes: Attributes, alarmName: string): MappingAndBlacklist[] {

    //  Hash of blacklists by name in order to speed up a little bit lookup
    const blacklistByName = blacklists.reduce((acc: Record<string, BlackList[]>, bl: BlackList) => {
        acc[bl.alarmName] = [...(acc[bl.alarmName] ?? []), bl];
        return acc;
    }, {})


    return mappings.filter((mapping: ErrorMapping) => mapping.deviceTypeId === meterAttributes.deviceTypeId && mapping.name === alarmName).flatMap((mapping: ErrorMapping) => {
        const existingBlacklists = blacklistByName[mapping.name];
        if (existingBlacklists) return existingBlacklists.map((bl) => ({ ...bl, display_name: mapping.displayName, meterIdentifier: meterAttributes.identifier }))
        else return [{
            display_name: mapping.displayName,
            alarmName: mapping.name,
            meterIdentifier: meterAttributes.identifier,
            groups: [],
            users: []
        }]
    })

}

export async function onSave(state: CloseAlarmModalState): Promise<string | true> {

    const items_to_update: BlackList[] = [];
    const items_to_create: BlackList[] = [];

    state.editedMeterBlackLists.forEach((mbl, i) => {

        let meterBlacklist: BlackList = state.datesEnabled ? { ...mbl, to: state.to, from: state.from } : {...mbl, to: undefined, from: undefined}

        if (!mbl.id) {
            items_to_create.push(meterBlacklist);
        } else {
            if (JSON.stringify(state.allMeterBlackLists[i]) !== JSON.stringify(meterBlacklist)) {
                items_to_update.push(meterBlacklist);
            }
        }
    })

    // creating stuff

    const creation_results = await Promise.all(items_to_create.map(createBlackList))
    // updating stuff
    // @ts-ignore
    const update_results = await Promise.all(items_to_update.map(updateBlacklist))

    const result: string | true = [...creation_results, ...update_results].reduce((acc: string | boolean, v: true | string) => {
        if (typeof v === "string") {
            if (typeof acc === "string") return `${acc}, ${v}`;
            else return v;
        }
        return true;
    }, true)

    return result
}