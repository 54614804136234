import React, { useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { FlatButton, LoaderButton, OutlineButton, PrimaryButton } from "../../../components/barbagli/common";
import { ErrorComponent } from "../../../components/error_component";
import { Condominium, CondominiumNode, CondominiumNodeType, CondominiumTab } from "../../../models/barbagli/condominium";
import { history } from "../../../index";
import { validateCondominium } from "./condominium_view_model";
import { showAlert } from "../../../components/alerts/alert";
import { TypeAuthorized } from "components/authorized";

type Props = {
    selectedNode: CondominiumNode;
    availableWidgetTypes: CondominiumNodeType[];
    onWidgetSelected: (c: CondominiumNodeType) => void;
    onNodeDeleted: () => void;
    condominium: Condominium;
    onCondominiumDeleted: () => void;
    onSave: () => Promise<true | string>;
    selectedTab: CondominiumTab
}

const ButtonRowContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-right: 2rem;
`;

const ChildSelectionPanel = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 30px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    left: 30px;
    border-radius: 6px;
    right: 30px;
`;

export function CondominiumCreationButtons(props: Props) {
    const [expanded, setExpanded] = useState(false);
    const [error, setError] = useState("");
    const intl = useIntl();
    const isEnabled = useMemo(() => props.condominium.status === "enabled", [props.condominium])
    const isCondSubCondOrImmobile = useMemo(() => {
        const type = props.selectedNode.type;
        return type === "CONDOMINIUM" || type === "SUBCONDOMINIUM" || type === "IMMOBILE";
    },
        [props.selectedNode])

    if (!isCondSubCondOrImmobile || props.selectedTab != "data") return <div></div>



    return <div>

        <ButtonRowContainer style={{ marginBottom: 12 }}>
            {props.selectedNode.type == "CONDOMINIUM" && <OutlineButton style={{ flexGrow: 1 }} onClick={
                () => history.push("/condominiums")
            }>{intl.messages["back"]}</OutlineButton>}
            <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                <DeleteButton {...props} intl={intl.messages as any} />
            </TypeAuthorized>



        </ButtonRowContainer>
        <ButtonRowContainer>
            <TypeAuthorized user_types={["admin", "company-admin", "partner-admin", "installer", "mnd-user"]} >
                {!isEnabled && props.selectedTab == "data" && <OutlineButton style={{ flexGrow: 1 }} onClick={() => { if (props.availableWidgetTypes.length > 0) setExpanded(!expanded) }}>{intl.messages["add_child"]}</OutlineButton>}
                {!isEnabled && <LoaderButton style={{ flexGrow: 1 }} onClick={() => {
                    return props.onSave().then((m) => {
                        // console.log(m);
                        if (typeof m === "string") setError(m);
                        else setError("");
                        return m;
                    })
                }}>{intl.messages["save"]}</LoaderButton>}
                {
                    expanded && <ChildSelectionPanel>
                        {
                            props.availableWidgetTypes.map((wt) => <FlatButton key={wt} onClick={
                                () => {
                                    props.onWidgetSelected(wt);
                                    setExpanded(false);
                                }
                            }>{intl.messages[wt]}</FlatButton>)
                        }
                    </ChildSelectionPanel>
                }
            </TypeAuthorized>

        </ButtonRowContainer>
    </div>
}

function DeleteButton(props: Props & { intl: Record<string, string> }) {

    if (props.selectedNode.type === "CONDOMINIUM" && props.condominium.status !== "enabled")
        return <OutlineButton style={{ flexGrow: 1 }} onClick={() => {
            confirmAlert({
                title: props.intl["confirm"].toString(),
                message: props.intl["cominium_deletion_confirm"].toString(),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            props.onCondominiumDeleted()
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }}>{props.intl["delete_condominium"]}</OutlineButton>

    if (props.condominium.status !== "enabled") return <OutlineButton style={{ flexGrow: 1 }} onClick={() => {
        confirmAlert({
            title: props.intl["confirm"].toString(),
            message: props.intl["this_action_cant_be_undone"].toString(),
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        props.onNodeDeleted()
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }}>{props.intl[`delete_${props.selectedNode.type.toLowerCase()}`]}</OutlineButton>
    return <div></div>

}