import { title } from "process";
import React, { Children } from "react";
import styled from "styled-components";
import { gray } from "../../utils/colors";

export const Title = styled.h2`font-size: 35px; color: ${(_) => gray}; text-transform: capitalize;`
export const P = styled.p``
export const H3 = styled.h3` 
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    `

export const H4 = styled.h4`
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
`

export const InformativeText = (props: {title: string, children: any, padded?: boolean}) => <> <H4 style={ props.padded ? {marginRight: 12} : {}} >{props.title}</H4> {props.children} </>