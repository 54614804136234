import { IconButton } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useReducer, useState } from "react"
import { act } from "react-dom/test-utils"
import { useIntl } from "react-intl"
import { fetchMeter } from "repsitory/meter_repository"
import styled, { css } from "styled-components"
import { Column, Row } from "../../../../components/barbagli/common"
import { Collapsed, Expanded } from "../../../../components/barbagli/icons"
import { Title } from "../../../../components/barbagli/text"
import { ErrorComponent } from "../../../../components/error_component"
import { Desc } from "../../../../components/icons"
import { LoaderComponent } from "../../../../components/loading_widget"
import { ErrorMapping } from "../../../../models/barbagli/error_mapping"
import { Attributes, Meter } from "../../../../models/meter"
import { fetchSinglePageCollection } from "../../../../repsitory/generic_repository"
import { deepCopy } from "../../../../utils/deep_copy"
import { TitleValueWidget } from "../../../dashboard_page/dashboard_detail/widgets/common/title_value_widget"
import { AlarmsIndex } from "../../alarms_index/alarms_index"

type Props = {
    attributes: Attributes
}

const Card = styled.div`
    border: 1px solid rgba(76, 85, 100, 0.77);
    box-sizing: border-box;
    padding: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    margin: 6px;
    transition-timing-function: ease;
    ${(props: { expanded: boolean }) => props.expanded && css`
        width: 100%;
    `}
    transition: all 0.5s;
    margin-right: 12px;
    &:hover {
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    }
`

const Descr = styled.h3`
font-weight: 300;
font-size: 16px;
margin-right: 6px;
`

const DescrContainer = styled.div`
display: flex;
justify-content: space-between;
`

const DescrName = styled.h2`
font-size: 22px;
text-transform: capitalize;
`

type MeterStateTabState =
    | { type: "loading" }
    | { type: "success", mappings: Record<string, ErrorMapping>, expandedItems: Record<string, boolean>, meter: Meter }
    | { type: "error", error: string }

type MeterStateTabAction =
    | { type: "setLoading" }
    | { type: "setError", error: string }
    | { type: "setSuccess", mappings: ErrorMapping[], meter: Meter }
    | { type: "toggleExpand", key: string }


function reducer(state: MeterStateTabState, action: MeterStateTabAction): MeterStateTabState {
    switch (action.type) {
        case "setError": return { type: "error", error: action.error }
        case "setLoading": return { type: "loading" }
        case "toggleExpand":
            if (state.type !== "success") return state;

            const nextExpandedState = deepCopy(state.expandedItems);
            if (!nextExpandedState[action.key]) nextExpandedState[action.key] = true;
            else nextExpandedState[action.key] = false;

            return { ...state, expandedItems: nextExpandedState }

        case "setSuccess": return {
            type: "success", mappings: action.mappings.reduce((acc, value) => {
                acc[value.name] = value
                return acc;
            }, {} as Record<string, ErrorMapping>), expandedItems: {}, meter: action.meter
        }
    }
}

export function MeterStateTab(props: Props) {
    const intl = useIntl();

    const [state, dispatch] = useReducer(reducer, { type: "loading" });
    const [anomExpanded, setAnomExpanded] = useState(false);


    useEffect(() => {

        (async() => {
            if(!props.attributes.id) return dispatch({ type: "setError", error: "Per visualizzare lo stato di un meter questo deve essere salvato" });
            const meter = await fetchMeter(props.attributes.id);
            if(typeof meter === "string") return dispatch({ type: "setError", error: meter });

            const mappings = await fetchSinglePageCollection<ErrorMapping>(`/api/v1/mapping?device_id=${props.attributes.deviceTypeId}`)(1, 10000);
            if (typeof mappings !== "string") {
                dispatch({ type: "setSuccess", mappings: mappings.sublist, meter: meter });
            } else {
                dispatch({ type: "setError", error: mappings });
            }
        })()
    }, [props.attributes.deviceTypeId])

    if (state.type === "loading") return <LoaderComponent height={100} width={100} />
    if (state.type === "error") return <ErrorComponent message={state.error} />

    if ((!state?.meter?.attributes?.activeAlarms || JSON.stringify(state.meter.attributes.activeAlarms) == '{}') && (!props.attributes.anomalies || JSON.stringify(props.attributes.anomalies) == '{}')) return <Title>{intl.messages["no_active_alarms"]}</Title>

    return <>
        <Row>
            {
                [...Object.keys(state.meter.attributes.activeAlarms!).map((k) => {
                    return <Card
                        expanded={state.expandedItems[k]}
                        key={k}>
                        <Column style={{ flexGrow: 1 }}>
                            <DescrContainer>
                                <DescrName>{state.mappings[k].displayName}</DescrName>
                                <IconButton onClick={() => dispatch({ type: "toggleExpand", key: k })}> {state.expandedItems[k] ? <Expanded /> : <Collapsed />} </IconButton>
                            </DescrContainer>

                            <DescrContainer>
                                <Descr>{`${intl.messages["alarm_opened_at"]}`}</Descr>
                                <Descr>{
                                    // @ts-ignore
                                `${moment(state.meter.attributes.activeAlarms[k].alarm_opened_at).format("DD/MM/YYYY HH:mm:ss")}`
                                }</Descr>

                            </DescrContainer>
                            <DescrContainer>
                                <Descr>{`${intl.messages["last_alarm_event"]}`}</Descr>
                                <Descr>{
                                    // @ts-ignore
                                `${moment(state.meter.attributes.activeAlarms[k].last_alarm_time).format("DD/MM/YYYY HH:mm:ss")}`
                                }</Descr>
                            </DescrContainer>
                            {state.expandedItems[k] && <AlarmsIndex prexistingFilters={[
                                { column: "meter_identifier", value: state.meter.attributes.identifier },
                                { column: "name", value: k },
                            ]} />}
                        </Column>
                    </Card>
                }),
                ...AnomaliesCard(state.meter.attributes, intl.messages as any, anomExpanded, setAnomExpanded)
                ]
            }

        </Row>

    </>
}

function AnomaliesCard(attributes: Attributes, intl: Record<string, string>, expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>) {
    if (!attributes.anomalies || JSON.stringify(attributes.anomalies) == "{}") return []
    return [
        <Card
            expanded={expanded}
            key={"anomaly"}>

            <Column style={{ flexGrow: 1 }}>
                <DescrContainer>
                    <DescrName>{intl["anomaly_title"]}</DescrName>
                    <IconButton onClick={() => setExpanded(!expanded)}> {expanded ? <Expanded /> : <Collapsed />} </IconButton>
                </DescrContainer>

                <DescrContainer>
                    <Descr>{`${intl["anomaly_at"]}`}</Descr>
                    <Descr>{`${moment(attributes.anomalies["negative_consumption"]).format("DD/MM/YYYY HH:mm:ss")}`}</Descr>
                </DescrContainer>

                {expanded && <AlarmsIndex prexistingFilters={[
                    { column: "meter_identifier", value: attributes.identifier },
                    { column: "name", value: "negative_consumption" },
                ]} />}
            </Column>
        </Card>
    ]
}