import { useIntl } from "react-intl";
import { SelfReadingComponent, showSelfReadingComponent } from "../../../components/barbagli/self_reading_component";
import { Condominium, CondominiumNode } from "../../../models/barbagli/condominium";
import { Meter } from "../../../models/meter";
import { parentSubCondominiumId } from "../condominium/condominium_view_model";
import { MetersIndex } from "../meters_index/meters_index";
import React, { useEffect, useMemo } from "react"

type Props = {
    condominium: Condominium,
    selectedNode: CondominiumNode
}

export function SelfReadingPage(props: Props) {
    const intl = useIntl();


    switch (props.selectedNode.type) {
        case "METER": return <SelfReadingComponent meter={props.selectedNode.attributes} intl={intl.messages as any} />
        case "CONDOMINIUM": return <MetersIndex
            selfReading
            onClick={(meter: Meter) => { showSelfReadingComponent({ meter: meter.attributes, intl: intl.messages as any }) }}
            filters={[
                { column: "condominium_id", value: props.selectedNode.id?.toString() ?? "" }
            ]}
        />
        case "SUBCONDOMINIUM": return <MetersIndex
        selfReading
        onClick={(meter: Meter) => { showSelfReadingComponent({ meter: meter.attributes, intl: intl.messages as any }) }}
            filters={[
                { column: "condominium_id", value: props.condominium.id?.toString() ?? "" },
                { column: "sub_condominium_uuid", value: props.selectedNode.node_id.toString() }
            ]}
        />
        case "IMMOBILE": return <MetersIndex
        selfReading
        onClick={(meter: Meter) => {
                showSelfReadingComponent({
                    meter: meter.attributes,
                    intl: intl.messages as any
                })
            }}
            filters={[
                { column: "condominium_id", value: props.condominium.id?.toString() ?? "" },
                { column: "sub_condominium_uuid", value: parentSubCondominiumId(props.selectedNode.node_id?.toString(), props.condominium) ?? "" },
                { column: "immoblie_uuid", value: props.selectedNode.node_id.toString() }
            ]}
        />
        case "GATEWAY": return <MetersIndex
        selfReading
        onClick={(meter: Meter) => { showSelfReadingComponent({ meter: meter.attributes, intl: intl.messages as any }) }}
            filters={[
                { column: "gateway_id", value: props.selectedNode.id?.toString() ?? "" },
            ]}
        />

    }

}