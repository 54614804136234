import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Responsive as ResponsiveGridLayout} from 'react-grid-layout';
import {Dashboard} from "../../../models/dashboard/dashboard";
import {WidgetConfig} from "../../../models/dashboard/widgetConfig";
import {useDispatch, useSelector} from "react-redux";
import {
  addWidgetToDashboard,
  buildWidget,
  removeWidgetToDashboard,
  statConfigsFromFilterCollection
} from "./dashboard_detail_vm";
import {ClearIcon, EyeIcon, EyeOffIcon, TrashIcon} from "../../../components/icons";
import {AppState} from "../../../store/store";
import "../dashboard.css";
import {Divider} from "../../../components/divider";
import {StatConfig} from "../../../models/stat_config";
import {fetchFilterCollections} from "../../../repsitory/filters_repository";
import { Authorized } from '../../../components/authorized';


type Props = {
  dashboard: Dashboard,
  editable: boolean,
  onDashBoardUpdate: (old: Dashboard, updated: Dashboard) => void
  onCancel: () => void
}

type EditorButtonState = "Only DEX" | "Only default" | "Both"


export function DashboardEditableDetail(props: Props) {

  const intl = useIntl();

  const dispatch = useDispatch();
  const appState = useSelector((a: AppState) => a);

  const [dashboard, setDashboard] = useState<Dashboard>(props.dashboard);
  const [editorVisible, setEditorVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const statConfigs = useSelector((s: AppState) => s.statConfig);

  const [dexStatConfigs, setDexStatConfigs] = useState<StatConfig[]>([]);
  useEffect(() => {
    (async () => {
      const filterCollections = await fetchFilterCollections();
      if(typeof filterCollections !== "string") setDexStatConfigs(statConfigsFromFilterCollection(filterCollections))
    })();
  }, [0])
  const [editorButtonState, setEditorButtonState] = useState<EditorButtonState>("Both")

  const combinedStatConfigs = [...dexStatConfigs, ...statConfigs.statConfigs];

  const [width, setWidth] = useState(1000);
  useEffect(() => {
    ref?.current?.clientWidth && setWidth(ref.current.clientWidth)
    const t = setInterval(() => {
      ref?.current?.clientWidth && setWidth(ref.current.clientWidth)
    }, 500)
    return () => clearInterval(t);
  }, [0])

  return (
    <div style={{position: "relative"}}>
      <div className={"row mt-2"} style={ {minHeight: 650, marginLeft: 0} } ref={ref}>
        <div className={"col-md-12"}>
          <ResponsiveGridLayout
            compactType={null}
            style={{marginLeft: -15}}
            onLayoutChange={(l) => {
              setDashboard(withNewCoordinates(dashboard, l)); //  This is really for persistence only
              props.dashboard.widgets = dashboard.widgets;  //  Side effect on the state because of the library
            }}
            width={width -15}
            cols={{lg: 10, md: 10, sm: 10, xs: 10, xxs: 10}}
          >

               {/*Widget taken from dashboard   */}
            {dashboard.widgets.map((w) =>
                <div className={ w.name.includes("gateway") ? "dashboard-gateway-widget" : "dashboard-widget"}
                     key={w.key}
                     data-grid={ {...w.data_grid, isDraggable: props.editable, isResizable: props.editable}}
                >
                {props.editable && <div className={"dashboard-editable-row d-flex flex-row-reverse "}>
                  <button className={"icon-button"} onClick={(e) => {
                    e.stopPropagation();
                    removeWidgetToDashboard(w.key, dashboard, setDashboard);
                  }}>
                    <TrashIcon/>
                  </button>
                </div>}
                { buildWidget(w, dispatch, appState, intl.messages, dexStatConfigs) }
              </div>
              )}
          </ResponsiveGridLayout>
        </div>
      </div>

      <div className={"row mt-3"}>
        <div className={"col-md-12 ellipsis mt-1"}>

          <div className={"d-flex flex-row justify-content-between"}>

            <button className={`p3 ${ editorVisible ? 'primary-button' : 'outline-button'} mr-3`} onClick={() => {
              setEditorVisible(!editorVisible);
            }}>
              {intl.messages['widgets']}
            </button>
            <div className={"d-flex flex-row-reverse"}>
              <Authorized visible_by={["admin"]}>
                <button className={"p3 primary-button"}  onClick={() => {
                  props.onDashBoardUpdate(props.dashboard, dashboard);
                }}>
                  {intl.messages['save']}
                </button>
              </Authorized>
              <button className={"p3 outline-button mr-3"} onClick={() => {
                props.onCancel();
                setDashboard(JSON.parse(JSON.stringify(props.dashboard)));
              }}>
                {intl.messages['cancel']}
              </button>
            </div>

          </div>

        </div>
      </div>

      { editorVisible && <div className={"d-flex flex-column dashboard-editor p-3"}>
        <div className={"row d-flex flex-row justify-content-between"}>
            <h3 className={"dashboard-editor-title"}>{intl.messages["widget-types"].toString().toUpperCase()}</h3>
              <button className={"flat-button"} onClick={() => setEditorButtonState(nextButtonState(editorButtonState))}>
                  <h3 style={{paddingTop: 5, textDecoration: "underline"}}>{editorButtonState}</h3>
              </button>
          <button className={"icon-button dashboard-editor-icon"} onClick={() => setEditorVisible(false)}><ClearIcon/></button>
        </div>
        <Divider className={"mt-1 mb-1"}/>
        {
          combinedStatConfigs.filter((sc) => {
            switch (editorButtonState) {
              case "Only DEX": return sc.name.includes("DEX")
              case "Only default": return !sc.name.includes("DEX")
              case "Both": return true
            }
          }).map((sc) =>
            <button
              key={sc.id.toString()}
              onClick={(e) => {
                e.stopPropagation()
                addWidgetToDashboard(sc, dashboard, setDashboard);
              }}
              className={"dashboard-editor-item"}
            >
              {intl.messages[sc.name] ?? sc.name}
            </button>
          )
        }
      </div> }

    </div>
  );
}

function nextButtonState(s: EditorButtonState): EditorButtonState {
  switch (s) {
    case "Only DEX":
      return "Only default"
    case "Only default":
      return "Both"
    case "Both":
      return "Only DEX"
  }
}

function withNewCoordinates(dashboard: Dashboard, coordinates: {x: number, y: number, w: number, h: number, i: string}[]): Dashboard {

  function updatedWidget(c: {x: number, y: number, w: number, h: number, i: string}): WidgetConfig {
    const original: WidgetConfig = dashboard.widgets.filter((w) => w.key === c.i)[0];

    return {
      ...original,
      data_grid: { ...original.data_grid,  h: c.h, w: c.w, x: c.x, y: c.y},
    };
  }

  return { ...dashboard, widgets: coordinates.map(updatedWidget) }
}
