import { async } from "@reactivex/rxjs/dist/package";
import React, { useEffect } from "react";
import { useState } from "react";
import * as RSelect from 'react-select';
import * as RAsyncSelect from "react-select/async";
import { fetchPaginatedCollection, remoteFetchAttributes } from "repsitory/generic_repository";
import styled from "styled-components";
import { gray } from "../../utils/colors";
import { H4 } from "./text";

type Props<T> = {
  isMulti?: boolean,
  availableOptions: T[],
  selectedOptions: T[],
  onChange: (ts: T[]) => void;
  placeholder?: string;
  valueMapper?: (t: T) => string;
  label?: string;
  enabled?: boolean;
  labelStyle?: any
  style?: any
}

const SelectContainer = styled.div`
display: flex;
flex-direction: column;
flex-grow: 1;
`

export function Select<T>(props: Props<T>) {
  const mapper = props.valueMapper ?? ((t: T) => JSON.stringify(t))
  const extractValue = (t: T) => ({
    value: t,
    label: mapper(t)
  })
  const [selectValues, setSelectValue] = useState<{ label: string, value: T }[]>(props.selectedOptions.map((so) => ({
    value: so,
    label: mapper(so)
  })));

  return <SelectContainer style={props.style ?? {}}>
    {props.label && <H4 style={{ marginBottom: 2, ...(props.labelStyle ?? {}) }}> {props.label}</H4>}

    <RSelect.default
      value={selectValues as any}
      isDisabled={!props.enabled}
      onChange={(g: any) => {
        if (g === null || g === undefined) {
          if (props.isMulti) { setSelectValue([]); props.onChange([]) }
          return;
        }
        setSelectValue(g);
        if (!props.isMulti) {
          props.onChange([g.value])
        } else {
          props.onChange(g.map((it: any) => it.value))
        }
      }}
      placeholder={props.placeholder}
      isClearable={false}
      isMulti={props.isMulti ?? true}
      options={props.availableOptions.map(extractValue) as any}
      theme={(t) => ({ ...t, colors: { ...t.colors, primary: gray, primary25: "rgba(0, 0, 0, 0.05)", neutral5: "#ccc" } })}
      name=""
      styles={selectCustomStyles}
    />
  </SelectContainer>

}


type AsyncProps<T> = {
  fetchOptions: (partialInput: string) => Promise<T[]>,
  cached?: boolean,
  isMulti?: boolean,
  selectedOptions: T[],
  onChange: (ts: T[]) => void;
  placeholder?: string;
  valueMapper?: (t: T) => string;
  label?: string;
  enabled?: boolean;
  labelStyle?: any;
  style?: any;
}


export function AsyncSelect<T>(props: AsyncProps<T>) {
  const mapper = props.valueMapper ?? ((t: T) => JSON.stringify(t))
  const extractValue = (t: T) => ({
    value: t,
    label: mapper(t)
  })
  const [selectValues, setSelectValue] = useState<{ label: string, value: T }[]>(props.selectedOptions.map((so) => ({
    value: so,
    label: mapper(so)
  })));

  return <SelectContainer style={props.style ?? {}}>
    {props.label && <H4 style={{ marginBottom: 2, ...(props.labelStyle ?? {}) }}> {props.label}</H4>}
    <RAsyncSelect.default

      defaultValue={props.selectedOptions}
      cacheOptions={props.cached}
      defaultOptions
      loadOptions={(input: string) => {
        return props.fetchOptions(input).then(r => {
          return r.map((u) => ({ value: u, label: mapper(u) }))
        })
      }}
      value={selectValues as any}
      placeholder={props.placeholder ?? undefined}
      isDisabled={!props.enabled}
      onChange={(g: any) => {
        if (!g) return;
        setSelectValue(g);
        // console.log(g);
        if (!props.isMulti) {
          props.onChange([g.value])
        } else {
          props.onChange(g.map((it: any) => it.value))
        }
      }}
      isClearable
      isMulti={props.isMulti ?? true}
      theme={(t) => ({ ...t, colors: { ...t.colors, primary: gray, primary25: "rgba(0, 0, 0, 0.05)", neutral5: "#ccc" } })}
      name=""
      styles={selectCustomStyles}
    />
  </SelectContainer>
}

type AsyncIdProps<T> = {
  baseUrl: string;
  valueMapper: (t: T) => string;
  idExtractor: (t: any) => any;
  onIdsSelected: (a: any[]) => void;
  selectedIds: any[];
  filterName: string;
  placeholder: string;
  label?: string;
  isMulti?: boolean
  enabled?: boolean;
  style?: any;
}

export function AsyncSelectIdsFromCrud(props: AsyncIdProps<any>) {

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  useEffect(() => {
    (async () => {
      try {
        // console.log(props.selectedIds)
        const r = (await Promise.all(props.selectedIds.map((id) => remoteFetchAttributes(`${props.baseUrl}/${id}`))))
        // console.log(r)
        const r1 = r.filter((it) => typeof it !== "string")
        // console.log(r1)
        setSelectedOptions(r1)
      } catch(e) {
        console.log(e)
      }

    })();
  }, [1]);

  

  return <AsyncSelect
  labelStyle={{marginBottom: 8}}
  fetchOptions={async (partialInput) => {
      const data = await fetchPaginatedCollection<any>(`${props.baseUrl}?${props.filterName}=${partialInput}`)(1, 8);
      if(typeof data === "string") return [];
      return data.sublist as any[];
  }}
  isMulti={props.isMulti ?? true}
  key={JSON.stringify(selectedOptions)}
  selectedOptions={selectedOptions}
  style={{marginTop: 8}}
  enabled
  {...props}
  onChange={(items: any[]) => { 
      props.onIdsSelected(items.map(props.idExtractor))
   }}
/>


}


export const selectCustomStyles = {
  option: (provided: React.CSSProperties, state: any) => ({
    ...provided,
  }),
  control: (provided: React.CSSProperties, state: any) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    borderRadius: 6,
    height: 32,
    minHeight: 0,
    borderColor: gray,

  }),

  multiValue: (provided: React.CSSProperties, state: any) => {

    return {
      ...provided,
      borderRadius: 1000,
      backgroundColor: "#C4C4C4",
      paddingLeft: 8
    };
  },
  container: (provided: React.CSSProperties, state: any) => ({
    ...provided,
    borderRadius: 6,
  }),
  valueContainer: (provided: React.CSSProperties, state: any) => ({
    ...provided,
    borderRadius: 6,
    height: 32,
    minHeight: 0,
  }),
  indicatorSeparator: () => ({}),
  multiValueRemove: () => ({
    marginRight: 8,
    marginLeft: 4
  }),
  dropdownIndicator: () => ({
    color: gray,
    marginRight: 10
  })
}
