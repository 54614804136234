import { v1 } from "uuid";
import { fallbackMapCenter } from "../..";
import { Gateway } from "../gateway";
import { GeoJSONPoint, geoJsonPointFromCoordinates } from "../geo_json";
import { Meter } from "../meter";

export function emptyCondominium(): Condominium {
    return {
        type: "CONDOMINIUM",
        path_url: "/",
        node_id: v1(),
        availableTabs: ["data"],
        children: [],
        coordinates: geoJsonPointFromCoordinates(fallbackMapCenter)!,
        data: {
            companyName: "",
            vatNumber: "",
            cond_name: "",
            address: "",
            city: "",
            province: "",
            postalCode: "",
            navId: "",
        },
    }
}

export function isEnabled(c: Condominium) {
    return c.status === 'enabled' || window.sessionStorage.getItem("user_type") === "user";
}


export type CondominiumTab = "data" | "readings" | "leak_detection" | "consumption" | "alarms" | "self_reading" | "notifications" | "state" | "meter_whitelist" | "note";


// /condominium/id/sum_condominium/id/immobile/id..
export type CondominiumUrl = string;

export type CondominiumNodeData = {
    path_url: CondominiumUrl;
    node_id: string;
    availableTabs: CondominiumTab[];
    children: CondominiumNode[];
}

export type CondominiumNode = (Condominium | SubCondominium | Immobile | (Gateway & CondominiumNodeData & { type: "GATEWAY" }) | (Meter & CondominiumNodeData & { type: "METER" }));
export type CondominiumNodeType = "GATEWAY" | "CONDOMINIUM" | "SUBCONDOMINIUM" | "IMMOBILE" | "METER";
export type Condominium = CondominiumNodeData & {
    type: "CONDOMINIUM",
    id?: number,
    managedBy?: string,
    updateGroups?: string[]
    listGroups?: string[]
    deleteGroups?: string[],
    partners?: string[],
    insertedAt?: Date,
    coordinates: GeoJSONPoint,
    navId?: string,
    data: {
        companyName?: string;
        vatNumber: string;
        cond_name?: string;
        address: string;
        city: string;
        province: string;
        postalCode: string;
        navId: string;
    },
    administrator?: User;
    status?: string
}

export type SubCondominium = CondominiumNodeData & {
    type: "SUBCONDOMINIUM",
    id?: string,
    data: {
        stair: string;
        name?: string;
        address: string;
        number: string;
    }
}

export type Immobile = CondominiumNodeData & {
    type: "IMMOBILE",
    id?: string,
    data: {
        floor: Floor;
        name?: string;
        location: string;
        flatNumber: string;
        identifier: string;
    }
    tenant: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        fax: string;
        vatNumber: string;
    }
    owner: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        fax: string;
        vatNumber: string;
    }
}


export type Floor = "Piano interrato -2" |
    "Piano interrato -1" |
    "Piano Terra" |
    "Piano Marciapiede" |
    "Piano 1" |
    "Piano 2" |
    "Piano 3" |
    "Piano 4" |
    "Piano 5" |
    "Piano 6" |
    "Piano 7" |
    "Piano 8" |
    "Piano 9" |
    "Piano 10"

export const floors: Floor[] = [
    "Piano interrato -2",
    "Piano interrato -1",
    "Piano Terra",
    "Piano Marciapiede",
    "Piano 1",
    "Piano 2",
    "Piano 3",
    "Piano 4",
    "Piano 5",
    "Piano 6",
    "Piano 7",
    "Piano 8",
    "Piano 9",
    "Piano 10"
]

export type User = {
    firstName: string;
    lastName: string;
    email: string;
    partner: string;
}
