import {Command, ConfigurationValue} from "../../models/configuration";
import React, {useState} from "react";
import _, { first } from "lodash";
import Select from "react-select";
import {showConfigurationEditor} from "../configuration_editor/configuration_editor";
import {showAlert} from "../alerts/alert";
import {SelectedIcon, TriangleIcon} from "../icons";
import { selectCustomStyles } from "../barbagli/select";
import { gray } from "../../utils/colors";

type Props = {
  commands: Command[],
  intl: Record<string, string>,
  executor: (c: Command, params: ConfigurationValue[]) => Promise<void | string>
}


export function CommandInvocationWidget(props: Props) {

  const [selectedCommand, setSelectedCommand] = useState<Command | undefined>(first(props.commands))

  return(
    <div>
      <div className={"row"}>
        <div className={"col-md-12 d-flex flex-row"}>
          <Select
            value={{label: selectedCommand?.name ?? "", value: selectedCommand?.name ?? ""}}
            onChange={(g: any) => {
              setSelectedCommand(_.first(props.commands.filter((c) => c.name === g.value)))
            }}
            isMulti={false}
            options={ props?.commands?.map((c) => ({label: c.name, value: c.name})) }
            theme={(t) => ({...t, colors: {...t.colors, primary: gray, primary25: "rgba(0, 0, 0, 0.05)", neutral5: "#ccc"}})}
            name=""
            styles={selectCustomStyles}
            className="basic-multi-select select-sizing flex-grow-1 mr-2"
            classNamePrefix="select"
          />
          <button
            className={"outline-button"}
            onClick={() => {
              if(selectedCommand !== undefined) {
                showConfigurationEditor({
                  configurationSpecs: selectedCommand.params,
                  intl: props.intl,
                  onClose(): void {},
                  onSave(values: ConfigurationValue[]): void {
                    props.executor(selectedCommand, values).then((r) => {
                      if(typeof r === "string") {
                        showAlert(props.intl["error"], r, {icon: TriangleIcon()})
                      } else {
                        showAlert(props.intl["success"], props.intl["command_scheduled"], {icon: SelectedIcon({width: 160, height: 160})})
                      }
                    })
                  },
                  saveButtonText: props.intl["exec"],
                  title: selectedCommand.name
                })
              }
            }}
          >
            { props.intl["exec"] }
          </button>
        </div>
      </div>

    </div>
  );
}