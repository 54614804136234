export type Partner = {
  id?: number;
  address: string;
  cap: string;
  city: string;
  code: string;
  companyName: string;
  portalAddress: string;
  portalErrorsColor: string;
  portalLogo: string;
  portalName: string;
  portalPrimaryColor: string;
  portalState: string;
  portalTextColor: string;
  condominiums: number[];
  prov: string;
  config?: PartnerCssConfig;
  vat: string;
  smtpConfig?: any;
};

export type SmtpConfig = {
  ssl: boolean;
  tls: string;
  port: number;
  email: string;
  server: string;
  retries: number;
  password: string;
  username: string;
  reply_email: string;
  allowed_tls_versions: string[];
};

export function emptySmtpConfig(): SmtpConfig {
  return {
    ssl: true,
    tls: "always",
    port: 587,
    email: "",
    server: "",
    retries: 1,
    password: "",
    username: "",
    reply_email: "",
    allowed_tls_versions: ["tlsv1", "tlsv1.1", "tlsv1.2"],
  };
}

export type PartnerCssConfig = {
  primary_color: string;
  text_color: string;
  error_color: string;
};

export function emptyPartnerConfig(): PartnerCssConfig {
  return {
    primary_color: "#4B88A2",
    error_color: "#BB0A21",
    text_color: "#252627",
  };
}

export function emptyPartner(): Partner {
  return {
    address: "",
    cap: "",
    city: "",
    code: "",
    companyName: "",
    portalAddress: "",
    portalErrorsColor: "",
    portalLogo: "",
    portalName: "",
    portalPrimaryColor: "",
    portalState: "",
    portalTextColor: "",
    prov: "",
    vat: "",
    config: emptyPartnerConfig(),
    condominiums: [],
    smtpConfig: emptySmtpConfig(),
  };
}

export const partnerPrimaryColors = [
  "#7e57c2",
  "#5c6bc0",
  "#66bb6a",
  "#ffca28",
  "#8d6e63",
  "#78909c",
];

export const partnerErrorColors = [
  "#e57373",
  "#f44336",
  "#d32f2f",
  "#9a0036",
  "#dc004e",
  "#f57c00",
];

export const partnerTextColor = [
  "#424242",
  "#212121",
  "#616161",
  "#37474f",
  "#455a64",
  "#263238",
];

export function isAPartner(c: any): boolean {
  return c?.portalAddress != undefined;
}
