import React from "react";
import { useIntl } from "react-intl";
import { TabView } from "../../../components/barbagli/tab_view";
import { TopTabView } from "../../../components/barbagli/top_tab_view";
import { Condominium, CondominiumNode } from "../../../models/barbagli/condominium";
import { FilterParam } from "../../../models/barbagli/filter_order";
import { MetersAnomaliesIndex } from "../anomaly_index/meters_anomaly_index";
import { AlarmsIndex } from "./alarms_index";

type Props = {
    condominium: Condominium,
    selectedNode: CondominiumNode
}


function AlarmAnomalyPage(props: {preexistingFilters: FilterParam[], key: string}) {
    return <TabView

  children={
    [
      [
        "Allarmi",
        <AlarmsIndex
            key={`${props.key}_alarms`}
            prexistingFilters={props.preexistingFilters}
        />
      ],
      [
        "Anomalie",
        <MetersAnomaliesIndex preexisting_filters={props.preexistingFilters} key={`${props.key}_anomaly`}/>
      ],
    ]
  } />
}

export function AlarmsPage(props: Props) {
    const intl = useIntl();


    switch (props.selectedNode.type) {
        case "METER": return <AlarmAnomalyPage
            key={JSON.stringify(props.selectedNode)}
            preexistingFilters={[
                {column: "serial_number", value: props.selectedNode.attributes.serialNumber ?? ""}
            ]}

        />
        case "CONDOMINIUM": return <AlarmAnomalyPage
            key={JSON.stringify(props.selectedNode)}
            preexistingFilters={[
                {column: "condominium_id", value: `${props.condominium.id}`}
            ]}
        />
        case "SUBCONDOMINIUM": return <AlarmAnomalyPage
            key={JSON.stringify(props.selectedNode)}
            preexistingFilters={[
                {column: "condominium_id", value: `${props.condominium.id}`},
                {column: "sub_condominium_id", value: `${props.selectedNode.node_id}`}
            ]} 
        />
        case "IMMOBILE": return <AlarmAnomalyPage
                key={JSON.stringify(props.selectedNode)}
                preexistingFilters={[
                    {column: "condominium_id", value: `${props.condominium.id}`},
                    {column: "immobile_id", value: `${props.selectedNode.node_id}`}
                ]} 
        />
        default: return <p></p>

    }

}