import React, { Dispatch } from "react";

import * as H from 'history'
import _ from "lodash";
import { User } from "../../../models/user";
import { UserGroup } from "../../../models/user_group";
import { post, put } from "../../../repsitory/generic_repository";
import { addUserToGroup, removeUserToGroup } from "../../../repsitory/user_repository";
import { useParams } from "react-router-dom";
import { fetchCondominium, updateCondominium } from "../../../repsitory/barbagli/condominium_repository";

//  Page state
export type EditUserPageState = User & {
  email: string
  status: string
  validationErrors: string[]
  loading: boolean
  error: string
  businessName: string
  companies: number[]
  condominiums: number[]
  partners: number[],
  confirmationSent: boolean

}

const validate = (state: EditUserPageState) => {
  let errors: string[] = [];
  return errors;
};




//  Page effects
export const createEditUser = async (state: EditUserPageState, oldUserGroups: UserGroup[], setState: React.Dispatch<React.SetStateAction<EditUserPageState>>, dispatch: Dispatch<any>, history: H.History, condominium?: string) => {

  const validation = validate(state);

  if (validation.length > 0) {
    setState({ ...state, validationErrors: validation });
    return;
  } else {
    setState({ ...state, loading: true })
  }

  const data = {
    "data": {
      "attributes": {
        "username": state.username,
        "firstName": state.firstName,
        "lastName": state.lastName,
        "email": state.email,
        "type": state.type,
        "condominiums": state.condominiums,
        "companies": state.companies,
        "partners": state.partners,
        "businessName": state.businessName,
        "attributes": {
          "badge_id": " ",
          "status": state.status
        },
        // "groups": state.groups
      },
      "type": "user"
    }
  };

  try {
    let result;
    let userId: string;

    if (state.id === undefined || state.id === "") {
      result = await post(data, '/api/v1/user');
      userId = result.data.attributes.userId;

      if (condominium && result.errors === undefined) {
        const cond = await fetchCondominium(condominium);
        if (typeof cond === "string") return setState({
          ...state, error: cond, loading: false
        });
        //  @ts-ignore
        result = await updateCondominium({ ...cond, managedBy: userId })
      }

    } else {
      result = await put(data, `/api/v1/user/${state.id}`);

    }


    if (result.errors !== undefined) {
      setState({ ...state, error: JSON.stringify(result), loading: false })
      return;
    }

    history.push('/users');

  } catch (e) {
    setState({ ...state, error: e.toString(), loading: false })
  }
};


export const resendVerifyEmailToUser = async (state: EditUserPageState, oldUserGroups: UserGroup[], setState: React.Dispatch<React.SetStateAction<EditUserPageState>>, dispatch: Dispatch<any>, history: H.History, condominium?: string) => {

  const validation = validate(state);

  if (validation.length > 0) {
    setState({ ...state, validationErrors: validation });
    return;
  } else {
    setState({ ...state, loading: true })
  }
  const data = {};

  try {
    let result;
    let userId: string;
    result = await post(data, `/api/v1/user/${state.id}/send_confirm`);
    if (result.errors !== undefined) {
      setState({ ...state, error: JSON.stringify(result), loading: false })
      return;
    }
    setState({ ...state, loading: false, confirmationSent: true })
  } catch (e) {
    setState({ ...state, error: e.toString(), loading: false })
  }
};

export async function syncGroups(oldGroupIds: string[], newGroupsIds: string[], userId: string): Promise<boolean> {
  return (await Promise.all([
    addGroups(oldGroupIds, newGroupsIds, userId),
    removeGroups(oldGroupIds, newGroupsIds, userId)
  ])).reduce((a, b) => a && b);
}

export async function addGroups(oldGroupIds: string[], newGroupsIds: string[], userId: string): Promise<boolean> {
  return (await Promise
    .all(_.difference(newGroupsIds, oldGroupIds)
      .map((groupId: string) => addUserToGroup(userId, groupId)))
  ).reduce((p, n) => p && n, true);
}

export async function removeGroups(oldGroupIds: string[], newGroupsIds: string[], userId: string): Promise<boolean> {
  return (await Promise
    .all(_.difference(oldGroupIds, newGroupsIds)
      .map((groupId: string) => removeUserToGroup(userId, groupId)))
  ).reduce((p, n) => p && n, true);
}


export const mkUserState: (u: User) => EditUserPageState = (user: User) => ({
  ...user,
  username: user.username || "",
  id: user.id || "",
  badge_id: (user?.additionalAttributes?.badge_id && user?.additionalAttributes?.badge_id[0]) || "",
  email: user.email || "",
  userId: user.userId,
  groups: user.groups || [],
  last_login: "??",
  businessName: user.businessName || "",
  status: (user?.additionalAttributes?.status && user?.additionalAttributes?.status[0]) || "",
  validationErrors: [],
  loading: false,
  error: "",
  condominiums: user.condominiums ?? [],
  companies: user.companies ?? [],
  partners: user.partners ?? [],
  confirmationSent: false
});