import {clamp} from "lodash-es";

export const green = "#425f3a";
export const orange = "#934c19";
export const red = "#720b0b";
export const gray = "#5a5a5a";
export const lightGray = "#c4c4c4";
export const blue = "#3C4B64";

export function grayGradient(steps: number): string[] {
  const darker = 50;
  const lighter = 125;
  const delta = lighter - darker;
  const increment = delta / steps;

  let values: string[] = [];

  for(let i = 0; i < steps; i++) {
    const component = darker + (i*increment);
    values = [`rgb(${component}, ${component}, ${clamp(component + 10, 0, 255)})`, ...values]
  }
  return values;
}