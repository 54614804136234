import React, { useEffect, useReducer } from "react";
import { OutlineButton, Row } from "../../../../components/barbagli/common";
import { Attributes, MeterHeatDivider } from "../../../../models/meter";
import * as vm from "./meter_heat_divider_vm";
import { IconButton, LoaderButton } from "../../../../components/barbagli/common";
import styled from "styled-components";
import { H4, Title } from "../../../../components/barbagli/text";
import { NotSelectedIcon, SelectedIcon } from "../../../../components/icons";
import { TextInput } from "../../../../components/barbagli/text_input";
import { showKcSelection } from "./kc_modal";
import { Select } from "../../../../components/barbagli/select";
import { DatePickerInput } from "../../../../components/datepicker";
import { useIntl } from "react-intl";

type Props = {
    attributes: Attributes
    onUpdate: (newState: vm.MeterHeatDividerState) => void;
    intl: Record<string, string>
}



const ButtonItem = styled.div`
    display: flex;
    flex-grow: 1;
    width: 50%;
    flex-direction: row;
    align-items: center;
    height: 32px;
`

//  Aggiungere la selezione se esiste un progetto 10200,
//   se esiste inserisco direttamente il Kq 
//   altrimenti inserisco i dati che servono per calcolarlo che sono quelli che ci sono 
//   più la tipologia di radiatore presa dalla tabella che ci deve mandare niccolò che determina 
//   il Kc e la selezione se il ripartitore è normale oppure sonda remota.

export function MeterHeatDividerFields(props: Props) {

    const [state, dispach] = useReducer(vm.reducer, vm.fromAttributes(props.attributes));
    const intl = useIntl();

    useEffect(() => {
        props.onUpdate(state);
    }, [state])

    

    if (!state.kqCalculationEnabled) return <>
        <Title>{props.intl["mapping"]}</Title>
        <Row style={{ justifyContent: "space-beetween" }}>
            <TextInput
                enabled
                style={{ flexGrow: 1, marginRight: 8 }}
                label={props.intl["room"].toString().toUpperCase()}
                value={(state.room).toString()}
                onChange={(v) => { dispach({ type: "setRoom", room: v }) }}
            />
            <DatePickerInput
                style={{ flexGrow: 1, marginRight: 20, marginTop: 10 }}
                value={new Date(state.k_update_from!)} onChange={(d) => { dispach({ type: "setUpdateFrom", from: d }) }}
                showTimeSelect={false}
                enabled
                label={props.intl["valid_from"].toString()} />
            <Row style={{ marginTop: 30, marginRight: 20 }}>
                <ButtonItem>
                    <H4 style={{ marginTop: 8, marginRight: 8 }}>{props.intl["has_10200"]}</H4>
                    <IconButton onClick={() => { dispach({ type: "toggleKqCalculation" }) }}>
                        {state.kqCalculationEnabled ?  <NotSelectedIcon /> : <SelectedIcon />}
                    </IconButton>
                </ButtonItem>
            </Row>
            <div style={{ marginTop: 30, marginLeft: 12, display: "flex", flexGrow: 1, flexDirection: "row-reverse" }}>
                <OutlineButton onClick={() => {
                    showKcSelection((description) => dispach({ type: "setDescriptionKey", descriptionKey: description }), props.intl)
                }}>{props.intl["radiator_type"]}</OutlineButton>
                <H4 style={{ marginTop: 10, marginRight: 20 }}>{state.description_key }</H4>
            </div>
        </Row>
        <Row >

            <TextInput
                enabled
                style={{ flexGrow: 1 }}
                type={"number"}
                label={props.intl["thermical_power"].toString().toUpperCase()}
                value={(state.thermal_power ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setThermalPower", thermalPower: Number(v) }) }}
            />
        </Row>
        <Row>
            <Select
                enabled
                isMulti={false}
                style={{ marginTop: 6 }}
                labelStyle={{ marginBottom: 10 }}
                label={props.intl["install_type"]}
                availableOptions={
                    ["normal", "external"]
                }
                valueMapper={(v) => props.intl[`mapping_${v}`]}
                selectedOptions={[state.type]}
                onChange={([v]: any) => { dispach({ type: "setType", t: v }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["kc"].toString().toUpperCase()}
                value={(state.kc ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setKc", kc: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["conversion_factor"].toString().toUpperCase()}
                value={(state.conversion_factor ?? vm.defaultConversionFactor(state)).toString()}
                onChange={(v) => { dispach({ type: "setConversionFactor", conversionFactor: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["k"].toString().toUpperCase()}
                value={(state.k ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setK", k: Number(v) }) }}
            />



        </Row>


    </>

    return <>
        <Title>{props.intl["mapping"]}</Title>

        <Row style={{ justifyContent: "space-beetween" }}>
            <TextInput
                enabled
                style={{ flexGrow: 1, marginRight: 8 }}
                label={props.intl["room"].toString().toUpperCase()}
                value={(state.room).toString()}
                onChange={(v) => { dispach({ type: "setRoom", room: v }) }}
            />
            <DatePickerInput
                style={{ flexGrow: 1, marginRight: 20, marginTop: 10 }}
                value={new Date(state.k_update_from!)} onChange={(d) => { dispach({ type: "setUpdateFrom", from: d }) }}
                showTimeSelect={false}
                enabled
                label={props.intl["valid_from"].toString()} />
            <Row style={{ marginTop: 30, marginRight: 20 }}>
                <ButtonItem>
                    <H4 style={{ marginTop: 8, marginRight: 8 }}>{props.intl["has_10200"]}</H4>
                    <IconButton onClick={() => { dispach({ type: "toggleKqCalculation" }) }}>
                        <NotSelectedIcon />
                    </IconButton>
                </ButtonItem>
            </Row>
            <div style={{ marginTop: 30, marginLeft: 12, display: "flex", flexGrow: 1, flexDirection: "row-reverse" }}>
                <OutlineButton onClick={() => {
                    showKcSelection((description) => dispach({ type: "setDescriptionKey", descriptionKey: description }), props.intl)
                }}>{props.intl["radiator_type"]}</OutlineButton>
                <H4 style={{ marginTop: 10, marginRight: 20 }}>{state.description_key}</H4>
            </div>
        </Row>

        <Row>
            <TextInput
                enabled
                style={{ flexGrow: 1, marginRight: 8 }}
                type={"number"}
                label={props.intl["height_mm"].toString().toUpperCase()}
                value={(state.height_mm ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setHeight_mm", height_mm: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8, marginRight: 8 }}
                type={"number"}
                label={props.intl["width_mm"].toString().toUpperCase()}
                value={(state.width_mm ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setWidth_mm", width_mm: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["depth_mm"].toString().toUpperCase()}
                value={(state.depth_mm ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setDepth_mm", depth_mm: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["C"].toString().toUpperCase()}
                value={(state.C ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setC", C: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["Kq"].toString().toUpperCase()}
                value={(state.thermal_power ?? 0).toFixed(2).toString()}
                onChange={(v) => { dispach({ type: "setThermalPower", thermalPower: Number(v) }) }}
            />

        </Row>
        <Row>
            <Select
                enabled
                isMulti={false}
                style={{ marginTop: 6 }}
                labelStyle={{ marginBottom: 10 }}
                label={props.intl["install_type"]}
                availableOptions={
                    ["normal", "external"]
                }
                valueMapper={(v) => props.intl[`mapping_${v}`]}
                selectedOptions={[state.type]}
                onChange={([v]: any) => { dispach({ type: "setType", t: v }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["kc"].toString().toUpperCase()}
                value={(state.kc ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setKc", kc: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["conversion_factor"].toString().toUpperCase()}
                value={(state.conversion_factor ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setConversionFactor", conversionFactor: Number(v) }) }}
            />
            <TextInput
                enabled
                style={{ flexGrow: 1, marginLeft: 8 }}
                type={"number"}
                label={props.intl["k"].toString().toUpperCase()}
                value={(state.k ?? 0).toString()}
                onChange={(v) => { dispach({ type: "setK", k: Number(v) }) }}
            />



        </Row>
    </>
}

