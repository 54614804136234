import React, { useEffect, useState } from "react";
import MenuItemType from "../models/menu_item_type";
import { SidebarItem } from "./sidebar_item";
import { MenuIcon } from "./icons";
import { BASE_URL, VERSION } from "../index";
import { EventsExplorerFavouritePanel } from "../pages/data_explorer_page/events_explorer_favourite_panel";
import { Authorized, TypeAuthorized } from "./authorized";

type Props = {
  selectedItem: MenuItemType;
};

export function Sidebar(props: Props) {
  return (
    <div className={"c-sidebar c-sidebar-fixed c-sidebar-lg-show"} id="sidebar">
      <div className={"row d-lg-none"}>
        <div className={"col-md-12 d-flex flex-row justify-content-between"}>
          <img
            src={`${BASE_URL}/api/v1/owner-configuration/logo`}
            alt={"Product logo"}
            className={"mt-4 pt-3 mb-4 product-logo"}
          />
          {/*<button className="c-header-toggler c-class-toggler d-lg-none menu-button m-2 mt-4 pt-1" type="button" data-target="#sidebar"*/}
          {/*        data-class="c-sidebar-show">*/}
          {/*  <MenuIcon white/>*/}
          {/*</button>*/}
        </div>
      </div>

      <div
        className={"c-sidebar-brand d-md-down-none sidebar-color-override mb-2"}
        id={"logo"}
      >
        <img
          src={`${BASE_URL}/api/v1/owner-configuration/logo`}
          alt={"Product logo"}
          className={"product-logo"}
        />
      </div>
      <div className="d-flex flex-column justify-content-between flex-grow-1">
        <ul className={"c-sidebar-nav ps ps--active-y"}>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "Dashboards"}
                name={"Dashboards"}
              />
            </TypeAuthorized>
          </li>

          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized
              user_types={[
                "user",
                "admin",
                "company-admin",
                "partner-admin",
                "installer",
                "mnd-user",
              ]}
            >
              <SidebarItem
                active={props.selectedItem === "Condominium"}
                name={"Condominium"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized
              user_types={[
                "admin",
                "company-admin",
                "partner-admin",
                "installer",
              ]}
            >
              <SidebarItem
                active={props.selectedItem === "Meters"}
                name={"Meters"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized
              user_types={[
                "admin",
                "company-admin",
                "partner-admin",
                "installer",
              ]}
            >
              <SidebarItem
                active={props.selectedItem === "Network"}
                name={"Network"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized
              user_types={[
                "user",
                "admin",
                "company-admin",
                "partner-admin",
                "installer",
                "mnd-user",
              ]}
            >
              <SidebarItem
                active={props.selectedItem === "Alarms"}
                name={"Alarms"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized
              user_types={[
                "user",
                "admin",
                "company-admin",
                "partner-admin",
                "installer",
                "mnd-user",
              ]}
            >
              <SidebarItem
                active={props.selectedItem === "Anomalies"}
                name={"Anomalies"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "ErrorMapping"}
                name={"ErrorMapping"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "Users"}
                name={"Users"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "Groups"}
                name={"Groups"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin", "partner-admin"]}>
              <SidebarItem
                active={props.selectedItem === "Companies"}
                name={"Companies"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized
              user_types={["admin", "company-admin", "partner-admin"]}
            >
              <SidebarItem
                active={props.selectedItem === "Installer"}
                name={"Installer"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "Partner"}
                name={"Partner"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "Maglia Idrica"}
                name={"Maglia Idrica"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "My Net Domus User"}
                name={"My Net Domus User"}
              />
            </TypeAuthorized>
          </li>
          <li className={"c-sidebar-nav-item"}>
            <TypeAuthorized user_types={["admin"]}>
              <SidebarItem
                active={props.selectedItem === "summary"}
                name={"summary"}
              />
            </TypeAuthorized>
          </li>
        </ul>
        <div className="d-flex flex-row justify-content-end">
          <p style={{ color: "white" }}>{VERSION}</p>
        </div>
      </div>
    </div>
  );
}

/*

          <Authorized visible_by={["admin"]}>
            <SidebarItem
              active={props.selectedItem === "Users" || props.selectedItem === "Admin" || props.selectedItem === "Groups"}
              name={"Admin"}
            />
          </Authorized>
          <Authorized visible_by={["admin"]}>
            <SidebarItem
              active={props.selectedItem === "Users"}
              visible={ props.selectedItem === "Users" || props.selectedItem === "Admin" || props.selectedItem === "Groups"}
              secondary
              name={"Users"}
            />
          </Authorized>
          <Authorized visible_by={["admin"]}>
            <SidebarItem
              active={props.selectedItem === "Groups"}
              visible={ props.selectedItem === "Users" || props.selectedItem === "Admin" || props.selectedItem === "Groups"}
              secondary name={"Groups"}
              linkTo={"/user-groups"}
            />
          </Authorized>

          <Authorized visible_by={["user"]}>
            <SidebarItem active={props.selectedItem === "Dashboards"} name={"Dashboards"}/>
          </Authorized>
          <Authorized visible_by={["user"]}>
            <SidebarItem active={props.selectedItem === "Meters"} name={"Meters"} />
          </Authorized>
          <Authorized visible_by={["dex", "admin"]}>
            <SidebarItem active={props.selectedItem === "Data Explorer"} name={"Data Explorer"} />
          </Authorized>
          <Authorized visible_by={["user"]}>
            <SidebarItem active={props.selectedItem === "Network"} name={"Network"}/>
          </Authorized>


*/
