import React, {ReactNode} from 'react';
import * as _ from 'lodash';
import { Permission, permissions } from '../contexts/auth';
import store, { AppState } from '../store/store';
import { useSelector } from 'react-redux';


export function Authorized(props: {children: ReactNode, visible_by: Permission[]}) {
    // useSelector((s: AppState) => s.auth.permissions.join(""))
    return <A visible_by={props.visible_by} user_permissions={["admin"]}>
        {props.children}
    </A>

}


export function TypeAuthorized(props: {children: ReactNode, user_types: string[]}) {
    const userType = window.sessionStorage.getItem("user_type")?.toString() ?? ""
    if(props.user_types.includes(userType)) return <> {props.children} </>
    else return <></>
}

/*
Checks if user_permission has at least one element in visible by
*/
function A(props: {children: ReactNode, user_permissions: Permission[], visible_by: Permission[]}) {
    const condition = props.visible_by.reduce((p, v) => p || props.user_permissions.includes(v) , false)

    if(condition) {
        return <>{props.children}</>;
    } else {
        return <></>
    }
}
    
