import {Attributes, Meter} from "../models/meter";
import {get, post} from "../repsitory/generic_repository"
import {Group} from "../models/group";

export async function fetchMeter(id: number): Promise<Meter | string> {

  try {
    const meterAndGroups = await get(`/api/v1/meter/${id}`);

    const groups: Group[] = meterAndGroups.included;
    const meter: Meter = {...meterAndGroups.data, groups: groups};

    return meter

  } catch (e) {
    return e.toString();
  }

}