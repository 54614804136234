import { IconButton } from "@material-ui/core"
import React, { useEffect, useMemo, useState } from "react"
import { DropdownIndicator } from "react-select/src/components/indicators"
import styled from "styled-components"
import { FilterParam } from "../../models/barbagli/filter_order"
import { ClearIcon } from "../icons"
import { FlatButton } from "./common"
import { Collapsed, Expanded, Search } from "./icons"
import {BehaviorSubject} from "@reactivex/rxjs/dist/package";
import {debounceTime} from 'rxjs/operators';


type Props = {
    avaliableFilters: string[]
    filterNamePostProcessor: (fn: string) => string
    onFiltersChange: (fs: FilterParam[]) => void
    filters: FilterParam[]
}

export function SearchBar(props: Props) {
    const [expanded, setExpanded] = useState(false);
    const [state, setState] = useState(props.filters);
    const subject = useMemo(() => new BehaviorSubject<FilterParam[]>(props.filters), [0])
    useEffect(() => {
            // @ts-ignore
        const s = subject.asObservable().pipe(debounceTime(props.debounceTimeMs ?? 300)).subscribe((v: FilterParam[]) => {
        if(v) {
          props.onFiltersChange(v);
        }})
        return () => s.unsubscribe();
    })

    return <SearchBarContainer onClick={() => {
        console.log("aaa")
        if(state.length === 0 && props.avaliableFilters.length > 0) {
            const nf = {value: "", column: props.avaliableFilters[0]}
            setState([...state, nf]);
            props.onFiltersChange([...state, nf]);
        }
    }}>
        <SearchBarChipContainer >
        {
            state.map((fp, i) => 
                <EditableChip 
                key={fp.column} 
                prefix={props.filterNamePostProcessor(fp.column)}
                 value={fp.value}
                 onEdit={(v) => {
                     const fc : FilterParam[] = JSON.parse(JSON.stringify(state));
                     fc[i] = {...fp, value: v}
                     setState(fc);
                     subject.next(fc);
                 }}
                 onDelete={
                     () => {
                        const fc = JSON.parse(JSON.stringify(state));
                        const newState = [...(fc.filter((fi: FilterParam) => fi.column !== fp.column))];
                        setState(newState);
                        subject.next(newState);
                     }
                 } 
                 />
            )
        }
        </SearchBarChipContainer>
        <SearchBarButtonContainer>
            <IconButton onClick={(e) => {
                setExpanded(!expanded);
                e.stopPropagation();
                }}><Collapsed/></IconButton>
            <Search/>
        </SearchBarButtonContainer>
        {expanded && 
        <ChildSelectionPanel>
                            {
                    props.avaliableFilters.filter((f) => !state.map(f => f.column).includes(f)).map((wt) => <FlatButton key={wt} onClick={
                        (e) => {
                            e.stopPropagation();
                            setState([...state, {column: wt, value: ""}]);
                            props.onFiltersChange([...state, {column: wt, value: ""}]);
                            setExpanded(false);
                        }
                    }>{props.filterNamePostProcessor(wt)}</FlatButton>)
                }
        </ChildSelectionPanel>}
    </SearchBarContainer>
}

const SearchBarContainer= styled.div`
display: flex;
position: relative;
min-height: 40px;
align-items: center;
justify-content: space-between;
border: 1px solid #666;
border-radius: 6px;
margin-bottom: 8px;
margin-top: 8px;
`

const ChildSelectionPanel = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 30px;
    z-index: 2;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    border-radius: 6px;
    right: 46px;
`;

const SearchBarChipContainer = styled.div`
display: flex;
flex-wrap: wrap;
`

const SearchBarButtonContainer = styled.div`
display: flex;
justify-content: space-between;
width: 60px;
align-items: center;
margin-right: 8px;
`



export function EditableChip(props: {prefix: string, value: string, onEdit: (s: string) => void, onDelete: () => void}) {
    return <EditableChipContainer>
        <EditableChipPrefix>{props.prefix}: </EditableChipPrefix>
        <EditableChipInput autoFocus size={(props?.value ?? "").length > 0 ? props.value.length : 5}  onChange={(e) => props.onEdit(e.target.value)} value={props.value}></EditableChipInput>
        <IconButton onClick={props.onDelete} style={{height: 20, width: 20, padding: 0}}><ClearIcon></ClearIcon></IconButton>
    </EditableChipContainer>
}

const EditableChipContainer = styled.div`
display: flex;
align-items: center;
padding-left: 8px;
padding-right: 12px;
margin: 4px;
padding-top: 0;
padding-bottom: 0; 
background-color: #AAA;
border-radius: 100px;
`

const EditableChipInput = styled.input`
    background-color: transparent;
    border: none;
    color: #666;
    &:focus {
        border-bottom: 1px solid #666;
    }
`

const EditableChipPrefix = styled.h3`
text-transform: uppercase;
margin-top: 10px;
margin-right: 8px;
line-height: 14px;

`