import {WidgetConfig} from "../../../models/dashboard/widgetConfig";
import {Dashboard} from "../../../models/dashboard/dashboard";
import React from "react";
import {TitleValueWidget} from "./widgets/common/title_value_widget";
import {StatConfig, StatConfigType} from "../../../models/stat_config";
import {NumericTypeWidget} from "./widgets/numeric_type_widget/numeric_type_widget";
import {Dispatch} from "redux";
import {subscribeToAction} from "../../../store/actions/logs_actions";
import {AppState} from "../../../store/store";
import {TimeSeriesChartWidget} from "./widgets/time_series_chart_widget/time_series_chart_widget";
import _ from "lodash";
import {FilterCollection} from "../../../models/filter";
import {
  reducedValueQueryUrl
} from "../../data_explorer_page/reduce_filter_vm";
import {NumericTypeDEXWidget} from "./widgets/numeric_type_dex_widget/numeric_type_dex_widget";
import {DEXChartWidget} from "./widgets/numeric_type_dex_chart_widget/numeric_type_dex_chart_widget";

// section  Showing filters on dashboard

//  FilterCollection[] -> StatConfig[]
export function statConfigsFromFilterCollection(fs: FilterCollection[]) : StatConfig[] {
  const validFs = fs.filter(fs => fs.reducer !== null);

  return validFs.map((f) => {
    const reducer = f.reducer!;
    return ({
      target: {
        pollingUrl: reducedValueQueryUrl(f)
      },
      config: reducer.type === "FILTER_COLLECTION_CHART_REDUCER" ? {x_column_name: reducer.x_column_name,y_column_name: reducer.y_column_name} : undefined,
      name: `${f.name} (DEX)`,
      type: f.reducer!.type === "FILTER_COLLECTION_REDUCER" ? "filter" : "chart-filter",
      id: -f.id
    });
  });

}

//  StatConfig -> WidgetConfig
function mkWidgetFromStatConfig(statConfig: StatConfig): WidgetConfig {

    switch (statConfig.type) {
      case "chart": return {
        config: {},
        data_grid: {h: 2, maxH: 4, maxW: 4, minH: 2, minW: 4, w: 4, x: 2, y: 0},
        key: (new Date()).getTime().toString(),
        type: statConfig.type,
        source: {
          type: "WEBSOCKET",
          channelName: statConfig.target.websocket!,
          eventName: statConfig.name,
        },
        name: statConfig.name
      };
      case "filter": return {
        config: {},
        data_grid: {h: 1, maxH: 4, maxW: 4, minH: 1, minW: 2, w: 2, x: 2, y: 0},
        key: (new Date()).getTime().toString(),
        type: statConfig.type,
        source: {
          type: "POLLING",
          pollingUrl: statConfig.target.pollingUrl!
        },
        name: statConfig.name
      };
      case "chart-filter": return {
        config: statConfig.config,
        data_grid: {h: 2, maxH: 4, maxW: 4, minH: 2, minW: 4, w: 4, x: 2, y: 0},
        key: (new Date()).getTime().toString(),
        type: statConfig.type,
        source: {
          type: "POLLING",
          pollingUrl: statConfig.target.pollingUrl!
        },
        name: statConfig.name
      };
      default: return {
        config: {},
        data_grid: {h: 1, maxH: 4, maxW: 4, minH: 1, minW: 2, w: 2, x: 2, y: 0},
        key: (new Date()).getTime().toString(),
        type: statConfig.type,
        source: {
          type: "WEBSOCKET",
          channelName: statConfig.target.websocket!,
          eventName: statConfig.name,
        },
        name: statConfig.name
      }
    }
}

//  WidgetConfig -> Widget
export const buildWidget = (w: WidgetConfig, dispatch: Dispatch<any>, appState: AppState, intlMessages: Record<string, any>, dexStatConfigs: StatConfig[]) => {

  if(w.source === undefined) return TitleValueWidget({title: "Error, this widget is not compatible with this application", value: ""})
  if(w.source.type === "POLLING") {
    const expiredMessage = dexStatConfigs.filter((d) => d.name === w.name).length === 0;
    const widget = {...w, name: expiredMessage ? `${w.name} (Expired)` : w.name}
    switch (w.type) {
      case "filter": return NumericTypeDEXWidget({intlMessages: intlMessages, widget})
      default: return DEXChartWidget({intlMessages, widget, x_column_name: w.config.x_column_name, y_column_name: w.config.y_column_name})
    }
  }


  dispatch(subscribeToAction(w.source.channelName, w.source.eventName))
  switch (w.type) {
    case "numeric_value":
      return NumericTypeWidget({widget: w, logs: appState.logs.items}, intlMessages)
    case "chart":
      return TimeSeriesChartWidget({widget: w, logs: appState.logs.items}, intlMessages)
    default : {
      return TitleValueWidget({title: "--", value: "--"})
    }
  }
}

export function addWidgetToDashboard(statConfig: StatConfig, dashboard: Dashboard, setDashboard: React.Dispatch<React.SetStateAction<Dashboard>>) {

  const widgetToAdd = mkWidgetFromStatConfig(statConfig);

  try{

    const m =  getDashboardTopology(dashboard.widgets);
    // console.log(m)
    const coords = getFirstFreeCoords(widgetToAdd.data_grid.h, widgetToAdd.data_grid.w, m);
    // console.log(coords)
    setDashboard({...dashboard, widgets: [ {...widgetToAdd, data_grid: {...widgetToAdd.data_grid, x: coords![0], y:coords![1] }} ,...dashboard.widgets]})
  } catch (e) {
    setDashboard({...dashboard, widgets: [ widgetToAdd ,...dashboard.widgets]})
  }

}

export function removeWidgetToDashboard(widgetKey: string, dashboard: Dashboard, setDashboard: React.Dispatch<React.SetStateAction<Dashboard>>) {
    setDashboard({...dashboard, widgets: dashboard.widgets.filter((w) => w.key !== widgetKey)})
}



function getDashboardTopology(widgets: WidgetConfig[], maxWidth: number = 10): number[][] {

  let maxY: number = widgets.length > 1
    ? widgets.map((a) => a.data_grid.y + a.data_grid.h).reduce((a, b) => Math.max(a, b))
    : 12

  let m: number[][] = _.range(0, maxY)
    .map((__) => _.range(0, maxWidth).map((n) => 0));



  for(let w of widgets) {
    for(let x = w.data_grid.x; x < w.data_grid.x + w.data_grid.w; x++)
    for(let y = w.data_grid.y; y < w.data_grid.y + w.data_grid.h; y++)
    m[y][x] = 1;
  }
  return m;
}


function getFirstFreeCoords(h: number, w: number, m: number[][]) {
  for(let y1 = 0; y1 < m.length; y1++) {
    for(let x1 = 0; x1 < m[0].length; x1++) {
      if(fits([x1, y1], w, h, m)) return [x1, y1]
    }
  }
  return [0, m.length];
}

function is_available(x: number, y: number, m: number[][]) {
  try {
    return m[y][x] === 0;
  } catch (e) {
    return y >= m.length;

  }
}

function fits(origin: [number, number], w: number, h: number, m: number[][]) {
  const [x, y] = origin;

  for(let y1 = y; y1 < y + h; y1++) {
    for(let x1 = x; x1 < x + w; x1++) {
      if(!is_available(x1, y1, m)) return false;
    }
  }
  return true;
}

