import { from } from "@reactivex/rxjs/dist/package";
import React from "react";
import { useIntl } from "react-intl";
import { H4, InformativeText, Title } from "../../../components/barbagli/text";
import { Condominium, Immobile, SubCondominium } from "../../../models/barbagli/condominium";
import { nodeForId } from "../condominium/condominium_view_model";
import {history} from "../../../index";
import { FlatButton } from "../../../components/barbagli/common";


type Props = {
    condominium: Condominium,
    subCondominiumId?: string,
    immobileId?: string,
}

export function MeterCondominiumView(props: Props) {
    const intl = useIntl();
    const subCondominium = props.subCondominiumId ? nodeForId(props.condominium, props.subCondominiumId) : undefined;
    const immobile = props.immobileId ? nodeForId(props.condominium, props.immobileId) : undefined;
    return <>
        <div className="row">
            <div className="col-md-12 mb-4">
                <Title>{intl.messages["condominium"]}</Title>
            </div>
        </div>
        <div className="row">
          { props.condominium?.data?.cond_name  && <div className="col-md-4">
           <H4>{intl.messages["condominium"]}</H4>
            <FlatButton onClick={() => {
                history.push(`/condominiums/${props.condominium?.data?.cond_name ?? props.condominium.id}`, props.condominium);
            }}>{props.condominium.data.cond_name}</FlatButton>
          </div> }
          {
              subCondominium && (subCondominium as SubCondominium).data.name && <div className="col-md-4">
                  <InformativeText title={intl.messages["subcondominium"].toString()}>
                  <FlatButton onClick={() => {
                history.push(`/condominiums/${props.condominium?.data?.cond_name ?? props.condominium.id}/node_id/${props.subCondominiumId}`, props.condominium);}}>
                {(subCondominium as SubCondominium).data.name}
                </FlatButton>
                  </InformativeText>
              </div>
          }
          {
              immobile && (immobile as Immobile).data.name && <div className="col-md-4">
                  <InformativeText title={intl.messages["immobile"].toString()}>
                  <FlatButton onClick={() => {
                history.push(`/condominiums/${props.condominium?.data?.cond_name ?? props.condominium.id}/node_id/${props.immobileId}`, props.condominium);}}>
                    {(immobile as Immobile).data.name}</FlatButton>
                  </InformativeText>
              </div>
          }
        </div>

        {
            immobile && <div className="row mt-4 mb-3">
              <div className="col-md-4">
                  <InformativeText title={intl.messages["owner"].toString()}>
                    {`${(immobile as Immobile).owner.firstName} ${(immobile as Immobile).owner.lastName}`.replaceAll("null", "--")}
                  </InformativeText>
              </div>
              <div className="col-md-4">
                  <InformativeText title={intl.messages["tenant"].toString()}>
                    {`${(immobile as Immobile).tenant.firstName} ${(immobile as Immobile).tenant.lastName}`.replaceAll("null", "--")}
                  </InformativeText>
              </div>
              <div className="col-md-4">
                  <InformativeText title={intl.messages["location"].toString()}>
                    {`${(immobile as Immobile).data.location}`.replaceAll("null", "--")}
                  </InformativeText>
              </div>
            </div>
        }

    </>
}