import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";
import { Provider, useSelector, useStore } from "react-redux";
import store from "./store/store";
import { Route, Router, Switch } from "react-router-dom";
import { Layout } from "./components/layout";

import { menuRoutes } from "./models/menu_item_type";
import { I18n } from "./components/I18n";
import { DashboardsIndexPage } from "./pages/dashboard_page/dashboards_index_page/dashboards_index_page";
import { LoginPage } from "./pages/login_page/login_page";
import { CondominiumIndex } from "./pages/barbagli/condominium/condominium_index";
import { CondominiumView } from "./pages/barbagli/condominium/condominium";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { MetersIndex } from "./pages/barbagli/meters_index/meters_index";
import { MeterView } from "./pages/barbagli/meters_page/meter_view";
import { GatewayView } from "./pages/barbagli/gateways_page/gateway_view";
import { GatewaysIndex } from "./pages/barbagli/gateways_index/gateways_index";
import SummaryIndex from "components/Summary/SummaryIndex";
import { ApiTester } from "./pages/barbagli/api-tester/api_tester";
// @ts-ignore
import AlertTemplate from "react-alert-template-basic";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";
import { OwnerConfigurationPage } from "./pages/admin_panel/admin_owner_config_page";
import { ErrorMappingPage } from "./pages/barbagli/error_mapping/error_mapping_page";
import { AlarmsIndex } from "./pages/barbagli/alarms_index/alarms_index";
import { AlarmDetailPage } from "./pages/barbagli/alarms_index/alarm_detail_page";
import { MetersAlarmsIndex } from "./pages/barbagli/alarms_index/meters_alarms_index";
import { MetersAnomaliesIndex } from "./pages/barbagli/anomaly_index/meters_anomaly_index";
import { UsersPage } from "./pages/users_page/users_page";
import { CreateEditUserPage } from "./pages/users_page/create_edit_user_page";
import { BarbagliUsersPage } from "./pages/barbagli/users_page/users_page";
import { CreateEditBarbagliUserPage } from "./pages/barbagli/users_page/create_edit_user_page";
import { CreateEditBarbagliUserGroupPage } from "./pages/barbagli/user_groups_page/create_edit_group_page";
import { BarbagliUsersGroupPage } from "./pages/barbagli/user_groups_page/users_group_page";
import {
  Company,
  CompanyDetailPage,
} from "./pages/barbagli/companies_index/company_detail_page";
import { CompaniesIndex } from "./pages/barbagli/companies_index/companies_index";
import { post, put } from "./repsitory/generic_repository";
import { PartnerDetailPage } from "pages/barbagli/partner/partner_edit";
import { ParnterIndex } from "pages/barbagli/partner/partner_index";
import { InstallerIndex } from "pages/barbagli/installer/installer_index";
import { InstallerDetailPage } from "pages/barbagli/installer/installer_edit";
import { MndUserDetailPage } from "pages/barbagli/my_net_domus_user/my_net_domus_user_edit";
import { MyNetDomusUserIndex } from "pages/barbagli/my_net_domus_user/my_net_domus_user_index";
import { DashboardDetailPage } from "pages/barbagli/dashboard_detail_page/dashboard_detail_page";
import { MagliaIdricaIndex } from "pages/barbagli/maglia_idrica/maglia_idrica_index";
import { MagliaIdricaShow } from "pages/barbagli/maglia_idrica/maglia_idrica_show";
import packageJson from "../package.json";
import { MagliaIdricaNew } from "pages/barbagli/maglia_idrica/maglia_idrica_new";

export const alert_options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1000,
  },
};

export const history = createBrowserHistory();

//          export const BASE_URL = "https://swarm-api.inkwelldata.net";

// export const BASE_URL = "https://swarm-api.mynetdomus.cloud";
// export const APP_BASE_URL = "http://192.168.1.94:3000";
// export const WEB_SOCKET_ADDRESS = "wss://swarm-api.mynetdomus.cloud/socket";
// console.log(process.env.NODE_ENV)
// console.log(process.env)
// debugger
// export const BASE_URL = process.env.NODE_ENV !== 'production'
//  ? "http://192.168.1.94:4002"
//  : "https://swarm-api.mynetdomus.cloud";

// export const APP_BASE_URL = process.env.NODE_ENV !== 'production'
//  ? "http://192.168.1.94:3000"
//  : "https://swarm.mynetdomus.cloud";

// export const WEB_SOCKET_ADDRESS = process.env.NODE_ENV !== 'production'
//  ? "ws://192.168.1.94:4002/socket"
//  : "wss://swarm-api.mynetdomus.cloud/socket";

export const BASE_URL = "https://swarm-api.mynetdomus.cloud";
export const APP_BASE_URL = "https://swarm.mynetdomus.cloud";
export const WEB_SOCKET_ADDRESS = "wss://swarm-api.mynetdomus.cloud/socket";
/* export const BASE_URL = "http://192.168.1.94:4002";
export const APP_BASE_URL = "http://192.168.1.39:3001";
export const WEB_SOCKET_ADDRESS = "ws://192.168.1.94:4002/socket"; */

export const VERSION = packageJson.version;

export const SIMULATED_ALTIOR = false;

export const fallbackMapCenter = { lat: 43.7874, lng: 11.2499 };

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alert_options}>
        <I18n>
          <Router history={history}>
            <Switch>
              {/* <Route path={"/api"}>
                <ApiTester />
              </Route> */}
              <Route path={"/dashboards"}>
                <Layout selectedItem={"Dashboards"} maxLength={2}>
                  <DashboardsIndexPage />
                </Layout>
              </Route>

              <Route path={menuRoutes["Network"] + "/:id"}>
                <Layout selectedItem={"Network"} maxLength={2}>
                  <GatewayView />
                </Layout>
              </Route>
              <Route path={menuRoutes["Network"]}>
                <Layout selectedItem={"Network"}>
                  <GatewaysIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Meters"] + "/:id/tab/:tab"}>
                <Layout selectedItem={"Meters"} maxLength={2}>
                  <MeterView />
                </Layout>
              </Route>
              <Route path={menuRoutes["Meters"] + "/:id"}>
                <Layout selectedItem={"Meters"} maxLength={2}>
                  <MeterView />
                </Layout>
              </Route>
              <Route path={menuRoutes["Meters"]}>
                <Layout selectedItem={"Meters"}>
                  <MetersIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Condominium"] + "/:id/node_id/:node_id"}>
                <Layout selectedItem={"Condominium"} maxLength={2}>
                  <CondominiumView />
                </Layout>
              </Route>
              <Route path={menuRoutes["Condominium"] + "/:id"}>
                <Layout selectedItem={"Condominium"} maxLength={2}>
                  <CondominiumView />
                </Layout>
              </Route>
              <Route path={menuRoutes["Condominium"]}>
                <Layout selectedItem={"Condominium"}>
                  <CondominiumIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["summary"]}>
                <Layout selectedItem={"summary"}>
                  <SummaryIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Dashboards"]}>
                <Layout selectedItem={"Dashboards"}>
                  <DashboardsIndexPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Alarms"] + "/:id"}>
                <Layout selectedItem={"Alarms"}>
                  <AlarmDetailPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Alarms"]}>
                <Layout selectedItem={"Alarms"}>
                  <MetersAlarmsIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Maglia Idrica"] + "/new"}>
                <Layout selectedItem={"Maglia Idrica"}>
                  <MagliaIdricaNew />
                </Layout>
              </Route>
              <Route path={menuRoutes["Maglia Idrica"] + "/:id"}>
                <Layout selectedItem={"Maglia Idrica"}>
                  <MagliaIdricaShow />
                </Layout>
              </Route>
              <Route path={menuRoutes["Maglia Idrica"]}>
                <Layout selectedItem={"Maglia Idrica"}>
                  <MagliaIdricaIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Users"] + "/:id"}>
                <Layout selectedItem={"Users"}>
                  <CreateEditBarbagliUserPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Users"]}>
                <Layout selectedItem={"Users"}>
                  <BarbagliUsersPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Installer"] + "/:id"}>
                <Layout selectedItem={"Installer"}>
                  <InstallerDetailPage
                    validationKeys={["username", "companies", "email"]}
                  />
                </Layout>
              </Route>
              <Route path={menuRoutes["Installer"]}>
                <Layout selectedItem={"Installer"}>
                  <InstallerIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["My Net Domus User"] + "/:id"}>
                <Layout selectedItem={"My Net Domus User"}>
                  <MndUserDetailPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["My Net Domus User"]}>
                <Layout selectedItem={"My Net Domus User"}>
                  <MyNetDomusUserIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Companies"] + "/:id"}>
                <Layout selectedItem={"Companies"}>
                  <CompanyDetailPage
                    validationKeys={[
                      "vat",
                      "prov",
                      "name",
                      "code",
                      "city",
                      "cap",
                      "address",
                    ]}
                  />
                </Layout>
              </Route>
              <Route path={menuRoutes["Companies"]}>
                <Layout selectedItem={"Companies"}>
                  <CompaniesIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Partner"] + "/:id"}>
                <Layout selectedItem={"Partner"}>
                  <PartnerDetailPage
                    validationKeys={[
                      "address",
                      "cap",
                      "city",
                      "code",
                      "companyName",
                      "prov",
                      "vat",
                    ]}
                  />
                </Layout>
              </Route>
              <Route path={menuRoutes["Partner"]}>
                <Layout selectedItem={"Partner"}>
                  <ParnterIndex />
                </Layout>
              </Route>
              <Route path={menuRoutes["Groups"] + "/:id/edit"}>
                <Layout selectedItem={"Groups"}>
                  <CreateEditBarbagliUserGroupPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Groups"] + "/:id"}>
                <Layout selectedItem={"Groups"}>
                  <CreateEditBarbagliUserGroupPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Groups"]}>
                <Layout selectedItem={"Groups"}>
                  <BarbagliUsersGroupPage />
                </Layout>
              </Route>
              <Route path={menuRoutes["Anomalies"]}>
                <Layout selectedItem={"Anomalies"}>
                  <MetersAnomaliesIndex />
                </Layout>
              </Route>

              <Route path={menuRoutes["ErrorMapping"]}>
                <Layout selectedItem={"ErrorMapping"}>
                  <ErrorMappingPage />
                </Layout>
              </Route>
              <Route path={"/admin/owner-config"}>
                <Layout selectedItem={"Admin"}>
                  <OwnerConfigurationPage />
                </Layout>
              </Route>
              <Route path={"/login"}>
                <LoginPage />
              </Route>
              <Route path={"/login/:token"}>
                <LoginPage />
              </Route>
              <Route>
                <LoginPage />
                {
                  //<PlayGround />
                }
                {/*<CustomizedTreeView items={demoMap}/>*/}
              </Route>
            </Switch>
          </Router>
        </I18n>
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

//  TODO enable in production to handle cache
serviceWorker.unregister();
