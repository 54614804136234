
import { Reading } from "../../models/barbagli/reading";
import { httpDelete, post, postRaw, put, remoteFetchAttributes } from "../generic_repository";



export async function fetchReading(id: number | string): Promise<Reading | string> {
    try {
        return fromJson(await remoteFetchAttributes<any>(`/api/v1/reading/${id}`));
    } catch (e) {
        return e.toString();
    }
}

export async function createReading(reading: Reading): Promise<Reading | string> {
    const j = toJson(reading);
    try {
        const r = await post(j, `/api/v1/reading`);
        if (r.errors != undefined) return JSON.stringify(r.errors);
        if (typeof r !== "string") return fromJson(r.data.attributes);
        else return r;
    } catch (e) {
        console.error(e);
        return e.toString();
    }
}

export async function createSelfReading(reading: Reading): Promise<Reading | string>  {

    const url = `/api/v1/meter/${reading.meter_identifier}/self-reading`;

    const j = toJson(reading);
    try {
        const r = await post(j, url);
        if (r.errors != undefined) return JSON.stringify(r.errors);
        if (typeof r !== "string") return r;
        else return r;
    } catch (e) {
        console.error(e);
        return e.toString();
    }

}

export async function deleteReading(id: number): Promise<string | true> {
    try {
        const r = await httpDelete(`/api/v1/reading/${id}`);
        if (typeof r === "string") return r;
        return true;
    } catch (e) {
        console.error(e);
        return e.toString();
    }
}

function toJson(c: Reading): any {
    return {
        "data": {
            "type": "reading",
            "attributes": {
                ...c,
                
            }
        }
    }
}


export function fromJson(json: any): Reading | string {
    return json
}
