import {PaginatedListComponent} from "./paginated_list_component";
import {PaginatedList} from "./paginated_sublist";
import React, {ReactNode, useEffect, useLayoutEffect, useState} from "react";
import {permute, removeIds} from "../../utils/arrays";
import {formatObject} from "../../utils/object_formatting";
import CSS from 'csstype';
import { Column } from "../barbagli/common";
import { useIntl } from "react-intl";

type Props<T, U> = {
  onTap?: (t: T) => void,
  perPage?: number,
  fetchCollection : (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string>
  initialPage?: number,
  showPaginationElement?: boolean;
  columnPermutations?: number[]
  visibleColumns?: boolean[]
  onHeaders?: (headers: string[]) => void,
  itemsPreFormatter?: (t: T) => U,
  headerBuilder?: (s: string) => React.ReactNode,
  rowStyle?: (t: T) => CSS.Properties;
  reload?: boolean;
}


export function DynamicPaginatedListComponent<T, U>(props: Props<T, U>) {

  const [headers, setHeaders] = useState<string[]>([]);
  useLayoutEffect(() => props.onHeaders && props.onHeaders(headers), [headers]);
  const intl = useIntl();



  let permutation = props.columnPermutations ?? headers.map((_, i) => i);
  if(permutation.length !== headers.length) permutation = headers.map((_, i) => i);

  let hiddenColumns = props.visibleColumns?.map((v, i) => {
    return { v, i: permutation.indexOf(i) }
  }).filter(({v}) => !v).map(({i}) => i);



  return <PaginatedListComponent
    headers={removeIds(permute(headers, permutation), hiddenColumns ?? [])}
    rowStyle={props.rowStyle}
    reload={props.reload ?? false}
    rowBuilder={(item: any) => {
      const itemMapped = props.itemsPreFormatter ? props.itemsPreFormatter(item) : item;
      const newHeaders = Object.keys(itemMapped);
      if(JSON.stringify(headers) !== JSON.stringify(newHeaders)) setHeaders(newHeaders);
      const map = removeIds(permute(Object.values(itemMapped).map((a) => formatBody(a, intl.messages as any)), permutation), hiddenColumns ?? []);
      return map;
    }}
    onTap={props.onTap}
    perPage={props.perPage}
    showPaginationElement={props.showPaginationElement}
    {...props}
  />

}


export function formatBody(a: any, intl: any): ReactNode {
  if(a === undefined || a === null) return <p>--</p>
  if(a === true) return <p>true</p>
  if(a === false) return <p>false</p>
  if(typeof a == "string" || typeof a == "number") return <p>{formatObject(a)}</p>

  if(Array.isArray(a)) {
    if(a.length == 0) return <p></p>
    const [b, ...c] = a;
    return <p key={JSON.stringify(a[0])}>{formatBody(b, intl)} {formatBody(c, intl)}</p>
  }

  try {
    const keys = Object.keys(a);
    return <Column>
      {keys.map((k) => {
        // console.log(k)
        return  <p key={JSON.stringify(a[k])}>{intl[k] ?? k}: {formatBody(a[k], intl)}</p>
      })}
    </Column>
  } catch(e) {
    return <p>{formatObject(a)}</p>
  }
}

export function formatHeader(header: string) {
  return header.replace("_", " ").toUpperCase();
}

