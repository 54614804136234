import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { CondominiumTab } from "../../models/barbagli/condominium"
import { gray, lightGray, blue } from "../../utils/colors"
import { first } from "../../utils/deep_copy"

type Props = {
    children: [string, React.ReactElement][]
    onTabChange?: (tab: CondominiumTab) => void;
    selectedTab?: CondominiumTab
}

const Tab = styled.button`
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${(_) => blue};
    height: 32px;
    border: none;
    transition: all 0.3s ease-in-out;
    &:active {
        background-color: ${(_) => lightGray};
    }
`
const TabValue = styled.p<{selected: boolean}>`
    font-weight: ${(props) => props.selected ? 900 : 400};
    color: white;
    text-transform: capitalize;
`

const ButtonPanel = styled.div`
`
const TabViewContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const TabViewHeaderContainer = styled.div`
display: flex;
flex-direction: row;
background-color: ${(_) => blue};
`

const TabViewContentContainer = styled.div`
    padding: 1rem;
`


export function TopTabView(props: Props) {
    const intl = useIntl();
    const [selectedIndex, setSekectedIndex] = useState(0)
    const limitedIndex = selectedIndex > props.children.length - 1 ? 0 : selectedIndex;

    //  Select the fist tab if selected tab is not present 
    useEffect(() => {
        const tabs = props.children.map(([t, _]) => t.toLowerCase());
        if(props.selectedTab && props.onTabChange && tabs.indexOf(props.selectedTab.toLowerCase()) == -1 && tabs.length > 0) {
            props.onTabChange(props.children[0][0] as CondominiumTab)
        }
    }, [props.selectedTab, props.children])

    if(props.onTabChange && props.selectedTab) 
    // @ts-ignore
    return <StatelessTopTabView {...props} intl={intl.messages as any}/>




    
    return <TabViewContainer>
        <TabViewHeaderContainer>
        { props.children.map(([title, _], i) => <Tab key={title} onClick={() => {
            setSekectedIndex(i);
            props.onTabChange && props.onTabChange(title as CondominiumTab)
        }}><TabValue selected={i == limitedIndex}>{intl.messages[title] ?? title}</TabValue></Tab>) }
        </TabViewHeaderContainer>
        
        <TabViewContentContainer>
            {props.children[limitedIndex][1]}
        </TabViewContentContainer>
        
    </TabViewContainer>
}

export function StatelessTopTabView(props: {
    children: [string, React.ReactElement][]
    onTabChange: (tab: CondominiumTab) => void;
    selectedTab: CondominiumTab
    intl: Record<string, string>
}) {

    const tabs = props.children.map(([t, _]) => t);

    return <TabViewContainer>
    <TabViewHeaderContainer>
    { props.children.map(([title, _], i) => <Tab key={title} onClick={() => {
        props.onTabChange(title as CondominiumTab)
    }}><TabValue selected={title == props.selectedTab}>{props.intl[title] ?? title}</TabValue></Tab>) }
    </TabViewHeaderContainer>
    
    <TabViewContentContainer>
        {props.children[Math.max(0, tabs.indexOf(props.selectedTab))][1]}
    </TabViewContentContainer>
    
</TabViewContainer>
}