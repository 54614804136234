import {Command, ConfigurationValue} from "../../models/configuration";
import {post, postRaw, remoteFetchData} from "../../repsitory/generic_repository";


export async function fetchCommands(deviceTypeId: number | string): Promise<Command[] | string> {
  const result = await remoteFetchData<any[]>(`/api/v1/commands/${deviceTypeId}`);
  if(typeof result === "string" || result === undefined) return result;

  return result.map((item: any) => {
    return ({
      name: item.attributes.name,
      params: [{name: item.attributes.name, type: item.attributes.valueType}]
    });
  });

}


export const execCommand = (deviceTypeId: number | string, deviceIdentifier: string) => async (command: Command, paramValues: ConfigurationValue[]) => {

  const data = {
    data: {
      device_identifier: deviceIdentifier,
      command_name: command.name,
      command_param: paramValues[0]?.value ?? null
      }
  }

  const result = await postRaw(data, `/api/v1/commands/${deviceTypeId}`)
  if(result.status !== 200) return result.body;

}


