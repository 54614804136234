import { Condominium, CondominiumNode } from "../../../../models/barbagli/condominium";
import { Gateway } from "../../../../models/gateway";
import { associateCondominiumGateway } from "../../../../repsitory/barbagli/condominium_repository";
import { updateGateway } from "../../../../repsitory/barbagli/gateway_repository";
import { condominiumSubCondominiumAndImmobile } from "../meter_gateways_association_view_model";




export async function associateGatewayToCondominiumNode(node: CondominiumNode, condominium: Condominium, gateway: Gateway): Promise<string | true> {
    // console.log(gateway)
    if(!gateway.id) return "gateway has not id";
    const data = condominiumSubCondominiumAndImmobile(node, condominium)
    // console.log(data);
    return associateCondominiumGateway(gateway.id!, condominium.id!, data?.subCondominiumId, data?.immobileId);
} 