import { uniqueId } from "lodash"
import { Log } from "models/log"
import React, { useEffect, useReducer } from "react"
import { useDispatch, useSelector } from "react-redux";
import { postFormData } from "repsitory/generic_repository";
import { subscribeToAction } from "store/actions/logs_actions";
import { AppState } from "store/store";
import { formatObject } from "utils/object_formatting";
import {v4 as uuidv4, v4} from 'uuid';

export type FileUploaderState = {
    file?: File,
    channel?: string,
    event_name?: string,
    logs: Log[],
    loading: boolean,
    webSocketPanelEnabled: boolean,
    api_result: string,
}

export type FileUploaderAction = 
| {type: "set_file", value: File}
| {type: "set_channel", value: string}
| {type: "set_event_name", value: string}
| {type: "set_logs", value: Log[]}
| {type: "set_loading", value: boolean}
| {type: "set_api_result", value: string}

function reducer(state: FileUploaderState, action: FileUploaderAction): FileUploaderState {

    switch(action.type) {
        case "set_file": return {...state, file: action.value}
        case "set_channel": return {...state, channel: action.value}
        case "set_event_name": return {...state, event_name:  action.value}
        case "set_logs": return {...state, logs: action.value}
        case "set_loading": return {...state, loading: action.value}
        case "set_api_result": return {...state, api_result: action.value}
    }
    return state
}



export function useFileUploaderState(webSocketEnabled: boolean, url: string): [FileUploaderState, React.Dispatch<FileUploaderAction>, (f: File) => Promise<any>, any] {
    const initialState: FileUploaderState = {logs: [], loading: false, webSocketPanelEnabled: webSocketEnabled, channel: "condominium_importer", api_result:""}
    const [state, dispatch] = useReducer(reducer, initialState)

    const logs = useSelector((a: AppState) => a.logs.items.filter((l) => l.channel === state.channel && l.eventName === state.event_name));  
    const dispatch2 = useDispatch();

    useEffect(() => {
        if(state.event_name && state.channel) {
            dispatch2(subscribeToAction(state.channel, state.event_name));
        }
    }, [state.event_name, state.channel])

    const f = async(file: File) => {
        const uuid = v4()
        dispatch({type: "set_loading", value: true})
        dispatch({type: "set_event_name", value: uuid})
        const r = await postImporterFile(file, uuid, url)
        dispatch({type: "set_loading", value: false})
        dispatch({type: "set_api_result", value: r})
    }

    return [state, dispatch, f, logs]
}

export function postImporterFile(file: File, uuid: string, url: string) {
    let formData = new FormData();
    formData.append('uuid', uuid); 
    formData.append('file', file);
    return postFormData(formData, url).then(r => r.json()).catch(e => e);
}