import { post, put } from "../generic_repository";
import { BlackList } from "../../models/barbagli/blacklist"

export async function createBlackList(blacklist: BlackList): Promise<true | string> {
    const j = toJson(blacklist);
    try {
        const r = await post(j, `/api/v1/blacklist`);
        if(r.errors != undefined) return JSON.stringify(r.errors);  
        if(typeof r !== "string") return true
        else return r;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}

export async function updateBlacklist(blacklist: BlackList & {id: number}): Promise<true | string> {
    const json = toJson(blacklist);
    try {
        const r = await put(json, `/api/v1/blacklist/${blacklist.id}`);
        if(r.errors != undefined)  return JSON.stringify(r.errors);
        if(typeof r !== "string") return true;
        else return r;
    } catch(e) {
        console.error(e);
        return e.toString();
    }
}

function toJson(c: BlackList): any {
    return {
        "data": {
            "type": "meterBlacklist",
            "attributes": {
                ...c
            }
        }
    }
}