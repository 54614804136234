import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useIntl} from 'react-intl';
import {confirmAlert} from "react-confirm-alert";
import _ from "lodash";
import { ErrorComponent } from '../../../components/error_component';
import { TrashIcon, MoreVerticalIcon } from '../../../components/icons';
import { PaginatedListComponent } from '../../../components/paginated_list/paginated_list_component';
import { UserGroup } from '../../../models/user_group';
import { fetchSinglePageCollection } from '../../../repsitory/generic_repository';
import { deleteGroup } from '../../../repsitory/groups_repository';
import {history} from '../../../index'

export function BarbagliUsersGroupPage() {
    const [error, setError] = useState("");
    const [initialPage, setInitialPage] = useState(0);
    const intl = useIntl();

    return (
      <div>
          <div className={"row"}>
              <div className={"col-md-12 ellipsis"}>
                  <PaginatedListComponent headers={
                      ["name", "description", "actions"]
                  } rowBuilder={
                      (u: UserGroup) => [
                          <p>{u.name}</p>,
                          <p>{_.first(u.additionalAttributes.description ?? []) ?? "--"}</p>,
                          <div style={{marginTop: "-6px"}} className={"d-flex"}>
                              <button className={"icon-button"} onClick={(e) => {
                                  e.stopPropagation();
                                  confirmAlert({
                                      title: intl.messages["confirm"].toString(),
                                      message: intl.messages["this_action_cant_be_undone"].toString(),
                                      buttons: [
                                          {
                                              label: 'Yes',
                                              onClick: async () => {
                                                  setError(await deleteGroup(u.id!));
                                                  setInitialPage(0);
                                                  setInitialPage(1);
                                              }
                                          },
                                          {
                                              label: 'No',
                                              onClick: () => {}
                                          }
                                      ]
                                  });

                              }}>
                                  <TrashIcon/>
                              </button>
                              <button className={"icon-button"}>
                                  <MoreVerticalIcon/>
                              </button>
                          </div>,
                      ]
                  }
                  fetchCollection={
                      fetchSinglePageCollection<UserGroup>("/api/v1/user/group")
                  }
                  onTap={ (ug: UserGroup) => history.push(`/groups/${ug.name}/edit`, ug) }
                  perPage={10000}
                  showPaginationElement={false}
                  initialPage={initialPage}
                  />
              </div>
          </div>
          { error !== "" && <ErrorComponent message={`Error: ${error}`}/> }
          <div className={"d-flex flex-row-reverse"}>
              <Link to={ "/groups/new" } style={{ textDecoration: 'none', color: '#fff' }}>
                  <div className={"p3 primary-button"}>
                      {intl.messages['add_group']}
                  </div>
              </Link>
          </div>
      </div>
    );
}