import React, { useState } from "react"
import { useIntl } from "react-intl";
import { PrimaryButton } from "components/barbagli/common";
import { SearchBar } from "components/barbagli/search_bar";
import { SortableTableView } from "components/barbagli/sortable_table_view";
import { FilterParam, OrderParam } from "models/barbagli/filter_order";
import { fetchPaginatedCollection } from "repsitory/generic_repository";
import { history } from "index";
import { deepCopy } from "utils/deep_copy";


type Props = {
    onClick?: (m: any) => void,
    filters?: FilterParam[],
}


export function ParnterIndex(props: Props,) {
    const [order, setOrder] = useState<OrderParam>({ column: "inserted_at", order: "asc" });
    const [filter, setFilter] = useState<FilterParam[]>([]);
    const intl = useIntl();
    return <div className="d-flex flex-column">
        <SearchBar filters={filter} avaliableFilters={[]}
            filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
            onFiltersChange={setFilter}
        />
        <SortableTableView
            key={JSON.stringify([...filter, ...(props.filters ?? [])])}
            perPage={8}
            fetchCollection={
                async (page, perPage) => {
                    // console.log(filter)
                    return fetchPaginatedCollection<any>(
                        `/api/v1/partner?sort=${order.column}&order=${order.order}${[...filter, ...(props.filters ?? [])].map(f => `&${f.column}=${f.value}`).join("")}`)
                        (page, perPage);
                }
            }
            onTap={
                (item: any) => {
                    // console.log(item)
                    if (props.onClick && typeof item != "string") {
                        props.onClick(item)
                    } else {
                        if (typeof item !== "string") history.push(`/partner/${item.companyName}`, item)
                    }
                }
            }
            translated
            itemsPreFormatter={(c: any) => {
                const cc: any = deepCopy(c);
                delete cc["condominiums"]
                delete cc["smtpConfig"]
                delete cc["config"]
                delete cc["id"]
                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "address",
                "cap",
                "city",
                "code",
                "company",
                "portal",
                "portal",
                "portal",
                "portal",
                "portal",
                "portal",
                "portal",
                "prov",
                "vat",
                "condominiums",
                "config",
                "logo",
                "name",
            ]}
        />
        <div className="d-flex flex-row-reverse">
            {!props.onClick && <PrimaryButton onClick={() => {
                history.push("/partner/new", null);
            }}>{intl.messages["add_partner"]}</PrimaryButton>}
        </div>
    </div>
}

const g = {
    "type": "generate_detail",
    "name_lower": "partner",
    "name_upper": "Partner",
    "output_file": "partner_detail.tsx",
    "model_shape": {
        "address": "string",
        "cap": "string",
        "city": "string",
        "code": "string",
        "companyName": "string",
        "portalAddress": "string",
        "portalErrorsColor": "string",
        "portalLogo": "string",
        "portalName": "string",
        "portalPrimaryColor": "string",
        "portalState": "string",
        "portalTextColor": "string",
        "prov": "string",
        "vat": "string"
    }
}
