import React, { useEffect, useMemo } from "react"
import { useState } from "react";
import styled from "styled-components";
import { Row } from "../../../components/barbagli/common";
import { TopTabView } from "../../../components/barbagli/top_tab_view";
import { TreeView } from "../../../components/barbagli/tree";
import { createCondominium, deleteCondominium, fetchCondominium, updateCondominium as rUpdateCondominium } from "../../../repsitory/barbagli/condominium_repository";
import { gray } from "../../../utils/colors";
import { CondominiumCreationButtons } from "./condominium_creation_buttons";
import { availableChildren, CondominiumViewState, deleteNode, emptyCondominiumNodeForType, emptyCondominiumViewState, hasChanges, moveUp, nodeForId, orderHashForCondominium, parentSubCondominiumId, parseCondominiumRoute, toTreeModel, updateCondominium, validateCondominium, } from "./condominium_view_model";
import { CondominiumDataView } from "./data_views/condominium_data_view";
import { ImmobileDataView } from "./data_views/immobile_data_view";
import { SubCondominiumDataView } from "./data_views/sub_condominium_data_view";
import { SelfReadingView } from "./self_reading_views/self_reading_view";
import { history } from "../../../index";
import { Condominium, CondominiumNode, CondominiumTab, emptyCondominium, isEnabled } from "../../../models/barbagli/condominium";
import { useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { MeterView } from "../meters_page/meter_view";
import { useLocation } from "react-router-dom";
import { deepCopy } from "../../../utils/deep_copy";
import { AddMeterView } from "./add_meter_view/add_meter_view";
import { fetchAssociatedResources, fetchAssociatedResourcesList } from "./meter_gateways_association_view_model";
import { AddGatewayView } from "./add_gateway_view/add_gateway_view";
import { MetersNoteView } from "./meters_note_view/meters_note_view";
import { MeterNoteView } from "./meters_note_view/meter_note_view";
import { GatewayView } from "../gateways_page/gateway_view";
import { ReadingPage } from "../readings_index/reading_page";
import { SelfReadingPage } from "../readings_index/self_reading_page";
import { ConsumptionsPage } from "../consumptions_index/consumptions_page";
import { ScaledP } from "../../../components/barbagli/scaled_text";
import { showAlert } from "../../../components/alerts/alert";
import { Attributes, Meter } from "../../../models/meter";
import { AlarmsPage } from "../alarms_index/alarm_page";
import { MeterStateTab } from "../meters_page/meter_state_tab/meter_state_tab";
import { MetersIndex } from "../meters_index/meters_index";
import { Gateway } from "models/gateway";
import * as vm from '../meters_page/meter_view_model';
import { MeterNotificationsPanel } from "../meters_page/meter_notifications/meter_notifications";

type Props = {
    //condominium: Condominium;
}

const CondominiumContainer = styled.div`
    flex-grow: 1;
    max-width: calc(100% - 290px);
`;

const CondominiumTreeContainer = styled.div`
    width: 240px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 2rem;
`

export function CondominiumView(props: Props) {
    const location = useLocation();
    const routeParams = useMemo(() => parseCondominiumRoute(location.pathname), [location.pathname]);
    const condominium: Condominium = (history?.location?.state as Condominium)?.id ? history?.location?.state as Condominium : emptyCondominium();
    const [state, setState] = useState<CondominiumViewState>(emptyCondominiumViewState(deepCopy(condominium)));
    const [selectedTab, setSelectedTab] = useState<CondominiumTab>("data");

    useEffect(() => {
        (async () => {
            // console.log("push push")
            if (routeParams.node_id && nodeForId(state.condominium, routeParams.node_id)) {
                state.selectedNode = nodeForId(state.condominium, routeParams.node_id)!;
                switch (state.selectedNode.type) {
                    case "CONDOMINIUM": state.expandedNodesIds = [state.condominium.node_id];
                    case "SUBCONDOMINIUM": state.expandedNodesIds = [routeParams.node_id, state.condominium.node_id]
                    case "IMMOBILE": state.expandedNodesIds = [routeParams.node_id, state.condominium.node_id, parentSubCondominiumId(state.selectedNode.node_id, state.condominium as Condominium)!]
                }

                setState(state)
            }
        })()

    }, [routeParams]);

    useEffect(() => {
        if (state.state === "show")
            fetchAssociatedResourcesList(condominium as Condominium, state.expandedNodesIds).then((nc) => {

                setState({ ...state, condominium: nc })

            })
    }, [state.state, routeParams]);

    const treeModel = useMemo(() => toTreeModel(state.condominium as Condominium), [state.state, JSON.stringify(state.condominium)]);

    const onExpand = useMemo(() => {
        return async (i: string, expanded: boolean, fromIcon: boolean) => {
            const sn = nodeForId(state.condominium, i);
            const status = (state.condominium as Condominium).status;
            let condominium_with_resources = state.condominium as Condominium;
            if (sn) {
                setState({
                    ...state,
                    selectedNode: sn,
                    expandedNodesIds: (expanded || !fromIcon) ? [...state.expandedNodesIds, i] : state.expandedNodesIds.filter((id) => id !== i)
                })
                condominium_with_resources = await fetchAssociatedResources(state.condominium as Condominium, sn);
            }
            if (sn) setState({
                ...state,
                condominium: { ...condominium_with_resources, status },
                selectedNode: sn,
                expandedNodesIds: (expanded || !fromIcon) ? [...state.expandedNodesIds, i] : state.expandedNodesIds.filter((id) => id !== i)
            })
        }
    }, [state.condominium, state.expandedNodesIds])

    const intl = useIntl();
    const alert = useAlert();

    if (state.state === "add_meter") return <AddMeterView state={state} setState={setState} intl={intl.messages as any} alert={alert}></AddMeterView>
    if (state.state === "add_gateway") return <AddGatewayView state={state} setState={setState} intl={intl.messages as any} alert={alert}></AddGatewayView>

    return <Row style={{ backgroundColor: "white", margin: "-30px" }}>
        <CondominiumContainer>
            <TopTabView
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                children={state.selectedNode.availableTabs.filter(value => {
                    const role = window.sessionStorage.getItem("user_type");
                    debugger
                    if (role === 'user' && (value === 'self_reading' || value === 'note')) return false;
                    return true
                }).map(t => [
                    t,
                    <CondominiumDetail
                        key={JSON.stringify(state.selectedNode.node_id)}
                        node={state.selectedNode}
                        active_tab={t}
                        alert={alert}
                        intl={intl}
                        condominium={state.condominium}
                        setCondominium={(c) => setState({ ...state, condominium: c, selectedNode: nodeForId(c, state.selectedNode.node_id)! })}
                    />
                ])}
            />
            {
                //<pre>{JSON.stringify(state.condominium, null, 2)}</pre>
            }
        </CondominiumContainer>
        <Divider></Divider>
        <CondominiumTreeContainer>
            <TreeView
                key={JSON.stringify(state.state) + JSON.stringify(state.condominium)}
                model={treeModel}
                expandedNodes={state.expandedNodesIds}
                onExpandClick={onExpand}
                rowBuilder={(i) => <CondominiumRow selected={state.selectedNode.node_id === i}>
                    {
                        <ScaledP
                            selected={state.selectedNode.node_id === i}
                            text={
                                (() => {
                                    const node = nodeForId(state.condominium, i) as any;

                                    switch (node.type) {
                                        case "METER":
                                            const m = (node as Meter);
                                            return `${m.attributes.type} ${m.attributes.serialNumber ?? m.attributes.mechanicalSerialNumber ?? m.attributes.id} ${m.attributes.meterHeatDivider?.room ?? ""}`
                                        case "GATEWAY": return `Centralina ${node.serial}`
                                        case "CONDOMINIUM": return (node?.data?.cond_name ?? "").replaceAll(" ", "") != "" ? node.data.cond_name : intl.messages["unnamed_CONDOMINIUM"]
                                        default: return (node?.data?.name ?? "").replaceAll(" ", "") != "" ? node.data.name : intl.messages["unnamed_" + node.type]
                                    }

                                })()
                            }
                        />

                    }</CondominiumRow>}
            />
            <CondominiumCreationButtons
                selectedTab={selectedTab}
                selectedNode={state.selectedNode}
                condominium={state.condominium as any}
                onCondominiumDeleted={async () => {
                    // @ts-ignore
                    const id = state.condominium.node_id;
                    if (id) {
                        // @ts-ignore
                        const r = await deleteCondominium(id);
                        if (typeof r !== "string") {
                            alert.show(intl.messages["operation_successfully_completed"])
                            history.push("/condominiums");
                            return true;
                        }
                        else {
                            alert.show(intl.messages["operation_error"])
                            return r;
                        }
                    }
                }}
                onSave={async () => {
                    debugger
                    let r;
                    const validation_result = validateCondominium(state.condominium, intl.messages as any);
                    if (validation_result.length > 0) {
                        const msg = validation_result.join("\n");
                        showAlert("Errore di validazione", msg)
                        return "Errore di validazione";
                    }



                    if (state.condominium.id) {
                        const a = orderHashForCondominium(condominium)
                        const b = orderHashForCondominium(state.condominium)
                        // Guardo l'ordine degli immobili nel condominio, se questo dovesse cambiare triggero il ricalcolo dei meter, se no no
                        r = await rUpdateCondominium(state.condominium as any, a !== b);
                    }
                    else r = await createCondominium(state.condominium as any);
                    if (typeof r !== "string") {
                        alert.show(intl.messages["operation_successfully_completed"])
                        setState({ ...state, condominium: r, selectedNode: r })
                        history.replace(`/condominiums/${r.data.cond_name ?? r.id}`, r)
                        return true;
                    }
                    else {
                        alert.show(intl.messages["operation_error"])
                        return r;
                    }
                }}
                availableWidgetTypes={availableChildren(state.selectedNode)}
                onWidgetSelected={(wt) => {

                    if ((wt === "METER" || wt === "GATEWAY") && hasChanges(state, condominium)) {
                        alert.error(intl.messages["pending_changes_cannot_assiciate"]);
                        return;
                    }

                    if (wt === "METER") {
                        setState({ ...state, state: "add_meter" })
                        return;
                    } else if (wt === "GATEWAY") {
                        setState({ ...state, state: "add_gateway" })
                        return;
                    }
                    const emptyNode = emptyCondominiumNodeForType(wt, intl.messages as any);
                    if (!emptyNode) return;
                    const newCondominium = updateCondominium(state.condominium, { ...state.selectedNode, children: [...state.selectedNode.children, emptyNode] });
                    if (newCondominium && emptyNode) setState({ ...state, condominium: newCondominium, selectedNode: emptyNode })
                }}
                onNodeDeleted={() => {
                    setState({ ...state, selectedNode: state.condominium, condominium: deleteNode(state.condominium, state.selectedNode)! });
                }}
            />
        </CondominiumTreeContainer>
    </Row>
}


const CondominiumRow = styled.button<{ selected: boolean }>`
    font-weight: ${(props) => props.selected ? 900 : 400};
    border: none;
    background-color: transparent;
    &:active {
        background-color: transparent;
    }
 `;

const Divider = styled.div`
    width: 1px;
    background-color: ${(_) => gray};
    margin-right: 2rem
 `


function CondominiumDetail(props: { node: CondominiumNode, active_tab: CondominiumTab, condominium: CondominiumNode, setCondominium: (c: CondominiumNode) => void, alert: any, intl: any }) {

    const intl = useIntl();
    const alert = useAlert();
    switch (props.active_tab) {

        case "data":
            switch (props.node.type) {
                case "CONDOMINIUM": return <CondominiumDataView
                    condominium={props.node}
                    onEdit={(c) => props.setCondominium(c)}
                    onActivation={async () => {
                        // @ts-ignore
                        const newState = props.condominium.status === "enabled" ? "disabled" : "enabled"
                        // @ts-ignore
                        let r = await rUpdateCondominium({ ...props.condominium, status: newState });
                        /* console.log(r); */
                        if (typeof r !== "string") {
                            props.alert.show(props.intl.messages["operation_successfully_completed"])
                            return true;
                        }
                        else {
                            props.alert.show(props.intl.messages["operation_error"])
                            return r;
                        }
                    }}
                />
                case "SUBCONDOMINIUM": return <SubCondominiumDataView
                    condominiumEnabled={isEnabled(props.condominium as Condominium)}
                    subcondominium={props.node}
                    moveUp={() => {
                        props.setCondominium(moveUp(props.condominium as Condominium, props.node.node_id))
                    }}
                    onEdit={(n) => props.setCondominium(updateCondominium(props.condominium, n)!)}
                />
                case "IMMOBILE": return <ImmobileDataView
                    condominiumEnabled={isEnabled(props.condominium as Condominium)}
                    immobile={props.node}
                    onEdit={(n) => props.setCondominium(updateCondominium(props.condominium, n)!)}
                    moveUp={() => {
                        const sc_id = parentSubCondominiumId(props.node.node_id, props.condominium as Condominium)
                        const imm_id = props.node.node_id;
                        props.setCondominium(moveUp(props.condominium as Condominium, sc_id!, imm_id))
                    }}
                />

                case "METER": return <MeterView disabled={(props.condominium as Condominium).status == "enabled"} embedded meter={props.node}></MeterView>
                case "GATEWAY": return <GatewayView disabled={(props.condominium as Condominium).status == "enabled"} embedded gateway={props.node}></GatewayView>
                default: return <pre>Pagina in costruzione</pre>
            }

        case "meter_whitelist": return <MetersIndex filters={[{ column: "gateway_identifier", value: (props.node as Gateway).identifier }]}></MetersIndex>

        case "readings": return <ReadingPage condominium={props.condominium as Condominium} selectedNode={props.node} />
        case "consumption": return <ConsumptionsPage condominium={props.condominium as Condominium} selectedNode={props.node} />
        case "self_reading": return <SelfReadingPage condominium={props.condominium as Condominium} selectedNode={props.node} />
        case "alarms": return <AlarmsPage condominium={props.condominium as Condominium} selectedNode={props.node} />
        case "note":
            if (props.node.type == "METER") {
                return <MeterNoteView condominium={props.condominium as Condominium} selectedNode={props.node} />
            } else { return <MetersNoteView condominium={props.condominium as Condominium} selectedNode={props.node} /> }

        case "state": if (props.node.type === "METER") return <MeterStateTab attributes={props.node.attributes} />; else return <pre>Pagina in costruzione</pre>
        case "notifications": if (props.node.type === "METER")

            return <MeterNotificationsPanel attributes={props.node.attributes} onMeterUpdate={async (a) => {

                const result = await vm.createEditMeterOnlyState({ ...(props.node as any).attributes, ...a })

                if (result) {
                    alert.show(intl.messages["update_success"]
                    )
                } else {
                    alert.error(intl.messages["operation_error"] + result)
                }
            }} />
        //return <SelfReadingView key={JSON.stringify(props.node)} node={props.node}></SelfReadingView>
        default: return <pre>Pagina in costruzione</pre>
    }
}
