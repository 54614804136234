import _ from "lodash";
import { Store } from "redux";
import store, { AppState } from "../store/store";

export function permissions(store: Store): Permission[] {
    const groups = userPermissions(store);
    return getPermissions(groups);
}

function userPermissions(store: Store): string[] {
    try {
        return (store.getState() as AppState).auth.permissions
    } catch(e) {
        console.error("Cannot get permissions",(store.getState() as AppState).auth.token ?? "", e)
        return [];
    }
}

export function userGroups(store: Store) : string[] {
    try {
        const token: string = (store.getState() as AppState).auth.token ?? ""
        return JSON.parse(atob(token.split(".")[1])).groups ?? []
    } catch(e) {
        console.error("Cannot get groups from token",(store.getState() as AppState).auth.token ?? "", e)
        return [];
    }
}

export type Action = "list" | "edit" | "delete"

export function can(action: Action, resource: {
    updateGroups: string[],
    deleteGroups: string[],
    listGroups: string[],
}) {
    // console.log(userGroups(store))
    switch(action) {
        case "list": return _.intersection(userGroups(store), resource.listGroups).length > 0; 
        case "delete": return _.intersection(userGroups(store), resource.deleteGroups).length > 0; 
        case "edit": return _.intersection(userGroups(store), resource.updateGroups).length > 0; 
    }
}

export type Permission = "admin" | "manage_meters" | "manage_gateways" | "dex" | "user";

function getPermissions(permissions: string[]): Permission[] {
    const roles = permissions.flatMap(g => {
        const pm = permssions_mapping[g];
        return pm;
        })
    return roles;
}
// Read: if user has permission on left then he can see things marked visible by things on the right
const permssions_mapping: Record<string, Permission[]> = {
"Index Device Type Meters": ["user"],
"List readings": ["user"],
"Manage Application": ["user", "admin", "manage_gateways"],
"Mange Users": ["user", "admin"],
"Mange User Groups": ["user", "admin"],
"Manage installer": ["user", "admin"],
"Manage Permission": ["user", "admin"],
"Mange Meter Groups": ["user", "manage_meters"],
"Mange Gateways": ["user", "manage_gateways"],
"Manage WebSocket": ["user"],
"Manage User Profile": ["user", "admin"],
"Manage Oban Worker": ["user", "admin"],
"Manage Oban Tasks": ["user", "admin"],
"Manage Meter Types": ["user",],
"Manage Meter Commands": ["user", ],
"Manage Maintenance": ["user","admin"],
"Manage jobs": ["user", ],
"Manage Job Configurations": ["user", ],
"Manage Hardware Types": ["user"],
"Manage Firmware": ["user", "admin", "manage_meters"],
"Manage Dashboard": ["user", "admin"],
"Manage Meters": ["user", "manage_meters"],
"Manage Backhauls": ["user", "manage_gateways"],
"Show Users": ["user"],
"Show User Groups": ["user"],
"Show Meter Groups": ["user"],
"Show Gateways": ["user"],
"Show User Profiles": ["user"],
"Show User Permissions": ["user"],
"Show Oban Workers": ["user",],
"Show Oban Tasks": ["user",],
"Show Meters": ["user"],
"Show Meter Types": ["user"],
"Show Meter Commands": ["user"],
"Show Jobs": ["user"],
"Show Job Configurations": ["user"],
"Show Hardware Types": ["user"],
"Show Firmwares": ["user"],
"Show User Dashboards": ["user"]
}
