import React, { useEffect, useMemo, useReducer } from "react";
import { Row } from "../../../../components/barbagli/common";
import { Attributes } from "../../../../models/meter";
import { Title } from "../../../../components/barbagli/text";
import { TextInput } from "../../../../components/barbagli/text_input";
import { useIntl } from "react-intl";
import * as vm from './dynamic_meter_fields_vm'
import { translate } from "utils/intl";

type Props = {
    attributes: Attributes
    onUpdate: (newState: Attributes) => void;
    intl: Record<string, string>
}

export function DynamicMeterFields(props: Props) {

    const [state, dispach] = useReducer(vm.reducer, vm.fromAttributes(props.attributes));
    useEffect(() => {
        props.onUpdate({...props.attributes, meta: {...props.attributes.meta, additional_fields: state}})
    }, [state])
    const translated = useMemo(() => translate(props.intl), [props.intl])

    return <> 
    <Title>{props.intl["additional_fields"]}</Title>

        <Row style={{ justifyContent: "space-beetween" }}>
            {Object.keys(state).map(key => {
                const property = state[key];
                if(!property) return <p>missing {key}</p>

                const converterIn = property.type === "string" ? (i: any) => i : (i: any) => i.toFixed(2).toString();
                const converterOut = property.type === "string" ? (i: any) => i : (i: any) => Number(i)

                return <TextInput
                key={key}
                enabled
                type={property.type == "string" ? "text" : "integer"}
                style={{ flexGrow: 1, marginRight: 8 }}
                label={translated(key)}
                value={converterIn(property.value)}
                onChange={(value) => { dispach({ type: "updateField", key, value: {...property, value: converterOut(value)} }) }}
            />
            })}
 
        </Row>
    </>
}

//01884041
export const tlrAdditionalFields: vm.DynamicMeterFieldState = {
    "liters_per_pulse": {
        "type": "number",
        "value": 10
    },
    "initial_value": {
        "type": "number",
        "value": 0
    }
}

