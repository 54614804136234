import React from "react"
import { CirclePicker } from "react-color";
import { PartnerCssConfig, partnerErrorColors, partnerPrimaryColors, partnerTextColor } from "models/barbagli/partner";
import { useIntl } from "react-intl";
import { Column, Row } from "components/barbagli/common";
import { H4 } from "components/barbagli/text";

type Props = {
    config: PartnerCssConfig,
    onChange: (config: PartnerCssConfig) => void
}

export function PartnerCssConfigWidget(props: Props) {
    const intl = useIntl();
    return <Column style={{height: 240}}>
    <Row style={{flexGrow: 1}}>
        <H4 style={{width: 80}}>{intl.messages["primary_color"] ?? "Primary Color"}</H4>
        <CirclePicker
            colors={partnerPrimaryColors}
            circleSize={24}
            color={props.config.primary_color}
        
            onChange={(c) => {
                props.onChange({ ...props.config, primary_color: c.hex })
            }} />

        </Row>
        <Row style={{flexGrow: 1}}>
        <H4 style={{width: 80}}>{intl.messages["text_color"] ?? "Text Color"}</H4>
        <CirclePicker
            colors={partnerTextColor}
            circleSize={24}
            color={props.config.text_color}
            onChange={(c) => {
                props.onChange({ ...props.config, text_color: c.hex })
            }} />
        </Row>

        <Row style={{flexGrow: 1}}>
        <H4 style={{width: 80}}>{intl.messages["error_color"] ?? "Error Color"}</H4>
        <CirclePicker
            colors={partnerErrorColors}
            circleSize={24}
            color={props.config.error_color}
            
            onChange={(c) => {
                props.onChange({ ...props.config, error_color: c.hex })
            }} />
        </Row>

    </Column>
}