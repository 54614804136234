import React, { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { LoaderComponent } from "../loading_widget";

export const Row = styled.div<any>`
display: flex;
flex-wrap: wrap;
align-items: ${(props: any) => props.center ? "center" : "default"};
`

export const Column = styled.div`
display: flex;
flex-direction: column`

export const OutlineButton = styled.button`
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(60, 75, 100, 0.77);
    color: #3C4B64;
    padding: 0.5rem 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 15px;
    text-align: center;
`

export const PrimaryButton = styled.button`
    background: #3C4B64;
    border-radius: 4px;
    color: white;
    border: 1px solid #3C4B64;
    padding: 0.5rem 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    transform: all 0.3s ease;
    text-align: center;`

export const LoaderButton = (props: {onClick: () => Promise<true | string>, children: any, disableSuccess?: boolean, style?: any, primary?: boolean}) => {
    const [state, setState] = useState<"loading" | "idle" | "success" | "error">("idle");
    const intl = useIntl();
    const primary = props.primary ?? true;
    const disableSuccess = props.disableSuccess ?? false;
    if(primary) return <PrimaryButton
        style={{
            backgroundColor: (() => {
                switch(state) {
                    case "error": return "red"
                    case "success": return "green"
                    default: return undefined
                }
            })(),
            ...(props.style ?? {})
        }}
        onClick={
            async (e) => {
                if(state !== "idle") return;
                setState("loading");
                const result = await props.onClick();
                if(typeof result === "string") setState("error");
                else if(!disableSuccess) setState("success");

                await new Promise(res => setTimeout(res, 5000));

                setState("idle");

            }
        }
    >
        {state === "idle" && props.children}
        {state === "loading" && <LoaderComponent color="white" width={32} height={32}></LoaderComponent>}
        {state !== "loading" && state !== "idle" && intl.messages[state]}
        </PrimaryButton>
        return <OutlineButton
        style={{
            backgroundColor: (() => {
                switch(state) {
                    case "error": return "red"
                    case "success": return "green"
                    default: return undefined
                }
            })(),
            ...(props.style ?? {})
        }}
        onClick={
            async (e) => {
                if(state !== "idle") return;
                setState("loading");
                const result = await props.onClick();
                if(typeof result === "string") setState("error");
                else if(!disableSuccess) setState("success");

                await new Promise(res => setTimeout(res, 5000));

                setState("idle");

            }
        }
    >
        {state === "idle" && props.children}
        {state === "loading" && <LoaderComponent color="blue" width={32} height={32}></LoaderComponent>}
        {state !== "loading" && state !== "idle" && intl.messages[state]}
        </OutlineButton>
}

export const FlatButton = styled.button`
    background-color: transparent;
    border: none;
    color: #3C4B64;`

export const IconButton = styled.button`
    border-radius: 4px;
    border: none;
    color: #3C4B64;
    font-style: normal;
    font-weight: normal;
    background-color: transparent;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
`